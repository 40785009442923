import React from "react";
import axios from "axios";
import * as ip from "ip";
import { Modal } from "antd";
import moment from "moment";
import { defineMessages, IntlProvider } from "react-intl";
import momentTimezone from "moment-timezone";
import { toJS, isObservable } from "mobx";
import TimezoneViewModel from "view-model/TimezoneViewModel";
import Twig from "twig";

const MessageData = require("../assets/messages/messages.json");

const TIME_DATE_FORMAT = "ddd MMM DD YYYY hh:mm:ss A";

const IOTIUM_USER = "a52f5dd2-fbbe-42c9-9e67-6036745ec85b";

const ROOT_ORG = "100a1f67-36ca-4a12-8abf-e7859ee3e677";

const DEFAULT_HEADERS_OPTIONS = [
  "Accept",
  "Accept-Charset",
  "Accept-Encoding",
  "Accept-Language",
  "Access-Control-Allow-Origin",
  "Access-Control-Request-Headers",
  "Access-Control-Request-Method",
  "Access-Control-Allow-Credentials",
  "Accept-Ranges",
  "Access-Control-Allow-Headers",
  "Access-Control-Allow-Methods",
  "Access-Control-Expose-Headers",
  "Allow",
  "Access-Control-Max-Age",
  "Timing-Allow-Origin",
  "Content-Location",
  "Forwarded",
  "Location",
  "Referrer-Policy",
  "Content-Range",
  "Cross-Origin-Embedder-Policy",
  "Cross-Origin-Opener-Policy",
  "Cross-Origin-Resource-Policy",
  "Content-Security-Policy-Report-Only",
  "Expect-CT",
  "Feature-Policy",
  "Strict-Transport-Security",
  "Upgrade-Insecure-Requests",
  "Public-Key-Pins",
  "Public-Key-Pins-Report-Only",
  "Sec-Fetch-Site",
  "Sec-Fetch-Mode",
  "Sec-Fetch-User",
  "Sec-Fetch-Dest",
  "Link",
  "Retry-After",
  "Server-Timing",
  "SourceMap",
  "Authorization",
  "Cache-Control",
  "Content-Disposition",
  "Content-MD5",
  "Content-Length",
  "Content-Security-Policy",
  "Content-Transfer-Encoding",
  "Content-Type",
  "Content-Encoding",
  "Content-Language",
  "Cookie",
  "Date",
  "Expect",
  "From",
  "Host",
  "If-Match",
  "If-Modified-Since",
  "If-None-Match",
  "If-Range",
  "If-Unmodified-Since",
  "Keep-Alive",
  "Max-Forwards",
  "Origin",
  "Proxy-Authorization",
  "Proxy-Authenticate",
  "Range",
  "Referer",
  "Set-Cookie",
  "TE",
  "Trailer",
  "Server",
  "Transfer-Encoding",
  "Upgrade",
  "User-Agent",
  "Via",
  "Warning",
  "DNT",
  "Connection",
  "WWW-Authenticate",
  "Age",
  "Clear-Site-Data",
  "Expires",
  "Pragma",
  "Downlink",
  "ECT",
  "RTT",
  "Last-Modified",
  "ETag",
  "Vary",
];

/**
 *  We should use the timezone, not the offset/abbreviation/aliases, so that day light saving can be easily calculated using tzdata.
 *  So the below list we didn't use as time zone.
 *  For references
 *  https://www.iana.org/time-zones
   * https://momentjs.com/timezone/docs/
   * http://www.timezoneconverter.com/cgi-bin/zoneinfo?tz=America/Los_Angeles

 *  https://www.joda.org/joda-time/timezones.html
 */
const RESTRICTED_TIME_ZONE = [
  "CET",
  "CST6CDT",
  "Cuba",
  "EET",
  "EST",
  "EST5EDT",
  "Egypt",
  "Eire",
  "Etc/GMT",
  "Etc/GMT+0",
  "Etc/GMT+1",
  "Etc/GMT+10",
  "Etc/GMT+11",
  "Etc/GMT+12",
  "Etc/GMT+2",
  "Etc/GMT+3",
  "Etc/GMT+4",
  "Etc/GMT+5",
  "Etc/GMT+6",
  "Etc/GMT+7",
  "Etc/GMT+8",
  "Etc/GMT+9",
  "Etc/GMT-0",
  "Etc/GMT-1",
  "Etc/GMT-10",
  "Etc/GMT-11",
  "Etc/GMT-12",
  "Etc/GMT-13",
  "Etc/GMT-14",
  "Etc/GMT-2",
  "Etc/GMT-3",
  "Etc/GMT-4",
  "Etc/GMT-5",
  "Etc/GMT-6",
  "Etc/GMT-7",
  "Etc/GMT-8",
  "Etc/GMT-9",
  "Etc/GMT0",
  "Etc/Greenwich",
  "Etc/UCT",
  "Etc/UTC",
  "Etc/Universal",
  "Etc/Zulu",
  "GB",
  "GB-Eire",
  "GMT",
  "GMT+0",
  "GMT-0",
  "GMT0",
  "Greenwich",
  "HST",
  "Hongkong",
  "Iceland",
  "Iran",
  "Iran",
  "Israel",
  "Israel",
  "Jamaica",
  "Japan",
  "Kwajalein",
  "Libya",
  "MET",
  "MST",
  "MST7MDT",
  "NZ",
  "NZ-CHAT",
  "Navajo",
  "PRC",
  "PST8PDT",
  "Poland",
  "Portugal",
  "ROC",
  "ROK",
  "Singapore",
  "Turkey",
  "UCT",
  "Universal",
  "W-SU",
  "WET",
  "Zulu",
];

const INTERFACE_DIAGRAM_AVAILABLE_HARDWARES = [
  "Edge_Gateway_5100",
  "UNO-2271G_V2",
  "UNO-2484G_V2",
  "UNO-2484G_V1",
  "PowerEdge_R240",
  "Edge_Gateway_5000",
  "PUB1245-100^LANNER-CSP1",
  "PUB5421-010^LANNER-CSP1",
  "UNO-2484G-734xAE",
];

let language = "en-US";
let languageWithoutRegionCode = "en";

if (typeof window !== "undefined") {
  language =
    (window.navigator.languages && window.navigator.languages[0]) ||
    window.navigator.language ||
    window.navigator.userLanguage;
  languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];

  var localeMessages = {};
}

const { intl } = new IntlProvider(
  {
    locale: "en",
    messages: {},
  },
  {},
).getChildContext();

const Timezones = getAvailableTimezones();

/**
 * Get the IP address in CSV format from node document. Will return N/A if not able to find.
 *
 * @param {Node} item Node document
 * @param {String} type public or private only allowd
 */
function getIP(item, type) {
  let pub = [];
  let pri = [];
  if (item && item.status && item.status.node && item.status.node.addresses) {
    item.status.node.addresses.map((addressItem, i) => {
      if (addressItem.type === "InternalIP") {
        pri.push(addressItem.address);
      } else {
        pub.push(addressItem.address);
      }
    });
    if (type === "public") {
      return pub.length > 0 ? pub.join(", ") : NOT_APPLICAPLE;
    } else {
      return pri.length > 0 ? pri.join(", ") : NOT_APPLICAPLE;
    }
  }
  return NOT_APPLICAPLE;
}

/**
 * This function will return version of inode from that given node document. Will return "N/A" if not able to find.
 *
 * @param {Node} item Node document
 */
function getNodeVersion(item) {
  if (
    item &&
    item.status &&
    item.status.node &&
    item.status.node.info &&
    item.status.node.info.version
  ) {
    return item.status.node.info.version.software_version;
  }

  return NOT_APPLICAPLE;
}
/**
 * This function will return that the node is master or not
 *
 * @param {Node} node Node document
 */
function isMasteriNode(node) {
  return;
  node &&
  node.status &&
  node.status.node &&
  node.status.node.node_cluster_status &&
  node.status.node.node_cluster_status.state &&
  node.status.node.node_cluster_status.state === "MASTER"
    ? true
    : false;
}
/**
 *
 * @param {Node} item Node document
 */

function getLastContactedAt(item) {
  if (
    item &&
    item.status &&
    item.status.node &&
    item.status.node.last_contacted_at &&
    nanoToDateString(item.status.node.last_contacted_at)
  ) {
    let lastContact = nanoToDateString(item.status.node.last_contacted_at);
    let currentDate = moment(new Date().getTime());
    let lastContactDate = moment(new Date(lastContact).getTime());
    return currentDate.diff(lastContactDate, "hours") < 24
      ? lastContactDate.fromNow()
      : `${moment(new Date(lastContact).getTime()).format(
          TIME_DATE_FORMAT,
        )} ${getTimezoneToDisplay(lastContact)}`;
  }

  return NOT_APPLICAPLE;
}

/**
 *
 * @param {Node} item Node document
 */
function getLastSSHAccess(item) {
  if (
    item &&
    item.status &&
    item.status.node &&
    item.status.node.last_ssh_login_at &&
    nanoToDateString(item.status.node.last_ssh_login_at)
  ) {
    let lastContact = nanoToDateString(item.status.node.last_ssh_login_at);
    let currentDate = moment(new Date().getTime());
    let lastContactDate = moment(new Date(lastContact).getTime());
    return currentDate.diff(lastContactDate, "hours") < 24
      ? lastContactDate.fromNow()
      : `${moment(new Date(lastContact).getTime()).format(
          TIME_DATE_FORMAT,
        )} ${getTimezoneToDisplay(lastContact)}`;
  }

  return NOT_APPLICAPLE;
}

/**
 *
 * @param {Node} item Node document
 */
function getLastConsoleAccess(item) {
  if (
    item &&
    item.status &&
    item.status.node &&
    item.status.node.last_console_login_at &&
    nanoToDateString(item.status.node.last_console_login_at)
  ) {
    let lastContact = nanoToDateString(item.status.node.last_console_login_at);
    let currentDate = moment(new Date().getTime());
    let lastContactDate = moment(new Date(lastContact).getTime());
    return currentDate.diff(lastContactDate, "hours") < 24
      ? lastContactDate.fromNow()
      : `${moment(new Date(lastContact).getTime()).format(
          TIME_DATE_FORMAT,
        )} ${getTimezoneToDisplay(lastContact)}`;
  }

  return NOT_APPLICAPLE;
}

/**
 *
 * @param {Long} nano Long of Instant object.
 */
function nanoToDateString(nano) {
  if (nano) {
    let date = new Date(nano);
    if (date) {
      let x = date.toString().split(" ");
      return x.join(" ");
    }
  }
  return null;
}
//activity timestamp conversion
function getLastActivityAt(item) {
  if (item && nanoToDateString(item)) {
    let lastContact = nanoToDateString(item);
    let currentDate = moment(new Date().getTime());
    let lastContactDate = moment(new Date(lastContact).getTime());
    return currentDate.diff(lastContactDate, "hours") < 24
      ? lastContactDate.fromNow()
      : `${moment(new Date(lastContact).getTime()).format(
          TIME_DATE_FORMAT,
        )} ${getTimezoneToDisplay(lastContact)}`;
  }
  return NOT_APPLICAPLE;
}

function milliToDateString(milli) {
  if (milli) {
    let date = momentTimezone(milli).format(TIME_DATE_FORMAT);
    return `${date} ${getTimezoneToDisplay(date)}`;
  }
  return null;
}

function isoToDateString(time) {
  if (time) {
    let lastContact = momentTimezone(time);
    let currentTZ = moment(time).tz();
    let currentDate = moment(new Date().getTime()).tz(currentTZ);
    let lastContactDate = moment(new Date(lastContact).getTime()).tz(currentTZ);
    return currentDate.diff(lastContactDate, "hours") < 24
      ? lastContactDate.fromNow()
      : `${moment(new Date(lastContact).getTime()).format(
          TIME_DATE_FORMAT,
        )} ${getTimezoneToDisplay(time)}`;
  }

  return NOT_APPLICAPLE;
}

function elapsedTimeString(time) {
  if (time) {
    let triggeredTime = momentTimezone(time);
    let currentTZ = moment(time).tz();
    let currentDate = moment(new Date().getTime()).tz(currentTZ);
    let lastContactDate = moment(new Date(triggeredTime).getTime()).tz(
      currentTZ,
    );
    if (currentDate.diff(lastContactDate, "seconds") < 60) {
      return "few seconds";
    } else if (currentDate.diff(lastContactDate, "minutes") === 1) {
      return "1 minute";
    } else if (
      currentDate.diff(lastContactDate, "minutes") > 1 &&
      currentDate.diff(lastContactDate, "minutes") < 59
    ) {
      return currentDate.diff(lastContactDate, "minutes") + " minutes";
    } else if (
      currentDate.diff(lastContactDate, "hours") >= 1 &&
      currentDate.diff(lastContactDate, "hours") < 24
    ) {
      return (
        PlotTimeDiff(time, 60, true).hours +
        " hours " +
        PlotTimeDiff(time, 60, true).minutes +
        " minutes"
      );
    } else {
      return (
        PlotTimeDiff(time, 60, true).days +
        " days " +
        PlotTimeDiff(time, 60, true).hours +
        " hours" +
        PlotTimeDiff(time, 60, true).minutes +
        " minutes"
      );
    }
  }

  return NOT_APPLICAPLE;
}

function toTimezone(milli, zone) {
  if (milli) {
    try {
      let date = momentTimezone(milli)
        .tz(zone)
        .format("MMM DD YYYY HH:mm:ss");
      return `${date}`;
    } catch (e) {
      return milli;
    }
  }
  return null;
}

function isLoggedIn(realTime = false) {
  new Promise((resolve, reject) => {
    if (realTime) {
      let currentUserReq = axios.get("/api/v1/user/current", {
        withCredentials: true,
      });
      currentUserReq
        .then(response => {
          resolve(true);
        })
        .catch(error => {
          reject(false);
        });
    } else {
      resolve(true);
    }
  });
}

//Process value given in CIDR field and mark it as empty if both IP Address and mask Lenth not there
function makeEmpty(value) {
  if (value) {
    let values = value.split("/");

    if (values.length > 0) {
      let isNull = true;
      for (let index = 0; index < values.length; index++) {
        if (values[index].length > 0) {
          isNull = false;
        }
      }
      if (isNull === true) {
        value = "";
      }
    }
  }
  return value;
}
function isCidr(value, allowSubnetMaskLength = true) {
  if (value) {
    if (!CIDRV4REGEX.test(value)) {
      return false;
    }
    let subnetInfo = null;
    try {
      subnetInfo = ip.cidrSubnet(value);
    } catch (e) {
      return false;
    }
    if (allowSubnetMaskLength && subnetInfo.subnetMaskLength === 32) {
      return false;
    }
    return true;
  }
  return false;
}

function isTanCidrRange(cidr, tan_cidr) {
  if (cidr && tan_cidr) {
    let cidrSubnet = ip.cidrSubnet(cidr);
    let tan_cidr_Subnet = ip.cidrSubnet(tan_cidr);
    if (cidrSubnet && tan_cidr_Subnet) {
      let tanIP = tan_cidr.split("/")[0];
      if (
        cidrSubnet.networkAddress == tan_cidr_Subnet.networkAddress &&
        isCidrRange(cidr, tanIP)
      ) {
        return true;
      }
      return false;
    }
    return false;
  }
  return false;
}
function isIPRangeOverlaps(firstAddress, lastAddress, ip_addr) {
  if (firstAddress && lastAddress && ip_addr) {
    if (
      ip.isV4Format(firstAddress) &&
      ip.isV4Format(lastAddress) &&
      ip.isV4Format(ip_addr)
    ) {
      let start = ip.toLong(firstAddress),
        end = ip.toLong(lastAddress),
        ip_address = ip.toLong(ip_addr);
      if (ip_address >= start && ip_address <= end) {
        return true;
      }
      return false;
    }
    return false;
  }
  return false;
}

function isCidrRange(cidr, ip_addr) {
  if (cidr && ip_addr) {
    let cidrSubnet = ip.cidrSubnet(cidr);
    if (cidrSubnet && ip.isV4Format(ip_addr)) {
      let start = ip.toLong(cidrSubnet.firstAddress),
        end = ip.toLong(cidrSubnet.lastAddress),
        ip_address = ip.toLong(ip_addr);
      if (ip_address >= start && ip_address <= end) {
        return true;
      }
      return false;
    }
    return false;
  }
  return false;
}

// get the CIDR details
function getCIDRdata(value, allowSubnetMaskLength = true) {
  if (value) {
    let subnetInfo = null;
    try {
      subnetInfo = ip.cidrSubnet(value);
    } catch (e) {}
    return subnetInfo;
  }
  return value;
}

function getIPListFromCidr(startIP, endIP, limit) {
  const startLong = ip.toLong(startIP);
  const endLong = ip.toLong(endIP);
  const ipRange = [];
  if (ip.isV4Format(startIP) && ip.isV4Format(startIP)) {
    for (let i = startLong; i <= endLong; i++) {
      ipRange.push(ip.fromLong(i));
      if (limit && ipRange.length >= limit) break;
    }
  }

  return ipRange;
}

/**
 * Function to check the given CIDR Ip address within the reserved (restricted) IP address range or not
 *
 * Logic :
 * Check the given CIDR first or last IP address with in the range of restricted CIDR first & last IP address,
 * Or check the restricted CIDR first or last IP address with in the range of given CIDR first and last address IP.
 *
 * @param CIDR
 */
function isRestrictedCIDR(CIDR) {
  let restrictedCIDR = [];
  let restrictedCIDRs = [
    "127.0.0.0/8",
    "255.0.0.0/8",
    "0.0.0.0/8",
    "169.254.0.0/16",
    "224.0.0.0/4",
    "172.17.0.0/24",
    "172.18.0.0/24",
    "192.168.192.0/24",
    "253.0.0.0/8",
  ];
  // Check conflicts or not
  restrictedCIDRs.forEach(element => {
    restrictedCIDR.push(isCidrRange(element, CIDR.firstAddress));
    restrictedCIDR.push(isCidrRange(element, CIDR.lastAddress));
    let elementCidr = getCIDRdata(element);
    let cidr = CIDR.networkAddress + "/" + CIDR.subnetMaskLength;
    restrictedCIDR.push(isCidrRange(cidr, elementCidr.firstAddress));
    restrictedCIDR.push(isCidrRange(cidr, elementCidr.lastAddress));
  });
  if (restrictedCIDR.includes(true)) return true;
  else return false;
}
const CIDRV4REGEX = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(\/([0-9]|[1-2][0-9]|3[0-2]))$/;
const NOT_APPLICAPLE = "N/A";
const DETAULT_DOCUMENT_TITLE = "Neeve Secure Edge";
const IPADDRESSREGEX = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;

function randomAlphaNumericString(length = 0) {
  let result = "",
    chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  for (let i = length; i > 0; --i)
    result += chars[Math.round(Math.random() * (chars.length - 1))];
  return result;
}

function focusFirstInput() {
  setTimeout(() => {
    if (document.querySelector(".firstInput input")) {
      document.querySelector(".firstInput input").focus();
    } else if (document.querySelector(".firstInput")) {
      document.querySelector(".firstInput").focus();
    }
  }, 500);
}
// this function returns the formated data for given string with given locale refer LAT-3448 for meore details

function getCurrentlocaleText(textId, dynValues = {}) {
  //this will define messages relates to locale
  let localeData = MessageData[language]
    ? MessageData[language][textId]
      ? MessageData[language][textId]
      : MessageData["en-US"][textId]
    : MessageData["en-US"][textId];

  let messages = defineMessages({
    data: {
      id: textId,
      defaultMessage: localeData.defaultMessage,
    },
  });
  return intl.formatMessage(messages.data, dynValues);
}

function highlightSearchText(text, searchText, color) {
  if (text && searchText) {
    if (text.toLowerCase().indexOf(searchText.toLowerCase()) !== -1) {
      let first = "",
        middle = "",
        last = "";
      let firstIndex = text.toLowerCase().indexOf(searchText.toLowerCase());
      let lastIndex = firstIndex + searchText.length;
      if (firstIndex != 0) {
        first = text.substring(0, firstIndex);
        middle = text.substring(firstIndex, lastIndex);
        last = text.substring(lastIndex);
      } else {
        middle = text.substring(firstIndex, lastIndex);
        last = text.substring(lastIndex);
      }
      return (
        <span>
          {first}
          <strong style={color ? { color: color } : {}}>{middle}</strong>
          {last}
        </span>
      );
    }
    return text;
  }
  return text;
}

function customSort(a, b) {
  let x = a && a.toUpperCase(), // For Case Insensitive sort
    y = b && b.toUpperCase();

  if (x < y) {
    return -1;
  }

  if (x > y) {
    return 1;
  }
  return 0;
}

function isEllipsisActive(e) {
  try {
    return e.offsetWidth < e.scrollWidth;
  } catch (e) {
    return 0;
  }
}

function notificationModal(type, content, cb, doLogout = false) {
  /*Default antd notification modal*/
  const modal = Modal[type]({
    title: content.title,
    className: content.className ? content.className : "",
    keyboard: content.keyboard ? content.keyboard : "",
    content: (
      <div>
        <h3>{content.data}</h3>
      </div>
    ),
    onOk() {
      cb ? cb(modal, doLogout) : "";
    },
  });
}

function capitalize(str) {
  return str ? str.charAt(0).toUpperCase() + str.toLowerCase().slice(1) : str;
}

function ipAddressSort(a, b) {
  let x = a.split("."),
    y = b.split(".");
  for (let i = 0; i < x.length; i++) {
    if (parseInt(x[i]) < parseInt(y[i])) {
      return -1;
    } else if (parseInt(x[i]) > parseInt(y[i])) {
      return 1;
    }
  }
  return 0;
}

function getGaTitle(pathname, AuthStore, orgName) {
  let gaTitle = DETAULT_DOCUMENT_TITLE;
  if (orgName) {
    gaTitle = orgName + " Secure Edge";
  }
  if (pathname) {
    let path = pathname.split("/");
    if (path[1] === "home" && !path[2] && !path[3] && !path[4] && !path[5]) {
      gaTitle = getCurrentlocaleText("home.navigation_menu.text.gatitle");
    } else if (
      path[1] === "dashboard" &&
      !path[2] &&
      !path[3] &&
      !path[4] &&
      !path[5]
    ) {
      gaTitle = getCurrentlocaleText("dashboard.navigation_menu.text.gatitle");
    } else if (path[1] === "dashboard" && path[3] === "orgs") {
      gaTitle = getCurrentlocaleText("dashboard.navigation_menu.orgs.gatitle");
    } else if (path[1] === "dashboard" && path[3] === "inodes") {
      gaTitle = getCurrentlocaleText(
        "dashboard.navigation_menu.inodes.gatitle",
      );
    } else if (path[1] === "dashboard" && path[3] === "networks") {
      gaTitle = getCurrentlocaleText(
        "dashboard.navigation_menu.networks.gatitle",
      );
    } else if (path[1] === "dashboard" && path[3] === "services") {
      gaTitle = getCurrentlocaleText(
        "dashboard.navigation_menu.services.gatitle",
      );
    } else if (path[1] === "dashboard" && path[3] === "users") {
      gaTitle = getCurrentlocaleText("dashboard.navigation_menu.users.gatitle");
    } else if (path[1] === "dashboard" && path[3] === "clusters") {
      gaTitle = getCurrentlocaleText(
        "dashboard.navigation_menu.clusters.gatitle",
      );
    } else if (
      path[1] === "dashboard" &&
      path[2] === "inodes" &&
      path[4] === "profile" &&
      path[5] === "virtual" &&
      path[6] === "status" &&
      path[7] === "new"
    ) {
      gaTitle = getCurrentlocaleText(
        "dashboard.navigation_menu.virtual.status.new.gatitle",
      );
    } else if (
      path[1] === "dashboard" &&
      path[2] === "inodes" &&
      path[4] === "profile" &&
      path[5] === "virtual" &&
      path[6] === "status" &&
      path[7] === "alive"
    ) {
      gaTitle = getCurrentlocaleText(
        "dashboard.navigation_menu.virtual.status.alive.gatitle",
      );
    } else if (
      path[1] === "dashboard" &&
      path[2] === "inodes" &&
      path[4] === "profile" &&
      path[5] === "virtual" &&
      path[6] === "status" &&
      path[7] === "unreachable"
    ) {
      gaTitle = getCurrentlocaleText(
        "dashboard.navigation_menu.virtual.status.unreachable.gatitle",
      );
    } else if (
      path[1] === "dashboard" &&
      path[2] === "inodes" &&
      path[4] === "profile" &&
      path[5] === "edge" &&
      path[6] === "status" &&
      path[7] === "new"
    ) {
      gaTitle = getCurrentlocaleText(
        "dashboard.navigation_menu.edge.status.new.gatitle",
      );
    } else if (
      path[1] === "dashboard" &&
      path[2] === "inodes" &&
      path[4] === "profile" &&
      path[5] === "edge" &&
      path[6] === "status" &&
      path[7] === "alive"
    ) {
      gaTitle = getCurrentlocaleText(
        "dashboard.navigation_menu.edge.status.alive.gatitle",
      );
    } else if (
      path[1] === "dashboard" &&
      path[2] === "inodes" &&
      path[4] === "profile" &&
      path[5] === "edge" &&
      path[6] === "status" &&
      path[7] === "unreachable"
    ) {
      gaTitle = getCurrentlocaleText(
        "dashboard.navigation_menu.edge.status.unreachable.gatitle",
      );
    } else if (
      path[1] === "dashboard" &&
      path[2] === "inodes" &&
      path[4] === "profile" &&
      path[5] === "virtualedge" &&
      path[6] === "status" &&
      path[7] === "new"
    ) {
      gaTitle = getCurrentlocaleText(
        "dashboard.navigation_menu.virtualedge.status.new.gatitle",
      );
    } else if (
      path[1] === "dashboard" &&
      path[2] === "inodes" &&
      path[4] === "profile" &&
      path[5] === "virtualedge" &&
      path[6] === "status" &&
      path[7] === "alive"
    ) {
      gaTitle = getCurrentlocaleText(
        "dashboard.navigation_menu.virtualedge.status.alive.gatitle",
      );
    } else if (
      path[1] === "dashboard" &&
      path[2] === "inodes" &&
      path[4] === "profile" &&
      path[5] === "virtualedge" &&
      path[6] === "status" &&
      path[7] === "unreachable"
    ) {
      gaTitle = getCurrentlocaleText(
        "dashboard.navigation_menu.virtualedge.status.unreachable.gatitle",
      );
    } else if (
      path[1] === "dashboard" &&
      path[2] === "inodes" &&
      path[4] === "status" &&
      path[5] === "alive"
    ) {
      gaTitle = getCurrentlocaleText(
        "dashboard.navigation_menu.inodes.alive.gatitle",
      );
    } else if (
      path[1] === "dashboard" &&
      path[2] === "inodes" &&
      path[4] === "status" &&
      path[5] === "new"
    ) {
      gaTitle = getCurrentlocaleText(
        "dashboard.navigation_menu.inodes.new.gatitle",
      );
    } else if (path[1] === "orgs" && path[3] === "orgs") {
      gaTitle = getCurrentlocaleText("orgs.navigation_menu.text.orgs.gatitle");
    } else if (
      path[1] === "orgs" &&
      !path[2] &&
      !path[3] &&
      !path[4] &&
      !path[5]
    ) {
      gaTitle = getCurrentlocaleText("orgs.navigation_menu.text.gatitle");
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      path[3] === "inodes" &&
      path[4] &&
      !path[5]
    ) {
      gaTitle = getCurrentlocaleText("document.title.text.case_1.gatitle");
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      path[3] === "networks" &&
      path[4] &&
      !path[5]
    ) {
      gaTitle = getCurrentlocaleText("document.title.text.networks.gatitle");
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      path[3] === "inodes" &&
      path[4] &&
      path[5] == "modify"
    ) {
      gaTitle = getCurrentlocaleText("document.title.text.case_2.gatitle");
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      path[3] === "inodes" &&
      path[4] &&
      path[5] == "networks" &&
      path[6] == "modify"
    ) {
      gaTitle = getCurrentlocaleText("document.title.text.case_2.gatitle");
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      path[3] === "inodes" &&
      path[4] &&
      path[5] == "networks"
    ) {
      gaTitle = getCurrentlocaleText("document.title.text.case_3.gatitle");
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      path[3] === "inodes" &&
      path[4] &&
      path[5] == "services"
    ) {
      gaTitle = getCurrentlocaleText("document.title.text.case_4.gatitle");
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      path[3] === "inodes" &&
      path[4] &&
      path[5] == "wanfirewall"
    ) {
      gaTitle = getCurrentlocaleText("document.title.text.case_5.gatitle");
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      path[3] === "inodes" &&
      path[4] &&
      path[5] == "events"
    ) {
      gaTitle = getCurrentlocaleText("document.title.text.case_6.gatitle");
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      !path[3] &&
      !path[4] &&
      !path[5]
    ) {
      gaTitle = getCurrentlocaleText("document.title.text.case_7.gatitle");
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      path[3] === "inodes" &&
      !path[4] &&
      !path[5]
    ) {
      gaTitle = getCurrentlocaleText("document.title.text.case_8.gatitle");
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      path[3] === "inodes" &&
      path[4] === "bulkconfig"
    ) {
      gaTitle = getCurrentlocaleText(
        "document.title.inodes.bulkconfig.gatitle",
      );
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      path[3] === "networks" &&
      path[4] === "bulkconfig"
    ) {
      gaTitle = getCurrentlocaleText(
        "document.title.networks.bulkconfig.gatitle",
      );
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      path[3] === "users" &&
      path[4] === "bulkconfig"
    ) {
      gaTitle = getCurrentlocaleText("document.title.users.bulkconfig.gatitle");
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      path[3] === "inodes" &&
      path[4] === "bulkconfig" &&
      path[5] === "upload"
    ) {
      gaTitle = getCurrentlocaleText(
        "document.title.inodes.bulkconfig.upload.gatitle",
      );
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      path[3] === "networks" &&
      path[4] === "bulkconfig" &&
      path[5] === "upload"
    ) {
      gaTitle = getCurrentlocaleText(
        "document.title.networks.bulkconfig.upload.gatitle",
      );
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      path[3] === "users" &&
      path[4] === "bulkconfig" &&
      path[5] === "upload"
    ) {
      gaTitle = getCurrentlocaleText(
        "document.title.users.bulkconfig.upload.gatitle",
      );
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      path[3] === "inodes" &&
      path[4] === "bulkconfig" &&
      path[5] === "view-history"
    ) {
      gaTitle = getCurrentlocaleText(
        "document.title.inodes.bulkconfig.viewhistory.gatitle",
      );
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      path[3] === "networks" &&
      path[4] === "bulkconfig" &&
      path[5] === "view-history"
    ) {
      gaTitle = getCurrentlocaleText(
        "document.title.networks.bulkconfig.viewhistory.gatitle",
      );
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      path[3] === "users" &&
      path[4] === "bulkconfig" &&
      path[5] === "view-history"
    ) {
      gaTitle = getCurrentlocaleText(
        "document.title.users.bulkconfig.viewhistory.gatitle",
      );
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      path[3] === "users" &&
      !path[4] &&
      !path[5]
    ) {
      gaTitle = getCurrentlocaleText("document.title.text.case_9.gatitle");
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      path[3] === "roles" &&
      !path[4] &&
      !path[5]
    ) {
      gaTitle = getCurrentlocaleText("document.title.text.case_10.gatitle");
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      path[3] === "serials" &&
      !path[4] &&
      !path[5]
    ) {
      gaTitle = getCurrentlocaleText("document.title.text.case_11.gatitle");
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      path[3] === "certificates" &&
      !path[4] &&
      !path[5]
    ) {
      gaTitle = getCurrentlocaleText(
        "document.title.text.certificates.gatitle",
      );
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      path[3] === "licenses" &&
      !path[4] &&
      !path[5]
    ) {
      gaTitle = getCurrentlocaleText("document.title.text.case_12.gatitle");
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      path[3] === "securitypolicies" &&
      !path[4] &&
      !path[5]
    ) {
      gaTitle = getCurrentlocaleText("document.title.text.case_13.gatitle");
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      path[3] === "orgs" &&
      !path[4] &&
      !path[5]
    ) {
      gaTitle = getCurrentlocaleText("document.title.text.case_14.gatitle");
    } else if (
      path[1] === "inodes" &&
      !path[2] &&
      !path[3] &&
      !path[4] &&
      !path[5]
    ) {
      gaTitle = getCurrentlocaleText("orgs.details.tabs.inodes.text.gatitle");
    } else if (
      path[1] === "inodes" &&
      path[2] &&
      !path[3] &&
      !path[4] &&
      !path[5]
    ) {
      gaTitle = `iNodes`;
    } else if (
      path[1] === "inodes" &&
      path[2] &&
      path[3] === "modify" &&
      !path[4] &&
      !path[5]
    ) {
      gaTitle = getCurrentlocaleText("document.title.text.case_15.gatitle");
    } else if (
      path[1] === "inodes" &&
      path[2] &&
      path[3] === "networks" &&
      path[4] === "modify" &&
      !path[5]
    ) {
      gaTitle = getCurrentlocaleText("document.title.text.case_15.gatitle");
    } else if (
      path[1] === "inodes" &&
      path[2] &&
      path[3] === "networks" &&
      !path[4] &&
      !path[5]
    ) {
      gaTitle = getCurrentlocaleText("document.title.text.case_16.gatitle");
    } else if (
      path[1] === "inodes" &&
      path[2] &&
      path[3] === "services" &&
      !path[4] &&
      !path[5]
    ) {
      gaTitle = getCurrentlocaleText("document.title.text.case_17.gatitle");
    } else if (
      path[1] === "inodes" &&
      path[2] &&
      path[3] === "wanfirewall" &&
      !path[4] &&
      !path[5]
    ) {
      gaTitle = getCurrentlocaleText("document.title.text.case_18.gatitle");
    } else if (
      path[1] === "inodes" &&
      path[2] &&
      path[3] === "events" &&
      !path[4] &&
      !path[5]
    ) {
      gaTitle = getCurrentlocaleText("document.title.text.case_19.gatitle");
    } else if (
      path[1] === "users" &&
      !path[2] &&
      !path[3] &&
      !path[4] &&
      !path[5]
    ) {
      gaTitle = getCurrentlocaleText("users.navigation_menu.text.gatitle");
    } else if (
      path[1] === "roles" &&
      !path[2] &&
      !path[3] &&
      !path[4] &&
      !path[5]
    ) {
      gaTitle = getCurrentlocaleText("general.roles.label.text.gatitle");
    } else if (
      path[1] === "keys" &&
      !path[2] &&
      !path[3] &&
      !path[4] &&
      !path[5]
    ) {
      gaTitle = getCurrentlocaleText("general.keys.label.text.gatitle");
    } else if (
      path[1] === "help" &&
      path[2] === "kb" &&
      !path[3] &&
      !path[4] &&
      !path[5]
    ) {
      gaTitle = getCurrentlocaleText("kb.navigation_menu.text.gatitle");
    } else if (path[1] === "settings") {
      gaTitle = getCurrentlocaleText("settings.navigation_menu.text.gatitle");
    } else if (path[1] === "logout") {
      gaTitle = getCurrentlocaleText("logout.navigation_menu.text.gatitle");
    } else if (path[1] === "login") {
      gaTitle = getCurrentlocaleText("login.navigation_menu.text.gatitle");
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      path[3] === "activity" &&
      path[4] === "downloads"
    ) {
      gaTitle = getCurrentlocaleText("downloads.navigation_menu.text.gatitle");
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      path[3] === "event" &&
      path[4] === "downloads"
    ) {
      gaTitle = getCurrentlocaleText("event.navigation_menu.text.gatitle");
    } else if (
      path[1] === "help" &&
      path[2] === "releases" &&
      !path[3] &&
      !path[4] &&
      !path[5]
    ) {
      gaTitle = getCurrentlocaleText("release.navigation_menu.text.gatitle");
    } else if (
      path[1] === "profile" &&
      !path[2] &&
      !path[3] &&
      !path[4] &&
      !path[5]
    ) {
      gaTitle = getCurrentlocaleText("myprofile.text.gatitle");
    } else if (
      path[1] === "managealerts" &&
      !path[2] &&
      !path[3] &&
      !path[4] &&
      !path[5]
    ) {
      gaTitle = getCurrentlocaleText("manage_alerts.text.gatitle");
    } else if (
      path[1] === "managealerts" &&
      path[2] === "add" &&
      !path[3] &&
      !path[4] &&
      !path[5]
    ) {
      gaTitle = `${getCurrentlocaleText(
        "managealerts.addalert.text.gatitle",
      )} - ${getCurrentlocaleText("manage_alerts.text.gatitle")}`;
    } else if (
      path[1] === "events" &&
      !path[2] &&
      !path[3] &&
      !path[4] &&
      !path[5]
    ) {
      gaTitle = `Events`;
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      path[3] === "branding" &&
      !path[4] &&
      !path[5]
    ) {
      gaTitle = getCurrentlocaleText("branding.text.gatitle");
    } else if (
      path[1] === "dashboard" &&
      path[2] &&
      path[3] === "branding" &&
      !path[4] &&
      !path[5]
    ) {
      gaTitle = getCurrentlocaleText("branding.text.gatitle");
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      path[3] === "activities" &&
      !path[4] &&
      !path[5]
    ) {
      gaTitle = getCurrentlocaleText("document.title.text.case_20.gatitle");
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      path[3] === "clusters" &&
      !path[4] &&
      !path[5]
    ) {
      gaTitle = getCurrentlocaleText("document.title.text.case_21.gatitle");
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      path[3] === "clusters" &&
      path[4] === "edit"
    ) {
      gaTitle = getCurrentlocaleText("document.title.text.case_22.gatitle");
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      path[3] === "clusters" &&
      path[4] === "view"
    ) {
      gaTitle = getCurrentlocaleText("document.title.text.case_23.gatitle");
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      path[3] === "clusters" &&
      path[4] &&
      path[5] === "modify"
    ) {
      gaTitle = getCurrentlocaleText("document.title.text.modify.gatitle");
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      path[3] === "clusters" &&
      path[4] != "view" &&
      path[4] != "edit"
    ) {
      gaTitle = getCurrentlocaleText("document.title.text.case_24.gatitle");
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      path[3] === "servicelistener" &&
      !path[4] &&
      !path[5]
    ) {
      gaTitle = getCurrentlocaleText("document.title.text.case_25.gatitle");
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      path[3] === "orgs" &&
      path[4] === "create"
    ) {
      gaTitle = getCurrentlocaleText("document.title.text.case_27.gatitle");
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      path[3] === "orgs" &&
      path[4] &&
      path[5] === "edit"
    ) {
      gaTitle = getCurrentlocaleText("document.title.text.case_28.gatitle");
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      path[3] === "orgs" &&
      path[4] &&
      path[5] === "view"
    ) {
      gaTitle = getCurrentlocaleText("document.title.text.case_29.gatitle");
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      path[3] === "inodes" &&
      path[4] === "create"
    ) {
      gaTitle = getCurrentlocaleText("document.title.text.case_30.gatitle");
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      path[3] === "inodes" &&
      path[4] &&
      path[5] === "edit"
    ) {
      gaTitle = getCurrentlocaleText("document.title.text.case_31.gatitle");
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      path[3] === "inodes" &&
      path[4] &&
      path[5] === "view"
    ) {
      gaTitle = getCurrentlocaleText("document.title.text.case_32.gatitle");
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      path[3] === "securitypolicies" &&
      path[4] === "create"
    ) {
      gaTitle = getCurrentlocaleText("document.title.text.case_33.gatitle");
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      path[3] === "securitypolicies" &&
      path[4] &&
      path[5] === "edit"
    ) {
      gaTitle = getCurrentlocaleText("document.title.text.case_34.gatitle");
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      path[3] === "securitypolicies" &&
      path[4] &&
      path[5] === "view"
    ) {
      gaTitle = getCurrentlocaleText("document.title.text.case_35.gatitle");
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      path[3] === "users" &&
      path[4] === "create"
    ) {
      gaTitle = getCurrentlocaleText("document.title.text.case_36.gatitle");
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      path[3] === "users" &&
      path[4] &&
      path[5] === "edit"
    ) {
      gaTitle = getCurrentlocaleText("document.title.text.case_37.gatitle");
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      path[3] === "roles" &&
      path[4] === "create"
    ) {
      gaTitle = getCurrentlocaleText("document.title.text.case_38.gatitle");
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      path[3] === "roles" &&
      path[4] &&
      path[5] === "edit"
    ) {
      gaTitle = getCurrentlocaleText("document.title.text.case_39.gatitle");
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      path[3] === "roles" &&
      path[4] &&
      path[5] === "view"
    ) {
      gaTitle = getCurrentlocaleText("document.title.text.case_40.gatitle");
    } else {
      if (AuthStore.IsPermitted("SUPER:ADMIN")) {
        gaTitle = getCurrentlocaleText(
          "dashboard.navigation_menu.text.gatitle",
        );
      } else if (AuthStore.IsPermitted("ORG:VIEW")) {
        gaTitle = getCurrentlocaleText("orgs.navigation_menu.text.gatitle");
      } else if (AuthStore.IsPermitted("NODE:VIEW")) {
        gaTitle = getCurrentlocaleText("orgs.details.tabs.inodes.text.gatitle");
      } else if (AuthStore.IsPermitted("USER:VIEW")) {
        gaTitle = getCurrentlocaleText(
          "dashboard.counts.users.tooltip.gatitle",
        );
      }
    }
  }
  return gaTitle;
}

function getDocumentTitle(pathname, AuthStore, orgName) {
  let docTitle = DETAULT_DOCUMENT_TITLE;
  if (orgName) {
    docTitle = orgName + " Secure Edge";
  }
  if (pathname) {
    let path = pathname.split("/");
    if (
      path[1] === "dashboard" &&
      !path[2] &&
      !path[3] &&
      !path[4] &&
      !path[5]
    ) {
      docTitle = getCurrentlocaleText("dashboard.navigation_menu.text");
    } else if (
      path[1] === "keys" &&
      !path[2] &&
      !path[3] &&
      !path[4] &&
      !path[5]
    ) {
      docTitle = getCurrentlocaleText("document.title.text.case_1_1");
    } else if (
      path[1] === "orgs" &&
      !path[2] &&
      !path[3] &&
      !path[4] &&
      !path[5]
    ) {
      docTitle = getCurrentlocaleText("orgs.navigation_menu.text");
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      path[3] === "inodes" &&
      path[4] &&
      !path[5]
    ) {
      docTitle = getCurrentlocaleText("document.title.text.case_1");
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      path[3] === "inodes" &&
      path[4] &&
      path[5] == "modify"
    ) {
      docTitle = getCurrentlocaleText("document.title.text.case_2");
    } else if (path[1] === "orgs" && path[2] && path[3] === "networks") {
      docTitle = getCurrentlocaleText("document.title.text.case_2_1");
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      path[3] === "certificates" &&
      !path[4] &&
      !path[5]
    ) {
      docTitle = getCurrentlocaleText("document.title.text.certificates");
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      path[3] === "inodes" &&
      path[4] &&
      path[5] == "networks" &&
      path[6] == "modify"
    ) {
      docTitle = getCurrentlocaleText("document.title.text.case_2");
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      path[3] === "inodes" &&
      path[4] &&
      path[5] == "networks"
    ) {
      docTitle = getCurrentlocaleText("document.title.text.case_3");
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      path[3] === "inodes" &&
      path[4] &&
      path[5] == "services"
    ) {
      docTitle = getCurrentlocaleText("document.title.text.case_4");
    } else if (path[1] === "orgs" && path[2] && path[3] === "services") {
      docTitle = getCurrentlocaleText("document.title.text.case_4_1");
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      path[3] === "inodes" &&
      path[4] &&
      path[5] == "wanfirewall"
    ) {
      docTitle = getCurrentlocaleText("document.title.text.case_5");
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      path[3] === "inodes" &&
      path[4] &&
      path[5] == "events"
    ) {
      docTitle = getCurrentlocaleText("document.title.text.case_6");
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      !path[3] &&
      !path[4] &&
      !path[5]
    ) {
      docTitle = getCurrentlocaleText("document.title.text.case_7");
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      path[3] === "inodes" &&
      !path[4] &&
      !path[5]
    ) {
      docTitle = getCurrentlocaleText("document.title.text.case_8");
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      path[3] === "inodes" &&
      path[4] &&
      path[5] === "view"
    ) {
      docTitle = getCurrentlocaleText("document.title.text.view_inode");
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      path[3] === "users" &&
      !path[4] &&
      !path[5]
    ) {
      docTitle = getCurrentlocaleText("document.title.text.case_9");
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      path[3] === "roles" &&
      !path[4] &&
      !path[5]
    ) {
      docTitle = getCurrentlocaleText("document.title.text.case_10");
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      path[3] === "serials" &&
      !path[4] &&
      !path[5]
    ) {
      docTitle = getCurrentlocaleText("document.title.text.case_11");
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      path[3] === "licenses" &&
      !path[4] &&
      !path[5]
    ) {
      docTitle = getCurrentlocaleText("document.title.text.case_12");
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      path[3] === "securitypolicies" &&
      !path[4] &&
      !path[5]
    ) {
      docTitle = getCurrentlocaleText("document.title.text.case_13");
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      path[3] === "orgs" &&
      !path[4] &&
      !path[5]
    ) {
      docTitle = getCurrentlocaleText("document.title.text.case_14");
    } else if (
      path[1] === "inodes" &&
      !path[2] &&
      !path[3] &&
      !path[4] &&
      !path[5]
    ) {
      docTitle = getCurrentlocaleText("orgs.details.tabs.inodes.text");
    } else if (
      path[1] === "inodes" &&
      path[2] &&
      !path[3] &&
      !path[4] &&
      !path[5]
    ) {
      docTitle = `:iNodeName - iNodes`;
    } else if (
      path[1] === "inodes" &&
      path[2] &&
      path[3] === "modify" &&
      !path[4] &&
      !path[5]
    ) {
      docTitle = getCurrentlocaleText("document.title.text.case_15");
    } else if (
      path[1] === "inodes" &&
      path[2] &&
      path[3] === "networks" &&
      path[4] === "modify" &&
      !path[5]
    ) {
      docTitle = getCurrentlocaleText("document.title.text.case_15");
    } else if (
      path[1] === "inodes" &&
      path[2] &&
      path[3] === "networks" &&
      !path[4] &&
      !path[5]
    ) {
      docTitle = getCurrentlocaleText("document.title.text.case_16");
    } else if (
      path[1] === "inodes" &&
      path[2] &&
      path[3] === "services" &&
      !path[4] &&
      !path[5]
    ) {
      docTitle = getCurrentlocaleText("document.title.text.case_17");
    } else if (
      path[1] === "inodes" &&
      path[2] &&
      path[3] === "wanfirewall" &&
      !path[4] &&
      !path[5]
    ) {
      docTitle = getCurrentlocaleText("document.title.text.case_18");
    } else if (
      path[1] === "inodes" &&
      path[2] &&
      path[3] === "events" &&
      !path[4] &&
      !path[5]
    ) {
      docTitle = getCurrentlocaleText("document.title.text.case_19");
    } else if (
      path[1] === "users" &&
      !path[2] &&
      !path[3] &&
      !path[4] &&
      !path[5]
    ) {
      docTitle = getCurrentlocaleText("users.navigation_menu.text");
    } else if (
      path[1] === "roles" &&
      !path[2] &&
      !path[3] &&
      !path[4] &&
      !path[5]
    ) {
      docTitle = getCurrentlocaleText("general.roles.label.text");
    } else if (
      path[1] === "help" &&
      path[2] === "kb" &&
      !path[3] &&
      !path[4] &&
      !path[5]
    ) {
      docTitle = getCurrentlocaleText("kb.navigation_menu.text");
    } else if (
      path[1] === "help" &&
      path[2] === "releases" &&
      !path[3] &&
      !path[4] &&
      !path[5]
    ) {
      docTitle = getCurrentlocaleText("release.navigation_menu.text");
    } else if (
      path[1] === "profile" &&
      !path[2] &&
      !path[3] &&
      !path[4] &&
      !path[5]
    ) {
      docTitle = getCurrentlocaleText("myprofile.text");
    } else if (
      path[1] === "managealerts" &&
      !path[2] &&
      !path[3] &&
      !path[4] &&
      !path[5]
    ) {
      docTitle = getCurrentlocaleText("manage_alerts.text");
    } else if (
      path[1] === "managealerts" &&
      path[2] === "add" &&
      !path[3] &&
      !path[4] &&
      !path[5]
    ) {
      docTitle = `${getCurrentlocaleText(
        "managealerts.addalert.text",
      )} - ${getCurrentlocaleText("manage_alerts.text")}`;
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      path[3] === "activity" &&
      path[4] === "downloads"
    ) {
      docTitle = getCurrentlocaleText(
        "downloads.navigation_menu.activity.text",
      );
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      path[3] === "event" &&
      path[4] === "downloads"
    ) {
      docTitle = getCurrentlocaleText("downloads.navigation_menu.events.text");
    } else if (
      path[1] === "help" &&
      path[2] === "releases" &&
      !path[3] &&
      !path[4] &&
      !path[5]
    ) {
      docTitle = getCurrentlocaleText("help.navigation_menu.release.text");
    } else if (
      path[1] === "events" &&
      !path[2] &&
      !path[3] &&
      !path[4] &&
      !path[5]
    ) {
      docTitle = `Events`;
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      path[3] === "branding" &&
      !path[4] &&
      !path[5]
    ) {
      docTitle = getCurrentlocaleText("branding.text");
    } else if (
      path[1] === "dashboard" &&
      path[2] &&
      path[3] === "branding" &&
      !path[4] &&
      !path[5]
    ) {
      docTitle = getCurrentlocaleText("branding.text");
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      path[3] === "activities" &&
      !path[4] &&
      !path[5]
    ) {
      docTitle = getCurrentlocaleText("document.title.text.case_20");
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      path[3] === "clusters" &&
      !path[4] &&
      !path[5]
    ) {
      docTitle = getCurrentlocaleText("document.title.text.case_21");
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      path[3] === "clusters" &&
      path[4] === "edit"
    ) {
      docTitle = getCurrentlocaleText("document.title.text.case_22");
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      path[3] === "clusters" &&
      path[4] === "view"
    ) {
      docTitle = getCurrentlocaleText("document.title.text.case_23");
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      path[3] === "clusters" &&
      path[4] != "view" &&
      path[4] != "edit"
    ) {
      docTitle = getCurrentlocaleText("document.title.text.case_24");
    } else if (
      path[1] === "orgs" &&
      path[2] &&
      path[3] === "servicelistener" &&
      !path[4] &&
      !path[5]
    ) {
      docTitle = getCurrentlocaleText("document.title.text.case_25");
    } else if (path[1] === "settings") {
      docTitle = getCurrentlocaleText("settings.navigation_menu.text");
    } else if (path[1] === "managewebhooks") {
      docTitle = getCurrentlocaleText("manage_webhooks.text");
    } else {
      if (AuthStore.IsPermitted("SUPER:ADMIN"))
        docTitle = getCurrentlocaleText("dashboard.navigation_menu.text");
      else if (AuthStore.IsPermitted("ORG:VIEW"))
        docTitle = getCurrentlocaleText("orgs.navigation_menu.text");
      else if (AuthStore.IsPermitted("NODE:VIEW"))
        docTitle = getCurrentlocaleText("orgs.details.tabs.inodes.text");
      else if (AuthStore.IsPermitted("USER:VIEW"))
        docTitle = getCurrentlocaleText("dashboard.counts.users.tooltip");
    }
  }
  return docTitle;
}

function getResourceForDisplay(resource) {
  if (resource === "orgs") {
    return getCurrentlocaleText("general.component.orgs.text");
  } else if (resource === "inodes") {
    return getCurrentlocaleText("general.component.inode.text");
  } else if (resource === "profiles") {
    return getCurrentlocaleText("general.component.profile.text");
  } else if (resource === "users") {
    return getCurrentlocaleText("general.component.users.text");
  } else if (resource === "roles") {
    return getCurrentlocaleText("general.component.role.text");
  } else if (resource === "pki") {
    return getCurrentlocaleText("general.component.pki.text");
  } else if (resource === "networks") {
    return getCurrentlocaleText("general.component.networks.text");
  } else if (resource === "") {
    return getCurrentlocaleText("general.component.firewall.text");
  } else {
    return resource;
  }
}

function isDevelopmentMode() {
  let mode = localStorage.getItem("iot_ui_mode");
  return mode === "development" ? true : false;
}

function getFirewallProtocolAndPort(key) {
  let obj = {};
  switch (key) {
    case "tcp443-443":
      obj = getProtocolAndPort("tcp443-443", "TCP", "443", "443", "HTTPS");
      break;
    case "icmp":
      obj = getProtocolAndPort("icmp", "ICMP", "1", "65535", "PING");
      break;
    case "tcp22-22":
      obj = getProtocolAndPort("tcp22-22", "TCP", "22", "22", "SSH");
      break;
    case "tcp":
      obj = getProtocolAndPort("tcp", "TCP", "", "", "TCP");
      break;
    case "udp":
      obj = getProtocolAndPort("udp", "UDP", "", "", "UDP");
      break;
    case "any":
      obj = getProtocolAndPort("any", "ANY", "", "", "ANY");
      break;
    default:
      obj = getProtocolAndPort("", "", "", "", "");
      break;
  }
  return obj;
}

function getProtocolAndPort(
  key,
  protocol,
  startPort,
  endPort,
  applicationProtocol,
) {
  return {
    key: key,
    protocol: protocol,
    startPort: startPort,
    endPort: endPort,
    applicationProtocol: applicationProtocol,
  };
}

function getPasswordExpireUrl() {
  return sessionStorage.changePasswordUrl
    ? sessionStorage.changePasswordUrl
    : undefined;
}
function getInitMFAUrl() {
  return sessionStorage.init2FAUrl ? sessionStorage.init2FAUrl : undefined;
}
function getConfirmMFAUrl() {
  return sessionStorage.confirm2FAUrl
    ? sessionStorage.confirm2FAUrl
    : undefined;
}

const FREQUENCE = {
  Terahertz: Math.pow(10, 12),
  Gegahertz: Math.pow(10, 9),
  Megahertz: Math.pow(10, 6),
  Kilohertz: Math.pow(10, 3),
  Hectohertz: Math.pow(10, 2),
  Decahertz: Math.pow(10, 1),
};

function frequenceConvertion(KiloHertz = 0) {
  if (KiloHertz > 0) {
    // Convert KiloHerts to hertz
    let hertz = KiloHertz * 1000;
    // Hertz To Terahertz
    if (hertz >= FREQUENCE.Terahertz) {
      return `${(hertz / FREQUENCE.Terahertz).toFixed(2)} THz`;
    } else {
      // Hertz To Gegahertz
      if (hertz >= FREQUENCE.Gegahertz) {
        return `${(hertz / FREQUENCE.Gegahertz).toFixed(2)} GHz`;
      } else {
        // Hertz To Megahertz
        if (hertz >= FREQUENCE.Megahertz) {
          return `${(hertz / FREQUENCE.Megahertz).toFixed(2)} MHz`;
        } else {
          // Hertz To Kilohertz
          if (hertz >= FREQUENCE.Kilohertz) {
            return `${(hertz / FREQUENCE.Kilohertz).toFixed(2)} kHz`;
          } else {
            // Hertz To Hectohertz
            if (hertz >= FREQUENCE.Hectohertz) {
              return `${(hertz / FREQUENCE.Hectohertz).toFixed(2)} hHz`;
            } else {
              // Hertz To Decahertz
              if (hertz >= FREQUENCE.Decahertz) {
                return `${(hertz / FREQUENCE.Decahertz).toFixed(2)} daHz`;
              } else {
                // Hertz
                return `${hertz} Hz`;
              }
            }
          }
        }
      }
    }
  } else {
    return KiloHertz;
  }
}

function clearConsole() {
  setTimeout(() => {
    window.console.clear();
  }, 5);
}

function getMomentDate(item) {
  return moment(new Date(item).getTime())
    .tz(
      TimezoneViewModel.userTimezone ||
        TimezoneViewModel.orgTimezone ||
        getDefaultTimezone().value,
    )
    .fromNow();
}

function calcStepSize(range, targetSteps) {
  let ln10 = Math.log(10);
  // calculate an initial guess at step size
  let tempStep = range / targetSteps;
  // get the magnitude of the step size
  let mag = Math.floor(Math.log(tempStep) / ln10);
  let magPow = Math.pow(10, mag);
  // calculate most significant digit of the new step size
  let magMsd = Math.round(tempStep / magPow + 0.5);

  // promote the MSD to either 1, 2, or 5
  if (magMsd > 5.0) magMsd = 10.0;
  else if (magMsd > 2.0) magMsd = 5.0;
  else if (magMsd > 1.0) magMsd = 2.0;

  let stepSize = Math.round(magMsd * magPow);
  return stepSize > 0 ? stepSize : 1;
}

function copyObject(obj) {
  return JSON.parse(JSON.stringify(obj));
}

function passwordValidator(password) {
  // TODO - RK - Need to handle password policy rules from api
  let pattern1 = /(^.{12,100}$)/,
    pattern2 = /(.*[A-Z].*)/,
    pattern3 = /(.*[a-z].*)/,
    pattern4 = /(.*[0-9].*)/,
    pattern5 = /(.*[!,@,#,$,&,*].*$)/,
    pattern6 = /[\w!@#$&*]+$/,
    msg = getCurrentlocaleText("password.errormessage.text"),
    errorMsg = [];
  // Validation begins only if password has value
  if (password) {
    if (!pattern1.test(password)) {
      let err1 = getCurrentlocaleText("password.errormessage1.text");
      errorMsg.push(`${msg} ${err1}`);
    }
    if (!pattern2.test(password)) {
      let err2 = getCurrentlocaleText("password.errormessage2.text");
      errorMsg.push(errorMsg.length === 0 ? `${msg} ${err2}` : ` ${err2}`);
    }
    if (!pattern3.test(password)) {
      let err3 = getCurrentlocaleText("password.errormessage3.text");
      errorMsg.push(errorMsg.length === 0 ? `${msg} ${err3}` : ` ${err3}`);
    }
    if (!pattern4.test(password)) {
      let err4 = getCurrentlocaleText("password.errormessage4.text");
      errorMsg.push(errorMsg.length === 0 ? `${msg} ${err4}` : ` ${err4}`);
    }
    let err5 = getCurrentlocaleText("password.errormessage5.text");
    if (!pattern5.test(password)) {
      errorMsg.push(errorMsg.length === 0 ? `${msg} ${err5}` : ` ${err5}`);
    } else {
      // Find special characters of the string & validate based on pattern5
      let spl_chars = password.match(/[^a-zA-Z0-9]/g),
        isValid = false;
      if (spl_chars) {
        let len = spl_chars.length;
        isValid = true;
        for (let i = 0; i < len; i++) {
          if (!pattern6.test(spl_chars[i])) {
            isValid = false;
          }
        }
        if (!isValid) {
          errorMsg.push(errorMsg.length === 0 ? `${msg} ${err5}` : ` ${err5}`);
        }
      }
    }
  }
  if (errorMsg.length > 1) {
    errorMsg[errorMsg.length - 1] = ` ${getCurrentlocaleText("and.text")}${
      errorMsg[errorMsg.length - 1]
    }`;
  }
  return errorMsg.length === 0 ? null : errorMsg.toString();
}

function getErrorMessage(error, resource) {
  if (error.response) {
    if (
      error.response.data &&
      error.response.data.validationErrors &&
      error.response.data.validationErrors[0]
    ) {
      return error.response.data.validationErrors[0].message;
    } else {
      let defaultMessage = `${resource}: ${
        error.response.status
      } - ${JSON.stringify(error.response.statusText)}`;
      return error.response.data && error.response.data.message
        ? `${error.response.data.message}`
        : defaultMessage;
    }
  }
}

function getServiceErrorMessage(error, resource) {
  if (error.response) {
    if (error.response.data && error.response.data.validationErrors) {
      const errorMessages = error.response.data.validationErrors
        .map(error => `${error.field} ${error.message}`)
        .join("; ");
      const seriveSpecErrorMessage = `Error(s) in Service Spec: ${errorMessages}`;
      return seriveSpecErrorMessage;
    } else {
      let defaultMessage = `${resource}: ${
        error.response.status
      } - ${JSON.stringify(error.response.statusText)}`;
      return error.response.data && error.response.data.message
        ? `${error.response.data.message}`
        : defaultMessage;
    }
  } else {
    return getCurrentlocaleText("custom.service.spec.validator.none");
  }
}

function stringToRegex(val) {
  /*remove quotes from regex received as props*/
  let regParts = val.match(/^\/(.*?)\/([gim]*)$/),
    regexp;
  if (regParts) {
    regexp = new RegExp(regParts[1], regParts[2]);
  } else {
    // we got pattern string without delimiters
    regexp = new RegExp(val);
  }

  return regexp;
}

function isArray(val) {
  /* convert to arry if it is observable */
  let x = isObservable(val) ? toJS(val) : val;
  return Array.isArray(x);
}
function isArrayOfStrings(val) {
  //LAT-8534 check for array contains strings as its elements will return true or else false
  // convert observable into native js array
  let x = isObservable(val) ? toJS(val) : val;
  if (x) {
    return x.every(function(i) {
      return typeof i === "string";
    });
  }
}

function isObjectOrFunction(obj) {
  // check and returns if the given value is object
  return (typeof obj === "object" && obj !== null) || typeof obj === "function";
}

function isEmpty(val) {
  /* check if val is empty */
  return val ? (Object.keys(val).length == 0 ? true : false) : true;
}

function deepMapValues(object, callback, propertyPath) {
  propertyPath = propertyPath || "";
  if (isArray(object)) {
    return object.map((value, key) => {
      var valuePath = propertyPath ? propertyPath + "[" + key + "]" : key;
      return this.deepMapValues(value, callback, valuePath);
    });
  } else if (
    isObjectOrFunction(object) &&
    !isDate(object) &&
    !isRegExp(object) &&
    !isFunction(object)
  ) {
    return Object.assign(
      {},
      object,
      Object.keys(object).reduce((i, key) => {
        var valuePath = propertyPath ? propertyPath + "." + key : key;
        return this.deepMapValues(object[key], callback, valuePath);
      }, {}),
    );
  } else {
    return callback(object, propertyPath);
  }
}

// Gets the length of the interval in nanoseconds.
function getInterval(current, previous) {
  var cur = new Date(current);
  var prev = new Date(previous);
  // ms -> ns.
  return (cur.getTime() - prev.getTime()) * 1000000;
}

function getNetworkStatus(statusTxt) {
  if (statusTxt) {
    switch (statusTxt.toLowerCase()) {
      case "connected":
        return getCurrentlocaleText("network.status.connected");
      case "connecting":
        return getCurrentlocaleText("network.status.connecting");
      case "connect_failed":
        return getCurrentlocaleText("network.status.connect_failed");
      default:
        return getCurrentlocaleText("network.status.unknown");
    }
  }
  return getCurrentlocaleText("network.status.unknown");
}

function getNetworkStatusColorCode(statusTxt) {
  if (statusTxt) {
    switch (statusTxt.toLowerCase()) {
      case "connected":
        return "iotium-success";
      case "connecting":
        return "iotium-warning";
      case "connect_failed":
      case "terminated":
        return "iotium-danger";
      default:
        return "";
    }
  }
  return "";
}

function getNetworkStatusMessage(status) {
  if (status && status.state) {
    switch (status.state.toLowerCase()) {
      case "connected":
        return status.properties && status.properties.currentConnectAt ? (
          <p>
            {getCurrentlocaleText("network.conneted_since.text") +
              " " +
              getLastActivityAt(status.properties.currentConnectAt)}
          </p>
        ) : (
          <p>{getCurrentlocaleText("network.connected_message.text")}</p>
        );
      case "connecting":
        return (
          <p>{getCurrentlocaleText("network.attempt_to_connecting.text")}</p>
        );
      case "connect_failed":
        if (status.properties && status.properties.message) {
          if (status.properties.errorCode === "TunnelConnectionUnknown") {
            return (
              <p>
                {getCurrentlocaleText("network.connect_failed_message.text")}
              </p>
            );
          } else {
            return (
              <div>
                <p>{status.properties.message}</p>
              </div>
            );
          }
        } else {
          return (
            <p>{getCurrentlocaleText("network.connect_failed_message.text")}</p>
          );
        }
    }
  }
  return <p>{getCurrentlocaleText("network.network_status_unknown.text")}</p>;
}

function isCameFromDashboardPage(pathname) {
  if (pathname) {
    if (pathname.indexOf("/dashboard/") !== -1) {
      return true;
    }
  }
  return false;
}

function getInodeDetailsBreadcrumbUrl(pathname) {
  let path_arr = pathname.split("/");
  if (pathname.indexOf("/dashboard/inodes/status") !== -1) {
    return `${path_arr[1]}/${path_arr[2]}/${path_arr[3]}/${path_arr[4]}`;
  } else if (pathname.indexOf("/dashboard/inodes/profile") !== -1) {
    return `${path_arr[1]}/${path_arr[2]}/${path_arr[3]}/${path_arr[4]}/${path_arr[5]}/${path_arr[6]}`;
  } else if (pathname.indexOf("/dashboard/inodes") !== -1) {
    return `${path_arr[1]}/${path_arr[2]}`;
  } else if (pathname.indexOf("/dashboard/network") !== -1) {
    return `${path_arr[1]}/${path_arr[2]}`;
  } else if (pathname.indexOf("/dashboard/service") !== -1) {
    return `${path_arr[1]}/${path_arr[2]}`;
  } else {
    return pathname.substring(1, pathname.lastIndexOf("/"));
  }
}

function getInodeDetailsBreadcrumbLinkName(pathname) {
  if (pathname.indexOf("/dashboard/inodes/status") !== -1) {
    return "Nodes Status";
  } else if (pathname.indexOf("/dashboard/inodes/profile") !== -1) {
    return "Nodes Profile";
  } else if (pathname.indexOf("/dashboard/inodes") !== -1) {
    return "Nodes";
  } else if (pathname.indexOf("/dashboard/network") !== -1) {
    return "Networks";
  } else if (pathname.indexOf("/dashboard/service") !== -1) {
    return "Services";
  } else {
    return "Nodes";
  }
}

function getDefaultTimezone() {
  let dfTimezone = Timezones.find(tz => {
    return tz.value === moment.tz.guess();
  });
  return dfTimezone;
}

function getTimezoneToDisplay(zone = null) {
  let timezoneId = getDefaultTimezone().value;
  if (zone !== null) {
    let currentTZ = moment(zone).tz();
    if (currentTZ) {
      timezoneId = currentTZ;
    }
  }
  return "(UTC" + moment.tz(timezoneId).format("Z") + ")";
}

function getOrgPolicyCounInfo(org_data, current_info) {
  switch (current_info) {
    case "label_count":
      if (org_data.policy && org_data.policy.max_labels_per_resource)
        return org_data.policy.max_labels_per_resource;
      else return 8;
      break;
    case "rules_count":
      if (
        org_data.policy &&
        org_data.policy.firewall &&
        org_data.policy.firewall.max_rules_per_firewallgroup
      )
        return org_data.policy.firewall.max_rules_per_firewallgroup;
      else return 16;
      break;
    case "group_count":
      if (
        org_data.policy &&
        org_data.policy.firewall &&
        org_data.policy.firewall.max_firewall_group_limit
      )
        return org_data.policy.firewall.max_firewall_group_limit;
      else return 8;
      break;
    case "custom_policy_count":
      /**
       * TS: This will come in future So commenting this lines
       */
      /* if (
        org_data.policy &&
        org_data.policy.network &&
        org_data.policy.network.max_firewall_selector
      )
        return org_data.policy.network.max_firewall_selector;
      else return 8;*/
      return 1;
      break;
    case "apikey_count":
      return 3;
    case "event_download_count":
      if (
        org_data.policy &&
        org_data.policy.download &&
        org_data.policy.download.events &&
        org_data.policy.download.events.max_download_per_user
      ) {
        return org_data.policy.download.events.max_download_per_user;
      }
    case "activity_download_count":
      if (
        org_data.policy &&
        org_data.policy.download &&
        org_data.policy.download.activity &&
        org_data.policy.download.activity.max_download_per_user
      ) {
        return org_data.policy.download.activity.max_download_per_user;
      }
    case "cluster_count":
      if (
        org_data.policy &&
        org_data.policy.cluster &&
        org_data.policy.cluster.max_cluster_per_organization
      ) {
        return org_data.policy.cluster.max_cluster_per_organization;
      }
    case "cluster_node_count":
      if (
        org_data.policy &&
        org_data.policy.cluster &&
        org_data.policy.cluster.max_node_per_cluster
      ) {
        return org_data.policy.cluster.max_node_per_cluster;
      }
    case "cluster_candidate_count":
      if (
        org_data.policy &&
        org_data.policy.cluster &&
        org_data.policy.cluster.max_candidate_per_cluster
      ) {
        return org_data.policy.cluster.max_candidate_per_cluster;
      } else return 3;
    default:
      return 8;
      break;
  }
}

function getAlertTitle(type) {
  switch (type) {
    case ALERT.TYPE.NODE_STATE_CHANGE:
      return getCurrentlocaleText("inode_status_alerts.text");
    case ALERT.TYPE.TUNNEL_STATE_CHANGE:
      return getCurrentlocaleText("tunnel_status_alerts.text");
    case ALERT.TYPE.AGGREGATED_ROUTE_CHANGE:
      return getCurrentlocaleText("aggregated_route_change_alerts.text");
    case ALERT.TYPE.DUPLICATE_ADDRESS_DETECTION:
      return getCurrentlocaleText("duplicate_address_detection.text");
    case ALERT.TYPE.SERVICE_STATE_CHANGE:
      return getCurrentlocaleText("service_status_alerts.text");
    case ALERT.TYPE.HEADLESS_EXPIRY:
      return getCurrentlocaleText("headless_status_alerts.text");
    case ALERT.TYPE.CERT_EXPIRY:
      return getCurrentlocaleText("inode.cert_status_alerts.text");
    case ALERT.TYPE.NODE_IP_CHANGE:
      return getCurrentlocaleText("inode.ip_change_alerts.text");
    case ALERT.TYPE.NODE_UPGRADE:
      return getCurrentlocaleText("inode.upgrade_alerts.text");
    case ALERT.TYPE.NODE_METRICS:
      return getCurrentlocaleText("inode.node_metrics_alerts.text");
    case ALERT.TYPE.THREAT_INTELLIGENCE:
      return getCurrentlocaleText(
        "inode.continuous_threat_detection_alerts.text",
      );
  }
  return "";
}

function removeDefaultLabels(labels = {}) {
  for (let key in labels) {
    if (key.includes("_iotium.")) delete labels[key];
  }
  return labels;
}

function markCollapseHeader(className = null, hasValue = false) {
  setTimeout(() => {
    let element = className
      ? document.querySelector(`.${className} .ant-collapse-header`)
      : document.querySelector(`.ant-collapse-header`);
    if (element) {
      element.style.borderLeft = hasValue ? "5px solid #00a854" : "0px";
    }
  }, 100);
}

// Separate tags for labels
function getTagKeyValues(labels) {
  let tags = [];
  for (let key in labels) {
    if (key && key.charAt(0) != "_") tags.push(key + " : " + labels[key]);
  }
  return tags;
}

function EpochToUserTime(date) {
  let x = moment(new Date(date).getTime()).format(TIME_DATE_FORMAT);
  return x.toLowerCase().indexOf("invalid") >= 0
    ? "-"
    : `${x} ${getTimezoneToDisplay(x)}`;
}

function UniqueArrayElements(arrayParam) {
  var arr = [];
  for (var i = 0; i < arrayParam.length; i++) {
    if (!arr.includes(arrayParam[i])) {
      arr.push(arrayParam[i]);
    }
  }
  return arr;
}

function isEqual(obj1, obj2) {
  if (obj1 && obj2) {
    return JSON.stringify(obj1) === JSON.stringify(obj2) ? true : false;
  }
  return obj1 === obj2 ? true : false;
}

function searchArrayEleWithRegex(literal_string, target_arr = []) {
  // sample input :  arr_grep(/.*this_word.*/ig, someArray)
  var match_bin = [];
  try {
    var o_regex = new RegExp(literal_string);
    for (var i = 0; i < target_arr.length; i++) {
      //loop through array. regex search each element.
      var test = String(target_arr[i]).search(o_regex);
      if (test > -1) {
        // if found push the element@index into our matchbin.
        match_bin.push(target_arr[i]);
      }
    }
    return match_bin;
  } catch (e) {
    return [];
  }
}

function getAlertScope(scope = "") {
  switch (scope) {
    case ALERT.SCOPE.ORG_SCOPE:
      return "Org";
    case ALERT.SCOPE.NODE_SCOPE:
      return "iNode";
  }
  return "";
}

function getAlertOrgScope(org_scope = "") {
  switch (org_scope) {
    case ALERT.ORGANIZATION.MY_ORG:
      return getCurrentlocaleText("alertform.myorg.text");
    case ALERT.ORGANIZATION.MY_ORG_AND_MY_CHILD_ORGS:
      return getCurrentlocaleText("alertform.myorg_and_mychild_orgs.text");
    case ALERT.ORGANIZATION.SPECIFIC_ORGS:
      return getCurrentlocaleText("alertform.specific_org.text");
  }
  return "";
}

function getAlertStatusDisplay(type = "", alert_me) {
  if (type === ALERT.TYPE.NODE_STATE_CHANGE) {
    if (alert_me === "ALL") {
      return `${ALERT.STATUS.NODE_STATE_CHANGE.ALIVE} or ${ALERT.STATUS.NODE_STATE_CHANGE.UNREACHABLE}`;
    } else {
      return alert_me.replace(new RegExp(",", "g"), " or ");
    }
  }
  if (type === ALERT.TYPE.AGGREGATED_ROUTE_CHANGE) {
    if (alert_me === "ALL") {
      return `${ALERT.STATUS.AGGREGATED_ROUTE_CHANGE.ADD} or ${ALERT.STATUS.AGGREGATED_ROUTE_CHANGE.DELETE}`;
    } else {
      return alert_me.replace(new RegExp(",", "g"), " or ");
    }
  } else if (type === ALERT.TYPE.TUNNEL_STATE_CHANGE) {
    if (alert_me === "ALL") {
      return `${ALERT.STATUS.TUNNEL_STATE_CHANGE.CONNECTED} or CONNECT FAILED`;
    } else {
      return alert_me === ALERT.STATUS.TUNNEL_STATE_CHANGE.TERMINATED
        ? "TERMINATED"
        : alert_me
            .replace(new RegExp(",", "g"), " or ")
            .replace(new RegExp("_", "g"), " ");
    }
  } else if (type === ALERT.TYPE.SERVICE_STATE_CHANGE) {
    if (alert_me === "ALL") {
      return `${ALERT.STATUS.SERVICE_STATE_CHANGE.HEALTHY} or ${ALERT.STATUS.SERVICE_STATE_CHANGE.UNHEALTHY} or ${ALERT.STATUS.SERVICE_STATE_CHANGE.TERMINATED}`;
    } else {
      return alert_me.replace(new RegExp(",", "g"), " or ");
    }
  } else if (type === ALERT.TYPE.NODE_IP_CHANGE) {
    if (alert_me === "ALL") {
      return `${ALERT.STATUS.NODE_IP_CHANGE.PRIVATE_IP} or ${ALERT.STATUS.NODE_IP_CHANGE.PUBLIC_IP}`.replace(
        new RegExp("_", "g"),
        " ",
      );
    } else {
      return alert_me
        .replace(new RegExp(",", "g"), " or ")
        .replace(new RegExp("_", "g"), " ");
    }
  } else if (type === ALERT.TYPE.NODE_UPGRADE) {
    if (alert_me === "ALL") {
      return `${ALERT.STATUS.NODE_UPGRADE.ENABLED} or ${ALERT.STATUS.NODE_UPGRADE.DISABLED} or ${ALERT.STATUS.NODE_UPGRADE.SUCCESSFUL} or ${ALERT.STATUS.NODE_UPGRADE.FAILED}`.replace(
        new RegExp("_", "g"),
        " ",
      );
    } else {
      return alert_me
        .replace(new RegExp(",", "g"), " or ")
        .replace(new RegExp("_", "g"), " ");
    }
  } else if (type === ALERT.TYPE.NODE_METRICS) {
    if (alert_me !== "ALL") {
      let valuesArr = alert_me.split(",");
      let cpu =
        valuesArr[0] !== ALERT.STATUS.NODE_METRICS_VALUES.OFF
          ? `${valuesArr[0]}%`
          : valuesArr[0];
      let memory =
        valuesArr[1] !== ALERT.STATUS.NODE_METRICS_VALUES.OFF
          ? `${valuesArr[1]}%`
          : valuesArr[1];
      let fs =
        valuesArr[2] !== ALERT.STATUS.NODE_METRICS_VALUES.OFF
          ? `${valuesArr[2]}%`
          : valuesArr[2];
      let alertstr = "";
      if (cpu !== "OFF") {
        alertstr = `${ALERT.STATUS.NODE_METRICS.CPU} Above ${cpu}`;
      }
      if (memory !== "OFF") {
        if (cpu !== "OFF") {
          alertstr = alertstr + ` or `;
        }
        alertstr =
          alertstr + `${ALERT.STATUS.NODE_METRICS.MEMORY} Above ${memory}`;
      }
      if (fs !== "OFF") {
        if (cpu !== "OFF" || memory !== "OFF") {
          alertstr = alertstr + ` or `;
        }
        alertstr =
          alertstr + `${ALERT.STATUS.NODE_METRICS.FILESYSTEM} Above ${fs}`;
      }

      return `${alertstr}`.replace(new RegExp("_", "g"), " ");
    } else {
      return alert_me
        .replace(new RegExp(",", "g"), " or ")
        .replace(new RegExp("_", "g"), " ");
    }
  } else if (type === ALERT.TYPE.HEADLESS_EXPIRY) {
    if (alert_me === "80,100" || alert_me === "100,80") {
      return `${"EXPIRED"} or ${"EXPIRES SOON"}`;
    } else if (alert_me === "80") {
      return `${"EXPIRES SOON"}`;
    } else {
      return `${"EXPIRED"}`;
    }
  } else if (type === ALERT.TYPE.CERT_EXPIRY) {
    let alertStr = "";
    if (alert_me.includes("-1")) {
      alertStr += `${"EXPIRED"} or `;
    }
    if (alert_me.includes("90")) {
      alertStr += `${"EXPIRES IN 90 DAYS"} or `;
    }
    if (alert_me.includes("45")) {
      alertStr += `${"EXPIRES IN 45 DAYS"} or `;
    }
    if (alert_me.includes("30")) {
      alertStr += `${"EXPIRES IN 30 DAYS"} or `;
    }
    if (alert_me.includes("15")) {
      alertStr += `${"EXPIRES IN 15 DAYS"} or `;
    }
    if (alertStr.length > 0) {
      return alertStr.substr(0, alertStr.length - 3);
    }
    return alertStr;
  }
  return "";
}

function getAlertConditionDisplay(alertType) {
  if (alertType === ALERT.TYPE.NODE_METRICS) {
    return `${getCurrentlocaleText("alerts-conditions-above-text")}`;
  } else {
    return `${getCurrentlocaleText("alerts-conditions-equals-to-text")}`;
  }
}

function getAlertDurationDisplay(duration = 5) {
  duration = parseInt(duration);
  // Days
  if (duration / 1440 >= 1) {
    let result = duration / 1440;
    return result === 1 ? `${result} day` : `${result} days`;
  } else if (duration / 60 >= 1) {
    // Hours
    let result = duration / 60;
    return result === 1 ? `${result} hour` : `${result} hours`;
  } else {
    // Minutes
    return `${duration} minutes`;
  }
}

/**
 * COMPUTES TIME DIFF AND PERCENTAGE FOR HEADLESS
 * @param {*} time
 * @param {*} max_duration
 */
function PlotTimeDiff(time, max_duration = 60, isPastTime = false) {
  if (time) {
    var today = new Date();
    var updatedtime = new Date(time);
    var localTime = moment.utc(updatedtime).toDate();
    if (isPastTime) var diffMs = moment.utc(today) - localTime;
    // milliseconds between now & givne pastvtime
    else var diffMs = localTime - moment.utc(today); // milliseconds between now & givne future time

    var diffDays = Math.floor(diffMs / 86400000); // days
    var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    var diffMs2Mins = Math.round(diffMs / 60000); // minutes
    return {
      minutes: diffMins,
      days: diffDays,
      hours: diffHrs,
      percentage: (diffMs2Mins * 100) / max_duration,
    };
  } else {
    return { minutes: 0, days: 0, hours: 0, percentage: 0 };
  }
}
/**
 * CHECK POLICY INFO FOR FEATURES
 */
function checkforFeatureEnabled(orginfo = { policy: {} }, feature = "") {
  let policy_data = orginfo && orginfo.policy ? orginfo.policy : { policy: {} };
  switch (feature) {
    case "headless_mode":
      if (
        policy_data &&
        policy_data.headless_mode &&
        policy_data.headless_mode.enable
      )
        return true;
      else return false;
    case "vlan_support":
      if (
        policy_data &&
        policy_data.vlan_support &&
        policy_data.vlan_support.enable
      )
        return true;
      else return false;
    case "apikey":
      if (policy_data && policy_data.apikey && policy_data.apikey.enable)
        return true;
      else return false;
    case "branding":
      if (policy_data && policy_data.branding && policy_data.branding.enable)
        return true;
      else return false;
    case "branding_inheritance":
      if (
        policy_data &&
        policy_data.branding &&
        policy_data.branding.inherit_from_parent
      )
        return true;
      else return false;
    case "one_arm_mode":
      if (
        policy_data &&
        policy_data.one_arm_mode &&
        policy_data.one_arm_mode.enable
      )
        return true;
      else return false;
    case "cluster":
      if (policy_data && policy_data.cluster && policy_data.cluster.enable)
        return true;
      else return false;
    case "webhook":
      if (policy_data && policy_data.webhook && policy_data.webhook.enable)
        return true;
      else return false;
    case "autorepnet":
      if (
        policy_data &&
        policy_data.network &&
        policy_data.network.representation_network_automation &&
        policy_data.network.representation_network_automation.enable
      )
        return true;
      else return false;
    case "hwm":
      if (
        policy_data &&
        policy_data.inode_hw_monitoring &&
        policy_data.inode_hw_monitoring.enable
      )
        return true;
      else return false;
    default:
      return false;
  }
}

/**
 * FORMAT TIME LEFT STRINGS
 * @param {*} data
 */
function formatRemainingTimeDiff(data) {
  let minutes = data.minutes,
    hours = data.hours,
    days = data.days;
  if (days > 0 && hours > 0 && minutes > 0) {
    return getCurrentlocaleText("inode.headless.expiry.state1.text", {
      0: days,
      1: hours,
      2: minutes,
    });
  }
  if (days > 0 && hours === 0 && minutes > 0) {
    return getCurrentlocaleText("inode.headless.expiry.state5.text", {
      0: days,
      1: minutes,
    });
  } else if (days <= 0 && hours > 0 && minutes > 0) {
    return getCurrentlocaleText("inode.headless.expiry.state2.text", {
      0: hours,
      1: minutes,
    });
  } else if (hours === 0 && days > 0 && minutes === 0) {
    return getCurrentlocaleText("inode.headless.expiry.state6.text", {
      0: days,
    });
  } else if (hours > 0 && days === 0 && minutes === 0) {
    return getCurrentlocaleText("inode.headless.expiry.state7.text", {
      0: hours,
    });
  } else if (minutes > 0) {
    return getCurrentlocaleText("inode.headless.expiry.state3.text", {
      0: minutes,
    });
  } else {
    return getCurrentlocaleText("inode.headless.expiry.state4.text");
  }
}
function computeDurationToMinutes(value) {
  if (value.units) {
    let unit = value.units;
    switch (unit) {
      case "MINUTES":
        return value.duration;
        break;
      case "DAYS":
        return value.duration * 24 * 60;
        break;
      case "HOURS":
        return value.duration * 60;
        break;
    }
  }
}
function computeMinutesToDuration(minutes) {
  if (minutes <= 60) {
    return { duration: minutes, units: "MINUTES" };
  } else if (minutes >= 1440) {
    if (Number.isInteger(minutes / 60 / 24)) {
      return { duration: minutes / 60 / 24, units: "DAYS" };
    } else {
      return { duration: minutes, units: "MINUTES" };
    }
  } else if (minutes < 1440) {
    if (Number.isInteger(minutes / 60)) {
      return { duration: minutes / 60, units: "HOURS" };
    } else {
      return { duration: minutes, units: "MINUTES" };
    }
  } else {
    return { duration: 60, units: "MINUTES" };
  }
}
// check for virtual profiles
function checkforVirtualProfiles(profileId, profiles) {
  let currentProfile = {};
  profiles.map(profile => {
    if (profile.id === profileId) currentProfile = profile;
  });
  if (currentProfile && currentProfile.config) {
    if (currentProfile.config.vpn_server) return true;
  } else {
    return false;
  }
}

function validNameRegex() {
  /* should start with alphanumeric and can have special chars - -_,.& */
  return /^[A-Za-z0-9]+[\w\d\&\-,\.]*$/;
}
function checkFordRepeatedInterfaces(interface_data = []) {
  let index = -1;
  interface_data.forEach(int_face => {
    if (int_face.type === "ppp") {
      index = interface_data.findIndex(x => x.type == "wwan");
    }
  });
  if (index != -1) {
    interface_data.splice(index, 1);
  }
  return interface_data;
}

const ALERT = {
  TYPE: {
    NODE_STATE_CHANGE: "NODE_STATE_CHANGE",
    CERT_EXPIRY: "CERT_EXPIRY",
    NODE_IP_CHANGE: "NODE_IP_CHANGE",
    NODE_UPGRADE: "NODE_UPGRADE",
    TUNNEL_STATE_CHANGE: "TUNNEL_STATE_CHANGE",
    SERVICE_STATE_CHANGE: "SERVICE_STATE_CHANGE",
    HEADLESS_EXPIRY: "HEADLESS_EXPIRY",
    NODE_METRICS: "NODE_METRICS",
    AGGREGATED_ROUTE_CHANGE: "AGGREGATED_ROUTE_CHANGE",
    DUPLICATE_ADDRESS_DETECTION: "DUPLICATE_ADDRESS_DETECTION",
    THREAT_INTELLIGENCE: "THREAT_INTELLIGENCE",
  },
  SCOPE: {
    ORG_SCOPE: "ORG_SCOPE",
    NODE_SCOPE: "NODE_SCOPE",
    CLUSTER_SCOPE: "CLUSTER_SCOPE",
  },
  ORGANIZATION: {
    MY_ORG: "MY_ORG",
    MY_ORG_AND_MY_CHILD_ORGS: "MY_ORG_AND_MY_CHILD_ORGS",
    SPECIFIC_ORGS: "SPECIFIC_ORGS_MENU",
  },
  OPERATOR: {
    ABOVE: "ABOVE",
    NONE: "NONE",
  },
  STATUS: {
    NODE_STATE_CHANGE: {
      ALIVE: "ALIVE",
      UNREACHABLE: "UNREACHABLE",
    },
    TUNNEL_STATE_CHANGE: {
      CONNECTED: "CONNECTED",
      TERMINATED: "TERMINATED",
    },
    SERVICE_STATE_CHANGE: {
      HEALTHY: "HEALTHY",
      UNHEALTHY: "UNHEALTHY",
      TERMINATED: "TERMINATED",
    },
    HEADLESS_EXPIRY: {
      REACHED_80: "80",
      EXPIRED: "100",
    },
    CERT_EXPIRY: {
      EXPIRES_IN_90_DAYS: "90",
      EXPIRES_IN_45_DAYS: "45",
      EXPIRES_IN_30_DAYS: "30",
      EXPIRES_IN_15_DAYS: "15",
      EXPIRED: "0",
    },
    NODE_IP_CHANGE: {
      PRIVATE_IP: "PRIVATE_IP",
      PUBLIC_IP: "PUBLIC_IP",
    },
    AGGREGATED_ROUTE_CHANGE: {
      ADD: "ADD",
      DELETE: "DELETE",
    },
    NODE_UPGRADE: {
      ENABLED: "ENABLED",
      DISABLED: "DISABLED",
      SUCCESSFUL: "SUCCESSFUL",
      FAILED: "FAILED",
    },
    NODE_METRICS: {
      FILESYSTEM: "Filesystem",
      CPU: "CPU",
      MEMORY: "Memory",
    },
    NODE_METRICS_VALUES: {
      OFF: "OFF",
      ABOVE_70: "70",
      ABOVE_80: "80",
      ABOVE_90: "90",
      ABOVE_99: "99",
    },
    DUPLICATE_ADDRESS_DETECTION: {},
    THREAT_INTELLIGENCE: {},
  },
  DURATION: {
    MINUTES: "MINUTES",
    HOURS: "HOURS",
    DAYS: "DAYS",
  },
  CHANNELS: {
    EMAIL: "EMAIL",
    WEBHOOK: "WEBHOOK",
  },
};

const DefaultStatsWidget = {
  rows: [
    {
      columns: [
        {
          className: "ant-col-24 stats-container",
          widgets: [
            {
              key: "CPUChart",
            },
          ],
          id: 1,
        },
      ],
    },
    {
      columns: [
        {
          className: "ant-col-24 stats-container",
          widgets: [
            {
              key: "MemoryChart",
            },
          ],
          id: 2,
        },
      ],
    },
    {
      columns: [
        {
          className: "ant-col-24 stats-container",
          widgets: [
            {
              key: "FileSystemChart",
            },
          ],
          id: 3,
        },
      ],
    },
    {
      columns: [
        {
          className: "ant-col-24 stats-container",
          widgets: [
            {
              key: "NetworkChart",
            },
          ],
          id: 4,
        },
      ],
    },
  ],
};

const OverviewDefaultStatsWidget = {
  rows: [
    {
      columns: [
        {
          className: "ant-col-24 stats-container",
          widgets: [
            {
              key: "SystemOverview",
            },
          ],
          id: 5,
        },
      ],
    },
    {
      columns: [
        {
          className: "ant-col-16 stats-container",
          widgets: [
            {
              key: "ContainerOverview",
            },
          ],
          id: 6,
        },
        {
          className: "ant-col-8 stats-container",
          widgets: [
            {
              key: "ServiceNetworkOverview",
            },
          ],
          id: 1,
        },
      ],
    },
    {
      columns: [
        {
          className: "ant-col-12 stats-container",
          widgets: [
            {
              key: "ServiceStatusChart",
            },
          ],
          id: 5,
        },
        {
          className: "ant-col-12 stats-container",
          widgets: [
            {
              key: "ContainerStatusChart",
            },
          ],
          id: 5,
        },
      ],
    },
  ],
};

const TECHDUMP_PROFILE = {
  BASE_NODE_DUMP: {
    displayName: getCurrentlocaleText("techdump.profile.base_inode_data.text"),
    value: "BASE_NODE_DUMP",
  },
  LEVEL2_NODE_DUMP: {
    displayName: getCurrentlocaleText(
      "techdump.profile.level2_inode_data.text",
    ),
    value: "LEVEL2_NODE_DUMP",
  },
  SYSTEM_INFO: {
    displayName: getCurrentlocaleText("techdump.profile.systeminfo.text"),
    value: "SYSTEM_INFO",
  },
  COMMANDS: {
    displayName: getCurrentlocaleText("techdump.profile.commands.text"),
    value: "COMMANDS",
  },
  DEFAULT: {
    displayName: getCurrentlocaleText("techdump.profile.default.text"),
    value: "DEFAULT",
  },
};

/* All Last At Time stamp should be shown in this format across IOTIUM UI*/
function formatTimestampToIotiumLastAtTimeString(
  timestamp,
  showTimestamp = false,
) {
  if (timestamp) {
    let lastContact = nanoToDateString(timestamp);
    let currentDate = moment(new Date().getTime());
    let lastContactDate = moment(new Date(lastContact).getTime());
    // For past date
    if (
      currentDate > lastContactDate &&
      currentDate.diff(lastContactDate, "hours") < 24 &&
      !showTimestamp
    ) {
      return lastContactDate.fromNow();
    } else if (
      lastContactDate > currentDate &&
      lastContactDate.diff(currentDate, "hours") < 24 &&
      !showTimestamp
    ) {
      // For future date
      return lastContactDate.fromNow();
    } else {
      return `${moment(new Date(lastContact).getTime()).format(
        TIME_DATE_FORMAT,
      )} ${getTimezoneToDisplay(lastContact)}`;
    }
  }
  return "-";
}

function getStatsConfig(statsMode) {
  if (statsMode) {
    switch (statsMode.toLowerCase()) {
      case "slow":
        return getCurrentlocaleText("statsConfig.slow");
      case "fast":
        return getCurrentlocaleText("statsConfig.fast");
      case "off":
        return getCurrentlocaleText("statsConfig.off");
      default:
        return getCurrentlocaleText("statsConfig.fast");
    }
  }
  return getCurrentlocaleText("statsConfig.fast");
}

function getNodeStatusColorCode(statusTxt) {
  if (statusTxt) {
    switch (statusTxt.toLowerCase()) {
      case "new":
        return "new";
      case "unreachable":
        return "unreachable";
      case "alive":
        return "alive";
      case "rebooting":
        return "rebooting";
      default:
        return "";
    }
  }
  return "";
}

function getTranslatedString(string) {
  switch (string) {
    case "REBOOT":
      return "REBOOTED";
    default:
      return string;
  }
}

function getPodStatusColorCode(statusTxt) {
  if (statusTxt) {
    switch (statusTxt.toLowerCase()) {
      case "pending":
        return "pending";
      case "healthy":
        return "healthy";
      case "terminated":
        return "terminated";
      case "unhealthy":
        return "unhealthy";
      case "unknown":
        return "unknown";
      case "restarting":
        return "restarting";
      default:
        return "";
    }
  }
  return "";
}

function getWebhookStatusColorCode(statusTxt) {
  if (statusTxt) {
    switch (statusTxt.toLowerCase()) {
      case "failing":
        return "iotium-warning";
      case "failed":
        return "iotium-danger";
      case "recovered":
        return "iotium-success";
      default:
        return "";
    }
  }
  return "";
}

function getDaysLeftInfo(not_after) {
  if (not_after) {
    let lastContact = nanoToDateString(not_after);
    let currentDate = moment(new Date().getTime());
    let lastContactDate = moment(new Date(lastContact).getTime());
    let time = lastContactDate.diff(currentDate, "days");
    let minutes = lastContactDate.diff(currentDate, "minutes");
    if (time != undefined) {
      if (time < 1) {
        if (minutes < 1) {
          return getCurrentlocaleText(
            "users.list.expired_day_difference.text",
            {
              0: formatTimestampToIotiumLastAtTimeString(not_after, true),
            },
          );
        } else {
          return getCurrentlocaleText(
            "users.list.expires_day_difference.text",
            {
              0: formatTimestampToIotiumLastAtTimeString(not_after, true),
            },
          );
        }
      } else if (time > 1)
        return getCurrentlocaleText("users.list.expired_days_difference.text", {
          0: Math.abs(time) + " " + getCurrentlocaleText("myprofile.days.text"),
          1: formatTimestampToIotiumLastAtTimeString(not_after, true),
        });
      else
        return getCurrentlocaleText("users.list.expired_days_difference.text", {
          0: Math.abs(time) + " " + getCurrentlocaleText("myprofile.day.text"),
          1: formatTimestampToIotiumLastAtTimeString(not_after, true),
        });
    } else {
      return "NA";
    }
  } else {
    return formatTimestampToIotiumLastAtTimeString(not_after, true);
  }
}

function getNodeUptime(node) {
  if (
    node &&
    node.status &&
    node.status.node &&
    node.status.node.info &&
    node.status.node.info.boot_time
  ) {
    let time = node.status.node.info.boot_time,
      bootTime = momentTimezone(time),
      bootTZ = moment(time).tz(),
      currentTimestamp = moment(new Date().getTime()).tz(bootTZ),
      bootTimestamp = moment(new Date(bootTime).getTime()).tz(bootTZ);
    let minutes = currentTimestamp.diff(bootTimestamp, "minutes");
    if (minutes == 0) {
      // To display seconds
      return "a few seconds";
    } else if (minutes == 1) {
      // To display minute
      return `${minutes} minute`;
    } else if (minutes > 1 && minutes < 60) {
      // To display minutes
      return `${minutes} minutes`;
    } else if (minutes >= 60 && minutes < 60 * 24) {
      // To display hours and minutes
      let h = parseInt(minutes / 60);
      let m = minutes % 60;
      let result = `${h} ` + (h === 1 ? "hour" : "hours");
      if (m > 0) {
        result += `, ${m} ` + (m === 1 ? "minute" : "minutes");
      }
      return result;
    } else {
      // To display days, hours, minutes
      let d = parseInt(minutes / (60 * 24));
      let h = parseInt((minutes % (60 * 24)) / 60);
      let m = (minutes % (60 * 24)) % 60;
      let result = `${d} ` + (d === 1 ? "day" : "days");
      if (h > 0) {
        result += `, ${h} ` + (h === 1 ? "hour" : "hours");
      }
      if (m > 0) {
        result += `, ${m} ` + (m === 1 ? "minute" : "minutes");
      }
      return result;
    }
  }
  return NOT_APPLICAPLE;
}

// converts ISO timestamp string format form epoch time
function convertEpochToISOTimestampString(epoch_timestamp) {
  if (!epoch_timestamp) {
    return "";
  }
  return new Date(epoch_timestamp).toISOString();
}

function getAvailableTimezones() {
  let timeZones = moment.tz.names();
  let availableTZ = [];
  for (let i in timeZones) {
    let timeZone = timeZones[i];
    if (!RESTRICTED_TIME_ZONE.includes(timeZone)) {
      availableTZ.push({
        label: timeZone + " (UTC" + moment.tz(timeZone).format("Z") + ")",
        value: timeZone,
      });
    }
  }
  return availableTZ;
}
function onRangeChange(from, to) {
  let result = [];

  if (from && to) {
    result.push(moment(from));
    result.push(moment(to));
  } else {
    result.push(null);
    result.push(null);
  }
  return result;
}
/**
 *
 * @param {*} value checks the given value is date object prototype or not.
 */
function isDate(value) {
  return value instanceof Date;
}
/**
 *
 * @param {*} value checks the given value is regex object prototype or not.
 */
function isRegExp(value) {
  return value instanceof RegExp;
}
/**
 *
 * @param {*} value checks the given value is function instance prototype or not.
 */
function isFunction(value) {
  return value instanceof Function;
}

function getMatches(obj, regex) {
  let labels = [];
  let conditions = obj.split(regex);
  conditions.forEach(d => {
    if (d.length > 0 && /^[A-Z]/gim.test(d)) {
      if (
        !(
          d.includes("if") ||
          d.includes("elseif") ||
          d.includes("else") ||
          d.includes("endif")
        )
      ) {
        inValidContent.push(d);
      } else {
        labels.push(d);
      }
    }
  });
  return labels;
}

function checkTwigAllowedConditions(data) {
  let inValidContent = [];
  if (data) {
    let conditions = data.split(/{% (.*?) %}/);
    conditions.forEach(d => {
      if (d.length > 0 && /^[A-Z]/gim.test(d)) {
        if (
          !(
            d.includes("if") ||
            d.includes("elseif") ||
            d.includes("else") ||
            d.includes("endif")
          )
        ) {
          inValidContent.push(d);
        }
      }
    });
  }
  return inValidContent.length > 0 ? false : true;
}
function normalizeTemplates(spec, computed) {
  //While applying twig all the keys with {{*}} will replaced, to avoid that just replace that with !@*@!
  spec = spec.replace(/{{/g, "!@");
  spec = spec.replace(/}}/g, "@!");
  var template = Twig.twig({
    data: spec,
  });
  spec = template.render(computed);
  spec = spec.replace(/!@/g, "{{");
  spec = spec.replace(/@!/g, "}}");
  return spec;
}
function removeByObjectKey(obj, keyword) {
  Object.keys(obj).forEach(key =>
    obj[key] === keyword ? delete obj[key] : "",
  );
  return obj;
}

function isValidURL(URL = null) {
  let urlRegex = /^(http(s)?:\/\/)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&/=$\-!*'(),;]*)$/;
  if (URL) {
    return urlRegex.test(URL);
  }
  return false;
}

function isValidFontURL(URL = null) {
  let urlRegex = /^$|(http(s)?:\/\/)fonts\.googleapis\.com\b([-a-zA-Z0-9@:%_\+.~#?&/=$\-!*\'(),;]*)/;
  if (URL) {
    return urlRegex.test(URL);
  }
  return false;
}

function isValidDomainName(domainName = null) {
  // Pattern for domain begins and ends with letter or digit
  let domainNameRegex = /^((?!-)[A-Za-z0-9-]{1,49}[A-Za-z0-9])$/;
  if (domainName) {
    return domainNameRegex.test(domainName);
  }
  return false;
}

function isChrome() {
  var userAgent = navigator.userAgent.toLowerCase(),
    browser = "",
    version = 0;

  // Detect Chrome
  var chromeAgent = userAgent.indexOf("chrome") > -1;

  // Detect Safari
  var safariAgent = userAgent.indexOf("safari") > -1;

  // Discard Safari since it also matches Chrome
  if (chromeAgent && safariAgent) safariAgent = false;

  // Detect Opera
  var operaAgent = userAgent.indexOf("op") > -1;

  // Discard Chrome since it also matches Opera
  if (chromeAgent && operaAgent) chromeAgent = false;

  if (!chromeAgent) {
    return false;
  }
  return true;
}

function humanize(num, size, units) {
  var unit;
  for (unit = units.pop(); units.length && num >= size; unit = units.pop()) {
    num /= size;
  }
  return [num, unit];
}

// Following the Metric naming convention
function humanizeMetric(num, state = false, isCeil = false) {
  num = num >= 0 ? num : 0;
  var ret = humanize(
    num,
    1000,
    !state
      ? ["Tbps", "Gbps", "Mbps", "Kbps", "bps"]
      : ["TB", "GB", "MB", "KB", "Bytes"],
  );
  let value =
    isCeil && ret[0] ? Math.ceil(ret[0].toFixed(2)) : ret[0].toFixed(2);
  return value + " " + ret[1];
}

function getFontFamilyFromURL(font_source_url) {
  let fonts = [];
  // Extract font family from url
  // Reference https://developers.google.com/fonts/docs/getting_started
  let fontURL = new URL(font_source_url);
  // Get search params
  let searchParams = fontURL.searchParams;
  if (searchParams) {
    // Extract family (font family)
    // In google font api extract font family name by the "family" query params
    let familyParam = searchParams.getAll("family");
    if (familyParam) {
      // Split multiple font by the (|) separator if any
      let fontFamilies = [];
      //let fontFamilies = familyParam.split("|");
      familyParam.forEach(family => {
        fontFamilies = fontFamilies.concat(family.split("|"));
        let fontFamily = [];
        fontFamilies.forEach(font => {
          if (font != null) {
            fontFamily.push(font.split(":")[0]);
          }
        });
        fonts.push(fontFamily);
      });
    }
  }
  return fonts;
}

function getTagFromReservedLabels(labels) {
  let tags = [];
  if (labels) {
    if (labels["_iotium.cluster.name"]) {
      tags.push(labels["_iotium.cluster.name"]);
    }
    if (labels["_iotium.cluster.candidate"] === "true") {
      tags.push("Candidate");
    }
    if (labels["_iotium.cluster.candidate"] === "false") {
      tags.push("Non-Candidate");
    }
  }
  return tags;
}

function arraysEqual(a, b) {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.
  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

function getComponentDefaultValues(type) {
  const componentDefaultObject = {
    ServiceNameTextBox: {
      value: null,
    },
    DropDown: {
      value: [],
    },
    License: {
      name: null,
      id: null,
      mount_path: null,
    },
    ipAddress: {
      value: null,
    },
    FileUpload: {},
    Textarea: {
      value: null,
    },
    EULA: {},
    NetworkList: {},
    SecretList: {},
    CheckBox: {
      value: null,
    },
    RadioBox: {
      value: null,
    },
    Secret: {
      name: null,
      id: null,
      mount_path: null,
      files: [],
    },
    TextArea: {
      value: null,
    },
    TextBox: {
      value: null,
    },
    NodeNetworkList: {
      node_id: null,
      network_id: null,
      ip: null,
      node: {},
      network: {},
      kind: null,
      cluster_id: null,
      node_selector: null,
    },
    PasswordBox: {
      value: null,
    },
    DockerConfig: {
      name: null,
      id: null,
      mount_path: null,
      files: [],
      value: [null],
    },
    JsonInputBox: {
      value: null,
    },
    DnsComponent: {
      dns: [null],
      dns_policy: "Default",
    },
    ToggleInput: {
      value: false,
    },
    NICList: {
      value: null,
    },
  };
  if (type) {
    return componentDefaultObject[type];
  } else {
    return null;
  }
}

function toTitleCase(str) {
  return str.replace(/\w\S*/g, function(txt) {
    let upperCaseCount = 0;
    if (txt) {
      for (let i = 1; i < txt.length; i++) {
        if (txt.charAt(i) === txt.charAt(i).toUpperCase()) {
          upperCaseCount = +1;
        }
      }
    }
    if (upperCaseCount <= 0)
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    else return txt;
  });
}

function toSentenceCase(strval) {
  var newstrs = strval.split(".");
  var finalstr = "";
  for (var i = 0; i < newstrs.length; i++)
    finalstr =
      finalstr +
      "." +
      newstrs[i].substr(0, 2).toUpperCase() +
      newstrs[i].substr(2);
  return finalstr.substr(1);
}

function formConnectNetworkData(data = []) {
  let connetedNetworks = [];
  let selectorConstructor = function(values) {
    let fw_selector_values = {};
    if (values) {
      values.forEach(value => {
        let data = value.split(":");
        fw_selector_values[data[0].trim()] = data[1].trim();
      });
    }
    return fw_selector_values;
  };
  if (data) {
    data.forEach(connectedNw => {
      if (connectedNw.network_id !== null) {
        let network =
          connectedNw.peer_representation_network ||
          connectedNw.local_representation_network
            ? {
                network_id: connectedNw.network_id,
                peer_representation_network:
                  connectedNw.peer_representation_network,
                local_representation_network:
                  connectedNw.local_representation_network,
                firewall_selector: Array.isArray(connectedNw.firewall_selector)
                  ? {
                      match_label: selectorConstructor(
                        connectedNw.firewall_selector,
                      ),
                    }
                  : connectedNw.firewall_selector,
                disable_representation_network_automation:
                  connectedNw.disable_representation_network_automation,
              }
            : {
                network_id: connectedNw.network_id,
                firewall_selector: Array.isArray(connectedNw.firewall_selector)
                  ? {
                      match_label: selectorConstructor(
                        connectedNw.firewall_selector,
                      ),
                    }
                  : connectedNw.firewall_selector,
                disable_representation_network_automation:
                  connectedNw.disable_representation_network_automation,
              };
        connetedNetworks.push(network);
      }
    });
  }
  return connetedNetworks;
}

function isMultinicAllowedNode(nodeVersionString) {
  const [
    MULTINIC_MAJOR_VERSION,
    MULTINIC_MINOR_VERSION,
    MULTINIC_PATCH_VERSION,
  ] = MULTINIC_VERSION.split(".").map(Number);
  const [
    NODE_MAJOR_VERSON = 0,
    NODE_MINOR_VERSON = 0,
    NODE_PATCH_VERSON = 0,
  ] = (nodeVersionString || "0.0.0").split(".").map(Number);
  if (NODE_MAJOR_VERSON < MULTINIC_MAJOR_VERSION) {
    return false;
  }
  if (
    NODE_MAJOR_VERSON === MULTINIC_MAJOR_VERSION &&
    NODE_MINOR_VERSON < MULTINIC_MINOR_VERSION
  ) {
    return false;
  }
  if (
    NODE_MAJOR_VERSON === MULTINIC_MAJOR_VERSION &&
    NODE_MINOR_VERSON === MULTINIC_MINOR_VERSION &&
    NODE_PATCH_VERSON <= MULTINIC_PATCH_VERSION
  ) {
    return false;
  }
  return true;
}

//LAT-7987 to change version
const MultiTANBaseVersion = 1502;
//LAT-7987 to change version
const MultiTANTunnelBlockBaseVersion = 1476;
//LAT-8486 to change version for datasaver mode and pod-dns
const DataSaverAndPodDnsBaseVersion = 1564;
//LAT-8901 New log infra base version
const LiveLogInfraBaseversion = 1565;
//LAT-8901 New log infra base version
const softwareUpgradeBaseVersion = 1641;
//LAT-9702 Static route supported version
const StaticRoutesBaseVersion = 1703;
// LAT-10036 Virtual side routing
const VirtualRoutesBaseVersion = 1714;
// LAT-11153 Restart Service
const RestartServiceBaseVersion = 1870;
// LAT-16374 Inter tan routing
const InterTanRouteBaseVersion = 2776;
// LAT-18105 MuntiNIC
const WanInterfaceDisabledVendor = "Advantech/UNO-2484G-734xAE";
const MULTINIC_VERSION = "3386.1.40";
// LAT-11531 DHCP:Limit node version to enable DHCP
const dynamicNetworkModeBaseVersion = 2208;
const serviceListenerBaseVersion = 2438;
const tanDadBaseVersion = 2642;
const resourceLimitBaseVersion = 2641;
const nwDeviceDiscoveryMaxVersion = 3201;
// Stats bandwidth reduction default mode
const DEFAULT_STAT_MODE = "FAST";

// Node network mode options
const DEFAULT_MODE = "DEFAULT_MODE";
const ONE_ARM_MODE = "ONE_ARM_MODE";

// Maximum allowed containers to view stats
const CONTAINER_STATS_LIMIT = 5;
function getdefaultHeaderValue() {
  let options = [];
  DEFAULT_HEADERS_OPTIONS.sort().forEach(OPTIONS => {
    options.push(OPTIONS);
  });
  return options;
}

function getSamplingFreqMarks() {
  return {
    100000: {
      label: (
        <small>
          <strong style={{ paddingRight: 25 }}>Low</strong>
        </small>
      ),
    },
    50000: {
      label: (
        <small>
          <strong>Medium</strong>
        </small>
      ),
    },
    5000: {
      label: (
        <small>
          <strong style={{ paddingLeft: 25 }}>High</strong>
        </small>
      ),
    },
  };
}

function getScanType() {
  return [
    {
      label: "Basic",
      value: "basic",
      desc: getCurrentlocaleText("basic.scan.useful.text"),
    },
    {
      label: "Balanced",
      value: "balanced",
      desc: getCurrentlocaleText("balanced.scan.useful.text"),
    },
    {
      label: "Deep",
      value: "deep",
      desc: getCurrentlocaleText("deep.scan.useful.text"),
    },
    {
      label: "BACNet",
      value: "bacnet",
      desc: getCurrentlocaleText("bacnet.scan.useful.text"),
    },
  ];
}

export {
  IOTIUM_USER,
  ROOT_ORG,
  isCidr,
  isLoggedIn,
  getIP,
  getNodeVersion,
  getLastContactedAt,
  nanoToDateString,
  milliToDateString,
  randomAlphaNumericString,
  focusFirstInput,
  NOT_APPLICAPLE,
  highlightSearchText,
  customSort,
  isEllipsisActive,
  notificationModal,
  capitalize,
  ipAddressSort,
  DETAULT_DOCUMENT_TITLE,
  getLastActivityAt,
  getDocumentTitle,
  getGaTitle,
  getResourceForDisplay,
  isDevelopmentMode,
  getFirewallProtocolAndPort,
  getPasswordExpireUrl,
  getCIDRdata,
  getIPListFromCidr,
  frequenceConvertion,
  isRestrictedCIDR,
  clearConsole,
  getMomentDate,
  calcStepSize,
  copyObject,
  isCidrRange,
  isIPRangeOverlaps,
  passwordValidator,
  getErrorMessage,
  getServiceErrorMessage,
  getCurrentlocaleText,
  toTimezone,
  stringToRegex,
  isArray,
  isObjectOrFunction,
  isEmpty,
  deepMapValues,
  getNetworkStatus,
  getNetworkStatusColorCode,
  getNetworkStatusMessage,
  isCameFromDashboardPage,
  getInodeDetailsBreadcrumbUrl,
  getInodeDetailsBreadcrumbLinkName,
  getDefaultTimezone,
  getTimezoneToDisplay,
  getAlertTitle,
  getOrgPolicyCounInfo,
  removeDefaultLabels,
  markCollapseHeader,
  getTagKeyValues,
  EpochToUserTime,
  getLastSSHAccess,
  getLastConsoleAccess,
  UniqueArrayElements,
  isEqual,
  searchArrayEleWithRegex,
  ALERT,
  getAlertScope,
  getAlertOrgScope,
  getAlertStatusDisplay,
  getAlertDurationDisplay,
  getAlertConditionDisplay,
  PlotTimeDiff,
  formatRemainingTimeDiff,
  computeDurationToMinutes,
  computeMinutesToDuration,
  checkforVirtualProfiles,
  checkforFeatureEnabled,
  isoToDateString,
  validNameRegex,
  CIDRV4REGEX,
  formatTimestampToIotiumLastAtTimeString,
  checkFordRepeatedInterfaces,
  MultiTANBaseVersion,
  getStatsConfig,
  DEFAULT_STAT_MODE,
  getNodeStatusColorCode,
  getPodStatusColorCode,
  getDaysLeftInfo,
  MultiTANTunnelBlockBaseVersion,
  getNodeUptime,
  DataSaverAndPodDnsBaseVersion,
  isArrayOfStrings,
  convertEpochToISOTimestampString,
  TIME_DATE_FORMAT,
  LiveLogInfraBaseversion,
  getAvailableTimezones,
  onRangeChange,
  isDate,
  isRegExp,
  isFunction,
  removeByObjectKey,
  softwareUpgradeBaseVersion,
  getTranslatedString,
  StaticRoutesBaseVersion,
  getInterval,
  DefaultStatsWidget,
  isValidURL,
  isValidFontURL,
  VirtualRoutesBaseVersion,
  DEFAULT_MODE,
  ONE_ARM_MODE,
  OverviewDefaultStatsWidget,
  isChrome,
  RestartServiceBaseVersion,
  InterTanRouteBaseVersion,
  getWebhookStatusColorCode,
  humanizeMetric,
  isValidDomainName,
  isTanCidrRange,
  dynamicNetworkModeBaseVersion,
  CONTAINER_STATS_LIMIT,
  makeEmpty,
  getFontFamilyFromURL,
  getMatches,
  checkTwigAllowedConditions,
  normalizeTemplates,
  getTagFromReservedLabels,
  arraysEqual,
  getComponentDefaultValues,
  toTitleCase,
  toSentenceCase,
  TECHDUMP_PROFILE,
  isMasteriNode,
  elapsedTimeString,
  getdefaultHeaderValue,
  serviceListenerBaseVersion,
  tanDadBaseVersion,
  resourceLimitBaseVersion,
  nwDeviceDiscoveryMaxVersion,
  getConfirmMFAUrl,
  getInitMFAUrl,
  formConnectNetworkData,
  getSamplingFreqMarks,
  getScanType,
  isMultinicAllowedNode,
  INTERFACE_DIAGRAM_AVAILABLE_HARDWARES,
  IPADDRESSREGEX,
  WanInterfaceDisabledVendor,
};
