import React, { Component } from "react";
import { Row, Form, Col, Select, Radio, Input } from "antd";
import InputBox from "components/UI-Components/InputBox";
import { observer, inject } from "mobx-react";
import propTypes from "prop-types";
import _ from "lodash";
import {
  focusFirstInput,
  getCurrentlocaleText,
  validNameRegex,
  copyObject,
} from "Core/Utils";
import { SecretList } from "components/serviceTemplates/lib/HtmlElements";

import { computed } from "mobx";
import Twig from "twig";

const FormItem = Form.Item;
const Option = Select.Option;
const RadioGroup = Radio.Group;
const { TextArea } = Input;

const helperObjects = {
  dockerContainerHelp: {
    title: getCurrentlocaleText("dockerconfig"),
    data: [
      {
        subtitle: "",
        content: `${getCurrentlocaleText("pull.secret.textarea.helptext")}`,
      },
    ],
  },
  secretDockerNameHelpObj: {
    help: {
      title: `${getCurrentlocaleText("pull.secret")} Name`,
      data: [
        {
          subtitle: "",
          content: `${getCurrentlocaleText("pull.secret.name.helptext")}`,
        },
      ],
    },
  },
};
@inject("ModelStore", "SecretViewModel", "UiStore")
@observer
export default class SecretDockerConfig extends Component {
  constructor(props) {
    super(props);
    this.secretModel = this.props.SecretViewModel;
  }

  componentDidMount() {
    /* Focus First Input */
    focusFirstInput();
    /*required as output from select is an array*/
    let defaultValue =
      this.props.properties && this.props.properties.defaultValue
        ? this.props.properties.defaultValue.value
          ? this.props.properties.defaultValue.value
          : this.props.properties.defaultValue.toString()
        : null;

    /* if default val is present , update it in the secretList drop down */
    if (
      !this.props.UiStore.templatingService.secrets[this.props.id] &&
      defaultValue
    ) {
      this.props.UiStore.templatingService.secrets[this.props.id] = {
        previousSelectedLicense: defaultValue,
      };
    }
  }

  @computed
  get componentVisibility() {
    let visibility = null;
    if (
      this.props.UiStore.computedOutputObject &&
      this.props.UiStore.computedOutputObject.steps
    ) {
      if (this.props.properties.visibility) {
        var template = Twig.twig({
          data: this.props.properties.visibility,
        });
        let computedOutputObject = copyObject(
          this.props.UiStore.computedOutputObject,
        );
        visibility = template.render(computedOutputObject);
        try {
          visibility = JSON.parse(visibility);
        } catch {}
      }
    }
    return visibility ? true : false;
  }

  selectedValue = (elm, type = "dockerconfig") => {
    let dockerNames = [];
    for (let element in elm) {
      let e = elm[element];
      let keys = Object.keys(this.props.UiStore.templatingService.secrets);
      keys.indexOf(this.props.id) < 0 &&
        (this.props.UiStore.templatingService.secrets[this.props.id] = {});

      this.props.UiStore.templatingService.secrets[
        this.props.id
      ].previousSelectedLicense = e;
      let in_dx = this.secretModel.secretsObj[type].findIndex(object => {
        return object.id === e;
      });
      if (in_dx != -1) {
        dockerNames.push(this.secretModel.secretsObj[type][in_dx].name);
      }
    }
    this.props.updateCurrentValueOnChange(dockerNames, "value");
  };

  updateHelp = (data, e) => {
    this.props.UiStore.SetStoreData("helpData", data);
    setTimeout(() => {
      let elm = document.querySelector(".help-content");
      if (elm) {
        elm.innerHTML =
          this.props.UiStore.serviceFormData[`assignedServiceCount`] &&
          this.props.UiStore.serviceFormData[`assignedServiceCount`] > 0 &&
          data &&
          data.data &&
          data.data[0] &&
          data.data[0].content
            ? data.data[0].content +
              "</br>" +
              getCurrentlocaleText("volume.note.text")
            : data.data[0].content;
      }
    }, 10);
  };
  render() {
    const { getFieldDecorator } = this.props.referenceForm;
    let id = this.props.id ? this.props.id : "secretdockerlist",
      required =
        this.props.properties &&
        this.props.properties.constraints &&
        this.props.properties.constraints.required
          ? this.props.properties.constraints.required
          : false,
      validationMessage =
        this.props.properties &&
        this.props.properties.constraints &&
        this.props.properties.constraints.validationMessage
          ? this.props.properties.constraints.validationMessage
          : `Docker configurationion is required`;

    let namefield = `${getCurrentlocaleText("pull.secret")} Name`;

    let visibility =
      this.props.properties && this.props.properties.visibility
        ? this.componentVisibility
        : true;

    return visibility ? (
      <div>
        {this.props.renderFrom &&
          this.props.renderFrom.toLowerCase() == "licensecomponent" && (
            <div>
              <Row gutter={8}>
                <Col span={20}>
                  {
                    <FormItem
                      label={namefield}
                      hasFeedback
                      key="secretCertiName"
                    >
                      {getFieldDecorator(`secretCertiName`, {
                        rules: [
                          {
                            required: true,
                            message: ` Please enter ${namefield}`,
                            whitespace: true,
                          },
                          {
                            max: 255,
                            message: getCurrentlocaleText(
                              "dockerconfig.name.limit",
                            ),
                          },
                          {
                            pattern: validNameRegex(),
                            message: getCurrentlocaleText(
                              "dockerconfig.name.splchars",
                            ),
                          },
                        ],
                        initialValue:
                          this.props.actionType == "edit" &&
                          this.props.UiStore.serviceFormData
                            ? this.props.UiStore.serviceFormData[
                                `secretCertiName`
                              ]
                            : "",
                      })(
                        <InputBox
                          type="input"
                          autoComplete="off"
                          className="firstInput"
                          placeholder={namefield}
                          onChange={this.props.inputChange}
                          id="secretCertiName"
                          onFocus={this.updateHelp.bind(
                            null,
                            helperObjects.secretDockerNameHelpObj.help,
                          )}
                          disabled={this.props.disableName}
                        />,
                      )}
                    </FormItem>
                  }
                </Col>
              </Row>
              <Row gutter={8} style={{ marginBottom: "20px" }}>
                <Col span={20}>
                  {
                    <FormItem label={`${getCurrentlocaleText("dockerconfig")}`}>
                      {getFieldDecorator(`docker_container`, {
                        rules: [
                          {
                            required: true,
                            message: `Please enter a valid docker configuration schema `,
                          },
                        ],
                        initialValue:
                          this.props.actionType == "edit" &&
                          this.props.UiStore.serviceFormData
                            ? this.props.UiStore.serviceFormData
                                .docker_container
                            : "",
                      })(
                        <TextArea
                          style={{ maxHeight: "300px" }}
                          onChange={this.props.inputChange}
                          placeholder={getCurrentlocaleText(
                            "pull.secret.textarea.placeholder",
                          )}
                          onFocus={this.updateHelp.bind(
                            null,
                            helperObjects.dockerContainerHelp,
                          )}
                          id="ssh_container"
                          autosize={{ minRows: 4, maxRows: 10 }}
                        />,
                      )}
                    </FormItem>
                  }
                </Col>
              </Row>
            </div>
          )}

        {/* only for render in service templates */
        this.props.renderFrom &&
          this.props.renderFrom.toLowerCase() == "servicetemplate" && (
            <div>
              <SecretList
                referenceForm={this.props.referenceForm}
                id={id}
                onFocus={this.props.onFocus}
                org_id={
                  this.props.urlParams.params.org_id
                    ? this.props.urlParams.params.org_id
                    : this.props.UiStore.currentUser.organization.id
                }
                defaultValue={
                  this.props.properties &&
                  this.props.properties.defaultValue.value
                }
                urlParams={this.props.urlParams}
                properties={{
                  label: this.props.properties.label,
                  selectedValue: e => {
                    this.selectedValue(e, "dockerconfig");
                  },
                  options: {
                    type: "dockerconfig",
                    mode: "multiple",
                  },
                  constraints: {
                    required: required,
                    validationMessage: validationMessage,
                  },
                  help: this.props.properties.help
                    ? this.props.properties.help
                    : "",
                }}
              />
            </div>
          )}
      </div>
    ) : (
      <div></div>
    );
  }
}

SecretDockerConfig.propTypes = {
  referenceForm: propTypes.any,
  id: propTypes.string,
  properties: propTypes.shape({
    label: propTypes.string.isRequired,
    constraints: propTypes.shape({
      validationMessage: propTypes.string,
      required: propTypes.bool,
    }),
  }),
};

/*

sample template schema for reference
{
  "type": "DockerConfig",
  "visible": true,
  "props": {
    "label": "Select docker configuration",
    "help" : "docker help text",
    "constraints": {
      "required": false,
      "validationMessage": "Please select a Docker configuration"
    }

  }
}

*/
