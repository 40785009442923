import React, { Component } from "react";
import { Card, Col, Row, Progress, Select } from "antd";
import { customSort, getCurrentlocaleText, humanizeMetric } from "Core/Utils";
import { observer, inject } from "mobx-react";

const { Option } = Select;

@inject(
  "AuthStore",
  "EventViewModel",
  "ServiceStatsViewModel",
  "NodeStatsViewModel",
)
@observer
class ContainerOverview extends Component {
  constructor(props) {
    super(props);
    // GLOBAL_SETTINGS is configured form node js
    this.GLOBAL_SETTINGS = GLOBAL_SETTINGS;
    this.textColor =
      this.GLOBAL_SETTINGS && this.GLOBAL_SETTINGS.text_color
        ? this.GLOBAL_SETTINGS.text_color
        : "#012a22";
    this.primaryColor =
      this.GLOBAL_SETTINGS && this.GLOBAL_SETTINGS.primary_color
        ? this.GLOBAL_SETTINGS.primary_color
        : "#065545";
    this.state = {
      currentContainer:
        this.props.NodeStatsViewModel.all_container_list_view &&
        this.props.NodeStatsViewModel.all_container_list_view[0]
          ? this.props.NodeStatsViewModel.all_container_list_view[0]
          : null,
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (
      this.props.NodeStatsViewModel.all_container_list_view !=
      newProps.NodeStatsViewModel.all_container_list_view
    ) {
      this.setState({
        currentContainer:
          newProps.NodeStatsViewModel.all_container_list_view.length > 0 &&
          newProps.NodeStatsViewModel.all_container_list_view.sort((x, y) =>
            customSort(x, y),
          )[0],
      });
    }
  }

  getContainerForView = () => {
    let options = this.props.NodeStatsViewModel.all_container_list_view
      ? this.props.NodeStatsViewModel.all_container_list_view
      : [];
    if (options.length > 0) options = options.sort((x, y) => customSort(x, y));

    let containerOptions = [];
    if (options.length > 0) {
      options.forEach((item, i) => {
        containerOptions.push(
          <Option key={i} value={item} title={item}>
            {item}
          </Option>,
        );
      });
    }

    return containerOptions;
  };

  changeContainer = value => {
    this.setState({ currentContainer: value });
  };

  render() {
    let currentContainer = this.state.currentContainer
      ? this.state.currentContainer
      : this.props.NodeStatsViewModel.all_container_list_view &&
        this.props.NodeStatsViewModel.all_container_list_view.sort((x, y) =>
          customSort(x, y),
        )[0];
    let data =
      this.props.NodeStatsViewModel &&
      this.props.NodeStatsViewModel.allContainerOverview
        ? this.props.NodeStatsViewModel.allContainerOverview
        : null;
    let current_data =
      data && data[currentContainer] ? data[currentContainer] : {};
    let cpu_live_info =
      current_data && current_data.cpu_live >= 0 ? current_data.cpu_live : "NA";

    let memory_live_info =
      current_data && current_data.memory_live >= 0
        ? current_data.memory_live
        : "NA";

    let fs_live_info =
      current_data && current_data.fs_live >= 0 ? current_data.fs_live : "NA";
    let extra_info = current_data.extra_info ? current_data.extra_info : {};
    let total_fs = this.props.NodeStatsViewModel.getTotalFileSystemValue;
    return (
      <Card
        title={
          <span>
            <Row>
              <Col span={12}>
                <span>{"Container Overview"}</span>
              </Col>
              <Col span={12} style={{ top: 8 }}>
                {this.props.NodeStatsViewModel.all_container_list_view &&
                  this.props.NodeStatsViewModel.all_container_list_view.length >
                    0 && (
                    <span>
                      <Select
                        key={"interface"}
                        value={currentContainer}
                        onChange={this.changeContainer}
                        className="network-over-selector"
                        width={50}
                        notFoundContent={getCurrentlocaleText(
                          "general.notfound.placeholder.text",
                        )}
                      >
                        {this.getContainerForView()}
                      </Select>
                    </span>
                  )}
              </Col>
            </Row>
          </span>
        }
        bordered={false}
        hoverable={false}
        bodyStyle={{ display: "grid", justifyContent: "center" }}
      >
        <div style={{ display: "flex" }}>
          {" "}
          <span>
            <Card bordered={false} hoverable={false}>
              <Row type="flex" align="middle" justify="center">
                <Col span={24}>
                  <center>
                    {cpu_live_info && cpu_live_info != "NA" ? (
                      <Progress
                        type="dashboard"
                        strokeColor={{
                          "100%": this.primaryColor,
                        }}
                        percent={parseFloat(
                          cpu_live_info && cpu_live_info.toFixed(1),
                        )}
                        format={percent => (
                          <span>
                            <Row>
                              <Col span={24} className="stats-gauge-title-txt">
                                {getCurrentlocaleText(
                                  "stats.card.cpu_memory.title.text",
                                )}
                              </Col>
                              <Col span={24}>{`${percent} %`}</Col>
                            </Row>
                          </span>
                        )}
                        strokeWidth={4}
                        width={120}
                      />
                    ) : (
                      getCurrentlocaleText("stats.nometrics.text")
                    )}
                  </center>
                </Col>
              </Row>
              <Row type="flex" align="middle" justify="center">
                <Col span={24}>
                  <Card
                    bordered={true}
                    hoverable={false}
                    className="stats-dashboard-memory-moreinfo"
                  >
                    <Row>
                      <Col span={24}>
                        <Row>
                          <Col span={24}>
                            <strong>{"Used/Total: "}</strong>
                            {extra_info &&
                            extra_info.cpu &&
                            extra_info.cpu.used &&
                            extra_info.cpu.numberof_cores
                              ? (parseFloat(extra_info.cpu.used)
                                  .toFixed(2)
                                  .toString() === "0.00"
                                  ? 0.01
                                  : parseFloat(extra_info.cpu.used).toFixed(
                                      2,
                                    )) +
                                "/" +
                                extra_info.cpu.numberof_cores +
                                " Cores"
                              : "NA"}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Card>
          </span>
          <span>
            <Card bordered={false} hoverable={false}>
              <span className="card-container">
                <Row type="flex" align="middle" justify="center">
                  <Col span={24}>
                    <center>
                      {memory_live_info >= 0 &&
                      memory_live_info != "NA" &&
                      extra_info &&
                      extra_info.memory &&
                      extra_info.memory.used &&
                      extra_info.memory.total ? (
                        <Progress
                          type="dashboard"
                          strokeColor={{
                            "100%": this.primaryColor,
                          }}
                          percent={parseFloat(
                            memory_live_info && memory_live_info.toFixed(1),
                          )}
                          format={percent => (
                            <span>
                              <Row>
                                <Col
                                  span={24}
                                  className="stats-gauge-title-txt"
                                >
                                  {getCurrentlocaleText(
                                    "stats.card.memory.title.text",
                                  )}
                                </Col>
                                <Col span={24}>{`${percent} %`}</Col>
                              </Row>
                            </span>
                          )}
                          strokeWidth={4}
                          width={120}
                        />
                      ) : (
                        getCurrentlocaleText("stats.nometrics.text")
                      )}
                    </center>
                  </Col>
                </Row>
                <Row type="flex" align="middle" justify="center">
                  <Col span={24}>
                    <Card
                      bordered={true}
                      hoverable={false}
                      className="stats-dashboard-memory-moreinfo"
                    >
                      <Row>
                        <Col span={24}>
                          <span className="card-container">
                            <strong>{"Used/Total: "}</strong>
                            {extra_info &&
                            extra_info.memory &&
                            extra_info.memory.used &&
                            extra_info.memory.total
                              ? humanizeMetric(extra_info.memory.used, true) +
                                "/" +
                                humanizeMetric(extra_info.memory.total, true)
                              : "NA"}
                          </span>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
                {extra_info &&
                extra_info.memory &&
                extra_info.memory.used >= 0 &&
                extra_info.memory.cache >= 0 &&
                extra_info.memory.free >= 0 &&
                extra_info.memory.total >= 0 ? (
                  <div className="card-overlay stats-dashboard-gauge-hover">
                    <div className="stats-overview-gauge-overlay ">
                      <Card
                        bordered={false}
                        hoverable={false}
                        className="stats-dashboard-memory-moreinfo"
                      >
                        <Row>
                          <Col span={8}>
                            <strong>{"Used: "}</strong>
                          </Col>
                          <Col span={14}>
                            {extra_info &&
                            extra_info.memory &&
                            extra_info.memory.used
                              ? humanizeMetric(extra_info.memory.used, true)
                              : "NA"}
                          </Col>
                        </Row>
                        <Row>
                          <Col span={8}>
                            <strong>{"Cache: "}</strong>
                          </Col>
                          <Col span={14}>
                            {extra_info &&
                            extra_info.memory &&
                            extra_info.memory.cache
                              ? humanizeMetric(extra_info.memory.cache, true)
                              : "NA"}
                          </Col>
                        </Row>
                        <Row>
                          <Col span={8}>
                            <strong>{"Free: "}</strong>
                          </Col>
                          <Col span={14}>
                            {extra_info &&
                            extra_info.memory &&
                            extra_info.memory.free
                              ? humanizeMetric(extra_info.memory.free, true)
                              : "NA"}
                          </Col>
                        </Row>
                        <Row>
                          <Col span={8}>
                            <strong>{"Total: "}</strong>
                          </Col>
                          <Col span={14}>
                            {extra_info &&
                            extra_info.memory &&
                            extra_info.memory.total
                              ? humanizeMetric(extra_info.memory.total, true)
                              : "NA"}
                          </Col>
                        </Row>
                      </Card>
                    </div>
                  </div>
                ) : null}
              </span>
            </Card>
          </span>
          <span>
            <Card
              bordered={false}
              className={"metics-hover-novalue-overflow"}
              hoverable={false}
            >
              <Row type="flex" align="middle" justify="center">
                <Col span={24}>
                  <center>
                    {fs_live_info &&
                    fs_live_info != "NA" &&
                    extra_info &&
                    extra_info.filesystem &&
                    parseInt(extra_info.filesystem.used) >= 0 &&
                    parseInt(total_fs) ? (
                      <Progress
                        type="dashboard"
                        strokeColor={{
                          "100%": this.primaryColor,
                        }}
                        percent={parseFloat(
                          fs_live_info && fs_live_info.toFixed(1),
                        )}
                        format={percent => (
                          <span>
                            <Row>
                              <Col span={24} className="stats-gauge-title-txt">
                                {getCurrentlocaleText(
                                  "stats.filesystem.title.text",
                                )}
                              </Col>
                              <Col span={24}>{`${percent} %`}</Col>
                            </Row>
                          </span>
                        )}
                        strokeWidth={4}
                        width={120}
                      />
                    ) : (
                      getCurrentlocaleText("stats.nometrics.text")
                    )}
                  </center>
                </Col>
              </Row>
              <Row type="flex" align="middle" justify="center">
                <Col span={24}>
                  <Card
                    bordered={true}
                    hoverable={false}
                    className="stats-dashboard-memory-moreinfo"
                  >
                    <Row>
                      <Col span={24}>
                        <strong>{"Used/Total: "}</strong>
                        {extra_info &&
                        extra_info.filesystem &&
                        parseInt(extra_info.filesystem.used) >= 0 &&
                        parseInt(total_fs)
                          ? humanizeMetric(extra_info.filesystem.used, true) +
                            "/" +
                            humanizeMetric(total_fs, true)
                          : "NA"}
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Card>
          </span>
        </div>
      </Card>
    );
  }
}

export default ContainerOverview;
