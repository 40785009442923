import React, { Component } from "react";
import { Alert, Card, Row, Col, Icon, Button, Menu, Dropdown } from "antd";

import { observer, inject } from "mobx-react";
import { Link } from "react-router-dom";
import ActionBtn from "Core/API/ActionBtn";

import { NetworkServiceTab, TechDump } from "components/Containers";

import LoadingComponent from "components/UI-Components/LoadingComponent";
import BreadcrumbComponent from "components/UI-Components/BreadcrumbComponent";
import RebootForm from "components/Forms/RebootForm";
import TanDadForm from "components/Forms/TanDadForm";
import InodesForm from "components/Forms/InodesForm";
import EditiNodeForm from "components/Forms/EditiNodeForm";
import ClusterConversionForm from "components/Clusters/ClusterConversionForm";
import Icons from "components/UI-Components/Icons";
import InodeInfoCard from "components/INodes/InodeInfoCard";

import InodeController from "controller/InodeController";
import OrgController from "controller/OrgController";
import OrgSelector from "components/Orgs/OrgSelector";
import StatsOverviewContainer from "components/Stats/StatsOverviewContainer";
import {
  isCameFromDashboardPage,
  getInodeDetailsBreadcrumbUrl,
  getInodeDetailsBreadcrumbLinkName,
  getCurrentlocaleText,
  LiveLogInfraBaseversion,
  getNodeVersion,
  tanDadBaseVersion,
  InterTanRouteBaseVersion,
} from "Core/Utils";
import CursorMovementInModal from "../CursorMovementInModal";
import InodeCTDDashboard from "./InodeCTDChart";
import InterfaceTrafficView from "../UI-Components/InterfaceTrafficView";
import ClusterController from "../../controller/ClusterController";
import InodeDeviceDiscoveryChart from "./InodeDeviceDiscoveryChart";

@inject(
  "ModelStore",
  "AuthStore",
  "UiStore",
  "InodeViewModel",
  "ClusterViewModel",
  "DadViewModel",
  "OrgViewModel",
  "ServiceViewModel",
)
@observer
class InodesDetails extends Component {
  constructor(props) {
    super(props);
    this.inodeModel = this.props.InodeViewModel;
    this.clusterModel = this.props.ClusterViewModel;
    this.serviceModel = this.props.ServiceViewModel;
    this.dadViewModel = this.props.DadViewModel;
    this.orgModel = this.props.OrgViewModel;
    this.state = {
      nodeId: this.props.match.params.nodeId || this.props.match.params.id,
      nodeDetails: {},
      nodeDadDetails: {},
      BreadcrumbList: [],
      selectedNode: {},
      disableReboot: true,
      disableScanDad: true,
      modelAction: "edit",
      autoOpen: false,
      autoOpenOrgID: null,
      showComponent: false,
      clusterSupport: true,
      threatDetection: false,
      multiNicStatus: true,
      ismanualRebootRequired: false,
      isDeviceDiscoveryExist: false,
      ucgGateway: {},
    };
    this.store = this.props.ModelStore;
    this.color = {};
    // this.updateINode = this.updateINode.bind(this);
    // TODO - SJ - recheck this binding
    this.toggleHover = this.toggleHover.bind(this);
    this.nodeApiByInterval = null;
  }

  toggleHover = (eventKey, domEvent) => {
    // removing default hover background color
    if (eventKey.domEvent) {
      let target = eventKey.domEvent.target;
      target.style.background = "#fff";
    }
  };

  get cameFromOrgDetailPage() {
    if (this.props.match.params.nodeId) {
      return true;
    }
    return false;
  }

  get cameFromDashboardPage() {
    return isCameFromDashboardPage(window.location.pathname);
  }

  get dashboardiNodeURL() {
    return getInodeDetailsBreadcrumbUrl(window.location.pathname);
  }

  get linkNameForiNode() {
    return getInodeDetailsBreadcrumbLinkName(window.location.pathname);
  }

  // To redirect the page to inode list once its deleted.
  redirectToINodeList = resp => {
    // removes id frm local storage if availabe for pop-up confirmation
    if (localStorage.IsCreateNetworkPopShowed) {
      let showediNodes = [];
      showediNodes = JSON.parse(localStorage.IsCreateNetworkPopShowed);
      let index = showediNodes.indexOf(this.state.nodeId);
      if (index !== -1) {
        showediNodes.splice(index, 1);
        localStorage.setItem(
          "IsCreateNetworkPopShowed",
          JSON.stringify(showediNodes),
        );
      }
    }
    /*Required to clear interval after the inode deleted */
    clearInterval(this.nodeApiByInterval);
    //including timeout to show notification message before redirect
    setTimeout(() => {
      let url = window.location.pathname.split("/"),
        updatedurl = "/orgs/" + url[2] + "/inodes";
      this.props.history.push(updatedurl);
    }, 3000);
  };

  iNodeDetails = (
    res = null,
    loading = true,
    forceCall = false,
    needDad = false,
  ) => {
    let map = {
      id: res !== null ? res.id : this.state.nodeId,
    };
    InodeController.getInode(map.id, loading, forceCall).then(res => {
      // Request restricted for new state, virtual and old versions of virtual edge & edge
      if (
        needDad &&
        this.inodeModel.inode &&
        this.inodeModel.inode.node_state &&
        this.inodeModel.inode.node_state.toLowerCase() != "new" &&
        this.inodeModel.inode.profile &&
        this.inodeModel.inode.profile.name &&
        this.inodeModel.inode.profile.name.toLowerCase() != "virtual" &&
        parseFloat(getNodeVersion(this.inodeModel.inode)) >=
          tanDadBaseVersion &&
        res &&
        res.duplicate_address_detection &&
        res.duplicate_address_detection.enable
      ) {
        this.iNodeDadDetails(null, loading, true);
      }

      if (res && res.cluster && res.cluster.id) {
        this.getClusterTrafficInsight(res.cluster.id);
      } else {
        this.clusterModel.setTrafficInsightData({});
        if (
          this.inodeModel.inode.profile &&
          this.inodeModel.inode.profile.name &&
          this.inodeModel.inode.profile.name.toLowerCase() != "virtual"
        ) {
          this.getDeviceDiscovery(map.id);
        }
      }
      if (this.isEdge) {
        this.getNodeUcg(this.inodeModel.inode.id);
      }
      return this.updateInodeDetails(res);
    });
  };

  getClusterTrafficInsight = clusterId => {
    ClusterController.getTrafficInsight(clusterId).then(resp => {
      if (resp) {
        this.clusterModel.setTrafficInsightData({
          resp,
        });
      }
    });
  };

  getDeviceDiscovery = node_id => {
    InodeController.getDeviceDiscovery(node_id)
      .then(res => {
        if (res && res.enable) {
          this.setState({
            isDeviceDiscoveryExist: res.enable,
          });
          this.inodeModel.setDeviceDiscovery(res.enable);
        } else {
          this.setState({
            isDeviceDiscoveryExist: false,
          });
          this.inodeModel.setDeviceDiscovery(false);
        }
      })
      .catch(err => {
        this.inodeModel.setDeviceDiscovery(false);
        this.setState({
          isDeviceDiscoveryExist: false,
        });
      });
  };

  iNodeDadDetails = (res = null, loading = true, forceCall = false) => {
    let map = {
      id: res !== null ? res.id : this.state.nodeId,
    };
    InodeController.getInodeDad(map.id, loading, forceCall).then(res => {
      return res;
    });
  };

  updateInodeDetails = res => {
    if (res && res.id) {
      this.setState(() => {
        let newObj = {},
          nodeDetails = this.state.nodeDetails,
          selectedNode = this.state.selectedNode,
          BreadcrumbList = [],
          disableReboot = this.state.disableReboot,
          disableScanDad = this.state.disableScanDad;
        nodeDetails = res;

        selectedNode[res.id] = {
          value: true,
          status: res.node_state && res.node_state.toLowerCase(),
          name: res.name,
          id: res.id,
          hardware_serial_number: res.hardware_serial_number,
        };
        res.node_state &&
        res.node_state.toLowerCase() === "alive" &&
        (this.props.AuthStore.IsPermitted("NODE:CREATE") ||
          this.props.AuthStore.IsPermitted("NODE:REBOOT"))
          ? (newObj["disableReboot"] = false)
          : (newObj["disableReboot"] = true);
        res.node_state &&
        res.node_state.toLowerCase() === "alive" &&
        (this.props.AuthStore.IsPermitted("NODE:CREATE") ||
          this.props.AuthStore.IsPermitted("NODE:REBOOT"))
          ? (newObj["disableScanDad"] = false)
          : (newObj["disableScanDad"] = true);

        let nodeCrumb = [
          {
            name: res.name ? res.name : "Details",
            link: this.cameFromOrgDetailPage
              ? `orgs/${res.organization.id}/inodes/${res.id}`
              : this.cameFromDashboardPage
              ? this.dashboardiNodeURL.indexOf("dashboard/service") === -1
                ? `${this.dashboardiNodeURL}/${res.id}`
                : `${this.dashboardiNodeURL}/${res.id}/services`
              : `inodes/${res.id}`,
          },
        ];
        if (this.cameFromOrgDetailPage) {
          BreadcrumbList.push(
            // { name: "Organizations", link: "orgs" },
            {
              name: res.organization.name,
              link: `dashboard/${res.organization.id}`,
            },
            { name: "Nodes", link: `orgs/${res.organization.id}/inodes` },
            ...nodeCrumb,
          );
        } else if (this.cameFromDashboardPage) {
          BreadcrumbList.push(
            {
              name: this.linkNameForiNode,
              link: this.dashboardiNodeURL,
            },
            ...nodeCrumb,
          );
        } else {
          BreadcrumbList.push({ name: "Nodes", link: "inodes" }, ...nodeCrumb);
        }
        this.props.UiStore.setInodeName(res.name);
        this.props.UiStore.setOrgName(res.organization.name);
        newObj["nodeDetails"] = nodeDetails;
        newObj["BreadcrumbList"] = BreadcrumbList;
        newObj["selectedNode"] = selectedNode;
        return newObj;
      });
    }
  };
  /**
   * GET CURRENT ORG POLICY INFO
   */
  getOrgInfo = () => {
    let currentOrgInfo = this.orgModel.org;
    if (
      currentOrgInfo &&
      currentOrgInfo.policy &&
      currentOrgInfo.policy.cluster &&
      currentOrgInfo.policy.cluster.enable
    ) {
      this.setState({ clusterSupport: currentOrgInfo.policy.cluster.enable });
    }
  };

  getNodeUcg = () => {
    const node_id = this.state.nodeId;
    InodeController.getNodeUCG(node_id)
      .then(response => {
        if (response) {
          this.setState({ ucgGateway: response });
        }
      })
      .catch(err => {});
  };

  isEdge = () => {
    return this.inodeModel &&
      this.inodeModel.inode &&
      this.inodeModel.inode.id &&
      this.inodeModel.inode.profile &&
      this.inodeModel.inode.profile.name &&
      this.inodeModel.inode.profile.name == "Edge"
      ? true
      : false;
  };

  componentDidMount() {
    this.getOrgInfo();
    this.iNodeDetails(null, true, true, true);
    /*API CALL EVERY 30 SEC Intiated HERE */
    this.nodeApiByInterval = setInterval(() => {
      this.iNodeDetails(null, false, true, true);
    }, 30000);
    this.getTrafficInsight();
    OrgController.getOrg(
      this.inodeModel.inode.organization
        ? this.inodeModel.inode.organization.id
        : this.props.orgId || this.props.match.params.id,
      false,
      this.props.UiStore.parentOrgData && this.props.UiStore.parentOrgData.id
        ? false
        : true,
    ).then(res => {
      if (res.parent_organization)
        this.props.UiStore.parentOrgData = res.parent_organization;
      else this.props.UiStore.parentOrgData = {};
    });

    let pathname = location.pathname;
    let path = pathname.split("/");
    if (pathname) {
      if (
        path[1] === "orgs" &&
        path[2] &&
        path[3] === "inodes" &&
        path[4] &&
        path[5] === "edit"
      ) {
        this.setState({
          autoOpen: true,
          autoOpenOrgID: path[4],
          modelAction: "edit",
        });
      } else if (
        path[1] === "orgs" &&
        path[2] &&
        path[3] === "inodes" &&
        path[4] &&
        path[5] === "view"
      ) {
        this.setState({
          autoOpen: true,
          autoOpenOrgID: path[4],
          modelAction: "view",
        });
      }
    }
  }

  componentDidUpdate(newprops, prevStates) {
    if (this.props != newprops) {
      let pathname = location.pathname;
      let path = pathname.split("/");
      if (
        path[1] === "orgs" &&
        path[2] &&
        path[3] === "inodes" &&
        path[4] &&
        path[5] === "scandad"
      ) {
        this.setState({
          autoOpen: true,
          autoOpenOrgID: path[4],
          modelAction: "scandad",
        });
      } else {
        this.setState({
          autoOpen: false,
          autoOpenOrgID: path[4],
          modelAction: "edit",
        });
      }
    }
  }

  componentWillUnmount = () => {
    /*Required to clear interval on component unmount*/
    clearInterval(this.nodeApiByInterval);
  };

  onAfterClick = () => {
    if (
      this.props.match.params &&
      this.props.match.params.id &&
      this.props.match.params.nodeId
    ) {
      this.props.history.push(
        `/orgs/${this.props.match.params.id}/inodes/${this.props.match.params.nodeId}`,
      );
    }
  };
  changeRoute = (resourse, action, id) => {
    if (action === "create" && this.props.match.params.id && resourse) {
      this.props.history.push(
        `/orgs/${this.props.match.params.id}/${resourse}/${action}`,
      );
    } else if (
      action === "edit" &&
      this.props.match.params.id &&
      resourse &&
      id
    ) {
      this.props.history.push(
        `/orgs/${this.props.match.params.id}/${resourse}/${id}/${action}`,
      );
    } else if (
      action === "view" &&
      this.props.match.params.id &&
      resourse &&
      id
    ) {
      this.props.history.push(
        `/orgs/${this.props.match.params.id}/${resourse}/${id}/${action}`,
      );
    } else if (
      action === "scandad" &&
      this.props.match.params.id &&
      resourse &&
      id
    ) {
      this.props.history.push(
        `/orgs/${this.props.match.params.id}/${resourse}/${id}/${action}`,
      );
    }
  };

  getTrafficInsight = () => {
    this.inodeModel.getTrafficInsightLoading = true;
    InodeController.getTrafficInsight(this.state.nodeId)
      .then(response => {
        if (response) {
          this.setState({
            threatDetection: response.threat_detection_enable,
          });
          this.inodeModel.setTrafficInsightData({
            response,
          });
          this.inodeModel.getTrafficInsightLoading = false;
        }
      })
      .catch(err => {
        this.inodeModel.getTrafficInsightLoading = false;
      });
  };

  render() {
    const ucgGateway = this.state.ucgGateway;
    let node_data = this.inodeModel.inode;
    let node_dad = this.dadViewModel.getInterfacesWithDuplicates;
    let nodeSupportTanDad = node_data.duplicate_address_detection
      ? node_data.duplicate_address_detection.enable
      : false;
    let isVirtual =
      this.inodeModel.inode &&
      this.inodeModel.inode.profile &&
      this.inodeModel.inode.profile.name &&
      this.inodeModel.inode.profile.name.toLowerCase() == "virtual"
        ? true
        : false;
    let path = window.location.pathname.split("/");
    if (path.length > 5) path.splice(5);
    let path1 = path.join("/") + "/inodedads";
    let scanDadUrl = window.location.pathname + "/scandad";

    let cluster = {};
    if (node_data.cluster) {
      cluster = Object.assign({}, node_data.cluster);
      cluster.status =
        node_data &&
        node_data.status &&
        node_data.status.node &&
        node_data.status.node.node_cluster_status &&
        node_data.status.node.node_cluster_status.state
          ? node_data.status.node.node_cluster_status.state
          : null;
      cluster.isMaster =
        cluster.status && cluster.status.toUpperCase() === "MASTER"
          ? true
          : false;
    }

    this.menu = (
      <Menu
        style={{ marginTop: -6 }}
        className="manage-inode"
        component={CursorMovementInModal}
      >
        {this.props.AuthStore.IsPermitted("NODE:VIEW") && (
          <Menu.Item
            key="4"
            style={this.color}
            onMouseEnter={this.toggleHover.bind(null)}
            className="iot-manage-dropdown"
          >
            <Link
              disabled={
                this.inodeModel.inode &&
                this.inodeModel.inode.node_state &&
                this.inodeModel.inode.node_state.toLowerCase() === "new"
                  ? true
                  : false
              }
              to={
                this.inodeModel.inode &&
                this.inodeModel.inode.node_state &&
                this.inodeModel.inode.node_state.toLowerCase() === "new"
                  ? path
                  : path.join("/") + "/securitypolicy"
              }
            >
              <Button
                style={{ border: "none", position: "relative", left: 5 }}
                disabled={
                  this.inodeModel.inode &&
                  this.inodeModel.inode.node_state &&
                  this.inodeModel.inode.node_state.toLowerCase() === "new"
                    ? true
                    : false
                }
                title={getCurrentlocaleText(
                  "network.security_policy.show_link.text",
                )}
              >
                <Icons type="fa" name="FaShieldAlt" style={{ fontSize: 13 }} />
                <span>
                  {getCurrentlocaleText(
                    "network.security_policy.show_link.text",
                  )}
                </span>
              </Button>
            </Link>
          </Menu.Item>
        )}
        <Menu.Item
          key="8"
          style={this.color}
          onMouseEnter={this.toggleHover.bind(null)}
          className="iot-manage-dropdown"
        >
          {(this.props.AuthStore.IsPermitted("NODE:CREATE") ||
            this.props.AuthStore.IsPermitted("ORG:SUPPORT")) && (
            <ActionBtn
              title={
                <span>
                  <Icons type="ai" name="AiOutlineIdcard" />
                  {getCurrentlocaleText("edit.node.text")}
                </span>
              }
              type="edit"
              action="edit"
              actionForm={EditiNodeForm}
              changeRoute={this.changeRoute}
              closeModal={this.onAfterClick}
              resource="inodes"
              orgId={
                this.state.nodeDetails.organization
                  ? this.state.nodeDetails.organization.id
                  : ""
              }
              disableSubmit={
                this.inodeModel.getCGroupMemoryLoading ||
                this.inodeModel.getDadInfoLoading ||
                this.inodeModel.getHWMInfoLoading ||
                this.inodeModel.getTrafficInsightLoading
              }
              disabled={
                this.props.AuthStore.IsPermitted("NODE:CREATE") ||
                this.props.AuthStore.IsPermitted("ORG:SUPPORT")
                  ? false
                  : true
              }
              formData={this.inodeModel.inode}
              map={{
                id: this.inodeModel.inode ? this.inodeModel.inode.id : null,
              }}
              afterCall={() => {
                setTimeout(() => {
                  this.iNodeDetails(null, false, true);
                }, 200);
              }}
              className="iot-manage-dropdown-actbtn"
              okText={
                <span>
                  <Icons type="ai" name="AiOutlineEdit" />{" "}
                  {getCurrentlocaleText("update.text")}{" "}
                </span>
              }
              controller={InodeController}
            >
              <Icons type="ai" name="AiOutlineEdit" />
              <span>{getCurrentlocaleText("general.edit.text")}</span>
            </ActionBtn>
          )}
        </Menu.Item>
        <Menu.Item
          key="5"
          style={this.color}
          onMouseEnter={this.toggleHover.bind(null)}
          className="iot-manage-dropdown"
        >
          {this.props.AuthStore.IsPermitted("NODE:VIEW") && (
            <ActionBtn
              title={
                <span>
                  <Icons type="ai" name="AiOutlineEye" />{" "}
                  {getCurrentlocaleText("view.inode.text")}
                </span>
              }
              action="view"
              resource="inodes"
              actionForm={EditiNodeForm}
              changeRoute={this.changeRoute}
              closeModal={this.onAfterClick}
              orgId={
                this.state.nodeDetails.organization
                  ? this.state.nodeDetails.organization.id
                  : ""
              }
              formData={this.inodeModel.inode}
              map={{
                id: this.inodeModel.inode ? this.inodeModel.inode.id : null,
              }}
              className="iot-manage-dropdown-actbtn"
              controller={InodeController}
              okText={
                <span>{getCurrentlocaleText("close.form.button.text")}</span>
              }
            >
              <Icons type="ai" name="AiOutlineEye" />
              <span>{getCurrentlocaleText("view.label.text")}</span>
            </ActionBtn>
          )}
        </Menu.Item>
        {parseFloat(getNodeVersion(node_data)) > InterTanRouteBaseVersion &&
          this.state.clusterSupport &&
          this.state.nodeDetails &&
          this.state.nodeDetails.profile &&
          this.state.nodeDetails.profile.name.toLowerCase() != "virtual" && (
            <Menu.Item
              key="0"
              style={this.color}
              onMouseEnter={this.toggleHover.bind(null)}
              className="iot-manage-dropdown"
            >
              {(this.props.AuthStore.IsPermitted("NODE:CREATE") ||
                this.props.AuthStore.IsPermitted("ORG:SUPPORT")) && (
                <ActionBtn
                  title={
                    <span>
                      <Icons type="bs" name="BsBoundingBox" />
                      {getCurrentlocaleText("create.cluster.text")}
                    </span>
                  }
                  HoverText={
                    <span>{getCurrentlocaleText("convert.cluster.text")}</span>
                  }
                  type="edit"
                  action="converttocluster"
                  actionForm={ClusterConversionForm}
                  orgId={
                    this.state.nodeDetails.organization
                      ? this.state.nodeDetails.organization.id
                      : ""
                  }
                  disabled={
                    this.state.nodeDetails && !this.state.nodeDetails.cluster
                      ? false
                      : true
                  }
                  formData={this.inodeModel.inode}
                  map={{
                    id: this.inodeModel.inode ? this.inodeModel.inode.id : null,
                  }}
                  afterCall={() => {
                    setTimeout(() => {
                      this.iNodeDetails(null, false, true);
                    }, 200);
                  }}
                  className="iot-manage-dropdown-actbtn"
                  controller={InodeController}
                  okText={
                    <span>
                      <Icons type="ai" name="AiOutlineEdit" />
                      {getCurrentlocaleText("update.text")}{" "}
                    </span>
                  }
                >
                  <Icons type="bs" name="BsBoundingBox" />
                  <span>{getCurrentlocaleText("convert.cluster.text")}</span>
                </ActionBtn>
              )}
            </Menu.Item>
          )}
        <Menu.Item
          key="2"
          style={this.color}
          onMouseEnter={this.toggleHover.bind(null)}
          className="iot-manage-dropdown"
        >
          <ActionBtn
            title={
              <span>
                <Icons type="md" name="MdRefresh" style={{ fontSize: 16 }} />
                {getCurrentlocaleText("node.list.table.reboot_node.label.text")}
              </span>
            }
            resource="reboot"
            type="reload"
            action="reboot"
            actionForm={RebootForm}
            disabled={this.state.disableReboot}
            selectedNode={this.state.selectedNode}
            rebootableNodes={[this.state.nodeId]}
            controller={InodeController}
            afterCall={() => {
              this.iNodeDetails(null, false, true);
            }}
            className="iot-manage-dropdown-actbtn"
          >
            <Icons type="md" name="MdRefresh" style={{ fontSize: 16 }} />
            <span>
              {getCurrentlocaleText("node.list.table.reboot_node.button.text")}
            </span>
          </ActionBtn>
        </Menu.Item>

        {/* Restrict for DAD */}
        {this.inodeModel.inode &&
          this.inodeModel.inode.duplicate_address_detection &&
          this.inodeModel.inode.duplicate_address_detection.enable == true && (
            <Menu.Item
              key="7"
              style={this.color}
              className="iot-manage-dropdown"
              onMouseEnter={this.toggleHover.bind(null)}
            >
              <Link to={path.join("/") + "/inodedads"}>
                <Button
                  style={{ border: "none", position: "relative", left: 5 }}
                  className="iot-manage-dropdown-actbtn"
                  title={getCurrentlocaleText("dad.text")}
                >
                  <Icons
                    type="hi"
                    name="HiOutlineDocumentDuplicate"
                    style={{ fontSize: 16 }}
                  />
                  <span>{getCurrentlocaleText("dad.text")}</span>
                </Button>
              </Link>
            </Menu.Item>
          )}
        {/* New log Infra menu */}
        {this.props.AuthStore.IsPermitted("INODELOG:VIEW") &&
          parseFloat(getNodeVersion(this.inodeModel.inode)) >=
            LiveLogInfraBaseversion && (
            <Menu.Item
              key="6"
              style={this.color}
              onMouseEnter={this.toggleHover.bind(null)}
              className="iot-manage-dropdown"
            >
              <Link
                disabled={
                  this.inodeModel.inode &&
                  this.inodeModel.inode.node_state &&
                  this.inodeModel.inode.node_state.toLowerCase() != "alive"
                    ? true
                    : false
                }
                to={path.join("/") + "/logs"}
              >
                <Button
                  style={{ border: "none", position: "relative", left: 5 }}
                  disabled={
                    this.inodeModel.inode &&
                    this.inodeModel.inode.node_state &&
                    this.inodeModel.inode.node_state.toLowerCase() != "alive"
                      ? true
                      : false
                  }
                  title={getCurrentlocaleText("inode.logs")}
                >
                  <Icons type="ai" name="AiOutlineFileText" />
                  <span>{getCurrentlocaleText("view.logs.text")}</span>
                </Button>
              </Link>
            </Menu.Item>
          )}
        {/* Node Delete */}
        <Menu.Item
          key="1"
          style={this.color}
          onMouseEnter={this.toggleHover.bind(null)}
          className="iot-manage-dropdown"
        >
          <ActionBtn
            title={
              <span className="delete-header-red">
                <Icons
                  type="ai"
                  name="AiOutlineDelete"
                  style={{ fontSize: 20 }}
                />{" "}
                {`${getCurrentlocaleText(
                  "node.list.table.delete_node.button.title.text",
                )}?`}
              </span>
            }
            HoverText={
              <span>
                {getCurrentlocaleText(
                  "node.list.table.delete_node.button.title.text",
                )}
              </span>
            }
            orgId={
              this.state.nodeDetails.organization
                ? this.state.nodeDetails.organization.id
                : ""
            }
            formData={this.state.selectedNode[this.state.nodeDetails.id]}
            map={{
              id: this.state.nodeDetails ? this.state.nodeDetails.id : "",
            }}
            afterCall={this.redirectToINodeList}
            resource="inodes"
            action="delete"
            custom={true}
            actionForm={InodesForm}
            okText={getCurrentlocaleText("general.delete.text")}
            disabled={!this.props.AuthStore.IsPermitted("NODE:DELETE")}
            className="iot-manage-dropdown-actbtn"
            controller={InodeController}
            cancelText={getCurrentlocaleText("general.cancel.text")}
          >
            <Icons
              type="ai"
              name="AiOutlineDelete"
              className="manage-inode-delete-icon"
              style={{ fontSize: 16 }}
            />
            <span>{getCurrentlocaleText("delete.text")}</span>
          </ActionBtn>
        </Menu.Item>
      </Menu>
    );

    let container = (
      <div>
        <Row gutter={16} type="flex" align="middle">
          <Col span={12}>
            <h2 className="page-title">
              <Icons type="fa" name="FaRegHdd" />
              {"  "}
              {node_data.name ? node_data.name : null}
            </h2>
            <BreadcrumbComponent
              {...this.props}
              BreadcrumbList={this.state.BreadcrumbList}
            />
          </Col>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            className="flex flex-h-right"
          >
            <Row gutter={16} type="flex">
              <Col
                span={24}
                className="flex flex-h-left d-flex flex-justify-right"
              >
                {(this.props.AuthStore.IsPermitted("NODE:CREATE") ||
                  this.props.AuthStore.IsPermitted("ORG:SUPPORT")) &&
                  (this.state.modelAction == "edit" ||
                    this.state.modelAction == "view") && (
                    <div className="component-visibility-hidden">
                      <ActionBtn
                        title={
                          <span>
                            {this.state.modelAction == "edit" && (
                              <span>
                                <Icons
                                  type="ai"
                                  name="AiOutlineEdit"
                                  style={{ fontSize: 16 }}
                                />{" "}
                                {getCurrentlocaleText("edit.node.text")}
                              </span>
                            )}
                            {this.state.modelAction == "view" && (
                              <span>
                                <Icons
                                  type="ai"
                                  name="AiOutlineEye"
                                  style={{ fontSize: 14, marginRight: 2 }}
                                />
                                {getCurrentlocaleText(
                                  "document.title.text.view_inode",
                                )}
                              </span>
                            )}
                          </span>
                        }
                        actionForm={EditiNodeForm}
                        orgId={
                          this.state.nodeDetails.organization
                            ? this.state.nodeDetails.organization.id
                            : ""
                        }
                        action={this.state.modelAction}
                        autoOpenModel={this.state.autoOpen}
                        changeRoute={this.changeRoute}
                        closeModal={this.onAfterClick}
                        resource="inodes"
                        formData={this.inodeModel.inode}
                        map={{
                          id: this.inodeModel.inode
                            ? this.inodeModel.inode.id
                            : null,
                        }}
                        afterCall={() => {
                          setTimeout(() => {
                            this.iNodeDetails(null, false, true);
                          }, 200);
                        }}
                        okText={
                          <span>
                            {this.state.modelAction == "edit" && (
                              <span>
                                <Icons type="ai" name="AiOutlineEdit" />{" "}
                                {getCurrentlocaleText("update.text")}{" "}
                              </span>
                            )}
                            {this.state.modelAction == "view" && (
                              <span>
                                <Icons type="ai" />{" "}
                                {getCurrentlocaleText("close.form.button.text")}{" "}
                              </span>
                            )}
                          </span>
                        }
                        controller={InodeController}
                      ></ActionBtn>
                    </div>
                  )}
                <div className="page-actions inode-action-card">
                  <Dropdown overlay={this.menu} trigger={["hover"]}>
                    <Button style={{ height: 46, border: "none", width: 260 }}>
                      {getCurrentlocaleText("manage.node.text")}{" "}
                      <Icons type="ai" name="AiOutlineDown" />
                    </Button>
                  </Dropdown>
                </div>

                <OrgSelector
                  {...this.props}
                  className="orgslector-ellipse-menu"
                />
              </Col>
            </Row>
          </Col>
        </Row>
        {nodeSupportTanDad && node_dad && node_dad.length > 0 && (
          <Row align="middle">
            <Alert
              style={{ marginBottom: 15, width: "100%" }}
              showIcon
              icon={
                <Icons
                  type="ai"
                  name="AiOutlineExclamationCircle"
                  className="dad-warning"
                />
              }
              message={
                <strong>{getCurrentlocaleText("dad.title.text")}</strong>
              }
              description={
                <p style={{ marginBottom: 0 }}>
                  {getCurrentlocaleText("dad.description.text")}
                  <Link to={path1}>
                    {getCurrentlocaleText("dad.view_details.text")}
                  </Link>
                  {node_data && !node_data.cluster && (
                    <span> {getCurrentlocaleText("dad.or.text")} </span>
                  )}
                  {node_data && !node_data.cluster && (
                    <>
                      <Link
                        to={scanDadUrl}
                        style={
                          this.state.disableScanDad
                            ? { pointerEvents: "none" }
                            : null
                        }
                        disabled={this.state.disableScanDad}
                      >
                        {getCurrentlocaleText("dad.force.text")}
                      </Link>
                      <ActionBtn
                        title={
                          <span>
                            <Icons
                              type="ai"
                              name="AiOutlineFileSync"
                              style={{ marginRight: 8 }}
                            />
                            {getCurrentlocaleText("dad.force_button.text")}
                          </span>
                        }
                        resource="scanNowNodeDad"
                        type="link"
                        action="scandad"
                        actionForm={TanDadForm}
                        selectedNode={this.state.selectedNode}
                        nodes={[this.state.nodeId]}
                        controller={InodeController}
                        autoOpenModel={this.state.autoOpen}
                        changeRoute={this.changeRoute}
                        closeModal={this.onAfterClick}
                        okText={
                          <span>
                            <Icons
                              type="ai"
                              name="AiOutlineFileSync"
                              style={{ marginRight: 8 }}
                            />
                            {getCurrentlocaleText("dad.force_button.text")}
                          </span>
                        }
                        afterCall={() => {
                          this.iNodeDetails(null, false, true);
                        }}
                        className="iot-manage-dropdown-actbtn act-link-btn-contents"
                      />
                    </>
                  )}
                </p>
              }
              type="warning"
              closable
            />
          </Row>
        )}
        <div>
          <Row gutter={2} type="flex" align="top">
            <Col
              xs={{ span: 5 }}
              sm={{ span: 5 }}
              md={{ span: 6 }}
              lg={{ span: 7 }}
              xl={{ span: 7 }}
              className="card-grid"
            >
              {/*System Details*/}
              <InodeInfoCard
                node_data={node_data}
                nodeId={this.state.nodeId}
                isVirtual={isVirtual}
                ucgGateway={ucgGateway}
              />
              {this.props.AuthStore.IsPermitted("NETWORK:DIAGNOSTIC") && (
                <Card>
                  <h3 className="card-title">
                    <Icons type="go" name="GoTools" style={{ fontSize: 16 }} />{" "}
                    {getCurrentlocaleText("diagnostic.text")}
                  </h3>
                  <TechDump
                    nodeDetails={this.state.nodeDetails}
                    nodeId={this.state.nodeId}
                  />
                </Card>
              )}
            </Col>
            <Col
              xs={{ span: 19 }}
              sm={{ span: 19 }}
              md={{ span: 18 }}
              lg={{ span: 17 }}
              xl={{ span: 17 }}
              className="card-grid"
            >
              <Row>
                <Col flex="auto">
                  {this.props.AuthStore.IsPermitted("NODE:VIEW") && (
                    <StatsOverviewContainer {...this.props} />
                  )}
                </Col>
                {this.state.isDeviceDiscoveryExist && !isVirtual && (
                  <Col flex="170px">
                    <InodeDeviceDiscoveryChart {...this.props} />
                  </Col>
                )}
                {(this.inodeModel.isTrafficInsightDataExist ||
                  (cluster &&
                    cluster.isMaster &&
                    this.clusterModel.isTrafficInsightDataExist)) &&
                  !isVirtual && (
                    <Col flex="195px">
                      <InodeCTDDashboard {...this.props} />
                    </Col>
                  )}
              </Row>
              {this.inodeModel.inode &&
                this.inodeModel.inode.node_state != "NEW" && (
                  <Col span={24} style={{ textAlign: "right" }}>
                    <Card bordered={false}>
                      <InterfaceTrafficView />
                    </Card>
                  </Col>
                )}
              {/*Chart*/}
              {this.props.AuthStore.IsPermitted("NETWORK:VIEW") && (
                <NetworkServiceTab
                  store={this.props.ModelStore}
                  orgId={this.props.orgId}
                  nodeId={this.state.nodeId}
                  updateINodeDetails={this.iNodeDetails}
                  currentInode={this.inodeModel.inode}
                  {...this.props}
                />
              )}
            </Col>
          </Row>
        </div>
      </div>
    );

    return (
      <LoadingComponent loading={this.inodeModel.loading}>
        {container}
      </LoadingComponent>
    );
  }
}

export default InodesDetails;
