import React, { Component } from "react";
import { Link } from "react-router-dom";
import { observer, inject } from "mobx-react";
import { Checkbox, Menu } from "antd";
import ActionBtn from "Core/API/ActionBtn";
import FireWallForm from "components/Forms/FireWallForm";
import FireWallViewForm from "components/Forms/FireWallViewForm";
import SearchInput from "components/UI-Components/SearchInput";
import {
  highlightSearchText,
  getFirewallProtocolAndPort,
  copyObject,
  getCurrentlocaleText,
  getOrgPolicyCounInfo,
  removeDefaultLabels,
} from "Core/Utils";
import _ from "lodash";
import LoadingComponent from "components/UI-Components/LoadingComponent";
import FirewallNameChangeInfoDialog from "components/UI-Components/FirewallNameChangeInfoDialog";
import LabelProvider from "components/UI-Components/LabelProvider";
import DropdownActionBtn from "components/UI-Components/DropdownActionBtn";
import FirewallGroupController from "controller/FirewallGroupController";
import Icons from "../UI-Components/Icons";
import TableLayoutV1 from "../layout/TableLayoutV1";

@inject("AuthStore", "UiStore", "FirewallGroupViewModel", "OrgViewModel")
@observer
class FirewallList extends Component {
  constructor(props) {
    super(props);
    this.firewallGroupModel = this.props.FirewallGroupViewModel;
    this.orgModel = this.props.OrgViewModel;
    this.timeout = null;
    this.searchTimeout = null;
    this.state = {
      selectedFirewall: {},
      searchText: "",
      deletableFirewall: {
        firewall: [],
      },
      autoOpen: false,
      autoOpenOrgID: null,
      modelAction: "create",
      currUserRecord: {},
      filterInfo: null,
      sorterInfo: { sorter: {}, sort: {} },
      isFirewallNameChanged: false,
      directions: [],
      currentPage: 1,
    };
  }

  firewallListFilter = e => {
    clearTimeout(this.searchTimeout);
    let searchText = e.target.value;
    this.setState({ searchText: searchText });
    this.searchTimeout = setTimeout(() => {
      this.firewallGroupList(
        false,
        0,
        searchText,
        this.state.sorterInfo.sort.by,
        this.state.sorterInfo.sort.order,
      );
    }, 500);
  };

  clearSearchFilter = () => {
    this.setState({ currentPage: 1, searchText: "" });
    this.firewallGroupList(
      true,
      0,
      "",
      this.state.sorterInfo.sort.by,
      this.state.sorterInfo.sort.order,
    );
  };

  componentDidMount() {
    this.props.UiStore.showChildSecurityGroups = this.props.cameFromDashboard
      ? true
      : false;
    let pathname = location.pathname;
    let path = pathname.split("/");
    if (path[5] && (path[5] == "view" || "edit")) {
      this.props.UiStore.showChildSecurityGroups = true;
      this.firewallGroupList(true);
    } else {
      this.props.UiStore.showChildSecurityGroups = false;
      this.firewallGroupList(true);
    }
    if (pathname) {
      if (
        path[1] === "orgs" &&
        path[2] &&
        path[3] === "securitypolicies" &&
        path[4] === "create"
      ) {
        this.setState({
          autoOpen: true,
          modelAction: "create",
        });
      } else if (
        path[1] === "orgs" &&
        path[2] &&
        path[3] === "securitypolicies" &&
        path[4] &&
        path[5] === "edit"
      ) {
        this.setState({
          autoOpen: true,
          autoOpenOrgID: path[4],
          modelAction: "edit",
        });
      } else if (
        path[1] === "orgs" &&
        path[2] &&
        path[3] === "securitypolicies" &&
        path[4] &&
        path[5] === "view"
      ) {
        this.setState({
          autoOpen: true,
          autoOpenOrgID: path[4],
          modelAction: "view",
        });
      }
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (this.props.orgId !== props.orgId) {
      this.firewallGroupList(
        true,
        0,
        this.state.searchText,
        this.state.sorterInfo.sortBy,
        this.state.sorterInfo.sortOrder,
        props.params,
      );
    }
  }

  componentWillUnmount() {
    this.props.UiStore.showChildSecurityGroups = false;
  }

  // selected table row status
  setSelectedStates = (record = {}, selected, selectedRows) => {
    for (let i = 0; i < selectedRows.length; i++) {
      let id = selectedRows[i].id;

      this.setState(() => {
        let newObj = {
            deletableFirewall: {
              firewall: [],
            },
          },
          selectedFirewall = this.state.selectedFirewall,
          item = selectedRows[i],
          tempArray = [];

        selectedFirewall[id] = {
          value: true,
          name: item.name,
          id: item.id,
        };

        //Temp array for deletable firewall list
        for (let i in selectedRows) {
          selectedFirewall[selectedRows[i].id]
            ? tempArray.push(selectedFirewall[selectedRows[i].id])
            : "";
        }
        // To set the value : false , when firewall is deselected and remove it from delete list

        !selected && selectedFirewall[record.id]
          ? ((selectedFirewall[record.id].value = false),
            (tempArray = _.filter(tempArray, val => {
              return val.id !== record.id;
            })))
          : null;

        newObj["selectedFirewall"] = selectedFirewall;
        newObj.deletableFirewall["firewall"] = tempArray;
        return newObj;
      });
    }
  };

  handleTableChange = (record, selected, selectedRows) => {
    selectedRows.length == 0 ? this.resetState() : "";
    this.setSelectedStates(record, selected, selectedRows);
  };
  getTagKeyValues = labels => {
    let tags = [];
    for (let key in labels) {
      if (key && key.charAt(0) != "_") tags.push(key + " : " + labels[key]);
    }
    return tags;
  };
  onSelectAllChange = (selected, selectedRows, changeRows) => {
    if (selected) {
      this.setSelectedStates(null, selected, selectedRows);
    } else {
      // on deselect all
      this.resetState();
    }
  };

  onTableChange = (pagination, filter, sorter) => {
    let sort = {};
    if (sorter.columnKey && sorter.order) {
      if (sorter.order === "ascend") {
        sort.order = "ASC";
      } else {
        sort.order = "DESC";
      }
      sort.by = sorter.columnKey;
    }
    this.setState({
      currentPage: pagination.current,
      filterInfo: filter,
      sorterInfo: { sorter: sorter, sort: sort },
    });
    let page = pagination.current - 1;
    this.firewallGroupList(
      true,
      page,
      this.state.searchText,
      sort.by,
      sort.order,
    );
  };
  computeInterfaceData(value) {
    let array = value ? value.toString().split(":") : [];
    let data = {};
    if (array.length > 1) data[array[0].trim()] = array[1].trim();
    return data;
  }

  prepareFormData = values => {
    let directions = this.state.directions;
    let firewallgroup = {
      name: values.name,
      rules: [],
      create_under: this.props.orgId,
    };
    let keys = Object.keys(values);
    if (keys) {
      keys.forEach(val => {
        if (val && val.indexOf("from_network") !== -1) {
          let ct = val.split("-")[1];
          let rule = {};
          let src_ipaddress = "0.0.0.0/0";
          let protocol_port_obj = getFirewallProtocolAndPort(
            values[`protocol-${ct}`],
          );
          let ipaddress_arr = values[`src_ipaddress-${ct}`]
            ? values[`src_ipaddress-${ct}`].split("/")
            : [];
          src_ipaddress =
            ipaddress_arr.length === 1
              ? (src_ipaddress = `${ipaddress_arr[0]}/32`)
              : (src_ipaddress = values[`src_ipaddress-${ct}`]
                  ? values[`src_ipaddress-${ct}`]
                  : src_ipaddress);
          let dest_ipaddress = "0.0.0.0/0";
          let priority = 1000;
          let to_network = "";
          let form_network = "";
          let dest_ipaddress_arr = values[`destination_ipaddress-${ct}`]
            ? values[`destination_ipaddress-${ct}`].split("/")
            : [];
          dest_ipaddress =
            dest_ipaddress_arr.length === 1
              ? (dest_ipaddress = `${dest_ipaddress_arr[0]}/32`)
              : (dest_ipaddress = values[`destination_ipaddress-${ct}`]
                  ? values[`destination_ipaddress-${ct}`]
                  : dest_ipaddress);
          (priority = values[`priority-${ct}`]),
            (to_network = values[`to_network-${ct}`]);
          form_network = values[`from_network-${ct}`];
          rule.source_ip = src_ipaddress;
          rule.destination_ip = dest_ipaddress;
          rule.action = values[`action-${ct}`];
          if (values[`direction-${ct}`]) {
            rule.direction = values[`direction-${ct}`];
          } else {
            if (directions) {
              directions.forEach(direction => {
                if (direction.key === ct && direction.direction) {
                  rule.direction = direction.direction;
                }
              });
            }
          }
          rule.destination_port = {};
          rule.source_port = {};
          // Protocol and port selection
          rule.protocol = protocol_port_obj.protocol;
          let portRange = values[`destination_port-${ct}`]
            ? values[`destination_port-${ct}`].split("-")
            : ["1", "65535"];
          let sourcePortRange = values[`source_port-${ct}`]
            ? values[`source_port-${ct}`].split("-")
            : ["1", "65535"];

          if (priority) {
            rule.priority = priority;
          }
          if (to_network != "") {
            rule.destination_network =
              typeof to_network === "object"
                ? to_network
                : typeof to_network === "string"
                ? this.computeInterfaceData(to_network)
                : {};
          }
          if (form_network != "") {
            rule.source_network =
              typeof form_network === "object"
                ? form_network
                : typeof form_network === "string"
                ? this.computeInterfaceData(form_network)
                : {};
          }
          if (portRange.length === 1) {
            rule.destination_port.start = parseInt(
              values[`destination_port-${ct}`],
            );
            rule.destination_port.end = rule.destination_port.start;
          } else {
            rule.destination_port.start = parseInt(portRange[0]);
            rule.destination_port.end = parseInt(portRange[1]);
          }
          if (sourcePortRange.length === 1) {
            rule.source_port.start = parseInt(values[`source_port-${ct}`]);
            rule.source_port.end = rule.source_port.start;
          } else {
            rule.source_port.start = parseInt(sourcePortRange[0]);
            rule.source_port.end = parseInt(sourcePortRange[1]);
          }

          if (rule.protocol === "ICMP") {
            rule.icmp_types = ["Echo"];
          }
          if (!values[`isdeleted-${ct}`]) {
            firewallgroup.rules.push(rule);
          }
        }
      });
      if (values.metadata && values.metadata.labels) {
        // removing default labels
        firewallgroup.metadata = {
          labels: removeDefaultLabels(
            values.metadata && values.metadata.labels,
          ),
        };
      }
    }
    return firewallgroup;
  };
  setDirections = value => {
    this.setState({ directions: value });
  };
  /*fireWallGroup = resp => {
    if (resp) {
      this.newFirewallId = resp.id;
    }
    this.clearFilterAndSorter();
    setTimeout(() => {
      this.moveToPage(this.newFirewallId);
    }, 1000);
  };

  moveToPage = firewallId => {
    this.newFirewallId = null;
    if (firewallId) {
      let index = this.firewallGroupModel.firewallGroups.findIndex(val => {
        return val.id == firewallId;
      });
      if (index != -1) {
        let page = Math.floor(index / this.props.UiStore.pageSize) + 1;
        this.props.UiStore.setCurrentPage(page);
      }
    }
  };*/

  createAfterCall = resp => {
    this.setState({
      currentPage: 1,
    });
    this.firewallGroupList(
      true,
      0,
      this.state.searchText,
      this.state.sorterInfo.sort.by,
      this.state.sorterInfo.sort.order,
    );
    this.props.onAfterClick("firewallgroup");
    this.resetState();
  };

  checkForNameChange = () => {
    this.props.onAfterClick("firewallgroup");
    if (this.props.UiStore.isFirewallNameChanged) {
      this.setState({ isFirewallNameChanged: true });
    } else {
      this.setState({ isFirewallNameChanged: false });
    }
  };

  resetNameInfoModal = () => {
    if (this.props.UiStore.isFirewallNameChanged) {
      this.setState({ isFirewallNameChanged: false });
      this.props.UiStore.isFirewallNameChanged = false;
      this.props.UiStore.FirewallNameNew = "";
      this.props.UiStore.FirewallNameOld = "";
    }
  };

  deleteAfterCall = () => {
    let page = this.state.currentPage ? this.state.currentPage : 0;
    if (this.firewallGroupModel.firewallGroupTableData.length == 0) {
      page--;
    }
    this.firewallGroupList(
      true,
      page - 1,
      this.state.searchText,
      this.state.sorterInfo.sort.by,
      this.state.sorterInfo.sort.order,
    );
    this.resetState();
  };

  resetState = () => {
    this.setState({
      selectedFirewall: {},
      modelAction: "create",
      currUserRecord: {},
      deletableFirewall: {
        firewall: [],
      },
    });
  };

  changeListview = e => {
    this.props.UiStore.showChildSecurityGroups = e.target.checked;
    this.setState({
      currentPage: 1,
    });
    this.firewallGroupList(
      true,
      0,
      this.state.searchText,
      this.state.sorterInfo.sort.by,
      this.state.sorterInfo.sort.order,
    );
  };

  firewallGroupList = (
    loading = true,
    page = 0,
    search = "",
    sortBy = null,
    sortOrder = null,
    params = this.props.params || {},
  ) => {
    params = copyObject(params);
    params.page = page;
    params.search = search;
    // For sort
    if (sortBy && sortOrder) {
      params.sort = `${sortBy}:${sortOrder}`;
    }
    if (this.props.UiStore.showChildSecurityGroups) {
      delete params.own;
    } else {
      params.own = true;
    }
    params.isDefault = false;
    return new Promise((resolve, reject) => {
      FirewallGroupController.getFirewallGroups(params, loading)
        .then(result => {
          const currFirewall = result.filter(firewall => {
            return (
              firewall &&
              firewall.id &&
              firewall.id === this.state.autoOpenOrgID
            );
          });
          this.setState({
            currUserRecord: currFirewall[0],
          });
          setTimeout(() => {
            if (
              this.state.modelAction == "edit" ||
              this.state.modelAction == "view"
            ) {
              this.setState({
                autoOpen: true,
              });
            }
          }, 5);
          resolve(result.data);
        })
        .catch(error => {
          console.error(error);
          reject(error);
        });
    });
  };

  lazyLoad = () => {
    if (this.firewallGroupModel.pageable.next) {
      let page = this.firewallGroupModel.pageable.page + 1;
      this.firewallGroupList(
        true,
        page,
        this.state.searchText,
        this.state.sorterInfo.sort.by,
        this.state.sorterInfo.sort.order,
      );
    }
  };

  render() {
    let { filterInfo, sorterInfo } = this.state;
    filterInfo = filterInfo || {};
    sorterInfo = sorterInfo || {};
    let firewallGroup = this.firewallGroupModel.firewallGroupTableData;

    let width_name = this.props.UiStore.showChildSerials ? "30%" : "50%";

    let width_label = this.props.UiStore.showChildSerials ? "25%" : "35%";

    // let width_action = this.props.UiStore.showChildSerials
    //   ? '42%'
    //   : '50%'

    // let width1=[60,30,10],
    //   width2=[30,30,10]

    this.columns = [
      {
        title: (
          <span
            title={getCurrentlocaleText(
              "firewall.security_policy_name.label.text",
            )}
          >
            {getCurrentlocaleText("firewall.security_policy_name.label.text")}
          </span>
        ),
        dataIndex: "name",
        width: width_name,
        ellipsis: true,
        sorter: true,
        sortOrder:
          sorterInfo.sorter.columnKey === "name" && sorterInfo.sorter.order,
        render: (text, record, index) => {
          return (
            <span className="col-wrap" style={{ width: "40%" }}>
              {this.state.searchText.trim().length > 0
                ? highlightSearchText(record.name, this.state.searchText)
                : record.name}
            </span>
          );
        },
      },
      {
        title: (
          <span
            title={getCurrentlocaleText("general.form.label.text").replace(
              ":",
              "",
            )}
          >
            {getCurrentlocaleText("general.form.label.text").replace(":", "")}
          </span>
        ),
        dataIndex: "label",
        key: "label",
        width: "30%",
        render: (text, record) => {
          return (
            <div>
              <span>
                {this.getTagKeyValues(record.metadata).length > 0 ? (
                  <LabelProvider
                    key={record.id}
                    metadata={record.metadata}
                    tags={this.getTagKeyValues(record.metadata)}
                    showPopupLabel={false}
                    viewOnly={true}
                  />
                ) : (
                  <span style={{ marginLeft: 15 }}>{"-"}</span>
                )}
              </span>
            </div>
          );
        },
      },
      {
        title: "",
        dataIndex: "",
        key: "firewall_action",
        width: "10%",
        render: (text, record, index) => {
          const view = (
            <span>
              <ActionBtn
                title={
                  <span>
                    <Icons
                      type="ai"
                      name="AiOutlineEye"
                      style={{ fontSize: 14, marginRight: 2 }}
                    />
                    {getCurrentlocaleText(
                      "firewall.security_policy.update.label.text",
                    )}
                  </span>
                }
                currentFirewallOrgId={
                  record && record.organization && record.organization.id
                    ? record.organization.id
                    : this.props.orgId
                }
                action="view"
                Help={false}
                footer={null}
                changeRoute={this.props.changeRoute}
                afterCall={this.props.onAfterClick}
                actionForm={FireWallViewForm}
                width={1280}
                id={record.id}
                resource="firewallgroup"
                {...this.props}
              >
                <Icons type="ai" name="AiOutlineEye" style={{ fontSize: 14 }} />
              </ActionBtn>
            </span>
          );
          const edit = (
            <span>
              <ActionBtn
                title={
                  <span>
                    <Icons type="ai" name="AiOutlineEdit" />{" "}
                    {getCurrentlocaleText(
                      "firewall.security_policy.edit.label.text",
                    )}
                  </span>
                }
                type=""
                actionForm={FireWallForm}
                action={"edit"}
                resource="firewallgroup"
                iconButton
                icon="AiOutlineEdit"
                orgId={
                  record && record.organization && record.organization.id
                    ? record.organization.id
                    : this.props.orgId
                }
                afterCall={this.checkForNameChange}
                changeRoute={this.props.changeRoute}
                initialVal={record.value}
                map={{ id: record.id }}
                width={1280}
                setDirections={this.setDirections}
                beforeCall={this.prepareFormData}
                okText={
                  <span>
                    <Icons type="ai" name="AiOutlineEdit" />{" "}
                    {getCurrentlocaleText("update.text")}{" "}
                  </span>
                }
                disabled={
                  !this.props.AuthStore.IsPermitted("SECURITY:ADMIN") ||
                  record.value.is_default
                }
                controller={FirewallGroupController}
              />
            </span>
          );
          const del = (
            <span>
              <ActionBtn
                title={
                  <span>
                    <Icons type="ai" name="AiOutlineDelete" />{" "}
                    {getCurrentlocaleText(
                      "inode.list.table.delete.button.title.text",
                      { 0: "Custom Security Policy" },
                    )}
                  </span>
                }
                HoverText={
                  <span>
                    {getCurrentlocaleText(
                      "inode.list.table.delete.button.hover.text",
                      { 0: "Custom Security Policy" },
                    )}
                  </span>
                }
                formData={record}
                orgId={
                  record && record.organization && record.organization.id
                    ? record.organization.id
                    : this.props.orgId
                }
                icon="AiOutlineDelete"
                iconButton
                map={{ id: record.id }}
                resource="firewallgroup"
                action="delete"
                actionForm={FireWallForm}
                disabled={
                  !this.props.AuthStore.IsPermitted("SECURITY:ADMIN") ||
                  record.value.is_default ||
                  record.network_count > 0
                }
                afterCall={this.deleteAfterCall.bind()}
                controller={FirewallGroupController}
                messageContent={
                  <span>
                    <p>
                      {getCurrentlocaleText(
                        "firewall.delete.confirmation.message.text",
                      )}
                    </p>
                  </span>
                }
                warningMessage={getCurrentlocaleText(
                  "firewall.delete.warning.message.text",
                )}
                cancelText={getCurrentlocaleText(
                  "inode.delete.confirmation.cancel.text",
                  { 0: "Custom Security Policy" },
                )}
                okText={
                  <span>
                    <Icons
                      type="ai"
                      name="AiOutlineDelete"
                      style={{ margin: 0 }}
                      className="iot-delete-icon"
                    />
                    {getCurrentlocaleText("inode.delete.confirmation.ok.text", {
                      0: "Custom Security Policy",
                    })}
                  </span>
                }
              >
                <Icons type="ai" name="AiOutlineDelete" style={{ margin: 0 }} />{" "}
                {getCurrentlocaleText("delete.confirmation.ok.text")}
              </ActionBtn>
            </span>
          );
          const menu = (
            <Menu>
              <Menu.Item key={0}>{view}</Menu.Item>
              <Menu.Item key={1}>{edit}</Menu.Item>
              <Menu.Item key={2}>{del}</Menu.Item>
            </Menu>
          );
          return (
            <div>
              <DropdownActionBtn menu={menu} />
            </div>
          );
        },
      },
    ];

    let org_column = {
      title: (
        <span title={getCurrentlocaleText("myprofile.organization.text")}>
          {getCurrentlocaleText("myprofile.organization.text")}
        </span>
      ),
      dataIndex: "current_org",
      key: "orgs",
      width: "40%",
      ellipsis: true,
      render: (text, record, index) => {
        return (
          <div className="col-wrap">
            <Link to={"/dashboard/" + record.organization.id}>
              {record.organization.name}
            </Link>
          </div>
        );
      },
    };

    if (this.props.UiStore.showChildSecurityGroups) {
      this.columns.splice(1, 0, org_column);
    }
    let container = (
      <div style={{ minHeight: 250 }}>
        <div
          style={{
            marginBottom: "10px",
            display: "inline-flex",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div style={{ display: "inline-flex", width: "50%" }}>
            <span className="headertext">
              {getCurrentlocaleText("general.component.firewall.text")}
            </span>
          </div>
          <div
            style={{
              marginBottom: "10px",
              display: "inline-flex",
              justifyContent: "flex-end",
              width: "50%",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 5,
              }}
            >
              <Checkbox
                checked={this.props.UiStore.showChildSecurityGroups}
                onChange={this.changeListview}
                disabled={
                  this.props.OrgViewModel.org &&
                  this.props.OrgViewModel.org.org_count > 0
                    ? false
                    : true
                }
              >
                {getCurrentlocaleText(
                  "firewall.list.table_view.show_all.checkbox.label",
                )}
              </Checkbox>
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <SearchInput
                placeholder="Filter by custom security policy name"
                onChange={this.firewallListFilter}
                value={this.state.searchText}
                clearSearch={this.clearSearchFilter}
              />
            </div>
            <div
              style={{
                marginLeft: "20px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {this.props.AuthStore.IsPermitted("SECURITY:ADMIN") && (
                <span
                  title={
                    this.state.modelAction == "create" &&
                    this.firewallGroupModel.firewallGroupTableData.length >=
                      getOrgPolicyCounInfo(this.orgModel.org, "group_count") &&
                    getCurrentlocaleText(
                      "security_policy.maximum_limit.organization.warning.text",
                      {
                        0: getOrgPolicyCounInfo(
                          this.orgModel.org,
                          "group_count",
                        ),
                      },
                    )
                  }
                >
                  <ActionBtn
                    type="primary"
                    title={
                      <span>
                        {this.state.modelAction == "create" && (
                          <span>
                            <Icons
                              type="ai"
                              name="MdPerson"
                              style={{ fontSize: 16 }}
                            />{" "}
                            {getCurrentlocaleText(
                              "firewall.security_policy.add.label.text",
                            )}
                          </span>
                        )}

                        {this.state.modelAction == "edit" && (
                          <span>
                            <Icons
                              type="ai"
                              name="AiOutlineEdit"
                              style={{ fontSize: 16 }}
                            />{" "}
                            {getCurrentlocaleText(
                              "firewall.security_policy.edit.label.text",
                            )}
                          </span>
                        )}
                        {this.state.modelAction == "view" && (
                          <span>
                            <Icons
                              type="ai"
                              name="AiOutlineEye"
                              style={{ fontSize: 14, marginRight: 2 }}
                            />
                            {getCurrentlocaleText(
                              "firewall.security_policy.update.label.text",
                            )}
                          </span>
                        )}
                      </span>
                    }
                    resource="firewallgroup"
                    icon="AiOutlinePlus"
                    iconButton
                    action={this.state.modelAction}
                    autoOpenModel={this.state.autoOpen}
                    map={{ id: this.state.autoOpenOrgID }}
                    initialVal={this.state.currUserRecord}
                    currentFirewallOrgId={
                      this.state.currUserRecord &&
                      this.state.currUserRecord.organization &&
                      this.state.currUserRecord.organization.id
                        ? this.state.currUserRecord.organization.id
                        : this.props.orgId
                    }
                    width={1250}
                    orgId={
                      this.state.currUserRecord &&
                      this.state.currUserRecord.organization &&
                      this.state.currUserRecord.organization.id
                        ? this.state.currUserRecord.organization.id
                        : this.props.orgId
                    }
                    beforeCall={this.prepareFormData}
                    id={this.state.autoOpenOrgID}
                    actionForm={
                      this.state.modelAction == "view"
                        ? FireWallViewForm
                        : FireWallForm
                    }
                    setDirections={this.setDirections}
                    changeRoute={this.props.changeRoute}
                    afterCall={this.createAfterCall}
                    controller={FirewallGroupController}
                    disabled={
                      this.firewallGroupModel.firewallGroupTableData.length >=
                      getOrgPolicyCounInfo(this.orgModel.org, "group_count")
                        ? true
                        : false
                    }
                  >
                    {this.state.modelAction == "create" && (
                      <span>
                        <Icons
                          type="ai"
                          name="AiOutlinePlus"
                          style={{ margin: 0 }}
                        />{" "}
                        {getCurrentlocaleText(
                          "firewall.security_policy.add.label.text",
                        )}
                      </span>
                    )}

                    {this.state.modelAction == "edit" && (
                      <span>
                        <Icons type="ai" name="AiOutlineEdit" />{" "}
                        {getCurrentlocaleText("update.text")}{" "}
                      </span>
                    )}
                    {this.state.modelAction == "view" && (
                      <span>
                        <Icons type="ai" />{" "}
                        {getCurrentlocaleText("close.form.button.text")}{" "}
                      </span>
                    )}
                  </ActionBtn>
                </span>
              )}
              {this.props.AuthStore.IsPermitted("SECURITY:ADMIN") && (
                <ActionBtn
                  title={
                    <span>
                      <Icons type="ai" name="AiOutlineDelete" />{" "}
                      {getCurrentlocaleText(
                        "inode.list.table.delete.button.title.text",
                        {
                          0:
                            this.state.deletableFirewall.firewall.length > 1
                              ? "Custom Security Policies"
                              : "Custom Security Policy",
                        },
                      )}
                    </span>
                  }
                  HoverText={
                    <span>
                      {getCurrentlocaleText(
                        "inode.list.table.delete.button.hover.text",
                        {
                          0:
                            this.state.deletableFirewall.firewall.length > 1
                              ? "Custom Security Policies"
                              : "Custom Security Policy",
                        },
                      )}
                    </span>
                  }
                  formData={this.state.deletableFirewall.firewall}
                  orgId={this.props.orgId}
                  icon="AiOutlineDelete"
                  iconButton
                  resource="firewallgroup"
                  action="delete"
                  afterCall={this.deleteAfterCall}
                  disabled={this.state.deletableFirewall.firewall.length === 0}
                  controller={FirewallGroupController}
                  messageContent={
                    <span>
                      <p>
                        {getCurrentlocaleText(
                          this.state.deletableFirewall.firewall.length > 1
                            ? "firewall.multiple.delete.confirmation.message.text"
                            : "firewall.delete.confirmation.message.text",
                        )}
                      </p>
                    </span>
                  }
                  warningMessage={getCurrentlocaleText(
                    this.state.deletableFirewall.firewall.length > 1
                      ? "firewall.multiple.delete.warning.message.text"
                      : "firewall.delete.warning.message.text",
                  )}
                  cancelText={getCurrentlocaleText(
                    "inode.delete.confirmation.cancel.text",
                    {
                      0:
                        this.state.deletableFirewall.firewall.length > 1
                          ? "Custom Security Policies"
                          : "Custom Security Policy",
                    },
                  )}
                  okText={
                    <span>
                      <Icons
                        type="ai"
                        name="AiOutlineDelete"
                        style={{ margin: 0 }}
                        className="iot-delete-icon"
                      />
                      {getCurrentlocaleText(
                        "inode.delete.confirmation.ok.text",
                        {
                          0:
                            this.state.deletableFirewall.firewall.length > 1
                              ? "Custom Security Policies"
                              : "Custom Security Policy",
                        },
                      )}
                    </span>
                  }
                ></ActionBtn>
              )}
            </div>
          </div>
        </div>
        <div>
          {this.firewallGroupModel.firewallGroups.length > 0 ? (
            <TableLayoutV1
              columns={this.columns}
              dataSource={firewallGroup}
              pagination={{
                pageSize:
                  this.firewallGroupModel &&
                  this.firewallGroupModel.pageable.size,
                total:
                  this.firewallGroupModel &&
                  this.firewallGroupModel.pageable.total_count,
                current: this.state.currentPage,
              }}
              hideExpandedRow={true}
              selectedRows={this.handleTableChange}
              selectAll={this.onSelectAllChange}
              onChange={this.onTableChange}
              disableFooter={true}
              className="firewallGroupList"
            />
          ) : (
            <div>
              {!this.firewallGroupModel.loading && (
                <h3>
                  {getCurrentlocaleText(
                    "firewall.security_policy.nodata.label.text",
                  )}
                </h3>
              )}{" "}
            </div>
          )}
        </div>
        {this.state.isFirewallNameChanged && (
          <FirewallNameChangeInfoDialog
            newName={this.props.UiStore.FirewallNameNew}
            oldName={this.props.UiStore.FirewallNameOld}
            onOk={this.resetNameInfoModal}
            visible={this.props.UiStore.isFirewallNameChanged}
          />
        )}
      </div>
    );

    return (
      <LoadingComponent loading={this.firewallGroupModel.loading}>
        {container}
      </LoadingComponent>
    );
  }
}

export default FirewallList;
