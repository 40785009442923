import React, { Component } from "react";
import { Link } from "react-router-dom";
import { observer, inject } from "mobx-react";
import { Menu, Dropdown, Button } from "antd";
import Icons from "components/UI-Components/Icons";
import TranslatedText from "components/UI-Components/TranslatedText";
import Support from "components/Containers/Support";
import About from "components/UI-Components/About";
import { getCurrentlocaleText } from "Core/Utils";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;
const menuItem = {
  height: 40,
};
const iconStyle = {
  fontSize: 16,
  lineHeight: 1.3,
  marginRight: 8,
};
const menuItemStyle = {
  lineHeight: 1.5,
};

@inject("AuthStore", "ModelStore", "UiStore", "OrgViewModel")
@observer
class QuickActionSupport extends Component {
  constructor(props) {
    super(props);
    // GLOBAL_SETTINGS configured from the node js
    this.GLOBAL_SETTINGS = GLOBAL_SETTINGS;
    this.ENV_CONFIG = ENV_CONFIG;
  }

  showSupportModal = () => {
    this.props.UiStore.ContactSupportMdl = !this.props.UiStore
      .ContactSupportMdl;
  };

  showAboutModal = () => {
    this.props.UiStore.AboutMdl = !this.props.UiStore.AboutMdl;
  };

  render() {
    const currentOrgId =
      this.props.OrgViewModel.org.id != undefined
        ? this.props.OrgViewModel.org.id
        : this.props.AuthStore.loggedInUser.organization
        ? this.props.AuthStore.loggedInUser.organization.id
        : "";
    let kbDocVersion =
      this.ENV_CONFIG &&
      this.ENV_CONFIG.KB_DOCUMENT_VERSION &&
      this.ENV_CONFIG.KB_DOCUMENT_VERSION.trim().length > 0
        ? this.ENV_CONFIG.KB_DOCUMENT_VERSION
        : "current";
    let kbMenu = (
        <Menu.Item key="kb" style={menuItem}>
          <a
            href={`https://help.view.com/secureedge-${kbDocVersion}/docs`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>
              <Icons
                type="fa"
                name="FaBook"
                style={iconStyle}
                className={this.props.UiStore.isSiderFold && "middle"}
              />
              <span style={menuItemStyle}>
                {" "}
                <TranslatedText UItextID="kb.navigation_menu.text" />
              </span>
            </span>
          </a>
        </Menu.Item>
      ),
      apiDocMenu = (
        <Menu.Item key="apidoc" style={menuItem}>
          <Link to="/apidoc/" target="_blank">
            <span>
              <Icons
                type="fa"
                name="FaBook"
                style={iconStyle}
                className={this.props.UiStore.isSiderFold && "middle"}
              />
              <span style={menuItemStyle}>
                {" "}
                <TranslatedText UItextID="navigation_menu.api.doc.text" />
              </span>
            </span>
          </Link>
        </Menu.Item>
      ),
      supportFormMenu = (
        <Menu.Item key="supportform" style={menuItem}>
          {
            <div>
              <span
                className="quick-menu-padding"
                onClick={this.showSupportModal.bind(this)}
              >
                <Icons
                  type="fa"
                  name="FaTicketAlt"
                  style={iconStyle}
                  className={this.props.UiStore.isSiderFold && "middle"}
                />
                <span style={menuItemStyle}>
                  {" "}
                  <TranslatedText UItextID="supportform.navigation_menu.text" />
                </span>
              </span>
              {this.props.UiStore.ContactSupportMdl && <Support />}
            </div>
          }
        </Menu.Item>
      ),
      supportLinkMenu = (
        <Menu.Item key="supportlink" style={menuItem}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://support.neeve.ai/"
          >
            <span>
              <Icons
                type="md"
                name="MdHeadsetMic"
                style={iconStyle}
                className={this.props.UiStore.isSiderFold && "middle"}
              />
              <span style={menuItemStyle}>
                {" "}
                <TranslatedText UItextID="support.navigation_menu.text" />
              </span>
            </span>
          </a>
        </Menu.Item>
      ),
      settingsGA = this.props.AuthStore.IsPermitted("SUPER:ADMIN") ? (
        <Menu.Item key="settingsGA" style={menuItem}>
          <Link to="/settings">
            <div>
              <span className="quick-menu-padding">
                <Icons
                  type="fa"
                  name="FaCog"
                  style={iconStyle}
                  className={this.props.UiStore.isSiderFold && "middle"}
                />
                <span style={menuItemStyle}>
                  {" "}
                  <TranslatedText UItextID="setting.navigation_menu.text" />
                </span>
              </span>
            </div>
          </Link>
        </Menu.Item>
      ) : null,
      aboutMenu = (
        <Menu.Item key="about" style={menuItem}>
          {
            <div>
              <span
                className="quick-menu-padding"
                onClick={this.showAboutModal.bind(this)}
              >
                <Icons
                  type="md"
                  name="MdInfoOutline"
                  style={iconStyle}
                  className={this.props.UiStore.isSiderFold && "middle"}
                />
                <span style={menuItemStyle}>
                  {" "}
                  <TranslatedText UItextID="about.navigation_menu.text" />
                </span>
              </span>
              {this.props.UiStore.AboutMdl && <About />}
            </div>
          }
        </Menu.Item>
      );

    const helpmenu = (
      <Menu
        key="newMneu"
        theme={
          this.GLOBAL_SETTINGS && this.GLOBAL_SETTINGS.sidebar_mode
            ? this.GLOBAL_SETTINGS.sidebar_mode
            : "dark"
        }
      >
        {kbMenu}
        {apiDocMenu}
        {supportFormMenu}
        {supportLinkMenu}
        {settingsGA}
        {aboutMenu}
      </Menu>
    );
    return (
      <span>
        {this.props.UiStore.isSiderFold ? (
          <Menu
            key="newMneu"
            mode="vertical"
            style={{
              marginLeft: "-70%",
            }}
            theme={
              this.GLOBAL_SETTINGS && this.GLOBAL_SETTINGS.sidebar_mode
                ? this.GLOBAL_SETTINGS.sidebar_mode
                : "dark"
            }
          >
            <SubMenu
              key="quick-action-support"
              title={
                <span>
                  <Button
                    className="quick-menu-button"
                    shape="circle"
                    size={"large"}
                    style={
                      !this.props.UiStore.isSiderFold ? { float: "left" } : {}
                    }
                  >
                    {" "}
                    <Icons
                      type="md"
                      name="MdHelp"
                      style={{
                        fontSize: 20,
                      }}
                      className="middle quick-icon-style"
                    />
                  </Button>
                </span>
              }
            >
              {kbMenu}
              {apiDocMenu}
              {supportFormMenu}
              {supportLinkMenu}
              {aboutMenu}
            </SubMenu>
          </Menu>
        ) : (
          <Dropdown size="small" trigger={["click"]} overlay={helpmenu}>
            <Button
              className="quick-menu-button"
              shape="circle"
              title={getCurrentlocaleText(
                "general.navigation_menu.help.label.text",
              )}
              size={"large"}
              style={!this.props.UiStore.isSiderFold ? { float: "left" } : {}}
            >
              {" "}
              <Icons
                type="md"
                name="MdHelp"
                style={{
                  marginLeft: this.props.UiStore.isSiderFold ? 20 : 0,
                }}
                className="middle quick-icon-style"
              />
            </Button>
          </Dropdown>
        )}
      </span>
    );
  }
}
export default QuickActionSupport;
