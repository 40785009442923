import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Row, Col } from "antd";
import InodesList from "components/DataLists/InodesList";
import BreadcrumbComponent from "components/UI-Components/BreadcrumbComponent";
import Icons from "components/UI-Components/Icons";

@inject("InodeViewModel")
@observer
class Inodes extends Component {
  constructor(props) {
    super(props);
    this.inodeModel = this.props.InodeViewModel;
    this.state = {
      shwModal: false,
      BreadcrumbList: [{ name: "Nodes", link: "inodes" }],
    };
  }
  showModal() {
    this.setState({ shwModal: true });
  }

  clsModal() {
    this.setState({ shwModal: false });
  }

  render() {
    let container = (
      <div>
        {/*--------------------------------------*/}
        <Row gutter={16} type="flex" align="middle">
          <Col span={12}>
            <h2 className="page-title">
              <Icons type="fa" name="FaRegHdd" /> Nodes
            </h2>
            <BreadcrumbComponent
              {...this.props}
              BreadcrumbList={this.state.BreadcrumbList}
            />
          </Col>
        </Row>
        <div className="list-contianer">
          <Row gutter={16} type="flex" align="middle">
            <Col span={24}>
              <InodesList
                resource="inodes"
                cache={false}
                params={{ own: true }}
                icon={<Icons type="ai" name="AiOutlineUsb" />}
                title="Nodes"
              />
            </Col>
          </Row>
        </div>
      </div>
    );

    return <div>{container}</div>;
  }
}

export default Inodes;
