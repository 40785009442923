import React, { Component } from "react";
import { Route } from "react-router-dom";
import { observer, inject } from "mobx-react";
import { Row, Col, Tabs } from "antd";
import OrgUsers from "components/DataLists/OrgUsers";
import BreadcrumbComponent from "components/UI-Components/BreadcrumbComponent";
import RolesList from "components/DataLists/RolesList";
import Icons from "components/UI-Components/Icons";

import UserController from "controller/UserController";
import RoleController from "controller/RoleController";
import { getCurrentlocaleText } from "../../Core/Utils";

const TabPane = Tabs.TabPane;

const RouteTab = props => (
  <Route
    render={({ history }) => (
      <Tabs
        onTabClick={key => {
          let path = "/" + key;
          history.push(path);
        }}
        {...props}
      >
        {props.children}
      </Tabs>
    )}
  />
);

@inject("ModelStore", "AuthStore", "UserViewModel")
@observer
class Users extends Component {
  static PropTypes = {};

  constructor(props) {
    super(props);
    this.userModel = this.props.UserViewModel;
    this.state = {
      tabKey: this.props.AuthStore.IsPermitted("USER:SHOW") ? "users" : "roles",
      shwModal: false,
      BreadcrumbList: [{ name: "Users", link: "users" }],
    };
    this.store = this.props.ModelStore;
    this.AuthStore = this.props.AuthStore;
  }

  UNSAFE_componentWillMount() {
    let tabKey = this.props.location.pathname.split("/")[1];
    if (tabKey) {
      this.setState({ tabKey: tabKey });
    }
    let params = { own: true };
    this.updateList(params);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.location.pathname !== nextProps.location.pathname) {
      let tabKey = nextProps.location.pathname.split("/")[1];
      if (tabKey) {
        this.setState({ tabKey: tabKey });
      }
    }
  }

  showModal() {
    this.setState({ shwModal: true });
  }

  clsModal() {
    this.setState({ shwModal: false });
  }

  updateList = params => {
    // Users list & role list
    params.page = 0;
    UserController.getUsers(params, true);
    RoleController.getRoles(params, true);
  };

  render() {
    let iconStyle = { height: 16, width: 16 };
    let container = (
      <div>
        {this.props.AuthStore.loggedInUser && (
          <Row gutter={16} type="flex" align="middle">
            <Col span={12}>
              <h2 className="page-title">
                <Icons type="md" name="MdGroup" style={{ fontSize: 26 }} />{" "}
                {getCurrentlocaleText("users.navigation_menu.text")}
              </h2>
              <BreadcrumbComponent
                {...this.props}
                BreadcrumbList={this.state.BreadcrumbList}
              />
            </Col>
          </Row>
        )}
        <div
          style={{
            padding: 24,
            background: "#fff",
            minHeight: 360,
          }}
        >
          <RouteTab
            activeKey={this.state.tabKey}
            onChange={tab => this.setState({ tabKey: tab })}
            {...this.props}
          >
            {this.props.AuthStore.IsPermitted("USER:SHOW") && (
              <TabPane
                tab={
                  <span>
                    <Icons type="md" name="MdGroup" style={iconStyle} />
                    {getCurrentlocaleText("users.navigation_menu.text")}
                  </span>
                }
                key="users"
              >
                <OrgUsers
                  resource="users"
                  cache={false}
                  params={{ own: true }}
                  icon={<Icons type="ai" name="AiOutlineUser" />}
                  title="Users"
                  orgId={
                    this.AuthStore.loggedInUser.organization
                      ? this.AuthStore.loggedInUser.organization.id
                      : null
                  }
                />
              </TabPane>
            )}
            {this.props.AuthStore.IsPermitted("ROLE:VIEW") && (
              <TabPane
                tab={
                  <span>
                    <Icons type="ai" name="AiOutlineIdcard" style={iconStyle} />
                    {getCurrentlocaleText("general.roles.label.text")}
                  </span>
                }
                key="roles"
              >
                <RolesList
                  orgId={
                    this.AuthStore.loggedInUser.organization
                      ? this.AuthStore.loggedInUser.organization.id
                      : null
                  }
                  params={{ own: true }}
                />
              </TabPane>
            )}
          </RouteTab>
        </div>
      </div>
    );

    return <div>{container}</div>;
  }
}

export default Users;
