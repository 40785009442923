import React, { Component } from "react";
import { Popover, Button } from "antd";
import Icons from "components/UI-Components/Icons";
import { getCurrentlocaleText } from "Core/Utils";

class NodeUnreachableStateInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showInfo: false,
    };
  }

  componentDidMount() {
    this.unreachableInfo(this.props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.unreachableInfo(props);
  }

  unreachableInfo(props) {
    let { nodeState, nodeProfile } = props;
    // TODO - RK - Need to change logic based on function type property (physical)
    if (nodeState && nodeProfile) {
      let profileName = nodeProfile.name
        ? nodeProfile.name.toLowerCase()
        : null;
      if (
        nodeState.toLowerCase() === "unreachable" &&
        (profileName === "edge" || profileName === "edge remote access")
      ) {
        this.setState({ showInfo: true });
      } else {
        this.setState({ showInfo: false });
      }
    } else {
      this.setState({ showInfo: false });
    }
  }

  render() {
    const style = { padding: "12px 15px", listStyleType: "disc" };
    let statusInfo = (
      <div>
        <span>
          {getCurrentlocaleText("node.unreachablestate_info.message1")}
        </span>
        <ul style={style}>
          <li>{getCurrentlocaleText("node.unreachablestate_info.message2")}</li>
          <li>{getCurrentlocaleText("node.unreachablestate_info.message3")}</li>
          <li>{getCurrentlocaleText("node.unreachablestate_info.message4")}</li>
        </ul>
        <span>
          <span>
            {getCurrentlocaleText("node.unreachablestate_info.message5")}&nbsp;
          </span>
          <a href="mailto:support@neeve.ai">support@neeve.ai</a>
        </span>
      </div>
    );

    return (
      <span>
        {this.state.showInfo ? (
          <Popover content={statusInfo}>
            <Button className="popover-help-button" style={{ padding: 0 }}>
              <Icons type="fa" name="FaInfoCircle" style={{ fontSize: 16 }} />
            </Button>
          </Popover>
        ) : null}
      </span>
    );
  }
}

export default NodeUnreachableStateInfo;
