import React, { Component } from "react";
import { Col, Row, Button, Popover, Tag, Menu, Checkbox, Modal } from "antd";
import { inject, observer } from "mobx-react";
import {
  ServiceController,
  NetworkController,
  OrgController,
} from "controller/Controllers";
import ClusterController from "controller/ClusterController";
import { Link } from "react-router-dom";
import ActionBtn from "Core/API/ActionBtn";
import "assets/styles/service.css";
import {
  getCurrentlocaleText,
  highlightSearchText,
  checkforFeatureEnabled,
  capitalize,
  isArray,
  copyObject,
} from "Core/Utils";
import Icons from "components/UI-Components/Icons";
import SearchInput from "components/UI-Components/SearchInput";
import DropdownActionBtn from "components/UI-Components/DropdownActionBtn";
import LoadingComponent from "components/UI-Components/LoadingComponent";
import ActionRoute from "Core/API/ActionRoute";
import TableLayoutV1 from "../layout/TableLayoutV1";
@inject(
  "UiStore",
  "ServiceViewModel",
  "AuthStore",
  "OrgViewModel",
  "InodeViewModel",
  "ClusterViewModel",
)
@observer
export default class ServiceList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedService: {},
      selectedRowKeys: [],
      skysparkWarningPopover: {},
      expandedRowKeys: [],
      searchText: "",
      sorterInfo: { sorter: {}, sort: {} },
      errorLoadingServices: false,
      emptyServiceCount: false,
      allOrgs: [],
      isloading: true,
      lastPageCalled: 0,
      loading: false,
      showRestartmod: false,
      showRestartCnm: true,
      message: null,
      currentService: null,
      currentPage: 1,
      modalContent: "",
      modalTitle: "",
      isTableModalToggle: false,
    };
    this.orgModel = this.props.OrgViewModel;
    this.inodeModel = this.props.InodeViewModel;
    this.clusterViewModel = this.props.ClusterViewModel;
    this.allNetworkId = [];
    this.inodeModel.resetInodes();
  }

  hideSkysparkWarningPopover = record => {
    this.setState({
      skysparkWarningPopover: {
        [record.id]: false,
      },
    });
  };

  handleVisibleChange = (record, popoverState) => {
    let imgVersion;
    let serType =
      record.serviceData.spec.labels &&
      record.serviceData.spec.labels["io_iotium_template"]
        ? record.serviceData.spec.labels["io_iotium_template"]
        : record.serviceData.spec.labels &&
          record.serviceData.spec.labels["io-iotium-template"]
        ? record.serviceData.spec.labels["io-iotium-template"]
        : "custom";

    if (serType.toLowerCase() === "skyspark") {
      imgVersion = record.serviceData.spec.services[0].image.version;
    }

    imgVersion !== "3.0.12"
      ? this.editOrViewService(record)
      : this.setState({
          skysparkWarningPopover: {
            [record.id]: popoverState,
          },
        });
  };

  lazyLoad = () => {
    if (this.props.ServiceViewModel.pageable.next) {
      let page =
        this.state.lastPageCalled > 0
          ? this.state.lastPageCalled + 1
          : this.props.ServiceViewModel.pageable.page + 1;

      if (page <= this.props.ServiceViewModel.pageable.total_page - 1) {
        this.setState({ lastPageCalled: page });
        this.fetchServices(page);
      }
    }
  };

  fetchServices = (
    page = 0,
    shldCallNetwork = true,
    params = this.props.params || {},
  ) => {
    params.page = page;
    params.inheritance = false;
    if (this.props.UiStore.showChildServices) {
      delete params.own;
    } else {
      params.own = true;
    }
    params.search = this.state.searchText;
    ServiceController.getServices(params, true, true, true).then(resp => {
      this.setState({
        isloading: false,
        emptyServiceCount: resp.results.length == 0 ? true : false,
      });
      shldCallNetwork && this.fetchNetwork();
    });
  };

  fetchNetwork = () => {
    this.props.ServiceViewModel.services.forEach(service => {
      service.spec.networks &&
        service.spec.networks.forEach(val => {
          this.allNetworkId.indexOf(val.network_id) < 0 &&
            this.allNetworkId.push(val.network_id);
        });
    });
    /* call unique networks only */
    this.allNetworkId = [...new Set(this.allNetworkId)];
    NetworkController.QueryAllNetworks(this.allNetworkId).then(netResp => {});
  };

  UNSAFE_componentWillReceiveProps(nextprops) {
    if (nextprops.params.org_id !== this.props.params.org_id) {
      this.props.ServiceViewModel.resetServices();
      this.fetchServices(0, true, nextprops.params);
    }
  }

  componentDidMount() {
    this.props.ServiceViewModel.resetServices();
    this.props.UiStore.showChildServices = this.props.cameFromDashboard
      ? true
      : false;
    this.fetchServices();
    this.fetchClusterDetails();
    /* call service api at regular intervals */
    this.getServicesByInterval();
    this.getOrg();
  }

  getOrg = () => {
    let orgId =
      this.props.OrgViewModel.org.id != undefined
        ? this.props.OrgViewModel.org.id
        : this.props.AuthStore.loggedInUser.organization
        ? this.props.AuthStore.loggedInUser.organization.id
        : "";
    OrgController.getOrgById(orgId, true).then(res => {
      if (res && res.parent_organization && res.parent_organization.id) {
        this.props.UiStore.parentOrgData = res.parent_organization;
      }
    });
  };

  getServicesByInterval = () => {
    clearInterval(this.interval);
    this.interval = setInterval(() => {
      let pageCount =
        this.props.ServiceViewModel &&
        this.props.ServiceViewModel.pageable &&
        this.props.ServiceViewModel.pageable.page
          ? this.props.ServiceViewModel.pageable.page
          : 0;
      let params = [];
      let param = this.props.params || {};
      if (this.props.UiStore.showChildServices) {
        delete param.own;
      } else {
        param.own = true;
      }
      param.inheritance = false;
      param.search = this.state.searchText;
      for (let page = 0; page <= pageCount; page++) {
        param.page = page;
        params.push(copyObject(param));
      }
      ServiceController.QueryAllServices(params, false).then(rep => {
        /* call networks after all services are fetched */
        this.fetchNetwork();
      });
    }, 30000);
  };

  changeListview = e => {
    this.setState({
      isloading: true,
      currentPage: 1,
    });
    this.props.ServiceViewModel.resetServices();
    this.props.UiStore.showChildServices = e.target.checked;
    this.fetchServices();
  };

  serviceListFilter = e => {
    clearTimeout(this.searchTimeout);
    let text = e.target.value;
    this.setState({ searchText: text, lastPageCalled: 0, currentPage: 1 });
    this.searchTimeout = setTimeout(() => {
      this.fetchServices();
    }, 500);
  };

  clearSearchFilter = () => {
    this.props.ServiceViewModel.resetServices();
    this.setState(
      { searchText: "", lastPageCalled: 0, currentPage: 1, isloading: true },
      () => {
        this.fetchServices();
      },
    );
  };

  componentWillUnmount() {
    clearInterval(this.interval);
    this.props.ServiceViewModel.resetServices();
    this.props.UiStore.showChildServices = false;
  }

  editOrViewService = (record, isView = false) => {
    let service_type =
      record.serviceData.spec.labels &&
      record.serviceData.spec.labels["io-iotium-template"]
        ? record.serviceData.spec.labels["io-iotium-template"]
        : record.serviceData.spec.labels &&
          record.serviceData.spec.labels["io_iotium_template"]
        ? record.serviceData.spec.labels["io_iotium_template"]
        : "custom";

    let serviceRoute = isView === true ? "serviceview" : "services";
    let url = null;
    if (record.serviceData.cluster && record.serviceData.cluster.id) {
      url = `/orgs/${record.serviceData.organization.id}/clusters/${
        record.serviceData.cluster.id
      }/${serviceRoute}/${service_type.toLowerCase()}/${record.id}`;
    } else {
      url = `/orgs/${record.serviceData.organization.id}/inodes/${
        record.serviceData.node.id
      }/${serviceRoute}/${service_type.toLowerCase()}/${record.id}`;
    }
    /*  goto edit or view service page */
    if (url) window.location = url;
  };

  getLogRoute = record => {
    let route = "";

    let currentPath = window.location.pathname;
    let pathSegments = currentPath.split("/");
    let lastSegment = pathSegments[pathSegments.length - 1];

    if (this.props.cameFromDashboard) {
      route = window.location.pathname.replace(
        "/dashboard/" +
          (this.props.props.match &&
          this.props.props.match.params &&
          this.props.props.match.params.id
            ? this.props.props.match.params.id
            : record.serviceData.organization.id) +
          "/" +
          lastSegment,
        record.serviceData.cluster && record.serviceData.cluster.id
          ? `/orgs/${record.serviceData.organization.id}/clusters/${record
              .serviceData.cluster && record.serviceData.cluster.id}/services/${
              record.id
            }/logs`
          : `/orgs/${record.serviceData.organization.id}/inodes/${record
              .serviceData.node &&
              record.serviceData.node.id}/servicetemplates/${record.id}/logs`,
      );
    } else {
      let x =
        record.serviceData.cluster && record.serviceData.cluster.id
          ? `/clusters/${record.serviceData.cluster &&
              record.serviceData.cluster.id}/services/${record.id}/logs`
          : `/inodes/${record.serviceData.node &&
              record.serviceData.node.id}/servicetemplates/${record.id}/logs`;
      route = window.location.pathname.replace(
        "/" + lastSegment,
        record.serviceData.cluster && record.serviceData.cluster.id
          ? `/clusters/${record.serviceData.cluster &&
              record.serviceData.cluster.id}/services/${record.id}/logs`
          : `/inodes/${record.serviceData.node &&
              record.serviceData.node.id}/servicetemplates/${record.id}/logs`,
      );
    }
    return route;
  };

  selectService = (record, selected, selectedRows) => {
    this.setState({
      selectedService: this.extractActiveServiceRows(selectedRows),
    });
  };

  selectServiceAll = (selected, selectedRows, changeRows) => {
    this.setState({
      selectedService: this.extractActiveServiceRows(selectedRows),
    });
  };

  extractActiveServiceRows = selectedRows => {
    // Return only active service rows
    return selectedRows.filter(row => {
      return row.disabled === false;
    });
  };

  //Fetch cluster details to show node selector details in expansion
  fetchClusterDetails = () => {
    let params = {};
    params.org_id = this.props.params && this.props.params.org_id;
    params.size = 1;
    return new Promise((resolve, reject) => {
      ClusterController.getClusters(params).then(response => {
        if (response && this.clusterViewModel.pageable.total_count) {
          let allParams = [],
            totalCount = this.clusterViewModel.pageable.total_count / 100;
          if (totalCount > 0) {
            for (let page = 0; page < totalCount; page++) {
              let params = {
                org_id: this.props.params && this.props.params.org_id,
                page: page,
                size: 100,
              };
              allParams.push(params);
            }
            ClusterController.getClusters(allParams, false)
              .then(response => {
                resolve(response);
              })
              .catch(error => {
                resolve([]);
              });
          } else {
            resolve([]);
          }
        } else {
          resolve([]);
        }
      });
    });
  };

  resetSelectedService = () => {
    this.setState({ selectedService: {} });
  };

  deleteAfterCall = response => {
    let page = this.state.currentPage ? this.state.currentPage : 0;
    if (this.props.ServiceViewModel.serviceTableData.length == 0) {
      page--;
    }
    this.fetchServices(page - 1);
    this.resetSelectedService();
  };

  // To set the state of the table - rows checked/unchecked
  onSelectChange = (selectedRowKeys, selectedRows) => {
    this.setState({ selectedRowKeys });
  };

  onSelect = (record, selected, selectedRows) => {
    this.selectService(record, selected, selectedRows);
  };

  onSelectAll = (selected, selectedRows, changeRows) => {
    this.selectServiceAll(selected, selectedRows, changeRows);
  };

  expandRow = () => {
    let keys = this.props.ServiceViewModel.services.map(item => {
      return item.id;
    });
    this.setExpandedRowKeys(
      this.state.expandedRowKeys.length !== keys.length ? keys : [],
    );
  };

  onExpand = (expanded, record) => {
    let keys = this.state.expandedRowKeys;
    let index = keys.findIndex(val => {
      return val === record.id;
    });
    if (expanded) {
      if (index === -1) {
        keys.push(record.id);
      }
      this.setExpandedRowKeys(keys);
    } else {
      if (index !== -1) {
        keys.splice(index, 1);
      }
      this.setExpandedRowKeys(keys);
    }
  };

  setExpandedRowKeys = keys => {
    this.setState({ expandedRowKeys: keys });
  };

  showStatus = record => {
    if (record) {
      let isExpandedRow = this.isExpandedRow(record.id);
      this.onExpand(!isExpandedRow, record);
    }
  };

  isExpandedRow = rowId => {
    if (rowId) {
      let expandedRowKeys = this.state.expandedRowKeys;
      if (expandedRowKeys) {
        return expandedRowKeys.includes(rowId);
      }
    }
    return false;
  };

  closeServiceRestartModal = () => {
    this.setState({ showRestartmod: false });
  };

  onTableChange = pagination => {
    let page = pagination.current - 1;
    this.setState({
      isloading: true,
      currentPage: pagination.current,
    });
    this.fetchServices(page);
  };

  showServiceRestartConfirm = record => {
    this.setState({
      showRestartmod: true,
      loading: false,
      currentService: record,
    });
  };
  handleRestart = () => {
    let record = this.state.currentService ? this.state.currentService : {};
    //API REQ BEGINS HERE
    ServiceController.restart(record && record.id)
      .then(response => {
        this.setState(
          {
            message: response.message,
            showRestartmod: false,
            loading: false,
            currentService: {},
          },
          () => {
            this.fetchServices();
          },
        );
      })
      .catch(error => {
        this.setState({
          showRestartmod: false,
          loading: false,
          currentService: {},
        });
      });
  };

  getModalContentJSX = record => {
    let extraInfo = {
      skysparkWarningPopover: this.state.skysparkWarningPopover,
    };
    let modalContent = this.props.ServiceViewModel.showExtraDetails(
      record.serviceData,
      "",
      "",
      extraInfo,
    );
    this.setState({
      modalTitle: record && record.name ? record.name : "",
      modalContent: modalContent,
    });
  };

  handleModalOk(e) {
    this.setState({
      isTableModalToggle: !this.state.isTableModalToggle,
    });
  }

  render() {
    const { isTableModalToggle } = this.state;
    const hasServiceCreatePermission = this.props.AuthStore.IsPermitted(
      "SERVICE:CREATE",
    );

    let services = this.props.ServiceViewModel.serviceTableData;
    this.ServiceTablecolumns = [
      {
        title: (
          <span title={getCurrentlocaleText("orgs.tablelist.namecolumn.text")}>
            {getCurrentlocaleText("orgs.tablelist.namecolumn.text")}
          </span>
        ),
        dataIndex: "name",
        key: "name",
        width: "15%",
        ellipsis: true,
        render: (text, record, index) => {
          return (
            <span
              title={
                record.deleted_at
                  ? `${text} ${getCurrentlocaleText("deleting.text")}`
                  : text
              }
              className="col-wrap"
            >
              {this.state.searchText.trim().length > 0
                ? highlightSearchText(text, this.state.searchText)
                : text}
              {record.system_service && (
                <div>
                  <Tag closable={false}>
                    <Icons
                      type="fa"
                      name="FaCogs"
                      style={{ height: 14, width: 14 }}
                    />{" "}
                    {getCurrentlocaleText("serial_list.system_service.text")}
                  </Tag>
                </div>
              )}
              {record.deleted_at && (
                <span className="iotium-danger">
                  {" "}
                  {getCurrentlocaleText("deleting.text")}
                </span>
              )}
            </span>
          );
        },
      },
      {
        title: (
          <span
            title={getCurrentlocaleText("general.table.inode_name.header.text")}
          >
            {getCurrentlocaleText("general.table.inode_name.header.text")}
          </span>
        ),
        dataIndex: "",
        key: "node",
        width: "14%",
        ellipsis: true,
        render: (text, record, index) => {
          let routeLink =
            record.serviceData.node && record.serviceData.node.id
              ? `/orgs/${record.serviceData.organization.id}/inodes/${record.serviceData.node.id}`
              : null;
          return record.serviceData &&
            record.serviceData.node &&
            record.serviceData.node.name ? (
            <a href={routeLink} className="col-wrap">
              {record.serviceData.node && record.serviceData.node.name
                ? record.serviceData.node.name
                : null}
            </a>
          ) : (
            "-"
          );
        },
      },
      {
        title: (
          <span
            title={getCurrentlocaleText("general.component.networks.text")}
            className="col-wrap"
          >
            {getCurrentlocaleText("general.component.networks.text")}
          </span>
        ),
        dataIndex: "network_id",
        key: "network_id",
        width: "14%",
        ellipsis: true,
        render: (text, record, index) => {
          if (
            isArray(record.networkDetails) &&
            record.networkDetails.length == 1
          ) {
            return (
              <a
                className="col-wrap"
                href={
                  record.serviceData.node && record.serviceData.node.id
                    ? `/orgs/${record.serviceData.organization.id}/inodes/${record.serviceData.node.id}/view/${record.networkDetails[0].networkId}`
                    : record.serviceData.cluster &&
                      record.serviceData.cluster.id &&
                      `/orgs/${record.serviceData.organization.id}/clusters/${record.serviceData.cluster.id}/view/${record.networkDetails[0].networkId}`
                }
              >
                {record.networkDetails[0].network_name}
              </a>
            );
          } else if (
            isArray(record.networkDetails) &&
            record.networkDetails.length > 1
          ) {
            let popoverContentData = [];
            let networkIpAddress =
              record &&
              record.serviceData &&
              record.serviceData.spec &&
              record.serviceData.spec.networks;
            // Needed copy the array for popover content
            let popoverNetworkDetails =
              record &&
              record.networkDetails.map(detail => ({
                ...detail,
              }));
            networkIpAddress.length > 0 &&
              networkIpAddress.forEach((network, i, arr) => {
                if (
                  network.is_default === true &&
                  network.ip_address === record.networkDetails[i].ip_address
                ) {
                  popoverNetworkDetails[i].network_name += " (Default)";
                }
                popoverContentData.push(
                  <div key={i}>
                    {popoverNetworkDetails[i].networkId && (
                      <a
                        href={
                          record.serviceData.node && record.serviceData.node.id
                            ? `/orgs/${record.serviceData.organization.id}/inodes/${record.serviceData.node.id}/view/${record.networkDetails[i].networkId}`
                            : record.serviceData.cluster &&
                              record.serviceData.cluster.id &&
                              `/orgs/${record.serviceData.organization.id}/clusters/${record.serviceData.cluster.id}/view/${record.networkDetails[i].networkId}`
                        }
                      >
                        {popoverNetworkDetails[i].network_name}
                      </a>
                    )}
                    {!popoverNetworkDetails[i].network_name && <span>-</span>}
                    {arr.length - 1 !== i && <span>,&nbsp;</span>}
                  </div>,
                );
              });

            return (
              <Popover content={popoverContentData}>
                <a
                  href={
                    record.serviceData.node && record.serviceData.node.id
                      ? `/orgs/${record.serviceData.organization.id}/inodes/${record.serviceData.node.id}/view/${record.networkDetails[0].networkId}`
                      : record.serviceData.cluster &&
                        record.serviceData.cluster.id &&
                        `/orgs/${record.serviceData.organization.id}/clusters/${record.serviceData.cluster.id}/view/${record.networkDetails[0].networkId}`
                  }
                >
                  {record.networkDetails[0].network_name}
                </a>
                ...
              </Popover>
            );
          } else {
            return <p style={{ marginLeft: 15 }}>{"-"}</p>;
          }
        },
      },
      {
        title: (
          <span
            title={getCurrentlocaleText(
              "node.list.table.node_status.label.text",
            )}
          >
            {getCurrentlocaleText("node.list.table.node_status.label.text")}
          </span>
        ),
        dataIndex: "pod_status",
        key: "pod_status",
        width: "15%",
        render: (text, record) => {
          // Popover Content
          let unknownStatusContent = (
            <div>
              {record.pod && record.pod.message
                ? record.pod.message
                : getCurrentlocaleText("service.status.unavailable.text")}
            </div>
          );
          const nonUnknownStatusContent = (
            <div>
              <p>
                <strong>
                  {getCurrentlocaleText("node.list.table.popover.created")}
                  {" : "}
                </strong>
                {record.pod && record.pod.start_time
                  ? record.pod.start_time
                  : "-"}
              </p>
              <p>
                <strong>
                  {getCurrentlocaleText("node.list.table.popover.message")}
                  {" : "}
                </strong>
                : {record.pod && record.pod.message ? record.pod.message : "-"}
              </p>
            </div>
          );
          let isDaemon =
            record.kind && record.kind.toLowerCase() === "daemon"
              ? true
              : false;
          let isReplica =
            record.kind && record.kind.toLowerCase() === "replica"
              ? true
              : false;
          return (
            <div className="col-wrap">
              {record.pod && record.pod.status && !isDaemon && !isReplica ? (
                <Popover
                  content={
                    record.pod.status.toLowerCase() === "unknown"
                      ? unknownStatusContent
                      : nonUnknownStatusContent
                  }
                  title={
                    record.pod.status.toLowerCase() === "unknown"
                      ? null
                      : getCurrentlocaleText("service.status")
                  }
                >
                  <Tag className={"tag-" + record.pod.status.toLowerCase()}>
                    {" "}
                    {record.pod.status}{" "}
                  </Tag>
                </Popover>
              ) : isDaemon || isReplica ? (
                <span onClick={this.showStatus.bind(this, record)}>
                  <a>
                    {this.isExpandedRow(record.id) || ""}
                    {"Show Status"}
                  </a>
                </span>
              ) : (
                <p style={{ marginLeft: 15 }}>{"-"}</p>
              )}
            </div>
          );
        },
      },
      {
        title: (
          <span
            title={getCurrentlocaleText("inode.interface.ipv4.label.text")}
            className="col-wrap"
          >
            {getCurrentlocaleText("inode.interface.ipv4.label.text")}
          </span>
        ),
        dataIndex: "ip_address",
        key: "ipAddress",
        width: "12%",
        ellipsis: true,
        render: (text, record) => {
          if (
            isArray(record.networkDetails) &&
            record.networkDetails.length == 1
          ) {
            return (
              <div key={0}>
                {" "}
                <span
                  key={0}
                  title={record.networkDetails[0].ip_address}
                  className="col-wrap"
                >
                  {record.networkDetails[0].ip_address}
                </span>
              </div>
            );
          } else if (
            isArray(record.networkDetails) &&
            record.networkDetails.length > 1
          ) {
            let popoverContentData = [];
            let networkIpAddress =
              record &&
              record.serviceData &&
              record.serviceData.spec &&
              record.serviceData.spec.networks;
            networkIpAddress.length > 0 &&
              networkIpAddress.map((network, i, arr) => {
                let showIpAddress = network.ip_address;
                if (network.is_default === true) {
                  showIpAddress += " (Default)";
                }
                popoverContentData.push(
                  <div key={i}>
                    {showIpAddress ? showIpAddress : "-"}
                    {arr.length - 1 !== i && <span>,&nbsp;</span>}
                  </div>,
                );
              });

            return (
              <Popover content={popoverContentData}>
                <div>{record.networkDetails[0].ip_address}...</div>
              </Popover>
            );
          } else {
            return <p style={{ marginLeft: 15 }}>{"-"}</p>;
          }
        },
      },
      {
        title: (
          <span
            title={getCurrentlocaleText("running.containers")}
            className="col-wrap"
          >
            {getCurrentlocaleText("running.containers")}
          </span>
        ),
        dataIndex: "running_container",
        key: "running_container",
        width: "15%",
        ellipsis: true,
        render: (text, record) => {
          return (
            <span style={{ marginLeft: 15 }}>{record.running_container}</span>
          );
        },
      },
      {
        title: "",
        dataIndex: "",
        key: "edit_node",
        width: "10%",
        render: (text, record, index) => {
          /*  LAT-5077 */
          let service_type =
            record.serviceData.spec.labels &&
            record.serviceData.spec.labels["io-iotium-template"]
              ? record.serviceData.spec.labels["io-iotium-template"]
              : record.serviceData.spec.labels &&
                record.serviceData.spec.labels["io_iotium_template"]
              ? record.serviceData.spec.labels["io_iotium_template"]
              : "custom";
          let nodeData = {};
          /* LAT-5252 : Allow service ip edit */

          const view = (
            <span>
              <Button
                title={getCurrentlocaleText("view.service.text")}
                onClick={this.editOrViewService.bind(null, record, true)}
                style={{ cursor: "pointer", padding: "0 10px" }}
              >
                {" "}
                <Icons type="ai" name="AiOutlineEye" style={{ fontSize: 14 }} />
              </Button>
            </span>
          );
          const edit = (
            <span>
              <Popover
                overlayClassName="skysparkWarnPopover"
                overlayStyle={{ width: "45%" }}
                visible={
                  this.state.skysparkWarningPopover[record.id]
                    ? this.state.skysparkWarningPopover[record.id]
                    : false
                }
                onVisibleChange={this.handleVisibleChange.bind(null, record)}
                placement="topRight"
                content={
                  <div className="popoverContentContainer">
                    <p className="popoverContent">
                      {getCurrentlocaleText("skyspark.warning.popover.content")}
                    </p>
                    <Row>
                      <Button
                        className="popoverProceedBtn"
                        type={record.disabled ? "" : "primary"}
                        onClick={this.editOrViewService.bind(null, record)}
                      >
                        {getCurrentlocaleText("proceed.service.text")}
                      </Button>
                    </Row>
                    <Row>
                      <Button
                        className="popoverCancelBtn"
                        onClick={this.hideSkysparkWarningPopover.bind(
                          null,
                          record,
                        )}
                      >
                        {getCurrentlocaleText("cancel.button.text")}
                      </Button>
                    </Row>
                  </div>
                }
                title={
                  <div className="popovertitleContainer">
                    <Icons
                      type="ai"
                      name="AiOutlineExclamationCircle"
                      className="warningIcon"
                    />

                    {getCurrentlocaleText("skyspark.warning.popover.title")}

                    <Button
                      className="popoverTitleCancelBtn"
                      onClick={this.hideSkysparkWarningPopover.bind(
                        null,
                        record,
                      )}
                    >
                      <Icons type="ai" name="AiOutlineCloseCircle" />
                    </Button>
                  </div>
                }
                trigger="click"
              >
                <Button
                  style={{ cursor: "pointer" }}
                  title={getCurrentlocaleText("service.edit.header.text")}
                  disabled={record.disabled ? record.disabled : false}
                >
                  <Icons type="ai" name="AiOutlineEdit" />
                </Button>
              </Popover>
            </span>
          );
          const serviceLog = (
            <span>
              <ActionRoute
                actionIcon="AiOutlineFileText"
                actionLabel={getCurrentlocaleText("service.logs")}
                iconButton
                title={getCurrentlocaleText("service.logs")}
                disabled={
                  (!record.node &&
                    !record.is_inheritance &&
                    !(
                      record.serviceData.spec &&
                      record.serviceData.spec.kind === "SINGLETON"
                    )) ||
                  (record.pod &&
                    record.pod.status &&
                    record.pod.status.toLowerCase() === "pending")
                    ? true
                    : false
                }
                route={this.getLogRoute(record)}
              />
            </span>
          );
          const restart = (
            <div>
              <div>
                {
                  <Button
                    title={
                      record.pod.restart_policy &&
                      record.pod.restart_policy === "Never"
                        ? getCurrentlocaleText(
                            "Service.restart.disabled.help.text1",
                          )
                        : "Restart Service"
                    }
                    onClick={() => {
                      this.showServiceRestartConfirm(record);
                    }}
                    disabled={
                      (record.pod.restart_policy &&
                        record.pod.restart_policy === "Never") ||
                      (record.pod &&
                        record.pod.status &&
                        record.pod.status.toLowerCase() === "restarting")
                        ? true
                        : false
                    }
                    style={{ minWidth: 50 }}
                  >
                    <Icons
                      style={{ margin: 0, fontSize: 16 }}
                      type="md"
                      name="MdRefresh"
                    />
                  </Button>
                }
              </div>
            </div>
          );
          const menu = (
            <Menu>
              {(this.props.AuthStore.IsPermitted("SERVICE:VIEW") ||
                this.props.AuthStore.IsPermitted("ORG:SUPPORT")) && (
                <Menu.Item key={0}>{view}</Menu.Item>
              )}
              {this.props.AuthStore.IsPermitted("SERVICE:CREATE") &&
                !record.is_inheritance &&
                !record.deleted_at &&
                !record.system_service && <Menu.Item key={1}>{edit}</Menu.Item>}
              {((record.serviceData.node && !record.is_inheritance) ||
                (record.is_inheritance &&
                record.serviceData.spec &&
                record.serviceData.spec.kind != "SINGLETON"
                  ? true
                  : false) ||
                (!record.is_inheritance &&
                  record.serviceData.spec &&
                  record.serviceData.spec.kind === "SINGLETON")) &&
                !record.deleted_at && (
                  <Menu.Item key={2}>{serviceLog}</Menu.Item>
                )}
              {this.props.AuthStore.IsPermitted("SERVICE:RESTART") &&
                !record.deleted_at &&
                (record.serviceData.node ||
                  (record.serviceData.spec &&
                    record.serviceData.spec.kind === "SINGLETON")) && (
                  <Menu.Item key={3}>{restart}</Menu.Item>
                )}
            </Menu>
          );

          return (
            <div
              onClick={e => {
                e.stopPropagation();
              }}
            >
              <DropdownActionBtn menu={menu} />
            </div>
          );
        },
      },
    ];

    // Org column
    let orgColumn = {
      title: (
        <span
          title={getCurrentlocaleText("myprofile.organization.text")}
          className="col-wrap"
        >
          {getCurrentlocaleText("myprofile.organization.text")}
        </span>
      ),
      dataIndex: "",
      key: "organization",
      width: "18%",
      ellipsis: true,
      render: (text, record, index) => {
        return (
          <a
            href={`/orgs/${record.serviceData.organization.id}`}
            title={record.serviceData.organization.name}
          >
            <span className="col-wrap">
              {record.serviceData.organization.name}
            </span>
          </a>
        );
      },
    };

    // Cluster Column
    let clusterColumn = {
      title: (
        <span title={getCurrentlocaleText("cluster.field.text")}>
          {getCurrentlocaleText("cluster.field.text")}
        </span>
      ),
      dataIndex: "cluster",
      key: "cluster_name",
      width: "15%",
      ellipsis: true,
      render: (text, record, index) => {
        let clusterLink =
          record.serviceData.cluster && record.serviceData.cluster.id
            ? `/orgs/${record.serviceData.organization.id}/clusters/${record.serviceData.cluster.id}`
            : null;
        return (
          <div
            title={
              record.serviceData.cluster && record.serviceData.cluster.name
            }
            className="col-wrap"
          >
            {record.serviceData.cluster && record.serviceData.cluster.id ? (
              <Link to={clusterLink}>
                {record.serviceData.cluster && record.serviceData.cluster.name}
              </Link>
            ) : (
              <span style={{ marginLeft: 15 }}>{"-"}</span>
            )}
          </div>
        );
      },
    };

    // Kind column
    let kindColumn = {
      title: (
        <span title={getCurrentlocaleText("services.kind.label")}>
          {getCurrentlocaleText("services.kind.label")}
        </span>
      ),
      dataIndex: "kind",
      key: "kind",
      width: "10%",
      ellipsis: true,
      render: (text, record, index) => {
        return (
          <span className="col-wrap">
            {record.kind ? (
              capitalize(record.kind)
            ) : (
              <p style={{ marginLeft: 15 }}>{"-"}</p>
            )}
          </span>
        );
      },
    };

    // Check if cluster feature is enabled or not
    if (
      checkforFeatureEnabled(
        this.props.OrgViewModel.org.id != undefined
          ? this.props.OrgViewModel.org
          : this.props.AuthStore.loggedInUserOrg,
        "cluster",
      )
    ) {
      if (this.props.UiStore.showChildServices) {
        // For Org Column with cluster
        this.ServiceTablecolumns.splice(1, 0, orgColumn);
        // For Cluster column
        this.ServiceTablecolumns.splice(2, 0, clusterColumn);
        // For kind column
        this.ServiceTablecolumns.splice(5, 0, kindColumn);
      } else {
        // For Cluster column
        this.ServiceTablecolumns.splice(1, 0, clusterColumn);
        // For kind column
        this.ServiceTablecolumns.splice(4, 0, kindColumn);
      }
    } else {
      // For Org column without cluster
      if (this.props.UiStore.showChildServices) {
        this.ServiceTablecolumns.splice(1, 0, orgColumn);
      }
    }

    let record = this.state.currentService ? this.state.currentService : {};
    let container = (
      <div style={{ minHeight: 250 }}>
        <div style={{ display: "inline-flex", width: "40%" }}>
          <span className="headertext">
            {getCurrentlocaleText("services.my")}
          </span>
        </div>
        <div
          style={{
            marginBottom: "10px",
            display: "inline-flex",
            justifyContent: "flex-end",
            width: "60%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 7,
            }}
          >
            <Checkbox
              checked={this.props.UiStore.showChildServices}
              onChange={this.changeListview}
              disabled={
                this.props.OrgViewModel.org &&
                this.props.OrgViewModel.org.org_count > 0
                  ? false
                  : true
              }
            >
              {getCurrentlocaleText("services.table_view.checkbox.label")}
            </Checkbox>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <SearchInput
              placeholder={getCurrentlocaleText("filter.services.by.name")}
              onChange={this.serviceListFilter}
              value={this.state.searchText}
              clearSearch={this.clearSearchFilter}
            />
          </div>

          <div
            style={{
              marginLeft: "20px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {hasServiceCreatePermission && (
              <div>
                <ActionBtn
                  title={
                    <span>
                      <Icons type="ai" name="AiOutlineDelete" />{" "}
                      {getCurrentlocaleText(
                        "inode.list.table.delete.button.title.text",
                        {
                          0:
                            this.state.selectedService.length > 1
                              ? "Services"
                              : "Service",
                        },
                      )}
                    </span>
                  }
                  HoverText={
                    <span>
                      {getCurrentlocaleText(
                        "inode.list.table.delete.button.hover.text",
                        {
                          0:
                            this.state.selectedService.length > 1
                              ? "Services"
                              : "Service",
                        },
                      )}
                    </span>
                  }
                  resource="service"
                  action="delete"
                  disabled={_.isEmpty(this.state.selectedService)}
                  iconButton={true}
                  icon="AiOutlineDelete"
                  formData={this.state.selectedService}
                  afterCall={this.deleteAfterCall}
                  controller={ServiceController}
                  messageContent={
                    <span>
                      <p>
                        {getCurrentlocaleText(
                          this.state.selectedService.length > 1
                            ? "service.multiple.delete.confirmation.message.text"
                            : "service.delete.confirmation.message.text",
                        )}
                      </p>
                    </span>
                  }
                  cancelText={getCurrentlocaleText(
                    "inode.delete.confirmation.cancel.text",
                    {
                      0:
                        this.state.selectedService.length > 1
                          ? "Services"
                          : "Service",
                    },
                  )}
                  warningMessage={getCurrentlocaleText(
                    this.state.selectedService.length > 1
                      ? "service.multiple.delete.warning.message.text"
                      : "service.delete.warning.message.text",
                  )}
                  okText={
                    <span>
                      <Icons
                        type="ai"
                        name="AiOutlineDelete"
                        style={{ margin: 0 }}
                        className="iot-delete-icon"
                      />
                      {getCurrentlocaleText(
                        "inode.delete.confirmation.ok.text",
                        {
                          0:
                            this.state.selectedService.length > 1
                              ? "Services"
                              : "Service",
                        },
                      )}
                    </span>
                  }
                ></ActionBtn>
                <div className="action-btn " />
              </div>
            )}
          </div>
        </div>
        <Modal
          title={getCurrentlocaleText("service.restart.title.text")}
          visible={this.state.showRestartmod}
          onCancel={this.closeServiceRestartModal}
          onOk={this.handleRestart}
          maskClosable={false}
          confirmLoading={this.state.loading}
          closable={!this.state.loading}
          okText={getCurrentlocaleText("service.restart.ok.text")}
          cancelText={getCurrentlocaleText("service.restart.cancel.text")}
        >
          <span>
            <h4 style={{ marginBottom: 15 }}>
              <p>{getCurrentlocaleText("service.restart.text1")}</p>
              <p>
                <Tag className="tag-iotium-info">{record.name}</Tag>
              </p>
              <p>{getCurrentlocaleText("service.restart.text2")}</p>
            </h4>
          </span>
        </Modal>
        <Row>
          <Col span={24}>
            {!this.state.emptyServiceCount &&
            this.props.ServiceViewModel.services.length > 0 ? (
              <TableLayoutV1
                columns={this.ServiceTablecolumns}
                dataSource={this.props.ServiceViewModel.serviceTableData}
                pagination={{
                  pageSize:
                    this.props.ServiceViewModel &&
                    this.props.ServiceViewModel.pageable.size,
                  total:
                    this.props.ServiceViewModel &&
                    this.props.ServiceViewModel.pageable.total_count,
                  current: this.state.currentPage,
                }}
                selectedRows={this.selectService}
                selectAll={this.selectServiceAll}
                expandedRowRender={(record, index, intent, expanded) => {
                  let extraInfo = {
                    skysparkWarningPopover: this.state.skysparkWarningPopover,
                  };
                  return this.props.ServiceViewModel.showExtraDetails(
                    record.serviceData,
                    index,
                    intent,
                    expanded,
                    true,
                    extraInfo,
                  );
                }}
                onChange={this.onTableChange}
                expandedRowKeys={this.state.expandedRowKeys}
                onExpand={this.onExpand}
                className="serviceList"
                getModalContent={record => {
                  return this.getModalContentJSX(record);
                }}
                modalTitle={this.state.modalTitle}
                modalContent={this.state.modalContent}
                isTableModalToggle={isTableModalToggle}
                isModalLoading={false}
                disableFooter={true}
              />
            ) : (
              <div>
                {this.state.emptyServiceCount && (
                  <h3>{getCurrentlocaleText("no.services.to.display")}</h3>
                )}
                {this.state.errorLoadingServices && (
                  <h3>{getCurrentlocaleText("error.in.loading.services")}</h3>
                )}
              </div>
            )}
          </Col>
        </Row>
      </div>
    );

    return (
      <LoadingComponent loading={this.state.isloading}>
        {container}
      </LoadingComponent>
    );
  }
}
