import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { Row, Col, Popover, Button, Input, Result, Table } from "antd";
import { Link } from "react-router-dom";
import { getCurrentlocaleText } from "../../Core/Utils";
import Icons from "../UI-Components/Icons";
import TableLayout from "../layout/TableLayout";
import BreadcrumbComponent from "../UI-Components/BreadcrumbComponent";

@inject("AuthStore", "UiStore", "InodeViewModel", "OrgViewModel")
@observer
export default class DeviceDiscoveryReportDetails extends Component {
  constructor(props) {
    super(props);
    this.inodeModel = this.props.InodeViewModel;
    this.orgModel = this.props.OrgViewModel;
    this.orgId = this.props.match.params.orgId;
    this.nodeId = this.props.match.params.nodeId;
    this.state = {
      report: {},
      BreadcrumbList: [],
    };
  }

  componentDidMount() {
    this.setBreadcrumb();
  }

  setBreadcrumb() {
    let BreadcrumbList = [];
    BreadcrumbList.push({
      name:
        this.props.OrgViewModel &&
        this.props.OrgViewModel.org &&
        this.props.OrgViewModel.org.name
          ? this.props.OrgViewModel.org.name
          : this.props.AuthStore.loggedInUser.organization.name,
      link: `dashboard/${this.orgId}`,
    });
    if (this.nodeId) {
      let nodeName =
        this.inodeModel && this.inodeModel.inode && this.inodeModel.inode.name
          ? this.inodeModel.inode.name
          : "";

      BreadcrumbList.push(
        { name: "Nodes", link: `orgs/${this.orgId}/inodes` },
        {
          name: nodeName ? nodeName : "Details",
          link: `orgs/${this.orgId}/inodes/${this.nodeId}`,
        },
        {
          name: "Device Discovery",
          link: `orgs/${this.orgId}/inodes/${this.nodeId}/devicediscovery`,
        },
        {
          name: "Report",
        },
      );
    }
    BreadcrumbList.push();
    this.setState({ BreadcrumbList });
  }

  render() {
    let { BreadcrumbList } = this.state;
    let deviceDiscoveryLink = `/orgs/${this.orgId}/inodes/${this.nodeId}/devicediscovery`;

    return (
      <section className="mt-15 fs-1">
        <Row gutter={16} type="flex" align="middle" className="m-15">
          <Col lg={{ span: 12 }} xl={{ span: 12 }}>
            <h2 className="page-title">
              {getCurrentlocaleText("device.discovery.text2")}
            </h2>
            <BreadcrumbComponent
              {...this.props}
              BreadcrumbList={BreadcrumbList}
            />
          </Col>
        </Row>

        <Row justify="start" className="pb-15 mb-15">
          <Col span={24} className="flex">
            <Link to={deviceDiscoveryLink}>
              <b>
                <Icons type="ai" name="AiOutlineArrowLeft" className="mr-15" />
                {getCurrentlocaleText("report")}:
              </b>
            </Link>
            [Scan Type] [MMM-DD] [##]
            <span className="pl-15 ml-15">
              {getCurrentlocaleText("device.discovery.unique.text")}
            </span>
          </Col>
        </Row>
        <Row justify="start" className="pb-10">
          <Col span={3}>
            <b>{getCurrentlocaleText("report")}:</b>
          </Col>
          <Col span={21}>
            {getCurrentlocaleText("device.discovery.unique.text")}
          </Col>
        </Row>
        <Row justify="start" className="pb-10">
          <Col span={3}>
            <b>{getCurrentlocaleText("general.component.scan.time.text")}:</b>
          </Col>
          <Col span={21}>
            {getCurrentlocaleText("device.discovery.starting.ending.date.text")}
          </Col>
        </Row>
        <Row justify="start" className="pb-10">
          <Col span={3}>
            <b>
              {getCurrentlocaleText(
                "stats.advanced.page.title.manage.time.text",
              )}
              :
            </b>
          </Col>
          <Col span={21}>
            {getCurrentlocaleText("device.discovery.hours.text")}
          </Col>
        </Row>
        <Row justify="start" className="pb-10">
          <Col span={3}>
            <b>{getCurrentlocaleText("upgrade.status.text")}:</b>
          </Col>
          <Col span={21}>
            {getCurrentlocaleText("device.discovery.completed.text")}
          </Col>
        </Row>
        <Row justify="start" className="pb-10">
          <Col span={3}>
            <b>{getCurrentlocaleText("general.component.timezone.text")}:</b>
          </Col>
          <Col span={21}>
            {getCurrentlocaleText("device.discovery.gmt.text")}
          </Col>
        </Row>
      </section>
    );
  }
}
