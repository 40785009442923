import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { Row, Col } from "antd";
import Icons from "../UI-Components/Icons";
import { getCurrentlocaleText } from "../../Core/Utils";

@inject("ModelStore", "UiStore")
@observer
class AccessDenied extends Component {
  render() {
    return (
      <div className="invalid-access-container">
        <center>
          <Row gutter={16}>
            <Col span={24}>
              <h1>
                <Icons type="ai" name="AiOutlineFrown" />
                {getCurrentlocaleText("access.denied.text1")}
              </h1>
              <p>{getCurrentlocaleText("access.denied.text2")}</p>
            </Col>
          </Row>
        </center>
      </div>
    );
  }
}

export default AccessDenied;
