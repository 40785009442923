import React, { Component } from "react";
import { Row, Col } from "antd";
import { observer, inject } from "mobx-react";
import LoadingComponent from "components/UI-Components/LoadingComponent";
import BreadcrumbComponent from "components/UI-Components/BreadcrumbComponent";
import Icons from "components/UI-Components/Icons";
import InodeController from "controller/InodeController";
import OrgSelector from "components/Orgs/OrgSelector";
import ClusterController from "controller/ClusterController";
import LogsContainer from "components/Logs/Components/LogsContainer";
import LogController from "controller/LogController";
import ServiceController from "controller/ServiceController";
import FileSaver from "file-saver";
import moment from "moment";
import { getDefaultTimezone } from "Core/Utils";

@inject(
  "UiStore",
  "LogViewModel",
  "InodeViewModel",
  "ServiceViewModel",
  "TimezoneViewModel",
)
@observer
class ServiceLogs extends Component {
  constructor(props) {
    super(props);
    this.logViewModel = this.props.LogViewModel;
    this.inodeModel = this.props.InodeViewModel;
    this.serviceModal = this.props.ServiceViewModel;
    this.state = {
      orgId: this.props.match.params.id,
      nodeId: this.props.match.params.nodeId,
      serviceId: this.props.match.params.serviceId,
      containerId: null,
      BreadcrumbList: [],
      nodeDetails: {},
      clusterDetails: {},
      containers: [],
      container_name: null,
      service_name: null,
      triggerAutoScrollBottom: false,
      triggerAutoScrollTop: false,
      isAutoloaded: false,
      isPreviousInstance: false,
    };
    this.color = {};
  }

  UNSAFE_componentWillMount() {
    this.logViewModel.resetLogs();
    this.logViewModel.setLoading(true);
  }

  componentDidMount() {
    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.cluster_id
    ) {
      this.clusterDetails();
    } else {
      this.iNodeDetails(null, true, true);
    }
  }

  serviceDetails = (isCluster = false) => {
    // Get service
    ServiceController.getService(this.state.serviceId, false).then(response => {
      if (response) {
        // update breadcrumblist
        let serviceName = response.name;
        let breadcrumbList = this.state.BreadcrumbList;
        if (isCluster) {
          breadcrumbList.push(
            {
              name: serviceName,
              link: `orgs/${this.state.orgId}/clusters/${this.props.match.params.cluster_id}/services`,
            },
            {
              name: "Service Logs",
              link: `orgs/${this.state.orgId}/clusters/${this.props.match.params.cluster_id}/services/${this.state.serviceId}/logs`,
            },
          );
        } else {
          breadcrumbList.push(
            {
              name: serviceName,
              link: `orgs/${this.state.orgId}/inodes/${this.state.nodeId}/servicetemplates`,
            },
            {
              name: "Service Logs",
              link: `orgs/${this.state.orgId}/inodes/${this.state.nodeId}/servicetemplates/${this.state.serviceId}/logs`,
            },
          );
        }

        this.setState({
          BreadcrumbList: breadcrumbList,
          service_name: serviceName,
        });
        let spec = response.spec;
        if (spec.services && spec.services[0]) {
          let containerId = spec.services[0].id;
          let map = {
            service_id: response.id,
            container_id: containerId,
          };
          this.setState({
            containers: spec.services,
            containerId: containerId,
            container_name: spec.services[0].name,
            isPreviousInstance: false,
          });
          // Get previous instance last log
          LogController.getPreviousInstanceLastLog("service_logs", map);
          // Get current instance first log
          LogController.getCurrentInstanceFirstLog("service_logs", map);
          // Get service log
          this.getLogData("service_logs", map, {}, true, true, false);
        } else {
        }
      }
    });
  };

  /**
   *  Get current iNode details for reference
   */
  iNodeDetails = (res = null, loading = true, forceCall = false) => {
    let map = {
      id: res !== null ? res.id : this.state.nodeId,
    };
    InodeController.getInode(map.id, loading, forceCall).then(res => {
      this.setState({ nodeDetails: res });
      this.updateInodeDetails(res);
      this.serviceDetails();
    });
  };

  /**
   *  Get current cluster details for reference
   */
  clusterDetails = (res = null, loading = true, forceCall = false) => {
    ClusterController.getCluster(this.props.match.params.cluster_id).then(
      result => {
        this.setState({ clusterDetails: result });
        this.updateClusterDetails(result);
        this.serviceDetails(true);
      },
    );
  };

  /**
   *  Common method to get log data
   */
  getLogData = (
    resource,
    map = {},
    params = {},
    loading = false,
    autoScrollBottom = false,
    autoScrollTop = false,
  ) => {
    if (this.logViewModel) {
      if (this.logViewModel.movePointerToOldest) {
        this.logViewModel.setPointerToOldest(false);
      }
      if (this.logViewModel.movePointerToLatest) {
        this.logViewModel.setPointerToLatest(false);
      }
    }
    LogController.getLogMessages(resource, map, params, loading)
      .then(result => {
        if (autoScrollBottom) {
          this.setState({
            triggerAutoScrollTop: false,
            triggerAutoScrollBottom: true,
          });
        } else if (autoScrollTop) {
          this.setState({
            triggerAutoScrollTop: true,
            triggerAutoScrollBottom: false,
          });
        } else {
          this.setState({
            triggerAutoScrollTop: false,
            triggerAutoScrollBottom: false,
          });
        }
      })
      .catch(error => {
        this.setState({
          triggerAutoScrollTop: false,
          triggerAutoScrollBottom: false,
        });
      });
  };

  /**
   *  Collect newest log
   */
  collectNewLogs = (autoScroll = false, canResetOld = false) => {
    let params = {};
    params.logFilePosition = "end";
    params.referenceTimestamp = "newest";
    params.offsetFrom = 2000000000;
    params.offsetTo = 2000000100;
    params.previous = false;
    // Set previous true if previous instance log exist
    if (this.logViewModel.showPreviousLogs) {
      params.previous = true;
    }

    if (canResetOld) {
      this.logViewModel.resetLogs();
    }
    let map = {
      service_id: this.props.match && this.props.match.params.serviceId,
      container_id: this.state.containerId,
    };
    this.getLogData("service_logs", map, params, true, autoScroll, false);
    this.setState({ isPreviousInstance: false });
  };

  /**
   *  Collect oldest log
   */
  collectOldLogs = (autoScroll = false) => {
    let params = {};
    params.logFilePosition = "beginning";
    params.referenceTimestamp = "oldest";
    params.offsetFrom = -2000000100;
    params.offsetTo = -2000000000;
    // Set previous true if previous instance log exist
    if (this.logViewModel.showPreviousLogs) {
      params.previous = true;
    }
    let map = {
      service_id: this.props.match && this.props.match.params.serviceId,
      container_id: this.state.containerId,
    };
    this.getLogData("service_logs", map, params, true, false, autoScroll);
    // Set isPreviousInstance as true only if the previous exited container log exists.
    if (this.logViewModel.isPreviousInstanceLastLogExists) {
      this.setState({ isPreviousInstance: true });
    }
  };

  /**
   *  Collect previous log, 1 step backward
   */
  collectPreviousLogs = () => {
    let params = {};
    params.logFilePosition = "end";
    params.offsetFrom = this.logViewModel.getLogPreviousOffsetFrom;
    params.offsetTo = this.logViewModel.getLogPreviousOffsetTo;
    params.referenceTimestamp = this.logViewModel.getLogPreviousReferenceTime;
    if (this.logViewModel.getLogPrevLineNum) {
      params.referenceLineNum = this.logViewModel.getLogPrevLineNum;
    }
    // For previous instance logs
    if (this.logViewModel.showPreviousLogs) {
      params.previous = true;
    }
    if (
      this.logViewModel.getLogPreviousReferenceTime &&
      this.logViewModel.getLogPreviousOffsetFrom &&
      this.logViewModel.getLogPreviousOffsetTo &&
      !this.logViewModel.movePointerToOldest
    ) {
      // For previous instance latest logs
      if (
        this.logViewModel.isPreviousInstanceLastLogExists &&
        this.logViewModel.isCurrentInstanceFirstLogReached &&
        this.logViewModel.showPreviousLogs
      ) {
        params = {};
        params.logFilePosition = "end";
        params.previous = true;
        params.offsetFrom = 0;
        params.offsetTo = 100;
        params.referenceTimestamp = "newest";
        this.getPrevLogs("service_logs", params, true);
        this.setState({ isPreviousInstance: true });
      } else {
        this.getPrevLogs("service_logs", params, true);
      }
    } else if (this.logViewModel.movePointerToOldest) {
      this.collectOldLogs();
    }
  };

  /**
   * Collect next recent log, 1 step forward
   */
  collectForwardLogs = () => {
    let params = {};
    params.logFilePosition = "end";
    params.referenceTimestamp = this.logViewModel.getLogNextReferenceTime;
    params.offsetFrom = this.logViewModel.getLogForwardOffsetFrom;
    params.offsetTo = this.logViewModel.getLogForwardOffsetTo;
    if (this.logViewModel.getLogNextLineNum) {
      params.referenceLineNum = this.logViewModel.getLogNextLineNum;
    }
    // For previous instance logs
    if (this.logViewModel.showPreviousLogs) {
      params.previous = true;
    }
    if (
      this.logViewModel.getLogNextReferenceTime &&
      this.logViewModel.getLogPreviousOffsetFrom &&
      this.logViewModel.getLogPreviousOffsetTo &&
      !this.logViewModel.movePointerToLatest
    ) {
      // For current instance oldest logs
      if (
        this.state.isPreviousInstance &&
        this.logViewModel.isPreviousInstanceLastLogReached
      ) {
        params = {};
        params.logFilePosition = "beginning";
        params.previous = false;
        params.offsetFrom = 0;
        params.offsetTo = 100;
        params.referenceTimestamp = "oldest";
        this.getNextLogs("service_logs", params, true);
        this.setState({ isPreviousInstance: false });
      } else {
        this.getNextLogs("service_logs", params, true);
      }
    } else if (this.logViewModel.movePointerToLatest) {
      this.collectNewLogs();
    }
  };

  getNextLogs = (resource, params = {}, loading = false) => {
    let map = {
      service_id: this.props.match && this.props.match.params.serviceId,
      container_id: this.state.containerId,
    };
    LogController.getForwardLogMessages(resource, map, params, loading).then(
      response => {
        this.setState({ isAutoloaded: true });
      },
    );
  };

  /**
   *  Common method to get previous/next log data
   */
  getPrevLogs = (resource, params = {}, loading = false) => {
    let map = {
      service_id: this.props.match && this.props.match.params.serviceId,
      container_id: this.state.containerId,
    };
    LogController.getPreviousLogMessages(resource, map, params, loading);
  };

  /**
   *  Send donwload data request
   */
  downloadLog = () => {
    let map = {};
    map.service_id = this.props.match && this.props.match.params.serviceId;
    map.container_id = this.state.containerId;
    let params = {};
    // Set previous true if previous instance log exist
    if (this.logViewModel.showPreviousLogs) {
      params.previous = true;
    }
    LogController.downloadLogs("download_service_logs", map, params, true).then(
      result => {
        let timezone =
          this.props.TimezoneViewModel.userTimezone ||
          this.props.TimezoneViewModel.orgTimezone ||
          getDefaultTimezone().value;
        let date = new Date();
        date = date ? moment.tz(date, timezone) : date;
        var datestring = date
          .toISOString()
          .replace(/-/g, "")
          .replace(/:/g, "")
          .split(".")[0];

        let blob = new Blob([result], { type: "text/plain;charset=utf-8" }),
          fileName =
            (this.props.match &&
            this.props.match.params &&
            this.props.match.params.cluster_id
              ? this.state.clusterDetails && this.state.clusterDetails.name
              : this.inodeModel.inode.name && this.inodeModel.inode.name) +
            "_" +
            this.state.service_name +
            "_" +
            this.state.container_name;
        FileSaver.saveAs(blob, fileName + "_" + datestring + ".log");
      },
    );
  };

  /**
   *  Update iNode details for breadcrumb and other routes info
   */
  updateInodeDetails = res => {
    this.setState(() => {
      let newObj = {},
        nodeDetails = this.state.nodeDetails,
        BreadcrumbList = [];

      nodeDetails = res;

      let nodeCrumb = [
        {
          name: res.name ? res.name : "Details",
          link: `orgs/${res.organization.id}/inodes/${res.id}`,
        },
      ];
      BreadcrumbList.push(
        {
          name: res.organization.name,
          link: `dashboard/${res.organization.id}`,
        },
        { name: "Nodes", link: `orgs/${res.organization.id}/inodes` },
        ...nodeCrumb,
      );
      this.props.UiStore.setInodeName(res.name);
      this.props.UiStore.setOrgName(res.organization.name);
      newObj["nodeDetails"] = nodeDetails;
      newObj["BreadcrumbList"] = BreadcrumbList;
      return newObj;
    });
  };

  /**
   *  Update iNode details for breadcrumb and other routes info
   */
  updateClusterDetails = res => {
    this.setState(() => {
      let newObj = {},
        clusterDetails = this.state.clusterDetails,
        BreadcrumbList = [];

      clusterDetails = res;

      let nodeCrumb = [
        {
          name: res.name ? res.name : "Details",
          link: `orgs/${res.organization.id}/clusters/${res.id}`,
        },
      ];
      BreadcrumbList.push(
        {
          name: res.organization.name,
          link: `dashboard/${res.organization.id}`,
        },
        { name: "Clusters", link: `orgs/${res.organization.id}/clusters` },
        ...nodeCrumb,
      );
      this.props.UiStore.setInodeName(res.name);
      this.props.UiStore.setOrgName(res.organization.name);
      newObj["nodeDetails"] = clusterDetails;
      newObj["BreadcrumbList"] = BreadcrumbList;
      return newObj;
    });
  };

  /**
   *  Container change action handler
   */
  onContainerChange = containerId => {
    let containerName = null;
    this.state.containers.map(container => {
      if (container.id === containerId) containerName = container.name;
    });
    let map = {
      service_id: this.props.match && this.props.match.params.serviceId,
      container_id: containerId,
    };
    this.setState({
      containerId: containerId,
      container_name: containerName,
      isPreviousInstance: false,
    });
    // Reset logs
    this.logViewModel.resetLogs();
    // Get previous instance last log
    LogController.getPreviousInstanceLastLog("service_logs", map);
    // Get current instance first log
    LogController.getCurrentInstanceFirstLog("service_logs", map);
    // Get service log
    this.getLogData("service_logs", map, {}, true);
  };

  /**
   * GO back
   */
  onCancel = () => {
    this.props.history.goBack();
  };
  resetScrollState = () => {
    this.setState({
      triggerAutoScrollTop: false,
      triggerAutoScrollBottom: false,
    });
  };
  render() {
    let node_data = this.inodeModel.inode;
    let cluster_data = this.state.clusterDetails;

    let container = (
      <div>
        <Row gutter={16} type="flex" align="middle">
          <Col span={12}>
            <h2 className="page-title">
              <Icons
                type={
                  this.props.match &&
                  this.props.match.params &&
                  this.props.match.params.cluster_id
                    ? "bs"
                    : "fa"
                }
                name={
                  this.props.match &&
                  this.props.match.params &&
                  this.props.match.params.cluster_id
                    ? "BsBoundingBox"
                    : "FaRegHdd"
                }
              />
              {"  "}
              {this.props.match &&
              this.props.match.params &&
              this.props.match.params.cluster_id
                ? cluster_data.name && cluster_data.name
                : node_data.name
                ? node_data.name
                : null}
            </h2>
            <BreadcrumbComponent
              {...this.props}
              BreadcrumbList={this.state.BreadcrumbList}
            />
          </Col>
          <Col span={12} className="flex flex-h-right">
            <OrgSelector
              {...this.props}
              className="org-selector-menu-padding"
            />
          </Col>
        </Row>
        <div>
          <Row gutter={2} type="flex" align="top">
            <Col span={24} className="card-grid">
              <LogsContainer
                type={"service"}
                downLoadRequest={this.downloadLog}
                searchText=""
                containers={this.state.containers}
                defaultContainer={this.state.containerId}
                onContainerChange={this.onContainerChange}
                collectNewLogs={this.collectNewLogs}
                collectOldLogs={this.collectOldLogs}
                collectForwardLogs={this.collectForwardLogs}
                collectPreviousLogs={this.collectPreviousLogs}
                onCancel={this.onCancel}
                triggerAutoScrollTop={this.state.triggerAutoScrollTop}
                triggerAutoScrollBottom={this.state.triggerAutoScrollBottom}
                resetScrollState={this.resetScrollState}
                isAutoloaded={this.state.isAutoloaded}
                refreshLog={canResetOld => {
                  this.collectNewLogs(true, canResetOld);
                }}
                resetAutoScroll={() => {
                  this.setState({ isAutoloaded: false });
                }}
              />
            </Col>
          </Row>
        </div>
      </div>
    );

    return (
      <LoadingComponent
        loading={
          typeof this.logViewModel.loading === "boolean"
            ? this.logViewModel.loading
            : true
        }
      >
        {container}
      </LoadingComponent>
    );
  }
}

export default ServiceLogs;
