import React, { Component } from "react";
import { Button, Row, Col, Modal, Select } from "antd";
import { observer, inject } from "mobx-react";
import TechDumpStatus from "components/Containers/TechDumpStatus";
import { getCurrentlocaleText, TECHDUMP_PROFILE } from "Core/Utils";
import Icons from "components/UI-Components/Icons";
import InodeController from "controller/InodeController";
import propTypes from "prop-types";
import PopOverHelp from "components/UI-Components/PopOverHelp";

const { Option } = Select;

@inject("UiStore", "InodeViewModel")
@observer
class TechDumpTrigger extends Component {
  constructor(props) {
    super(props);
    this.GLOBAL_SETTINGS = GLOBAL_SETTINGS;
    this.name =
      this.GLOBAL_SETTINGS && this.GLOBAL_SETTINGS.name
        ? this.GLOBAL_SETTINGS.name
        : "Neeve";
    this.inodeModel = this.props.InodeViewModel;
    this.state = {
      visible: false,
      loading: false,
      profile: "DEFAULT",
    };
  }

  showTechDumpTriggerModal = () => {
    this.setState({ visible: true, loading: false });
  };

  hideTechDumpTriggerModal = () => {
    this.setState({ visible: false, loading: false });
  };

  triggerRequest = () => {
    this.props.UiStore.techDumpTrigger(true);
    this.setState({ loading: true });
    let data = {
      profile: this.state.profile,
    };
    InodeController.triggerTechdump(this.props.node_id, data)
      .then(response => {
        this.setState({ loading: false });
        this.props.UiStore.techDumpTrigger(false);
        this.props.UiStore.successMessage = getCurrentlocaleText(
          "techdump.send.success.text",
        );
      })
      .catch(error => {
        this.setState({ loading: false });
        this.props.UiStore.techDumpTrigger(false);
      });
    this.hideTechDumpTriggerModal();
  };

  onProfileChange = value => {
    this.setState({
      profile: value,
    });
  };

  getProfileMenu = () => {
    let profiles = [];
    for (const [key, val] of Object.entries(TECHDUMP_PROFILE)) {
      profiles.push({ name: val.displayName, value: val.value });
    }
    if (profiles.length > 0) {
      return profiles.map((item, i) => {
        return (
          <Option key={item.value} value={item.value}>
            {item.name}
          </Option>
        );
      });
    }
  };

  render = () => {
    let statusResponse = this.inodeModel.techdumpStatus;
    return (
      <div>
        <div className="techdump-card">
          <Row style={{ marginBottom: "10px" }} type="flex">
            <Col
              xs={{ span: 12 }}
              sm={{ span: 12 }}
              md={{ span: 14 }}
              lg={{ span: 14 }}
              xl={{ span: 14 }}
            >
              {getCurrentlocaleText("techdump.profile.text")}
              <span style={{ marginLeft: -8 }}>
                <PopOverHelp
                  isHelpOnForm={true}
                  height={340}
                  width={340}
                  placement={"top"}
                  overlayClassName="iot-techdump-profile"
                  helpData={{
                    title: getCurrentlocaleText(
                      "techdump.profile.text",
                    ).replace(":", " "),
                    data: [
                      {
                        subtitle: "",
                        content: getCurrentlocaleText(
                          "techdump.profile.message",
                        ),
                      },
                      {
                        subtitle:
                          getCurrentlocaleText(
                            "techdump.profile.base_inode_data.text",
                          ) + ": ",
                        content: getCurrentlocaleText(
                          "techdump.profile.message2",
                        ),
                      },
                      {
                        subtitle:
                          getCurrentlocaleText(
                            "techdump.profile.level2_inode_data.text",
                          ) + ": ",
                        content: getCurrentlocaleText(
                          "techdump.profile.message3",
                        ),
                      },
                      {
                        subtitle:
                          getCurrentlocaleText(
                            "techdump.profile.systeminfo.text",
                          ) + ": ",
                        content: getCurrentlocaleText(
                          "techdump.profile.message4",
                        ),
                      },
                      {
                        subtitle:
                          getCurrentlocaleText(
                            "techdump.profile.commands.text",
                          ) + ": ",
                        content: getCurrentlocaleText(
                          "techdump.profile.message5",
                        ),
                      },
                      {
                        subtitle:
                          getCurrentlocaleText(
                            "techdump.profile.default.text",
                          ) + ": ",
                        content: getCurrentlocaleText(
                          "techdump.profile.message6",
                        ),
                      },
                    ],
                  }}
                />
              </span>
            </Col>
            <Col
              xs={{ span: 12 }}
              sm={{ span: 12 }}
              md={{ span: 10 }}
              lg={{ span: 10 }}
              xl={{ span: 10 }}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Select
                size={"default"}
                style={{ width: 200 }}
                placeholder="Select Profile"
                onChange={this.onProfileChange}
                defaultValue={this.state.profile}
                disabled={
                  this.props.nodeDetails.node_state !== "ALIVE" ||
                  (statusResponse &&
                    statusResponse.status &&
                    !statusResponse.completed_at &&
                    statusResponse.status.toLowerCase() === "initiated")
                    ? true
                    : false
                }
              >
                {this.getProfileMenu()}
              </Select>
            </Col>
          </Row>
          <Row style={{ marginBottom: "10px" }} type="flex">
            <Col
              xs={{ span: 12, push: 6 }}
              sm={{ span: 12, push: 6 }}
              md={{ span: 12, push: 6 }}
              lg={{ span: 12, push: 6 }}
              xl={{ span: 12, push: 6 }}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Button
                size="small"
                onClick={this.showTechDumpTriggerModal}
                disabled={
                  this.props.nodeDetails.node_state !== "ALIVE" ||
                  (statusResponse &&
                    statusResponse.status &&
                    !statusResponse.completed_at &&
                    statusResponse.status.toLowerCase() === "initiated")
                    ? true
                    : false
                }
              >
                <Icons
                  type="md"
                  name="MdSend"
                  style={{ fontSize: 16, marginBottom: 1 }}
                />{" "}
                {getCurrentlocaleText(
                  "node.update.techdump_modal.title",
                ).replace("?", "")}
              </Button>
            </Col>
          </Row>
        </div>
        {statusResponse &&
        statusResponse.status &&
        !statusResponse.completed_at &&
        statusResponse.status.toLowerCase() === "initiated" ? (
          <Row style={{ marginTop: "10px", marginBottom: "10px" }}>
            <TechDumpStatus
              Status={statusResponse ? statusResponse.status : null}
              nodeId={this.props.node_id}
            />
          </Row>
        ) : null}
        <Modal
          title={
            <span>
              <Icons
                type="md"
                name="MdSend"
                style={{ fontSize: 16, marginBottom: 2 }}
              />{" "}
              &nbsp;
              {getCurrentlocaleText("node.update.techdump_modal.title")}
            </span>
          }
          visible={this.state.visible}
          onOk={this.triggerRequest}
          onCancel={this.hideTechDumpTriggerModal}
          maskClosable={false}
          confirmLoading={this.state.loading}
          closable={!this.state.loading}
          width={800}
          footer={[
            <Button
              key="cancel"
              size="large"
              onClick={this.hideTechDumpTriggerModal}
              disabled={this.state.loading}
            >
              {getCurrentlocaleText(
                "node.update.techdump_modal_cancel_button.text",
              )}
            </Button>,
            <Button
              key="send"
              size="large"
              type="primary"
              onClick={this.triggerRequest}
            >
              <Icons
                type="md"
                name="MdSend"
                style={{ fontSize: 16, marginBottom: 2 }}
              />{" "}
              {getCurrentlocaleText(
                "node.update.techdump_modal_send_button.text",
              )}
            </Button>,
          ]}
        >
          <p>
            {getCurrentlocaleText("node.update.techdump_modal.message_1", {
              0: this.name,
            })}
          </p>
          <p>{getCurrentlocaleText("node.update.techdump_modal.message_2")}</p>
        </Modal>
      </div>
    );
  };
}
//nodeDetails props is required for the disable the send diagnostic button for inode which status is NEW:
TechDumpTrigger.propTypes = {
  nodeDetails: propTypes.object.isRequired,
};
// Specifies the default values for nodeDetails props
TechDumpTrigger.defaultProps = {
  nodeDetails: {},
};
export default TechDumpTrigger;
