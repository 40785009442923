import React, { Component } from "react";
import { Card, Col, Row, Progress } from "antd";
import { getInterval, getCurrentlocaleText, humanizeMetric } from "Core/Utils";
import { observer, inject } from "mobx-react";
import Icons from "components/UI-Components/Icons";
import { NetworkController } from "../../../controller/Controllers";
import { LoadingOutlined } from "@ant-design/icons";

@inject("AuthStore", "NodeStatsViewModel", "InodeViewModel")
@observer
class SystemOverview extends Component {
  constructor(props) {
    super(props);
    // GLOBAL_SETTINGS is configured form node js
    this.GLOBAL_SETTINGS = GLOBAL_SETTINGS;
    this.textColor =
      this.GLOBAL_SETTINGS && this.GLOBAL_SETTINGS.text_color
        ? this.GLOBAL_SETTINGS.text_color
        : "#012a22";
    this.primaryColor =
      this.GLOBAL_SETTINGS && this.GLOBAL_SETTINGS.primary_color
        ? this.GLOBAL_SETTINGS.primary_color
        : "#065545";
    this.state = {
      interfaces: this.props.NodeStatsViewModel.getStatsAndInterfaces
        .interfaces,
      currentInterface: this.props.NodeStatsViewModel.currentInterface,
      computed_networks: [],
    };
  }

  UNSAFE_componentWillReceiveProps(newprops) {
    if (this.props != newprops) {
      this.setState({
        interfaces:
          newprops.NodeStatsViewModel.getStatsAndInterfaces.interfaces,
      });
      this.updateInterface();
    }
  }

  updateInterface = () => {
    let interfaces = this.state.interfaces;
    if (interfaces && interfaces.length > 0) {
      interfaces.forEach(data => {
        if (data.name.startsWith("n-")) {
          let computed_networks = this.state.computed_networks;
          if (!computed_networks.includes(data.name)) {
            this.checkForNeworkName(data.name);
          }
        }
      });
    }
  };

  checkForNeworkName = name => {
    if (name && name.startsWith("n-")) {
      let networkIds = name.split("_");
      let fromNetwork = {};
      let toNetwork = {};
      let computed_networks = this.state.computed_networks;

      if (
        networkIds &&
        networkIds.length === 2 &&
        !computed_networks.includes(name)
      ) {
        NetworkController.getSingleNetworkById(networkIds[0]).then(res => {
          fromNetwork = res;
          NetworkController.getSingleNetworkById(networkIds[1]).then(res2 => {
            toNetwork = res2;
            this.setState({
              [name]:
                fromNetwork.name +
                " \u27F7 " +
                toNetwork.node.name +
                "/" +
                toNetwork.name,
              computed_networks: computed_networks,
            });
          });
        });
      }
    } else {
      return name;
    }
  };
  humanize = (num, size, units) => {
    var unit;
    for (unit = units.pop(); units.length && num >= size; unit = units.pop()) {
      num /= size;
    }
    return [num, unit];
  };

  // Get the index of the interface with the specified name.
  getNetworkInterfaceIndex = (interfaceName, interfaces) => {
    if (interfaces) {
      for (var i = 0; i < interfaces.length; i++) {
        if (interfaces[i].name == interfaceName) {
          return i;
        }
      }
    }
    return -1;
  };

  getBytesRx = (showSpeed = true) => {
    let data = this.props.NodeStatsViewModel.getStatsAndInterfaces.stats;
    let current_usage = this.props.NodeStatsViewModel.getStatsAndInterfaces
      .currentUsage;
    if (
      data.length > 2 &&
      current_usage &&
      current_usage.interfaces &&
      current_usage.interfaces.length > 0
    ) {
      var intervalInSec =
        getInterval(data[0].timestamp, data[1].timestamp) / 1000000000;
      let cur = current_usage.interfaces ? current_usage.interfaces : null;
      let curInterfaceIndex = this.getNetworkInterfaceIndex(
        this.props.NodeStatsViewModel.currentInterface
          ? this.props.NodeStatsViewModel.currentInterface
          : this.props.InodeViewModel.interfacesList
          ? this.props.InodeViewModel.interfacesList
          : cur[0].name
          ? cur[0].name
          : null,
        cur,
      );
      if (
        curInterfaceIndex !== -1 &&
        cur[curInterfaceIndex] &&
        cur[curInterfaceIndex].current_rx_bytes &&
        cur[curInterfaceIndex].current_rx_bytes > 0
      ) {
        if (showSpeed) {
          return humanizeMetric(
            cur[curInterfaceIndex].current_rx_bytes > 0
              ? cur[curInterfaceIndex].current_rx_bytes
              : 0,
          );
        } else {
          return humanizeMetric(
            cur[curInterfaceIndex].current_rx_bytes * intervalInSec > 0
              ? cur[curInterfaceIndex].current_rx_bytes * intervalInSec
              : 0,
            true,
          );
        }
      } else {
        if (!showSpeed) {
          return humanizeMetric(0, true);
        }
      }
    }
  };

  getBytesTx = (showSpeed = true) => {
    let data = this.props.NodeStatsViewModel.getStatsAndInterfaces.stats;
    let current_usage = this.props.NodeStatsViewModel.getStatsAndInterfaces
      .currentUsage;
    if (
      data.length > 2 &&
      current_usage &&
      current_usage.interfaces &&
      current_usage.interfaces.length > 0
    ) {
      var intervalInSec =
        getInterval(data[0].timestamp, data[1].timestamp) / 1000000000;
      let cur = current_usage.interfaces ? current_usage.interfaces : null;
      let curInterfaceIndex = this.getNetworkInterfaceIndex(
        this.props.NodeStatsViewModel.currentInterface
          ? this.props.NodeStatsViewModel.currentInterface
          : this.props.InodeViewModel.interfacesList
          ? this.props.InodeViewModel.interfacesList
          : cur[0].name
          ? cur[0].name
          : null,
        cur,
      );
      if (
        curInterfaceIndex !== -1 &&
        cur[curInterfaceIndex] &&
        cur[curInterfaceIndex].current_tx_bytes &&
        cur[curInterfaceIndex].current_tx_bytes > 0
      ) {
        if (showSpeed) {
          return humanizeMetric(
            cur[curInterfaceIndex].current_tx_bytes > 0
              ? cur[curInterfaceIndex].current_tx_bytes
              : 0,
          );
        } else {
          return humanizeMetric(
            cur[curInterfaceIndex].current_tx_bytes * intervalInSec > 0
              ? cur[curInterfaceIndex].current_tx_bytes * intervalInSec
              : 0,
            true,
          );
        }
      } else {
        if (!showSpeed) {
          return humanizeMetric(0, true);
        }
      }
    }
  };

  replaceInterfaceName = name => {
    if (name.startsWith("n-")) {
      return this.state[name] ? this.state[name] : name;
    } else {
      let multinic_interfaces = this.props.InodeViewModel
        .multiNicInterfacesList;
      if (multinic_interfaces && multinic_interfaces.length > 0) {
        let interface_name = null;
        multinic_interfaces.forEach(multiNicInterface => {
          if (multiNicInterface.name == name) {
            interface_name = multiNicInterface.display_name + " (" + name + ")";
          }
        });
        return interface_name ? interface_name : name;
      }
      return name;
    }
  };
  render() {
    let cpu_live_info = this.props.NodeStatsViewModel.getCurrentCPUValue
      ? this.props.NodeStatsViewModel.getCurrentCPUValue
      : "NA";
    let memory_live_info = this.props.NodeStatsViewModel.getCurrentMemoryValue;
    let fs_live_info = this.props.NodeStatsViewModel.getCurrentFileSystemValue;
    let extra_info = this.props.NodeStatsViewModel.getExtraInfoForGauge;

    return (
      <Card
        title={
          <span>
            <span>
              {this.props.InodeViewModel &&
                this.props.InodeViewModel.inode &&
                this.props.InodeViewModel.inode.name &&
                this.props.InodeViewModel.inode.name + " Overview"}
            </span>
          </span>
        }
        bordered={false}
        hoverable={false}
        bodyStyle={{ display: "grid", justifyContent: "center" }}
      >
        <div style={{ display: "flex" }}>
          {" "}
          <span>
            <Card bordered={false} hoverable={false}>
              <Row type="flex" align="middle">
                <Col span={24}>
                  <center>
                    {cpu_live_info != "NA" ? (
                      <Progress
                        type="dashboard"
                        strokeColor={{
                          "100%": this.primaryColor,
                        }}
                        percent={parseFloat(
                          cpu_live_info && cpu_live_info.toFixed(1),
                        )}
                        format={percent => (
                          <span>
                            <Row>
                              <Col span={24} className="stats-gauge-title-txt">
                                {getCurrentlocaleText(
                                  "stats.card.cpu_memory.title.text",
                                )}
                              </Col>
                              <Col span={24}>{`${percent} %`}</Col>
                            </Row>
                          </span>
                        )}
                        strokeWidth={4}
                        width={120}
                      />
                    ) : (
                      getCurrentlocaleText("stats.nometrics.text")
                    )}
                  </center>
                </Col>
              </Row>
              <Row type="flex" align="middle">
                <Col span={24}>
                  <Card
                    bordered={true}
                    hoverable={false}
                    className="stats-dashboard-memory-moreinfo"
                  >
                    <Row>
                      <Col span={24}>
                        <strong>{"Used/Total: "}</strong>
                        {extra_info &&
                        extra_info.cpu &&
                        extra_info.cpu.used &&
                        extra_info.cpu.numberof_cores
                          ? extra_info.cpu.used.toFixed(2) +
                            "/" +
                            extra_info.cpu.numberof_cores +
                            " Cores"
                          : "NA"}
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Card>
          </span>
          <span>
            <Card bordered={false} hoverable={false}>
              <span className="card-container">
                <Row type="flex" align="middle">
                  <Col span={24}>
                    <center>
                      {memory_live_info && memory_live_info != "NA" ? (
                        <Progress
                          type="dashboard"
                          strokeColor={{
                            "100%": this.primaryColor,
                          }}
                          percent={parseFloat(
                            memory_live_info && memory_live_info.toFixed(1),
                          )}
                          format={percent => (
                            <span>
                              <Row>
                                <Col
                                  span={24}
                                  className="stats-gauge-title-txt"
                                >
                                  {getCurrentlocaleText(
                                    "stats.card.memory.title.text",
                                  )}
                                </Col>
                                <Col span={24}>{`${percent} %`}</Col>
                              </Row>
                            </span>
                          )}
                          strokeWidth={4}
                          width={120}
                        />
                      ) : (
                        getCurrentlocaleText("stats.nometrics.text")
                      )}
                    </center>
                  </Col>
                </Row>
                <Row type="flex" align="middle">
                  <Col span={24}>
                    <Card
                      bordered={true}
                      className="stats-dashboard-memory-moreinfo"
                      hoverable={false}
                    >
                      <Row>
                        <Col span={24}>
                          <span>
                            <strong>{"Used/Total: "}</strong>
                            {extra_info &&
                            extra_info.memory &&
                            extra_info.memory.used &&
                            extra_info.memory.total
                              ? humanizeMetric(extra_info.memory.used, true) +
                                "/" +
                                humanizeMetric(extra_info.memory.total, true)
                              : "NA"}
                          </span>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
                {extra_info &&
                  extra_info.memory &&
                  extra_info.memory.used &&
                  extra_info.memory.cache &&
                  extra_info.memory.free &&
                  extra_info.memory.total && (
                    <div className="card-overlay stats-dashboard-gauge-hover">
                      <div className="stats-overview-gauge-overlay ">
                        <Card
                          bordered={false}
                          hoverable={false}
                          className="stats-dashboard-memory-moreinfo"
                        >
                          <Row>
                            <Col span={8}>
                              <strong>{"Used: "}</strong>
                            </Col>
                            <Col span={14}>
                              {extra_info &&
                              extra_info.memory &&
                              extra_info.memory.used
                                ? humanizeMetric(extra_info.memory.used, true)
                                : "NA"}
                            </Col>
                          </Row>
                          <Row>
                            <Col span={8}>
                              <strong>{"Cache: "}</strong>
                            </Col>
                            <Col span={14}>
                              {extra_info &&
                              extra_info.memory &&
                              extra_info.memory.cache
                                ? humanizeMetric(extra_info.memory.cache, true)
                                : "NA"}
                            </Col>
                          </Row>
                          <Row>
                            <Col span={8}>
                              <strong>{"Free: "}</strong>
                            </Col>
                            <Col span={14}>
                              {extra_info &&
                              extra_info.memory &&
                              extra_info.memory.free
                                ? humanizeMetric(extra_info.memory.free, true)
                                : "NA"}
                            </Col>
                          </Row>
                          <Row>
                            <Col span={8}>
                              <strong>{"Total: "}</strong>
                            </Col>
                            <Col span={14}>
                              {extra_info &&
                              extra_info.memory &&
                              extra_info.memory.total
                                ? humanizeMetric(extra_info.memory.total, true)
                                : "NA"}
                            </Col>
                          </Row>
                        </Card>
                      </div>
                    </div>
                  )}
              </span>
            </Card>
          </span>
          <span>
            <Card
              bordered={false}
              className={
                !fs_live_info || !extra_info
                  ? "metics-hover-novalue-overflow"
                  : ""
              }
              hoverable={false}
            >
              <Row type="flex" align="middle">
                <Col span={24}>
                  <center>
                    {fs_live_info && fs_live_info != "NA" ? (
                      <Progress
                        type="dashboard"
                        strokeColor={{
                          "100%": this.primaryColor,
                        }}
                        percent={parseFloat(
                          fs_live_info && fs_live_info.toFixed(1),
                        )}
                        format={percent => (
                          <span>
                            <Row>
                              <Col span={24} className="stats-gauge-title-txt">
                                {getCurrentlocaleText(
                                  "stats.filesystem.title.text",
                                )}
                              </Col>
                              <Col span={24}>{`${percent} %`}</Col>
                            </Row>
                          </span>
                        )}
                        strokeWidth={4}
                        width={120}
                      />
                    ) : (
                      getCurrentlocaleText("stats.nometrics.text")
                    )}
                  </center>
                </Col>
              </Row>
              <Row type="flex" align="middle">
                <Col span={24}>
                  <Card
                    bordered={true}
                    hoverable={false}
                    className="stats-dashboard-memory-moreinfo"
                  >
                    <Row>
                      <Col span={24}>
                        <strong>{"Used/Total: "}</strong>
                        {extra_info &&
                        extra_info.filesystem &&
                        extra_info.filesystem.used &&
                        extra_info.filesystem.total
                          ? humanizeMetric(extra_info.filesystem.used, true) +
                            "/" +
                            humanizeMetric(extra_info.filesystem.total, true)
                          : "NA"}
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Card>
          </span>
          <span>
            <Card
              bordered={false}
              hoverable={false}
              bodyStyle={{ paddingBottom: 0, paddingTop: 12, width: 225 }}
            >
              <Card
                bordered={true}
                hoverable={false}
                bodyStyle={{ paddingBottom: 0 }}
              >
                <Row>
                  <Col span={24}>
                    <div
                      title={getCurrentlocaleText(
                        "stats.traffic_overview.title.text",
                      )}
                      className="network-over-view-header network-over-view-header-text"
                      style={{ marginLeft: "14%" }}
                    >
                      {getCurrentlocaleText(
                        "stats.traffic_overview.title.text",
                      )}
                      {this.replaceInterfaceName(
                        this.props.NodeStatsViewModel.currentInterface
                          ? this.props.NodeStatsViewModel.currentInterface
                          : this.props.InodeViewModel.interfacesList
                          ? this.props.InodeViewModel.interfacesList
                          : "eth0",
                      ).startsWith("n-") ? (
                        <LoadingOutlined />
                      ) : (
                        " (" +
                        this.replaceInterfaceName(
                          this.props.NodeStatsViewModel.currentInterface
                            ? this.props.NodeStatsViewModel.currentInterface
                            : this.props.InodeViewModel.interfacesList
                            ? this.props.InodeViewModel.interfacesList
                            : "eth0",
                        ) +
                        ")"
                      )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Card
                      bordered={false}
                      bodyStyle={{ paddingBottom: 0, paddingTop: 0 }}
                    >
                      <Row>
                        <Col span={24}>
                          <Icons
                            className="inode-card-number-icon"
                            type="io"
                            name="IoIosArrowRoundDown"
                            style={{ paddingBottom: 16, marginBottom: -20 }}
                          />
                        </Col>
                      </Row>
                      <Row style={{ paddingBottom: 40 }}>
                        <Col span={24}>
                          <span
                            title={
                              this.getBytesRx() ? this.getBytesRx() : "0 bps"
                            }
                            className="stats-network-overview-count"
                            style={{ paddingBottom: 16, marginBottom: -20 }}
                          >
                            {this.getBytesRx() ? this.getBytesRx() : "0 bps"}
                          </span>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                  <Col span={12}>
                    <Card
                      bordered={false}
                      bodyStyle={{ paddingBottom: 0, paddingTop: 0 }}
                    >
                      <Row>
                        <Col span={24}>
                          <Icons
                            type="io"
                            name="IoIosArrowRoundUp"
                            className="inode-card-number-icon"
                            style={{ paddingBottom: 16, marginBottom: -20 }}
                          />
                        </Col>
                      </Row>
                      <Row style={{ paddingBottom: 40 }}>
                        <Col span={24}>
                          <span
                            title={
                              this.getBytesTx() ? this.getBytesTx() : "0 bps"
                            }
                            className="stats-network-overview-count"
                            style={{ paddingBottom: 16 }}
                          >
                            {this.getBytesTx() ? this.getBytesTx() : "0 bps"}
                          </span>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </Card>
              <Row type="flex" align="middle" style={{ marginTop: 12 }}>
                <Col span={24}>
                  <Card
                    bordered={true}
                    hoverable={false}
                    bodyStyle={{ textAlign: "center" }}
                  >
                    <Row>
                      <Col span={24}>
                        <strong>{"Transmit/Receive: "}</strong>
                        {this.getBytesTx(false) && this.getBytesRx(false)
                          ? this.getBytesTx(false) +
                            "/" +
                            this.getBytesRx(false)
                          : "NA"}
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Card>
          </span>
        </div>
      </Card>
    );
  }
}

export default SystemOverview;
