import React, { Component } from "react";
import { Link } from "react-router-dom";
import { observer, inject } from "mobx-react";
import { Row, Col, Tag, Popover, Button, Select } from "antd";
import {
  getCurrentlocaleText,
  getLastContactedAt,
  getTagKeyValues,
  getIP,
  NOT_APPLICAPLE,
  getTagFromReservedLabels,
} from "Core/Utils";
import LabelProvider from "components/UI-Components/LabelProvider";
import TableLayout from "components/layout/TableLayout";
import { NodeStatus } from "components/INodes";
import SearchInput from "components/UI-Components/SearchInput";
import Icons from "components/UI-Components/Icons";
import EthernetImage from "../../assets/img/ethernet.svg";
import ClusterController from "controller/ClusterController";
import LoadingComponent from "components/UI-Components/LoadingComponent";
import InterfaceGroupReAssignModal from "components/Containers/InterfaceGroupReAssignModal";
import PopOverHelp from "components/UI-Components/PopOverHelp";

@inject("AuthStore", "ClusterViewModel", "UiStore", "OrgViewModel")
@observer
class ClusteriNodesList extends Component {
  constructor(props) {
    super(props);
    this.clusterModel = this.props.ClusterViewModel;
    this.searchTimeout = null;
    this.state = {
      filterInfo: null,
      sorterInfo: { sorter: {}, sort: {} },
      expandedRowKeys: [],
      ClusteriNodesList: this.props.ClusterediNodeList
        ? this.props.ClusterediNodeList
        : [],
      searchText: "",
      showMasterWarning: this.props.showMasterWarning
        ? this.props.showMasterWarning
        : false,
      isMasterUnAvailable: this.props.isMasterUnAvailable
        ? this.props.isMasterUnAvailable
        : false,
      showInodes: true,
      showInterfaces: false,
      loading: false,
      isEditMode: false,
      netplan: [],
      tanInterfaces: [],
      freeInterfaces: [],
      showModal: false,
    };
  }

  componentDidMount() {
    this.fetchClusterNetplan(this.props.clusterId);
  }

  fetchClusterNetplan = clusterId => {
    this.setState({ loading: true, netplan: [] });
    ClusterController.getClusterNetplan(clusterId)
      .then(response => {
        let netplan = this.formNetplanArray(response);
        this.setState({ netplan: netplan, loading: false });
      })
      .catch(err => {
        this.setState({ netplan: [], loading: false });
      });
  };

  formNetplanArray = netplanObject => {
    let netplan = [];
    if (netplanObject) {
      if (netplanObject.wan) {
        netplan = netplan.concat(
          ...netplanObject.wan.map(item => {
            item.group = "wan";
            item.originalGroup = item.group;
            item.id = item.display_name;
            return item;
          }),
        );
      }
      if (netplanObject.tan) {
        netplan = netplan.concat(
          ...netplanObject.tan.map(item => {
            item.group = "tan";
            item.originalGroup = item.group;
            item.id = item.display_name;
            return item;
          }),
        );
      }
      if (netplanObject.free) {
        netplan = netplan.concat(
          ...netplanObject.free.map(item => {
            item.group = "free";
            item.originalGroup = item.group;
            item.id = item.display_name;
            return item;
          }),
        );
      }
    }
    return netplan;
  };

  handleConfirm = () => {
    if (
      this.state.tanInterfaces.length > 0 ||
      this.state.freeInterfaces.length > 0
    ) {
      let netplanUpdateData = {
        TAN: {
          interfaces: this.state.tanInterfaces.map(
            item => item && item.display_name,
          ),
        },
        FREE: {
          interfaces: this.state.freeInterfaces.map(
            item => item && item.display_name,
          ),
        },
      };
      this.setState({ loading: true }, () => {
        ClusterController.updateClusterNetplan(
          this.props.clusterId,
          netplanUpdateData,
        ).then(response => {
          let netplan = this.formNetplanArray(response);
          this.setState({
            loading: false,
            showModal: false,
            netplan: netplan,
            isEditMode: false,
            tanInterfaces: [],
            freeInterfaces: [],
          });
        });
      });
    } else {
      this.setState({
        loading: false,
        isEditMode: false,
      });
    }
  };

  closeModal = () => {
    this.setState({
      showModal: false,
    });
  };

  handleInterfaceGroupChange = (value, record) => {
    let toGroup = value;
    let tanInterfaces = this.state.tanInterfaces;
    let freeInterfaces = this.state.freeInterfaces;
    let netplan = this.state.netplan;
    switch (toGroup) {
      case "tan":
        {
          if (record.originalGroup !== toGroup) {
            tanInterfaces.push(record);
          }
          let index = freeInterfaces.findIndex(item => {
            return item && item.display_name === record.display_name;
          });
          if (index !== -1) {
            freeInterfaces.splice(index, 1);
          }
          // Update the netplan
          index = this.state.netplan.findIndex(item => {
            return item && item.name === record.name;
          });
          if (index !== -1) {
            netplan[index].group = toGroup;
          }
        }
        break;
      case "free":
        {
          if (record.originalGroup !== toGroup) {
            freeInterfaces.push(record);
          }
          let index = tanInterfaces.findIndex(item => {
            return item && item.display_name === record.display_name;
          });
          if (index !== -1) {
            tanInterfaces.splice(index, 1);
          }
          // Update the netplan
          index = this.state.netplan.findIndex(item => {
            return item && item.name === record.name;
          });
          if (index !== -1) {
            netplan[index].group = toGroup;
          }
        }
        break;
    }
    this.setState({
      netplan: netplan,
      tanInterfaces: tanInterfaces,
      freeInterfaces: freeInterfaces,
    });
  };

  onTableChange = (pagination, filter, sorter) => {
    let sort = {};
    (sortBy = null), (sortOrder = null);
    if (sorter.columnKey && sorter.order) {
      if (sorter.order === "ascend") {
        sort.order = "ASC";
      } else {
        sort.order = "DESC";
      }
      sort.by = sorter.columnKey;
    }

    this.setState({
      filterInfo: filter,
      sorterInfo: { sorter: sorter, sort: sort },
    });
    let sortBy = sort.by;
    let sortOrder = sort.order;
    let params = {};
    // For sort
    if (sortBy && sortOrder) {
      params.sort = `${sortBy}:${sortOrder}`;
    }
    // For Filter
    if (filter) {
      let keys = Object.keys(filter);
      keys.forEach(key => {
        if (filter[key]) {
          params[key] = filter[key];
        }
      });
    }
    this.props.fetchInodeList(null, params);
  };

  UNSAFE_componentWillUpdate(prevProps) {
    if (this.props != prevProps) {
      this.setState({
        ClusteriNodesList: prevProps.ClusterediNodeList,
        showMasterWarning: prevProps.showMasterWarning,
        isMasterUnAvailable: prevProps.isMasterUnAvailable,
      });
    }
  }

  resetState = () => {
    this.setState({
      selectedCluster: {},
      deletableClusters: {
        clusters: [],
      },
    });
  };

  expandRow = () => {
    let keys = this.clusterModel.clustered_inodes.map(item => {
      return item.id;
    });
    this.setExpandedRowKeys(
      this.state.expandedRowKeys.length !== keys.length ? keys : [],
    );
  };

  onExpand = (expanded, record) => {
    let keys = this.state.expandedRowKeys;
    let index = keys.findIndex(val => {
      return val === record.id;
    });
    if (expanded) {
      if (index === -1) {
        keys.push(record.id);
      }
      this.setExpandedRowKeys(keys);
    } else {
      if (index !== -1) {
        keys.splice(index, 1);
      }
      this.setExpandedRowKeys(keys);
    }
  };

  setExpandedRowKeys = keys => {
    this.setState({ expandedRowKeys: keys });
  };

  lazyLoad = () => {
    if (this.props.ClusterViewModel.pageable.next) {
      let page = this.props.ClusterViewModel.pageable.page + 1;
      this.clusterList(
        true,
        page,
        this.state.searchText,
        this.state.sorterInfo.sort.by,
        this.state.sorterInfo.sort.order,
        this.state.filterInfo,
        false,
      );
    }
  };
  /**
   * GET CLUSTER ROUTE
   */
  getCurrentRoute = () => {
    let current_path = window.location.pathname.split("/");
    current_path = current_path.splice(0, 5);
    current_path = current_path.join("/");
    return current_path;
  };

  getMoreInfo(record) {
    let tags = record.metadata ? getTagKeyValues(record.metadata.labels) : [];
    let reservedLabels = record.metadata
      ? getTagFromReservedLabels(record.metadata.labels)
      : [];

    return (
      <div>
        <Row>
          <Col>
            <div style={{ width: 66 }} className="expand-content-right">
              <strong>{"Label"}</strong>
            </div>
            <strong>:&nbsp;</strong>
            {record.metadata && tags.length + reservedLabels.length > 0 ? (
              <div style={{ display: "inline-block", marginLeft: 2 }}>
                <LabelProvider
                  key={record.id}
                  tags={tags}
                  metadata={record.metadata.labels}
                  showPopupLabel={false}
                  viewOnly={true}
                  reservedLabels={reservedLabels}
                />
              </div>
            ) : (
              <span style={{ marginLeft: 2 }}>{"-"}</span>
            )}
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <div>
              <Col span={24}>
                <div className="expand-content-left">
                  <strong>
                    {getCurrentlocaleText("inode.systeminfo.publicip.text")}
                  </strong>
                </div>
                <strong> &nbsp;:&nbsp;</strong>
                <span>{getIP(record, "public")}</span>
              </Col>
            </div>
            <div>
              <Col span={24}>
                <div className="expand-content-left">
                  <strong>
                    {getCurrentlocaleText("inode.systeminfo.privateip.text")}
                  </strong>
                </div>
                <strong> &nbsp;:&nbsp;</strong>
                {getIP(record, "private")}
              </Col>
            </div>
          </Col>
          <Col span={12}>
            <div>
              <Col span={24}>
                <div className="expand-content-right">
                  <strong>
                    {getCurrentlocaleText(
                      "inode.systeminfo.device_vendor.text",
                    )}
                  </strong>
                </div>
                <strong>&nbsp;:&nbsp;</strong>
                {record.status &&
                record.status.node &&
                record.status.node.info &&
                record.status.node.info.device_vendor_details
                  ? record.status.node.info.device_vendor_details
                  : NOT_APPLICAPLE}
              </Col>
            </div>
            <div>
              <Col span={24}>
                <div className="expand-content-right">
                  <strong>
                    {getCurrentlocaleText("inode.systeminfo.serialnumber.text")}
                  </strong>
                </div>
                <strong>&nbsp;:&nbsp;</strong>
                {record.hardware_serial_number}
              </Col>
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  iNodeListFilter = e => {
    clearTimeout(this.searchTimeout);
    let searchText = e.target.value;
    let qry_params = {};
    this.setState({ searchText: searchText });
    this.searchTimeout = setTimeout(() => {
      qry_params.search = searchText;
      let sortBy = this.state.sorterInfo.sort.by;
      let sortOrder = this.state.sorterInfo.sort.order;
      let filter = this.state.filterInfo;
      // For sort
      if (sortBy && sortOrder) {
        qry_params.sort = `${sortBy}:${sortOrder}`;
      }
      // For Filter
      if (filter) {
        let keys = Object.keys(filter);
        keys.forEach(key => {
          if (filter[key]) {
            qry_params[key] = filter[key];
          }
        });
      }
      this.props.fetchInodeList(null, qry_params);
    }, 500);
  };
  clearSearchFilter = () => {
    let params = {};
    params.search = "";
    let sortBy = this.state.sorterInfo.sort.by;
    let sortOrder = this.state.sorterInfo.sort.order;
    let filter = this.state.filterInfo;
    // For sort
    if (sortBy && sortOrder) {
      params.sort = `${sortBy}:${sortOrder}`;
    }
    // For Filter
    if (filter) {
      let keys = Object.keys(filter);
      keys.forEach(key => {
        if (filter[key]) {
          params[key] = filter[key];
        }
      });
    }
    this.props.fetchInodeList(null, params);
    this.setState({ searchText: "" });
  };

  render() {
    let { filterInfo, sorterInfo } = this.state;
    filterInfo = filterInfo || {};
    sorterInfo = sorterInfo || {};

    let cluster_node_list_data = this.state.ClusteriNodesList;
    const electionNwId =
      this.clusterModel.cluster &&
      this.clusterModel.cluster.config &&
      this.clusterModel.cluster.config.election_network_id
        ? this.clusterModel.cluster.config.election_network_id
        : "";
    const isTanCluster =
      this.clusterModel &&
      this.clusterModel.cluster &&
      this.clusterModel.cluster.config &&
      this.clusterModel.cluster.config.election_network_type &&
      this.clusterModel.cluster.config.election_network_type == "TAN"
        ? true
        : false;
    const isMultinicMode =
      this.clusterModel &&
      this.clusterModel.cluster &&
      this.clusterModel.cluster.config &&
      this.clusterModel.cluster.config.multinic_mode &&
      this.clusterModel.cluster.config.multinic_mode.enable
        ? true
        : false;

    {
      /** iNodes columns */
    }
    this.columns = [
      {
        title: getCurrentlocaleText("node.list.table.node_name.label.text"),
        dataIndex: "name",
        key: "name",
        sorter: true,
        width: "20%",
        sortOrder:
          sorterInfo.sorter.columnKey === "name" && sorterInfo.sorter.order,
        render: (text, record, index) => {
          let clusterLink = `/orgs/${record.details.organization.id}/inodes/${record.id}`;
          return (
            <div>
              <Link to={clusterLink}>{text}</Link>
              <br />
              {record.is_master &&
                record.is_candidate &&
                !this.state.showMasterWarning && (
                  <Tag className="iot-tag-label-color" closable={false}>
                    {getCurrentlocaleText("cluster.node_list.master.cidr.text")}
                  </Tag>
                )}

              {isTanCluster && !electionNwId ? (
                <Popover
                  overlayStyle={{ maxWidth: "20%", float: "right" }}
                  placement="right"
                  content={getCurrentlocaleText(
                    "cluster.node.mastership.election.help",
                  )}
                >
                  <span>
                    <Icons
                      style={{ fontSize: 25 }}
                      className="p-5 iot-status-red"
                      type="fa"
                      name="FaExclamationTriangle"
                    />
                  </span>
                </Popover>
              ) : (
                ((record.is_master &&
                  this.state.showMasterWarning &&
                  record.is_candidate) ||
                  (this.state.isMasterUnAvailable &&
                    record.is_candidate &&
                    record.status === "ALIVE")) &&
                this.state.searchText == "" && (
                  <Popover
                    overlayStyle={{ maxWidth: "20%", float: "right" }}
                    placement="right"
                    content={getCurrentlocaleText(
                      "cluster.master.election.inprogress.message",
                    )}
                  >
                    <span>
                      <Icons
                        style={{ fontSize: 25 }}
                        className="p-5 iot-status-yellow"
                        type="fa"
                        name="FaRegClock"
                      />
                    </span>
                  </Popover>
                )
              )}
            </div>
          );
        },
      },
      {
        title: getCurrentlocaleText("node.list.table.node_status.label.text"),
        dataIndex: "state",
        key: "state",
        width: "20%",
        filters: [
          {
            text: "ALIVE",
            value: "ALIVE",
          },
          {
            text: "NEW",
            value: "NEW",
          },
          {
            text: "REBOOTING",
            value: "REBOOTING",
          },
          {
            text: "UNREACHABLE",
            value: "UNREACHABLE",
          },
        ],
        filteredValue: filterInfo.state || null,
        render: (text, record, index) => {
          return (
            <div>
              <NodeStatus
                nodeState={record.status}
                lastContact={getLastContactedAt(record.details)}
                nodeProfile={record.details.profile}
              />
            </div>
          );
        },
      },
      {
        title: getCurrentlocaleText("cluster.candidate.label.text"),
        dataIndex: "is_candidate",
        key: "is_candidate",
        width: "18%",
        filters: [
          {
            text: "Yes",
            value: true,
          },
          {
            text: "No",
            value: false,
          },
        ],
        filterMultiple: true,
        onFilter: (value, record) => record.is_candidate === value,
        render: (text, record, index) => {
          let x = record.is_candidate
            ? getCurrentlocaleText("clusters.candidate.yes")
            : getCurrentlocaleText("clusters.candidate.no");

          return <span style={{ marginLeft: 10 }}>{x}</span>;
        },
      },
      {
        title: getCurrentlocaleText("firewall.list.table.header.priority.text"),
        dataIndex: "priority",
        key: "priority",
        sortOrder:
          sorterInfo.sorter.columnKey === "priority" && sorterInfo.sorter.order,
        sorter: true,
        render: (text, record, index) => {
          return <div>{record.is_candidate ? record.priority : "-"}</div>;
        },
      },
    ];

    {
      /** Interface columns */
    }
    this.interfaces_columns = [
      {
        title: getCurrentlocaleText("orgs.tablelist.namecolumn.text"),
        dataIndex: "display_name",
        width: "25%",
        defaultSortOrder: "ascend",
        sorter: (a, b) => {
          if (a.display_name.toLowerCase() < b.display_name.toLowerCase())
            return -1;
          if (a.display_name.toLowerCase() > b.display_name.toLowerCase())
            return 1;
          return 0;
        },
        key: "diaplay_name",
        render: (text, record, index) => {
          return (
            <span>
              <code>{record.label + " (" + record.name + ")"}</code>
            </span>
          );
        },
      },
      {
        title: getCurrentlocaleText("inode.interface.type.label.text"),
        key: "type",
        width: "15%",
        render: (text, record, index) => {
          return (
            <span>
              {getCurrentlocaleText("inode.interface.ethernet.label.text")}
            </span>
          );
        },
      },
      {
        title: getCurrentlocaleText("general.available.text"),
        key: "availability",
        width: "15%",
        render: (text, record, index) => {
          return (
            <span>
              {record.is_available !== undefined &&
              record.is_available !== null ? (
                <span>
                  {record.is_available && record.group.toLowerCase() !== "wan"
                    ? "Yes"
                    : "No"}
                </span>
              ) : (
                "-"
              )}
            </span>
          );
        },
      },
      {
        title: getCurrentlocaleText("service_listener.port.text"),
        key: "port",
        width: "15%",
        render: (text, record, index) => {
          let type = record.type && record.type.toLowerCase();
          return (
            <span
              title={getCurrentlocaleText(
                "inode.interface.ethernet.label.text",
              )}
            >
              <EthernetImage
                style={{
                  height: 20,
                  width: 24,
                  marginTop: 3,
                  marginBottom: -4,
                }}
              />
            </span>
          );
        },
      },
      {
        title: (
          <span>
            {getCurrentlocaleText("assignto.text")}
            <PopOverHelp
              isHelpOnForm={true}
              height={180}
              width={250}
              placement="right"
              helpData={{
                data: [
                  {
                    subtitle: "",
                    content: getCurrentlocaleText(
                      "re-assign.interface.group.help.text1",
                    ),
                  },
                  {
                    subtitle: "",
                    content: getCurrentlocaleText(
                      "re-assign.interface.group.help.text2",
                    ),
                  },
                  {
                    subtitle: "",
                    content: getCurrentlocaleText(
                      "re-assign.interface.group.help.text3",
                    ),
                  },
                ],
              }}
            />
          </span>
        ),
        key: "assignto",
        render: (text, record, index) => {
          return (
            <span>
              {record.group ? (
                <Select
                  style={{ width: 150 }}
                  defaultValue={record.group}
                  value={record.group}
                  disabled={
                    !this.state.isEditMode ||
                    !record.is_available ||
                    record.group.toLowerCase() === "wan"
                  }
                  onChange={value => {
                    this.handleInterfaceGroupChange(value, record);
                  }}
                  options={[
                    {
                      value: "wan",
                      label: "WAN",
                      disabled: true,
                    },
                    {
                      value: "tan",
                      label: "TAN",
                    },
                    {
                      value: "free",
                      label: "FLOATING",
                    },
                  ]}
                />
              ) : (
                "-"
              )}
            </span>
          );
        },
      },
    ];

    let container = (
      <Row>
        {isMultinicMode && (
          <Row>
            <Col>
              <div className="my-15">
                {/** Inodes sub tab */}
                <span>
                  <Button
                    className={
                      this.state.showInodes
                        ? "btn-highlight br-8"
                        : "br-8 btn-border"
                    }
                    onClick={() => {
                      this.setState({
                        showInodes: true,
                        showInterfaces: false,
                      });
                    }}
                  >
                    <strong>
                      {getCurrentlocaleText(
                        "clusters.inodes.table.column.text",
                      )}
                    </strong>
                  </Button>
                </span>
                {/** Interfaces sub tab */}
                <span className="ml-15">
                  <Button
                    className={
                      this.state.showInterfaces
                        ? "btn-highlight br-8"
                        : "br-8 btn-border"
                    }
                    onClick={() => {
                      this.setState({
                        showInterfaces: true,
                        showInodes: false,
                      });
                    }}
                  >
                    <strong>
                      {getCurrentlocaleText("inode.interface.label.text")}
                    </strong>
                  </Button>
                </span>
              </div>
            </Col>
          </Row>
        )}
        {/** Show inodes */}
        {this.state.showInodes && (
          <Col xs={24} sm={12} md={18} lg={18} xl={24}>
            <div style={{ minHeight: 250 }}>
              <div
                style={{
                  display: "inline-flex",
                  width: "40%",
                  marginBottom: 15,
                }}
              >
                {cluster_node_list_data.length > 0 && (
                  <span>
                    <a
                      style={{ fontSize: 14, marginLeft: 20 }}
                      onClick={this.expandRow}
                    >
                      <span>
                        <Icons
                          className={"valign-middle"}
                          type="ai"
                          name={
                            this.state.expandedRowKeys.length !==
                            cluster_node_list_data.length
                              ? "AiOutlinePlusSquare"
                              : "AiOutlineMinusSquare"
                          }
                        />
                        <span style={{ marginLeft: 5 }}>
                          {this.state.expandedRowKeys.length !==
                          cluster_node_list_data.length
                            ? getCurrentlocaleText(
                                "node.list.table.expand_all.label.text",
                              )
                            : getCurrentlocaleText(
                                "node.list.table.collapse_all.label.text",
                              )}
                        </span>
                      </span>
                    </a>
                  </span>
                )}
              </div>
              <div
                style={{
                  marginBottom: "10px",
                  display: "inline-flex",
                  justifyContent: "flex-end",
                  width: "60%",
                }}
              >
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <SearchInput
                    placeholder={getCurrentlocaleText(
                      "node.list.table.filter_all.placeholder.text",
                    )}
                    onChange={this.iNodeListFilter}
                    value={this.state.searchText}
                    clearSearch={this.clearSearchFilter}
                  />
                </div>
              </div>
              {this.state.filterInfo || cluster_node_list_data.length > 0 ? (
                <div>
                  <TableLayout
                    columns={this.columns}
                    dataSource={cluster_node_list_data}
                    pagination={false}
                    onChange={this.onTableChange}
                    hideRowSelection={true}
                    expandedRowKeys={this.state.expandedRowKeys}
                    expandedRowRender={record => {
                      return this.getMoreInfo(record.details);
                    }}
                    onExpand={this.onExpand}
                    className={
                      this.props.className
                        ? this.props.className + " iot-table"
                        : "clusterNodeList"
                    }
                    lazyLoad={this.lazyLoad}
                    scroll={{ y: 365 }}
                  />
                </div>
              ) : (
                <div>
                  {!this.clusterModel.loading && (
                    <h3>
                      {getCurrentlocaleText("cluster.form.inode.notfound.text")}
                    </h3>
                  )}
                </div>
              )}
            </div>
          </Col>
        )}
        {/** Interfaces */}
        {this.state.showInterfaces && (
          <Col xs={24} sm={12} md={18} lg={18} xl={24}>
            <LoadingComponent loading={this.state.loading}>
              <div style={{ minHeight: 250 }}>
                {/* Action Bar */}
                {this.props.AuthStore.IsPermitted("NODE:CREATE") && (
                  <div className="action-bar">
                    <div className="action-bar-container">
                      {/* Edit Button */}
                      {!this.state.isEditMode && (
                        <span className="action-bar-item">
                          <span>
                            <Button
                              className="action-btn action-bar-button"
                              title={getCurrentlocaleText("general.edit.text")}
                              onClick={() => {
                                this.setState({
                                  isEditMode: !this.state.isEditMode,
                                });
                              }}
                            >
                              <Icons
                                type="ai"
                                name="AiOutlineEdit"
                                className="valign-middle"
                                style={{ marginRight: 5 }}
                              />
                              <span style={{ margin: 0 }}>
                                {getCurrentlocaleText("general.edit.text")}
                              </span>
                            </Button>
                          </span>
                        </span>
                      )}
                      {/* Cancel Button */}
                      {this.state.isEditMode && (
                        <span className="action-bar-item">
                          <span>
                            <Button
                              className="action-btn action-bar-button"
                              title={getCurrentlocaleText(
                                "general.form.button.cancel.text",
                              )}
                              onClick={() => {
                                if (
                                  this.state.tanInterfaces.length > 0 ||
                                  this.state.freeInterfaces.length > 0
                                ) {
                                  this.fetchClusterNetplan(
                                    this.props.clusterId,
                                  );
                                }
                                this.setState({
                                  isEditMode: false,
                                  tanInterfaces: [],
                                  freeInterfaces: [],
                                });
                              }}
                            >
                              <Icons
                                type="ai"
                                name="AiOutlineClose"
                                className="valign-middle"
                                style={{ marginRight: 5 }}
                              />
                              <span style={{ margin: 0 }}>
                                {getCurrentlocaleText(
                                  "general.form.button.cancel.text",
                                )}
                              </span>
                            </Button>
                          </span>
                        </span>
                      )}
                      {/* Save Button */}
                      {this.state.isEditMode && (
                        <span className="action-bar-item">
                          <span>
                            <Button
                              className="action-btn action-bar-button"
                              title={getCurrentlocaleText("save.button.text")}
                              onClick={() => {
                                if (
                                  this.state.tanInterfaces.length > 0 ||
                                  this.state.freeInterfaces.length > 0
                                ) {
                                  this.setState({ showModal: true });
                                } else {
                                  this.setState({
                                    loading: false,
                                    isEditMode: false,
                                  });
                                }
                              }}
                            >
                              <Icons
                                type="ai"
                                name="AiOutlineSave"
                                className="valign-middle"
                                style={{ marginRight: 5 }}
                              />
                              <span style={{ margin: 0 }}>
                                {getCurrentlocaleText("save.button.text")}
                              </span>
                            </Button>
                          </span>
                        </span>
                      )}
                    </div>
                  </div>
                )}
                {this.state.netplan.length > 0 ? (
                  <div>
                    <TableLayout
                      columns={this.interfaces_columns}
                      dataSource={this.state.netplan}
                      size={"middle"}
                      pagination={false}
                      indentSize={5}
                      hideExpandedRow={true}
                      hideRowSelection={true}
                    />
                  </div>
                ) : (
                  <div>
                    {!this.clusterModel.loading && (
                      <h3>
                        {getCurrentlocaleText(
                          "inode.interface.nocontent.label.text",
                        )}
                      </h3>
                    )}
                  </div>
                )}
              </div>
              <div>
                {this.state.showModal && (
                  <InterfaceGroupReAssignModal
                    visible={this.state.showModal}
                    closeModal={this.closeModal}
                    handleConfirm={this.handleConfirm}
                    interfaceData={{
                      tan: this.state.tanInterfaces,
                      free: this.state.freeInterfaces,
                    }}
                    isCluster={true}
                  />
                )}
              </div>
            </LoadingComponent>
          </Col>
        )}
      </Row>
    );
    return container;
  }
}

ClusteriNodesList.propTypes = {};

export default ClusteriNodesList;
