import React, { Component } from "react";
import { Row, Col, Button, Modal, Tag, Select, Checkbox, Input } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { Form } from "@ant-design/compatible";
import InputBox from "components/UI-Components/InputBox";
import { observer, inject } from "mobx-react";
import propTypes from "prop-types";
import FormErrorMessage from "components/UI-Components/FormErrorMessage";
import SecretController from "controller/SecretController";
/*------------------N/W CIDR ----------------------*/
import {
  getCurrentlocaleText,
  searchArrayEleWithRegex,
  focusFirstInput,
  validNameRegex,
} from "Core/Utils";
import { setTimeout } from "timers";
import Icons from "../../UI-Components/Icons";
import { LoadingOutlined } from "@ant-design/icons";

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;
let uuid = 0;
const helperObjects = {
  volumenameHelpObj: {
    help: {
      title: getCurrentlocaleText("volume.name.label"),
      data: [
        {
          subtitle: "",
          content: getCurrentlocaleText("volume.name.help.text"),
        },
      ],
    },
  },
  uploaderHelpObj: {
    help: {
      title: getCurrentlocaleText("upload.file"),
      data: [
        {
          subtitle: " ",
          content: " ",
        },
      ],
    },
  },
  filenameHelpObj: {
    help: {
      title: getCurrentlocaleText("file.name"),
      data: [
        {
          subtitle: "",
          content: getCurrentlocaleText("volume.file.name.help.text"),
        },
      ],
    },
  },
  filecontentHelpObj: {
    help: {
      title: getCurrentlocaleText("file.content"),
      data: [
        {
          subtitle: "",
          content: getCurrentlocaleText("volume.file.content.help.text1"),
        },
        {
          subtitle: "",
          content: getCurrentlocaleText("volume.file.content.help.text2"),
        },
      ],
    },
  },
};

@inject("ModelStore", "UiStore", "AuthStore", "SecretViewModel")
@observer
class ModalForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showFormModal: false,
      errorMsg: null,

      validateError: {
        volumeName: {},
        fileName: {},
        fileContentTextBox: {},
        uploader: {},
      },
      validateErrorText: {
        volumeName: {},
        fileName: {},
        fileContentTextBox: {},
        uploader: {},
      },
      fileName: {},
      initialDomValue: {},
    };

    this.UiStore = this.props.UiStore;
  }

  componentDidMount() {
    this.props.UiStore.SetStoreData("helpData", {});
    /* Focus First Input */
    focusFirstInput();
  }

  showModal = () => {
    this.setState({ showFormModal: true, errorMsg: null });
  };

  componentDidUpdate(prevProps) {
    if (this.props.actionType !== prevProps.actionType) {
      this.UiStore.volumes.volumeName = "";
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    /* required to stop rendering of modal on window resize and to close modal on successful submit*/
    nextProps.afterClose && this.resetState();
  }

  closeModal = e => {
    // close Modal and reset states
    this.resetState();
    this.props.form.resetFields();
    this.UiStore.resetVolumes();
    this.UiStore.SetStoreData("serviceFormData", {
      uploadedLicenseFileName: [],
    });
    /* reset keys */
    uuid = 0;
    this.props.UiStore.SetStoreData("helpData", {});
    this.props.closeModal ? this.props.closeModal(e) : null;
  };

  resetState = () => {
    this.setState({
      showFormModal: false,
      errorMsg: null,
      validateError: {
        volumeName: {},
        fileName: {},
        fileContentTextBox: {},
        uploader: {},
      },
      validateErrorText: {
        volumeName: {},
        fileName: {},
        fileContentTextBox: {},
        uploader: {},
      },
      fileName: {},
      initialDomValue: {},
    });
    /* reset keys */
    uuid = 0;
  };

  validateSubmit = e => {
    /*Validate form values before service creation*/
    let allKeys = this.props.form.getFieldsValue().keys;

    if (allKeys.length > 0) {
      /* remove keys */
      let allEle = Object.keys(this.props.form.getFieldsValue());
      allEle.splice(allEle.indexOf("keys"), 1);
      let obj = {};

      allKeys.length > 0 &&
        allEle.map((key, index) => {
          let shldValidate = false;
          let x = this.props.form.getFieldsValue()[key];

          /*
          antd in-built prop - data-__meta.
          Check if ele is 'required' before validating.
          Take 1 instance and check for 'required' attr in that element
        */
          let eleInst;

          if (key.toLowerCase() === "volumename") {
            eleInst = this.props.form.getFieldInstance(`volumeName[0]`);
          } else {
            eleInst = this.props.form.getFieldInstance(`${key}[${allKeys[0]}]`);
          }

          let eleRules =
            eleInst &&
            eleInst.props &&
            eleInst.props["data-__meta"] &&
            eleInst.props["data-__meta"]["rules"]
              ? eleInst.props["data-__meta"]["rules"]
              : [];

          if (eleRules.length > 0) {
            eleRules.forEach(rule => {
              shldValidate = rule.required && true;
            });
          }

          if (shldValidate) {
            /* if required , the only validate */

            if (key.toLocaleLowerCase() === "volumename") {
              /* volume name is always 1.
                check if no name or fails validation, throw error
                volume element id = ' volumeName[0]'
              */
              if (
                x[0] === undefined ||
                x[0].length <= 0 ||
                !this.validatevolumeName(x[0], key, 0)
              ) {
                obj[key] = {
                  ...obj[key],
                };
                obj[key][0] = "error";
                obj = {
                  ...this.state.validateError,
                  ...obj,
                };
                this.setState({
                  validateError: obj,
                });
                /* update error text */
                let helptext = getCurrentlocaleText("error.volume.volumename1");
                this.updateErrorState(key, 0, helptext);
              } else {
                /*  update error status */
                obj[key] = {
                  ...obj[key],
                };
                obj[key][0] = "success";
                obj = {
                  ...this.state.validateError,
                  ...obj,
                };
                this.setState({
                  validateError: obj,
                });

                /* update error text */
                let helptext = null;
                this.updateErrorState(key, 0, helptext);
              }
            } else {
              allKeys.forEach(val => {
                /* if ele is 'fileName' , do validation */

                let isValidFileName =
                  key.toLocaleLowerCase() === "filename"
                    ? this.validatefileName(x[val], key, val)
                    : false;
                if (
                  x[val] === undefined ||
                  x[val].length <= 0 ||
                  isValidFileName
                ) {
                  obj[key] = {
                    ...obj[key],
                  };
                  obj[key][val] = "error";

                  /* Also check showFileChkBox to show file content on error */
                  if (key.toLowerCase() === "filecontenttextbox") {
                    let ckBox = document.getElementsByClassName(
                      `showFileChkBox[${val}]`,
                    )[0];

                    let classes = ckBox.querySelector(".ant-checkbox")
                      .classList;
                    /* when it is not checked , check the show file content */
                    !classes.contains("ant-checkbox-checked") &&
                      ckBox.querySelector(".ant-checkbox").click();
                  }

                  obj = {
                    ...this.state.validateError,
                    ...obj,
                  };
                  this.setState({
                    validateError: obj,
                  });

                  /* update error text */
                  let helptext =
                    key.toLowerCase() === "filename"
                      ? getCurrentlocaleText("error.volume.filename.1")
                      : key.toLowerCase() === "filecontenttextbox"
                      ? getCurrentlocaleText("error.volume.filecontent.1")
                      : null;

                  this.updateErrorState(key, val, helptext);
                } else {
                  obj[key] = {
                    ...obj[key],
                  };
                  obj[key][val] = "success";
                  obj = {
                    ...this.state.validateError,
                    ...obj,
                  };
                  this.setState({
                    validateError: obj,
                  });

                  /* update error text */
                  this.updateErrorState(key, val, null);
                }
              });
            }
          }
        });

      /* submit on no error */
      this.validateBeforeSubmit();
    } else if (this.props.actionType === "edit") {
      /* submit on no error and no new files added */

      this.props.form.validateFields((err, values) => {
        !err && this.validateBeforeSubmit();
      });
    }
  };

  validateBeforeSubmit = () => {
    /* submit on no error */
    let fieldKeys = Object.keys(this.props.form.getFieldsValue()),
      keyCount = this.props.form.getFieldsValue()["keys"].length,
      validVol,
      validFilename,
      validFileContent;

    fieldKeys.forEach((key, index) => {
      if (key.toLocaleLowerCase() === "volumename") {
        validVol = false;
        if (
          this.props.form.getFieldsValue()[key].length > 0 &&
          this.props.form.getFieldsValue()[key][0].length > 0
        ) {
          validVol = this.validatevolumeName(
            this.props.form.getFieldsValue()[key][0],
            key,
            0,
          );
        }
      } else if (key.toLocaleLowerCase() === "filename") {
        if (this.props.form.getFieldsValue()[key].length > 0) {
          let formFieldVals = this.props.form.getFieldsValue()[key];
          let removeFalsy = formFieldVals.filter(n => n);
          validFilename = true;
          //if (removeFalsy.length === keyCount) {
          if (removeFalsy.length > 0) {
            /* if validation fails , make it false */
            removeFalsy.forEach(val => {
              let regex = validNameRegex();
              if (!val || val.length > 60 || !regex.test(val)) {
                validFilename = false;
                let eleNos = formFieldVals.indexOf(val);
                let helptext = getCurrentlocaleText("error.volume.filename.1");

                let obj = {},
                  errState = {};
                obj[key] = {};
                errState[key] = {};
                obj[key][eleNos] = helptext;
                errState[key][eleNos] = "error";
                this.setState(() => {
                  /* update error text and error state */
                  let rtnObj = {};
                  let dom = this.state.validateErrorText;
                  let status = this.state.validateError;

                  dom[key] = {
                    ...dom[key],
                    ...obj[key],
                  };

                  status[key] = {
                    ...status[key],
                    ...errState[key],
                  };

                  rtnObj["validateErrorText"] = dom;
                  rtnObj["validateError"] = status;
                  return rtnObj;
                });
              }
            });
          } else {
            validFilename = false;
          }
        }
      } else if (key.toLocaleLowerCase() === "filecontenttextbox") {
        if (this.props.form.getFieldsValue()[key].length > 0) {
          let formFieldVals = this.props.form.getFieldsValue()[key];
          let removeFalsy = formFieldVals.filter(n => n);

          validFileContent = true;
          if (removeFalsy.length === keyCount) {
            /* if validation fails , make it false */
            removeFalsy.forEach(val => {
              if (this.validatefileContentTextBox(val) === false) {
                validFileContent = false;
              }
            });
          } else {
            validFileContent = false;
          }
        }
      }
    });

    if (this.props.actionType === "create") {
      if (validVol && validFilename && validFileContent) {
        /* when everything is success , proceed with form submit */
        this.onSubmit();
      }
    } else if (this.props.actionType === "edit") {
      /*
          on edit , if previous store - UiStore.serviceFormData.uploadedLicenseFileName is present,
          include values to the volumes.data obj and send PUT call
        */
      if (Object.keys(this.UiStore.volumes.data).length > 0) {
        if (
          (validVol && validFilename && validFileContent) ||
          (validVol &&
            validFilename === undefined &&
            validFileContent === undefined)
        ) {
          /* new files added. append to  uploadedLicenseFileName list*/
          if (this.UiStore.serviceFormData.uploadedLicenseFileName.length > 0) {
            let previous_data = {};
            this.UiStore.serviceFormData.uploadedLicenseFileName.forEach(
              val => {
                previous_data[
                  val
                ] = this.UiStore.serviceFormData.rowDetails.selectedLicenseDetails.data[
                  val
                ];
              },
            );
            /* append previous data */
            this.UiStore.volumes.data = {
              ...this.UiStore.volumes.data,
              ...previous_data,
            };
          }
          this.onSubmit();
        }
      } else if (
        this.UiStore.serviceFormData.uploadedLicenseFileName.length > 0
      ) {
        let previous_data = {};
        this.UiStore.serviceFormData.uploadedLicenseFileName.forEach(val => {
          previous_data[
            val
          ] = this.UiStore.serviceFormData.rowDetails.selectedLicenseDetails.data[
            val
          ];
        });

        setTimeout(() => {
          this.props.form.validateFields((err, values) => {
            /* confirm if volume name is valid */
            let isvalidVolname = true;
            if (this.props.form.getFieldsValue()["volumeName"]) {
              isvalidVolname = this.validatevolumeName(
                this.props.form.getFieldsValue()["volumeName"][0],
                "volumeName",
                0,
              );
            } else if (this.props.UiStore.volumes.volumeName) {
              isvalidVolname = this.validatevolumeName(
                this.props.UiStore.volumes.volumeName,
                "volumeName",
                0,
              );
            }
            if (!err && isvalidVolname) {
              /* assign updated values to volumes.data */
              this.UiStore.volumes.data = {
                ...previous_data,
              };

              this.onSubmit();
            }
          });
        }, 300);
      } else if (
        this.UiStore.serviceFormData.uploadedLicenseFileName.length === 0
      ) {
        /* throw error when all data is removed */
        this.props.UiStore.errorMessage = getCurrentlocaleText(
          "volume.atleast.1.file.required.error",
        );
      }
    }
  };

  onSubmit = () => {
    /* remove placeholder = '__position_{NOS}' from volumes.data  */
    let allData = Object.keys(this.UiStore.volumes.data);
    if (allData.length > 0) {
      allData.forEach(val => {
        let split = val.split("__position_");
        if (split.length >= 2) {
          /* split[0] = key name , split[1] = pisition */
          this.UiStore.volumes.data[split[0]] = this.UiStore.volumes.data[val];
          delete this.UiStore.volumes.data[val];
        }
      });
    }
    if (this.props.actionType === "edit") {
      this.props.onSubmit();
    } else {
      SecretController.isSecretAvailable(
        this.props.UiStore.volumes.volumeName,
        this.props.params.org_id,
      ).then(isNameAvailable => {
        if (!isNameAvailable) {
          this.props.onSubmit();
          this.resetState();
          this.props.form.resetFields();
        } else {
          let error_msg = getCurrentlocaleText(
            "secret.name.error.message.text",
          );
          this.props.UiStore.setErrorMessage(error_msg);
          this.props.UiStore.setFormErrorMessage(error_msg);
        }
      });
    }
  };

  remove = k => {
    /* Remove error states for selected elements */
    let errKeys = Object.keys(this.state.validateError),
      errEle = this.state.validateError;

    errKeys.forEach(ele => {
      if (ele.toLocaleLowerCase() !== "volumename") {
        errEle[ele][k] = null;
      }
    });

    this.setState({
      validateError: errEle,
    });

    const { form } = this.props;
    // can use data-binding to get
    const keys = form.getFieldValue("keys");

    let all = keys.filter(key => key !== k);
    // can use data-binding to set
    form.setFieldsValue({
      keys: all,
    });

    let savedKey = this.findVolumeDataKey(k);
    if (savedKey.length > 0) {
      /* remove data from volume store */
      delete this.UiStore.volumes.data[savedKey[0]];
    }
  };

  add = () => {
    const { form } = this.props;
    const keys = form.getFieldValue("keys");
    const nextKeys = keys.concat(uuid);
    uuid++;
    form.setFieldsValue({
      keys: nextKeys,
    });
  };

  /* validation func for input elements */

  updateErrorState = (ele, eleNos, helptext = null) => {
    let obj = {};
    obj[ele] = {};
    obj[ele][eleNos] = helptext;

    /* update error state */
    this.setState(() => {
      let dom = this.state.validateErrorText;

      dom[ele] = {
        ...dom[ele],
        ...obj[ele],
      };
      return dom;
    });
  };

  validatefileName = (value, ele, eleNos, event = null) => {
    if (value) {
      if (!this.validatevolumeName(value, ele, eleNos)) {
        /* on error , update error text */
        let helptext = getCurrentlocaleText("error.volume.filename.1");
        this.updateErrorState(ele, eleNos, helptext);
        return false;
      } else if (Object.keys(this.UiStore.volumes.fileName).length > 0) {
        /* check if input is unique file name */
        let allfileName = Object.values(this.UiStore.volumes.fileName);
        if (allfileName.length > 0) {
          let isFileNameAvailable = allfileName.indexOf(value),
            totalVal = allfileName.filter(val => val === value);
          if (isFileNameAvailable >= 0) {
            let helptext = getCurrentlocaleText("error.volume.filename.2");
            this.updateErrorState(ele, eleNos, helptext);

            return false;
          } else if (this.props.actionType === "edit") {
            /* on edit , compare if filename is unique with uploaded files */
            if (
              this.props.UiStore.serviceFormData.uploadedLicenseFileName
                .length > 0
            ) {
              let z = this.props.UiStore.serviceFormData.uploadedLicenseFileName.indexOf(
                value,
              );
              if (z >= 0) {
                /* name copy of previous uploaded file. Throw error */
                let helptext = getCurrentlocaleText("error.volume.filename.2");
                this.updateErrorState(ele, eleNos, helptext);
              } else {
                /* on success , remove error */
                let helptext = null;
                this.updateErrorState(ele, eleNos, helptext);

                return this.validatevolumeName(value, ele, eleNos);
              }
            }
          } else {
            /* on success , remove error */

            let helptext = null;
            this.updateErrorState(ele, eleNos, helptext);

            return this.validatevolumeName(value, ele, eleNos);
          }
        }
      } else {
        /* on success , remove error */
        let helptext = null;
        this.updateErrorState(ele, eleNos, helptext);

        return this.validatevolumeName(value, ele, eleNos);
      }
    } else {
      let helptext = getCurrentlocaleText("error.volume.filename.1");
      this.updateErrorState(ele, eleNos, helptext);
      return false;
    }
  };

  validatevolumeName = (value, ele, eleNos) => {
    /*
      volume name length should be <=60 and pass below regex
    */

    let regex = validNameRegex();
    if (value && value.length <= 60 && regex.test(value)) {
      let helptext = null;
      this.updateErrorState(ele, eleNos, helptext);
      return true;
    } else if (!value) {
      /* on error , update error text for volumename */
      let helptext = getCurrentlocaleText("error.volume.volumename1");
      this.updateErrorState(ele, eleNos, helptext);
      return false;
    } else if (ele.toLowerCase() === "volumename") {
      /* on error , update error text for volumename */
      let helptext = getCurrentlocaleText("error.volume.volumename");
      this.updateErrorState(ele, eleNos, helptext);
      return false;
    } else {
      /*  fallback  */
      return false;
    }
  };

  validatefileContentTextBox = (value, ele, eleNos) => {
    /* file content cannot be empty */
    if (value && value.length > 0) {
      let helptext = null;
      this.updateErrorState(ele, eleNos, helptext);
      return true;
    } else {
      /* on error , update error text for volumename */
      let helptext = getCurrentlocaleText("error.volume.filecontent");
      this.updateErrorState(ele, eleNos, helptext);
      return false;
    }
  };

  inputChanged = e => {
    e.persist();
    /* validate the input */
    let dom = this.state.validateError,
      split = e.target.id.split("[");

    let ele = split[0],
      eleNos = e.target.id.slice(
        e.target.id.indexOf("[") + 1,
        e.target.id.indexOf("]"),
      );

    /* call validation func */
    let func = `validate${split[0]}`,
      validation = this[func](e.target.value, ele, eleNos, e);

    let val = validation ? "success" : "error";

    let obj = {};
    obj[ele] = {};
    obj["uploader"] = {};
    obj[ele][eleNos] = val;
    /* update error state */
    this.setState(
      () => {
        let dom = this.state.validateError;

        dom[ele] = {
          ...dom[ele],
          ...obj[ele],
        };
        return dom;
      },
      () => {
        /* remove error from uploader , if filename and filecontent are valid  */
        if (
          this.state.validateError["fileName"][`${eleNos}`] === "success" &&
          this.state.validateError["fileContentTextBox"][`${eleNos}`] ===
            "success"
        ) {
          if (
            this.state.validateError["uploader"][eleNos] &&
            this.state.validateError["uploader"][eleNos] === "error"
          ) {
            obj["uploader"][eleNos] = "success";
            this.props.form.resetFields([`uploader[${eleNos}]`]);

            this.setState(() => {
              let dom = this.state.validateError;

              dom["uploader"] = {
                ...dom["uploader"],
                ...obj["uploader"],
              };
              return dom;
            });
          }
        }
      },
    );

    /* Assign the value to the store */
    if (split[0].toLocaleLowerCase() === "volumename") {
      this.UiStore.volumes.volumeName = e.target.value;
    } else if (ele.toLowerCase() === "filename") {
      this.UiStore.volumes.fileName[`${ele}[${eleNos}]`] = e.target.value;

      /* also update the key_name in store.volumes.data */

      if (Object.keys(this.UiStore.volumes.data).length > 0) {
        /* some data is present. check with eleNos to replace the data content */

        let savedKey = this.findVolumeDataKey(eleNos);
        if (savedKey.length > 0) {
          this.UiStore.volumes.data[
            `${e.target.value}__position_${eleNos}`
          ] = this.UiStore.volumes.data[savedKey[0]];
          delete this.UiStore.volumes.data[savedKey[0]];
        } else {
          this.UiStore.volumes.data[`${e.target.value}__position_${eleNos}`] =
            "";
        }
      } else {
        this.UiStore.volumes.data[`${e.target.value}__position_${eleNos}`] = "";
      }
    } else if (ele.toLowerCase() === "filecontenttextbox") {
      this.UiStore.volumes.fileContentTextBox[`${ele}[${eleNos}]`] =
        e.target.value;

      /* save data in store.volumes.data */
      let shouldConvert = this.validateBase64Conversion(e.target.value);

      if (Object.keys(this.UiStore.volumes.data).length > 0) {
        /* some data is present. check with eleNos to replace the data content */

        let savedKey = this.findVolumeDataKey(eleNos);
        if (savedKey.length > 0) {
          try {
            this.UiStore.volumes.data[savedKey[0]] = shouldConvert
              ? btoa(e.target.value)
              : e.target.value;
          } catch (e) {
            this.UiStore.volumes.data[savedKey[0]] = shouldConvert
              ? btoa(unescape(encodeURIComponent(e.target.value)))
              : e.target.value;
          }
        } else {
          try {
            this.UiStore.volumes.data[
              `${eleNos}__position_${eleNos}`
            ] = shouldConvert ? btoa(e.target.value) : e.target.value;
          } catch (e) {
            this.UiStore.volumes.data[
              `${eleNos}__position_${eleNos}`
            ] = shouldConvert
              ? btoa(unescape(encodeURIComponent(e.target.value)))
              : e.target.value;
          }
        }
      } else {
        /* when filename is not given. defaults to volume.data[${eleNos}]*/

        try {
          this.UiStore.volumes.data[
            `${eleNos}__position_${eleNos}`
          ] = shouldConvert ? btoa(e.target.value) : e.target.value;
        } catch (e) {
          this.UiStore.volumes.data[
            `${eleNos}__position_${eleNos}`
          ] = shouldConvert
            ? btoa(unescape(encodeURIComponent(e.target.value)))
            : e.target.value;
        }
      }
    }
  };

  findVolumeDataKey = eleNos => {
    if (Object.keys(this.UiStore.volumes.data).length > 0) {
      /* some data is present. check with eleNos to replace the data content */
      let dataKeys = Object.keys(this.UiStore.volumes.data);

      let dKey = dataKeys.filter(key => {
        let split = key.split("__position_");
        return parseInt(split[1]) === parseInt(eleNos);
      });
      if (dKey.length > 0) {
        let str = `.*__position_${eleNos}.*`;
        let regex = new RegExp(str);
        let savedKey = searchArrayEleWithRegex(regex, dKey);
        return savedKey;
      } else return [];
    } else return [];
  };

  fileUpload = event => {
    let input = event.target;
    for (let i = 0; i < input.files.length; i++) {
      // Validate file size
      let isFileSizeError = false,
        fileSizeErrorText;
      if (input.files[i].size !== undefined && input.files[i].size !== null) {
        // File size is zero
        if (input.files[i].size === 0) {
          isFileSizeError = true;
          fileSizeErrorText = getCurrentlocaleText(
            "volume.zero_file_size_error_message.text",
          );
        } else if (input.files[i].size > 1048576) {
          // File size is more than 1 MB
          isFileSizeError = true;
          fileSizeErrorText = getCurrentlocaleText(
            "volume.file_size_error_message.text",
          );
        }
      }
      if (isFileSizeError) {
        let split = input.id.split("[");
        let ele = split[0],
          eleNos = input.id.slice(
            input.id.indexOf("[") + 1,
            input.id.indexOf("]"),
          );
        /* update error state and text of uploader*/
        let obj = {};
        obj[ele] = {};
        obj[ele][eleNos] = "error";

        this.setState(() => {
          let dom = this.state.validateError;

          dom[ele] = {
            ...dom[ele],
            ...obj[ele],
          };

          return dom;
        });

        let helptext = fileSizeErrorText;
        this.updateErrorState(ele, eleNos, helptext);
        this.updateFileNameAndContent(input.files[i].name, "", eleNos);
        break;
      }

      let reader = new FileReader();
      reader.onloadend = (file => {
        return evt => {
          let validateUploadedFileName = false;
          let split = input.id.split("[");
          let ele = split[0],
            eleNos = input.id.slice(
              input.id.indexOf("[") + 1,
              input.id.indexOf("]"),
            );
          validateUploadedFileName = true;
          /* Proceed only when file name is valid*/
          if (validateUploadedFileName) {
            /* update error state and text of uploader*/
            let obj = {};
            obj[ele] = {};
            obj[ele][eleNos] = "success";
            this.setState(() => {
              let dom = this.state.validateError;

              dom[ele] = {
                ...dom[ele],
                ...obj[ele],
              };
              return dom;
            });
            let helptext = null;
            this.updateErrorState(ele, eleNos, helptext);
            this.updateFileNameAndContent(file.name, evt.target.result, eleNos);
          } else {
            /* file name is invalid */

            let helptext = "invalid file name";
            this.updateErrorState(ele, eleNos, helptext);

            /* update error state of uploader*/
            let obj = {};
            obj[ele] = {};
            obj[ele][eleNos] = "error";

            this.setState(() => {
              let dom = this.state.validateError;

              dom[ele] = {
                ...dom[ele],
                ...obj[ele],
              };

              return dom;
            });
          }
        };
      })(input.files[i]);
      reader.readAsBinaryString(input.files[i]);
    }
  };

  updateFileNameAndContent = (fileName = "", content = "", eleNos) => {
    let newFileName, helptext;
    let allfileName = Object.values(this.UiStore.volumes.fileName);
    if (
      this.props.actionType === "edit" &&
      this.UiStore.serviceFormData.uploadedLicenseFileName.length > 0
    ) {
      /* on edit , also check for names in previously uploaded files */
      allfileName = [
        ...new Set([
          ...allfileName,
          ...this.UiStore.serviceFormData.uploadedLicenseFileName,
        ]),
      ];
    }
    if (allfileName.length > 0) {
      let isFileNameAvailable = allfileName.indexOf(fileName);
      if (isFileNameAvailable >= 0) {
        /*file name exists. Find the last integer value*/
        let filecount = allfileName.map(val => {
          let nametosearch = val.split("__");
          nametosearch.pop();
          return (
            nametosearch.toString() === fileName &&
            val.indexOf("__") >= 0 &&
            parseInt(val.split("__")[val.split("__").length - 1])
          );
        });
        /*To remove falsy from the array*/
        filecount = filecount.filter(n => n);

        filecount.sort()[filecount.length - 1]
          ? (newFileName = `${fileName}__${filecount.sort()[
              filecount.length - 1
            ] + 1}`)
          : (newFileName = `${fileName}__1`);

        /* Assing vol data to store */

        this.updateVolumeDataFromUploader(newFileName, eleNos, content);
      } else {
        /* update file content in base64 */
        newFileName = fileName.trim();
        this.updateVolumeDataFromUploader(newFileName, eleNos, content);
      }
    } else {
      /* new file upload. No filename is present */
      /* update store.volumes.data */
      newFileName = fileName.trim();
      this.updateVolumeDataFromUploader(newFileName, eleNos, content);
    }
    /* assign initial value to filename and textbox ele */
    /* update file name and remove error*/
    this.UiStore.volumes.fileName[`fileName[${eleNos}]`] = newFileName;
    document.getElementById(`fileName[${eleNos}]`).value = newFileName;
    /* assign the new file name to the form element */
    let a = {};
    a[`fileName[${eleNos}]`] = newFileName;
    this.props.form.setFieldsValue({ ...a });
    /* remove error from DOM and update status*/
    helptext = null;
    this.updateErrorState("fileName", eleNos, helptext);
    let fileNameobj = { fileName: {} };
    fileNameobj["fileName"][eleNos] = "success";
    /* update error state */
    this.setState(() => {
      let dom = this.state.validateError;
      dom["fileName"] = {
        ...dom["fileName"],
        ...fileNameobj["fileName"],
      };
      return dom;
    });

    /* update filecontent text box and remove error */
    let currfilecontent = {};
    currfilecontent[`fileContentTextBox[${eleNos}]`] = content;
    this.UiStore.volumes.fileContentTextBox = {
      ...this.UiStore.volumes.fileContentTextBox,
      ...currfilecontent,
    };
    /* remove error from DOM */
    let textBoxObj;
    if (content && content.length > 0) {
      let helptext = null;
      this.updateErrorState("fileContentTextBox", eleNos, helptext);
      textBoxObj = { fileContentTextBox: {} };
      textBoxObj["fileContentTextBox"][eleNos] = "success";
    } else {
      let helptext = getCurrentlocaleText("error.volume.filecontent");
      this.updateErrorState("fileContentTextBox", eleNos, helptext);
      textBoxObj = { fileContentTextBox: {} };
      textBoxObj["fileContentTextBox"][eleNos] = "error";
      this.props.form.resetFields([`fileContentTextBox[${eleNos}]`]);
    }
    /* update error state */
    this.setState(() => {
      let dom = this.state.validateError;

      dom["fileContentTextBox"] = {
        ...dom["fileContentTextBox"],
        ...textBoxObj["fileContentTextBox"],
      };
      return dom;
    });
  };

  validateBase64Conversion = input => {
    let val,
      base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/,
      isBase64 = base64regex.test(input);

    if (isBase64) {
      /* if base64 do not encode */
      return false;
    } else {
      /* if not of base64 do encode */
      return true;
    }
  };

  updateVolumeDataFromUploader = (newFileName, eleNos, result) => {
    if (Object.keys(this.UiStore.volumes.data).length > 0) {
      /* some data is present. check with eleNos to replace the data content */
      let savedKey = this.findVolumeDataKey(eleNos);

      /* update file content in base64 */
      let shouldConvert = this.validateBase64Conversion(result);
      try {
        this.UiStore.volumes.data[
          `${newFileName}__position_${eleNos}`
        ] = shouldConvert ? btoa(result) : result;
      } catch (e) {
        this.UiStore.volumes.data[
          `${newFileName}__position_${eleNos}`
        ] = shouldConvert ? btoa(unescape(encodeURIComponent(result))) : result;
      }

      if (savedKey.length > 0) {
        delete this.UiStore.volumes.data[savedKey[0]];
      }
    } else {
      /* update file content in base64 */
      let shouldConvert = this.validateBase64Conversion(result);
      try {
        this.UiStore.volumes.data[
          `${newFileName}__position_${eleNos}`
        ] = shouldConvert ? btoa(result) : result;
      } catch (e) {
        this.UiStore.volumes.data[
          `${newFileName}__position_${eleNos}`
        ] = shouldConvert ? btoa(unescape(encodeURIComponent(result))) : result;
      }

      this.UiStore.volumes.uploadedFileName.push(newFileName);
    }
  };

  showfileContents = e => {
    let selectedChild = e.target.id.slice(
      e.target.id.indexOf("[") + 1,
      e.target.id.indexOf("]"),
    );
    if (e.target.checked) {
      document.getElementById(
        `fileContentContainer[${selectedChild}]`,
      ).style.display = "inline-block";
    } else {
      document.getElementById(
        `fileContentContainer[${selectedChild}]`,
      ).style.display = "none";
    }
  };
  updateHelp = (data, e) => {
    let isUploader = e.target.id.match("uploader");
    this.props.UiStore.SetStoreData("helpData", data);
    if (isUploader) {
      setTimeout(() => {
        let x = document.getElementsByClassName("help-content");
        if (x && x[0]) {
          x[0].innerHTML =
            this.UiStore.volumes.assignedServiceCount > 0
              ? getCurrentlocaleText("volume.text.area.help.text") +
                "</br>" +
                getCurrentlocaleText("volume.note.text")
              : getCurrentlocaleText("volume.text.area.help.text");
        }
      }, 300);
    } else {
      setTimeout(() => {
        let elm = document.querySelector(".help-content");
        if (elm) {
          elm.innerHTML =
            this.UiStore.volumes.assignedServiceCount > 0 &&
            data &&
            data.data &&
            data.data[0] &&
            data.data[0].content
              ? data.data[0].content +
                "</br>" +
                getCurrentlocaleText("volume.note.text")
              : data.data[0].content;
        }
      }, 10);
    }
  };

  renderFormItems = () => {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    getFieldDecorator("keys", { initialValue: [] });
    const keys = getFieldValue("keys");

    let formItems = keys.map((k, index) => {
      setTimeout(() => {
        /* update initial value. */
        let fileEle = document.getElementById(`fileName[${k}]`);
        if (fileEle) {
          fileEle.value = this.UiStore.volumes.fileName[`fileName[${k}]`]
            ? this.UiStore.volumes.fileName[`fileName[${k}]`]
            : "";
        }
        let textBoxEle = document.getElementById(`fileContentTextBox[${k}]`);
        if (textBoxEle) {
          textBoxEle.value = this.UiStore.volumes.fileContentTextBox[
            `fileContentTextBox[${k}]`
          ]
            ? this.UiStore.volumes.fileContentTextBox[
                `fileContentTextBox[${k}]`
              ]
            : "";
        }
        let fileTextBox = document.getElementById(`fileContentTextBox[${k}]`);
        if (fileTextBox) {
          document.getElementById(`fileContentTextBox[${k}]`).style.overflowY =
            "scroll";
        }
      }, 100);

      return (
        <div key={k}>
          <Row
            style={{
              border: "2px dashed #f3f3f3",
              padding: "10px",
              marginTop: "2px",
              flexDirection: "column",
            }}
          >
            <Col>
              {
                //keys.length > 1 &&
                <Button
                  icon={<DeleteOutlined />}
                  title={`Delete File`}
                  style={{
                    float: "right",
                    cursor: "pointer",
                    zIndex: 1,
                  }}
                  id={`delete_dynamic_volume_content[${k}]`}
                  onClick={() => this.remove(k)}
                />
              }
            </Col>
            <Row>
              <Col span={10}>
                <FormItem
                  className="dynamicFormCtrls"
                  label={getCurrentlocaleText("upload.file")}
                  validateStatus={this.state.validateError["uploader"][k]}
                  help={
                    this.state.validateError["uploader"][k] === "error" &&
                    this.state.validateErrorText["uploader"][k]
                  }
                >
                  {getFieldDecorator(`uploader[${k}]`, {
                    rules: [
                      {
                        required: false,
                      },
                    ],
                  })(
                    <InputBox
                      style={{ padding: "3px" }}
                      type="file"
                      multiple={false}
                      onChange={this.fileUpload}
                      onFocus={this.updateHelp.bind(
                        this,
                        helperObjects.uploaderHelpObj.help,
                      )}
                    />,
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col span={10}>
                <FormItem
                  label={getCurrentlocaleText("file.name")}
                  hasFeedback
                  className="dynamicFormCtrls"
                  validateStatus={this.state.validateError["fileName"][k]}
                  help={
                    this.state.validateError["fileName"][k] === "error" &&
                    this.state.validateErrorText["fileName"][k]
                  }
                >
                  {getFieldDecorator(`fileName[${k}]`, {
                    rules: [
                      {
                        required: true,
                      },
                    ],
                    initialValue: this.UiStore.volumes.fileName[
                      `fileName[${k}]`
                    ]
                      ? this.UiStore.volumes.fileName[`fileName[${k}]`]
                      : "",
                  })(
                    <InputBox
                      type="input"
                      autoComplete="off"
                      placeholder={getCurrentlocaleText("file.name")}
                      onChange={this.inputChanged}
                      onFocus={this.updateHelp.bind(
                        this,
                        helperObjects.filenameHelpObj.help,
                      )}
                    />,
                  )}
                </FormItem>
              </Col>
              <Col span={8} push={1} style={{ top: "32px" }}>
                <FormItem className={`dynamicFormCtrls`}>
                  {getFieldDecorator(`showFileChkBox[${k}]`, {
                    rules: [
                      {
                        required: false,
                      },
                    ],
                  })(
                    <Checkbox
                      onChange={this.showfileContents}
                      className={`showFileChkBox[${k}]`}
                    >
                      {getCurrentlocaleText("show.file.content")}
                    </Checkbox>,
                  )}
                </FormItem>
              </Col>
            </Row>

            <Col
              span={24}
              id={`fileContentContainer[${k}]`}
              style={{
                display: "none",
              }}
            >
              <FormItem
                label={getCurrentlocaleText("file.content")}
                validateStatus={
                  this.state.validateError["fileContentTextBox"][k]
                }
                className="dynamicFormCtrls"
                help={
                  this.state.validateError["fileContentTextBox"][k] ===
                    "error" &&
                  this.state.validateErrorText["fileContentTextBox"][k]
                }
              >
                {getFieldDecorator(`fileContentTextBox[${k}]`, {
                  rules: [
                    {
                      required: true,
                    },
                  ],
                  initialValue: this.UiStore.volumes.fileContentTextBox[
                    `fileContentTextBox[${k}]`
                  ]
                    ? this.UiStore.volumes.fileContentTextBox[
                        `fileContentTextBox[${k}]`
                      ]
                    : "",
                })(
                  <TextArea
                    style={{
                      maxHeight: "200px",
                      minHeight: "200px",
                      overflowY: "scroll",
                    }}
                    onChange={this.inputChanged}
                    placeholder={getCurrentlocaleText(
                      "volume.file.content.placeholder",
                    )}
                    onFocus={this.updateHelp.bind(
                      this,
                      helperObjects.filecontentHelpObj.help,
                    )}
                    autosize={{ minRows: 2, maxRows: 4 }}
                  />,
                )}
              </FormItem>
            </Col>
          </Row>
        </div>
      );
    });

    return formItems;
  };

  removeSecret = (e, val) => {
    e.preventDefault(); /*required, else multi-tags will be deleted*/

    let licenseFileIndex = this.props.UiStore.serviceFormData.uploadedLicenseFileName.indexOf(
      val,
    );
    if (licenseFileIndex != -1) {
      this.props.UiStore.serviceFormData.uploadedLicenseFileName.splice(
        licenseFileIndex,
        1,
      );
      if (
        this.props.UiStore.serviceFormData.rowDetails &&
        this.props.UiStore.serviceFormData.rowDetails.name
      ) {
        this.props.UiStore.temporaryLicenseData[
          this.props.UiStore.serviceFormData.rowDetails.name
        ].tempInitialLicenseFileName = JSON.stringify(
          this.props.UiStore.serviceFormData.uploadedLicenseFileName,
        );
      }
    }
  };

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    getFieldDecorator("keys", { initialValue: [] });
    const keys = getFieldValue("keys");

    /*Default footer when cutom footer is not required*/
    const defaultFooter = [
      <Button
        key="cancel"
        onClick={this.closeModal}
        disabled={this.props.confirmLoading ? this.props.confirmLoading : false}
      >
        Cancel
      </Button>,
      <Button
        type="primary"
        key="next"
        onClick={this.validateSubmit.bind(this)}
        disabled={this.props.confirmLoading ? this.props.confirmLoading : false}
      >
        {this.props.confirmLoading && (
          <LoadingOutlined
            spin={this.props.confirmLoading ? this.props.confirmLoading : false}
          />
        )}{" "}
        {this.props.okText ? this.props.okText : "OK"}
      </Button>,
    ];
    return (
      <div style={{ display: "inline-block" }}>
        <Button
          type={this.props.type}
          onClick={this.showModal}
          disabled={this.props.disabled}
          title={this.props.title}
        >
          {this.props.buttonName ? (
            this.props.buttonName
          ) : (
            <Icons type="ai" name="AiOutlinePlus" />
          )}
        </Button>

        {this.state.showFormModal && (
          <Modal
            visible={this.state.showFormModal} //{this.props.UiStore[this.props.resource].showFormModal}
            title={
              this.props.modalTitle ? this.props.modalTitle : this.props.title
            }
            onOk={this.validateSubmit}
            onCancel={this.closeModal}
            okText={this.props.okText}
            width={this.props.modalWidth}
            footer={this.props.footer ? this.props.footer : defaultFooter}
            //style={this.props.modalStyle}
            bodyStyle={{
              maxHeight: "350px",
              overflowY: "scroll",
            }}
            maskClosable={
              this.props.maskClosable ? this.props.maskClosable : false
            }
            confirmLoading={
              this.props.confirmLoading ? this.props.confirmLoading : false
            }
            closable={
              this.props.confirmLoading ? !this.props.confirmLoading : true
            }
          >
            <div>
              <Row gutter={24} type="flex">
                <Col span={this.props.help ? 14 : 24}>
                  <div>
                    <Form className="login-form">
                      <Row
                        style={
                          {
                            //display: "none",
                          }
                        }
                        key={0}
                      >
                        <Col span={12}>
                          <FormItem
                            label="Volume Name"
                            validateStatus={
                              this.state.validateError["volumeName"][0]
                            }
                            help={
                              this.state.validateError["volumeName"][0] ===
                                "error" &&
                              this.state.validateErrorText["volumeName"][0]
                            }
                          >
                            {getFieldDecorator(`volumeName[0]`, {
                              rules: [
                                {
                                  required: true,
                                },
                              ],
                              initialValue:
                                this.props.actionType == "edit" &&
                                this.UiStore.volumes.volumeName
                                  ? this.props.actionType == "edit" &&
                                    this.UiStore &&
                                    this.UiStore.volumes &&
                                    this.UiStore.volumes.volumeName
                                  : "",
                            })(
                              <InputBox
                                placeholder={"Volume Name"}
                                style={{ marginRight: 8 }}
                                onChange={this.inputChanged}
                                autoComplete="off"
                                className="firstInput"
                                onFocus={this.updateHelp.bind(
                                  this,
                                  helperObjects.volumenameHelpObj.help,
                                )}
                                disabled={
                                  this.props.disableName ||
                                  this.props.actionType === "edit"
                                }
                              />,
                            )}
                          </FormItem>
                        </Col>

                        <Col span={4} push={4}>
                          <FormItem>
                            <Button
                              type="primary"
                              onClick={this.add}
                              style={{ marginTop: "30px" }}
                            >
                              <Icons type="ai" name="AiOutlinePlus" /> Add File
                            </Button>
                          </FormItem>
                        </Col>
                      </Row>
                      {this.props.actionType === "edit" && (
                        <div>
                          {this.UiStore.serviceFormData
                            .uploadedLicenseFileName &&
                            this.UiStore.serviceFormData.uploadedLicenseFileName
                              .length > 0 && (
                              <Row>
                                <Col span={6}>
                                  <h3>
                                    {getCurrentlocaleText("uploaded.files")}
                                    {` :`}
                                  </h3>
                                </Col>
                                <Col span={18}>
                                  {this.props.UiStore.serviceFormData.uploadedLicenseFileName.map(
                                    (val, i) => {
                                      return (
                                        <Tag
                                          closable
                                          key={i}
                                          style={{
                                            marginBottom: "10px",
                                            marginRight: "12px",
                                          }}
                                          onClose={e => {
                                            this.removeSecret(e, val);
                                          }}
                                        >
                                          {val}
                                        </Tag>
                                      );
                                    },
                                  )}
                                </Col>
                              </Row>
                            )}
                          {
                            <div>
                              <h3>
                                {getCurrentlocaleText("volume.id")}
                                <span>
                                  {this.props.UiStore.serviceFormData &&
                                    this.props.UiStore.serviceFormData
                                      .rowDetails &&
                                    this.props.UiStore.serviceFormData
                                      .rowDetails.id}
                                </span>
                              </h3>
                            </div>
                          }
                        </div>
                      )}

                      {this.renderFormItems()}

                      <FormErrorMessage />
                    </Form>
                  </div>
                </Col>

                {this.props.help ? (
                  <Col span={10}>
                    <h2 className="help-title">
                      {this.props.UiStore.helpData.title
                        ? this.props.UiStore.getHelpcontent.title
                        : null}
                    </h2>
                    {this.props.UiStore.getHelpcontent.data &&
                      this.props.UiStore.getHelpcontent.data.map((item, i) => {
                        return (
                          <div
                            key={i + "help-div"}
                            style={{ marginBottom: "-10px" }}
                          >
                            <h3
                              className="help-subtitle"
                              key={i + "help-subtitle"}
                            >
                              {item.subtitle}
                            </h3>
                            <p
                              className="help-content"
                              key={i + "help-content"}
                            >
                              {item.content}
                            </p>
                            <br />
                          </div>
                        );
                      })}
                  </Col>
                ) : null}
              </Row>
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

ModalForm.propTypes = {
  onSubmit: propTypes.func.isRequired,
  resource: propTypes.string.isRequired,
  buttonName: propTypes.object,
  title: propTypes.string,
  actionType: propTypes.string,
};

const ModalForms = Form.create()(ModalForm);
export default ModalForms;
