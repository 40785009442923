import React, { Component } from "react";
import { Row, Col } from "antd";
import { observer, inject } from "mobx-react";
import BreadcrumbComponent from "components/UI-Components/BreadcrumbComponent";
import Icons from "components/UI-Components/Icons";
import {
  convertEpochToISOTimestampString,
  DefaultStatsWidget,
  getCurrentlocaleText,
  copyObject,
} from "Core/Utils";
import NetworkStatsController from "controller/NetworkStatsController";
import ServiceController from "controller/ServiceController";
import InodeController from "controller/InodeController";
import EventController from "controller/EventController";
import LoadingComponent from "components/UI-Components/LoadingComponent";
import NetworkChart from "components/Stats/Charts/NetworkChart";
import FileSystemChart from "components/Stats/Charts/FileSystemChart";
import CPUChart from "components/Stats/Charts/CPUChart";
import MemoryChart from "components/Stats/Charts/MemoryChart";
import StatsHeader from "components/Stats/StatsHeader";
import Dashboard, { addWidget } from "react-dazzle";
import "react-dazzle/lib/style/style.css";
import EventsTimeLine from "components/Stats/EventsTimeLine";
import AddWidgetModal from "components/Dashboard/components/AddWidgetModal";
import ResetDashboardModal from "components/Dashboard/components/ResetDashboardModal";
import AddWidget from "components/Dashboard/components/AddWidget";

@inject(
  "AuthStore",
  "NodeStatsViewModel",
  "InodeViewModel",
  "ServiceStatsViewModel",
  "EventViewModel",
)
@observer
class StatsContainer extends Component {
  constructor(props) {
    super(props);
    this.inodeModel = this.props.InodeViewModel;
    this.ServiceStatsViewModel = this.props.ServiceStatsViewModel;
    this.NodeStatsViewModel = this.props.NodeStatsViewModel;
    this.interval = null;
    this.defaultInterval = 30000;

    this.state = {
      BreadcrumbList: [
        {
          name: "Stats",
          link: "stats",
        },
      ],
      data: [],
      opts: {},
      completeData: [],
      interfaces: [],
      FileSystemInfo: [],
      loading: false,
      ServiceList: [],
      isInvalidRange: false,
      widgets: {
        CPUChart: {
          type: CPUChart,
          title: "CPU Stats",
          key: "CPUChart",
          props: this.props,
          category: "node",
        },
        MemoryChart: {
          type: MemoryChart,
          title: "Memory Usage",
          key: "MemoryChart",
          props: this.props,
          category: "node",
        },
        FileSystemChart: {
          type: FileSystemChart,
          title: "File System",
          key: "FileSystemChart",
          props: this.props,
          category: "node",
        },
        NetworkChart: {
          type: NetworkChart,
          title: "Network Chart",
          key: "NetworkChart",
          props: this.props,
          category: "node",
        },
      },
      layout:
        localStorage.layoutVersion &&
        JSON.parse(localStorage.layoutVersion) === 2 &&
        localStorage.statsLayout
          ? JSON.parse(localStorage.statsLayout)
          : copyObject(DefaultStatsWidget),
      editMode: false,
      isModalOpen: false,
      addWidgetOptions: {},
      resetModalOpen: false,
      value: this.props.match.params.containerId
        ? this.props.match.params.containerId
        : this.props.match.params.nodeId,
      serviceId: this.props.match.params.spec
        ? this.props.match.params.spec
        : null,
      containerId: this.props.match.params.containerId
        ? this.props.match.params.containerId
        : null,
    };
  }

  updateNodeChart = (loading = false) => {
    let params = this.getStartEndParams();
    params.fields = ["filesystem"];
    NetworkStatsController.getNodeStatsOnly(
      this.props.match.params.nodeId,
      params,
      loading,
    )
      .then(res => {})
      .catch(error => {});
  };
  UNSAFE_componentWillMount() {
    this.props.NodeStatsViewModel.currentInterface = null;
    this.props.NodeStatsViewModel.currentContainerDisplayName = null;
  }
  componentDidMount() {
    this.iNodeDetails(null, true, true);
    this.serviceList(true, 0, "", false);
    this.updateNodeChart(true);
    this.eventList(true, 0);
    if (this.props.match.params.containerId) {
      this.getNetworkStats(true);
      this.getContainerStats(true);
      this.setStatsInterval();
      this.props.ServiceStatsViewModel.containerId = this.props.match.params
        .containerId
        ? this.props.match.params.containerId
        : null;
    }
  }

  setStatsInterval = () => {
    if (this.interval) {
      clearInterval(this.interval);
    }
    /*API CALL EVERY timeoutInterval Intiated HERE */
    this.interval = setInterval(() => {
      let duration = this.props.NodeStatsViewModel.statsDuration;
      if (parseInt(duration) != 4) {
        this.iNodeDetails(null, false, true);
        this.updateNodeChart(false);
        this.getNetworkStats(false);
        this.getContainerStats(false);
      }
    }, this.defaultInterval);
  };

  getNetworkStats = (loading = false) => {
    let params = this.getStartEndParams();
    params.fields = ["network", "spec"];
    NetworkStatsController.getServiceNetworkStats(
      this.state.serviceId,
      params,
      loading,
    )
      .then(res => {
        this.drawFileSystemValues();
      })
      .catch(error => {});
  };

  getContainerStats = (loading = false) => {
    let contianerId = this.state.containerId;

    let params = this.getStartEndParams();
    params.fields = ["cpu", "memory", "spec", "filesystem"];
    NetworkStatsController.getServiceContainerStats(
      this.state.serviceId,
      contianerId,
      params,
      loading,
    )
      .then(res => {
        this.drawFileSystemValues();
      })
      .catch(error => {});
  };

  componentWillUnmount = () => {
    /*Required to clear interval on component unmount*/
    clearInterval(this.interval);
    this.props.NodeStatsViewModel.statsDuration = "1";
    this.props.NodeStatsViewModel.currentInterface = null;
  };

  eventList = (loading = true, page = 0, forcecall = false) => {
    let params = this.getStartEndParams();
    params.page = page;
    params.size = 1;
    EventController.getInodeEvents(
      this.props.match.params.nodeId,
      params,
      loading,
      forcecall,
      true,
    ).then(res => {
      if (res) {
        let total_count = this.props.EventViewModel.pageable.total_count / 100;
        this.updateEventsData(total_count, [], loading, forcecall);
      }
    });
  };

  updateEventsData(totalCount, availableNetworks, loading, forcecall) {
    if (totalCount > 0) {
      let allParams = [];
      for (let page = 0; page < totalCount; page++) {
        let params = this.getStartEndParams();
        params.page = page;
        params.size = 100;
        // include service id
        if (
          this.props &&
          this.props.match &&
          this.props.match.params &&
          this.props.match.params.spec
        ) {
          params.service_id = this.props.match.params.spec;
        }
        allParams.push(params);
      }
      EventController.getAllInodeEvents(
        this.props.match.params.nodeId,
        allParams,
        loading,
        forcecall,
      ).then(resp => {});
    }
  }

  updateInodeDetails = res => {
    this.setState(() => {
      let newObj = {},
        nodeDetails = this.state.nodeDetails,
        BreadcrumbList = [];

      nodeDetails = res;

      let nodeCrumb = [
        {
          name: res.name ? res.name : "Details",
          link: `orgs/${res.organization.id}/inodes/${res.id}`,
        },
      ];
      BreadcrumbList.push(
        {
          name: res.organization.name,
          link: `dashboard/${res.organization.id}`,
        },
        { name: "Nodes", link: `orgs/${res.organization.id}/inodes` },
        ...nodeCrumb,
        {
          name: "Service Metrics",
          link: `orgs/${res.organization.id}/inodes/${res.id}/stats`,
        },
      );
      newObj["nodeDetails"] = nodeDetails;
      newObj["BreadcrumbList"] = BreadcrumbList;
      return newObj;
    });
  };

  fetchNewStatInfo = () => {
    this.getNetworkStats(true);
    this.getContainerStats(true);
    this.eventList(true);
  };

  /**
   *  Get current iNode details for reference
   */
  iNodeDetails = (res = null, loading = true, forceCall = false) => {
    let map = {
      id: this.props.match.params.nodeId,
    };
    InodeController.getInode(map.id, loading, forceCall).then(res => {
      return this.updateInodeDetails(res);
    });
  };

  //reset the default state
  resetdefault = () => {
    let layout = copyObject(DefaultStatsWidget);
    this.setState({
      layout: layout,
    });
    this.updateStore(layout);
    this.onRestoreClose();
    this.setState({
      editMode: false,
    });
  };

  onRestoreClose = () => {
    this.setState({
      resetModalOpen: false,
    });
  };

  UNSAFE_componentWillReceiveProps(newProps) {
    let interval = 30000; // 30 Seconds
    if (this.inodeModel.inode && this.inodeModel.inode.stat_config) {
      if (this.inodeModel.inode.stat_config.stat_mode === "SLOW") {
        interval = 120000; // 2 Minutes
      }
    }
    if (interval !== this.defaultInterval) {
      this.defaultInterval = interval;
      this.setStatsInterval();
    }
    if (
      this.props.match.params.containerId != newProps.match.params.containerId
    ) {
      this.setState({
        value: newProps.match.params.containerId,
        serviceId: newProps.match.params.spec,
        containerId: newProps.match.params.containerId,
      });
      setTimeout(() => {
        this.props.ServiceStatsViewModel.resetStats();
        this.iNodeDetails(null, true, true);
        this.serviceList(true, 0, "", false);
        this.getNetworkStats(true);
        this.getContainerStats(true);
        this.eventList(true, 0);
      }, 200);
    }
  }

  getStartEndParams() {
    let duration = this.NodeStatsViewModel.statsDuration;
    let params = {};
    var now = new Date().getTime();
    let customStartDate = this.NodeStatsViewModel.customStartDate;
    let customEndDate = this.NodeStatsViewModel.customEndDate;

    switch (parseInt(duration)) {
      case 1:
        var hoursago = new Date(now - 1000 * 60 * 60 * 5);
        break;
      case 2:
        var hoursago = new Date(now - 1000 * 60 * 60 * 24);
        break;
      case 3:
        var hoursago = new Date(now - 1000 * 60 * 60 * 48);
        break;
      case 4:
        params.start_date = convertEpochToISOTimestampString(customStartDate);
        params.end_date = convertEpochToISOTimestampString(customEndDate);
        break;
      default:
        var hoursago = new Date(now - 1000 * 60 * 60 * 5);
        break;
    }
    if (parseInt(duration) === 4) {
      return params;
    } else {
      let updated_time = Date.parse(hoursago);
      params.start_date = convertEpochToISOTimestampString(updated_time);
      params.end_date = convertEpochToISOTimestampString(now);
      return params;
    }
  }

  serviceList = (
    loading = true,
    page = 0,
    search = "",
    forceCall = false,
    resolveData = true,
  ) => {
    let params = this.props.params || {
      node_id: this.props.match.params.nodeId
        ? this.props.match.params.nodeId
        : this.props.nodeId,
    };
    params.page = page;
    params.search = search;

    ServiceController.getServices(params, loading, forceCall, resolveData).then(
      repsonse => {
        this.setState({
          ServiceList: repsonse.results ? repsonse.results : [],
        });
        this.computeContainerInfo(repsonse.results ? repsonse.results : []);
      },
    );
  };

  computeContainerInfo = serviceList => {
    let currentService = [];
    currentService = serviceList.filter(service => {
      if (
        service.id ===
        (this.props.match &&
          this.props.match.params &&
          this.props.match.params.spec &&
          this.props.match.params.spec)
      ) {
        return service;
      }
    });
    let display_name = null;
    if (
      currentService.length > 0 &&
      currentService[0].spec &&
      currentService[0].spec.services &&
      currentService[0].spec.services.length > 0
    ) {
      currentService[0].spec.services.forEach(container => {
        if (container.id === this.props.match.params.containerId) {
          display_name = currentService[0].name + " / " + container.name;
        }
      });
    }
    if (display_name) {
      this.NodeStatsViewModel.currentContainerDisplayName = display_name;
    }
  };

  drawFileSystemValues = () => {
    let containerInfo = this.NodeStatsViewModel.getStatsAndInterfaces.stats;
    let devices = [];
    let filesystems =
      containerInfo && containerInfo[0] && containerInfo[0].filesystem
        ? containerInfo[0] && containerInfo[0].filesystem
        : [];
    for (var i = 0; i < filesystems.length; i++) {
      var data = filesystems[i];
      var totalUsage = Math.floor((data.usage * 100.0) / data.capacity);
      if (data.device.includes("vda11") || data.device.includes("tmpfs")) {
        devices.push({
          name: data.device,
          usage: totalUsage,
          capacity: data.capacity,
          total: data.usage,
        });
      }
    }
    this.setState({ FileSystemInfo: devices });
  };

  //move func for widget
  onMove = layout => {
    let updatedLayout = this.avoidMultiple(layout);
    this.setState({
      layout: updatedLayout,
    });
    this.updateStore(updatedLayout);
    this.toggleEdit();
  };
  //remove func for widget
  onRemove = layout => {
    this.setState({
      layout: layout,
    });
    this.updateStore(layout);
    this.toggleEdit();
  };
  //switching between edit and done for dashboard
  toggleEdit = () => {
    this.setState({
      editMode: !this.state.editMode,
    });
  };
  //on add new modal func
  onAdd = (layout, rowIndex, columnIndex) => {
    this.setState({
      isModalOpen: true,
      addWidgetOptions: {
        layout,
        rowIndex,
        columnIndex,
      },
    });
  };
  //Modal close func
  onRequestClose = () => {
    this.setState({
      isModalOpen: false,
    });
  };

  //restore the default state
  retoreDefault = () => {
    this.setState({
      resetModalOpen: true,
    });
  };

  //update the current layout in the stream.
  updateStore(layout) {
    let storableData = [];
    let layoutData = {
      rows: layout.rows,
    };
    storableData.push(layoutData);
    this.setState({ layout: layout });
    localStorage.setItem("statsLayout", JSON.stringify(layout));
    localStorage.setItem("layoutVersion", JSON.stringify(2));
  }
  // on change slection widgets
  handleWidgetSelection = widgetName => {
    const { layout, rowIndex, columnIndex } = this.state.addWidgetOptions;
    let Updatedlayout = this.avoidDuplicates(layout, widgetName);
    if (layout.rows[rowIndex].columns[columnIndex].widgets.length > 0) {
      let data = layout.rows[rowIndex].columns[columnIndex].widgets.splice(0);
      layout.rows[rowIndex].columns[columnIndex].widgets = Array(data[0]);
      layout.rows[rowIndex].columns[columnIndex].widgets[0].key = widgetName;
    } else {
      Updatedlayout = addWidget(layout, rowIndex, columnIndex, widgetName);
    }

    this.setState({
      layout: Updatedlayout,
    });

    this.updateStore(Updatedlayout);
    this.toggleEdit();
    this.onRequestClose();
  };

  avoidDuplicates = (layout, WidgetName) => {
    let layOutPattern = layout.rows;

    layOutPattern.map((val, index) => {
      if (val.columns[0].widgets.length > 0) {
        if (WidgetName === val.columns[0].widgets[0].key) {
          {
            layout.rows[index].columns[0].widgets = [];
          }
        }
      }
    });
    return layout;
  };
  render() {
    let node_data = this.inodeModel.inode;

    return (
      <div>
        <LoadingComponent
          loading={
            this.NodeStatsViewModel.loading ||
            this.ServiceStatsViewModel.loading
          }
        >
          <Row gutter={24} type="flex" align="middle">
            <Col span={20}>
              <h2 className="page-title">
                <Icons type="fa" name="FaCogs" />
                {"  "}
                {node_data.name ? node_data.name : null}
              </h2>
              <BreadcrumbComponent
                {...this.props}
                BreadcrumbList={this.state.BreadcrumbList}
              />
            </Col>
          </Row>
          <div
            style={{
              padding: 24,
              background: "#fff",
              minHeight: 360,
            }}
          >
            <Row gutter={0}>
              <StatsHeader
                fetchNewStatInfo={this.fetchNewStatInfo}
                ServiceList={this.state.ServiceList}
                nodeId={
                  this.props.match.params.containerId
                    ? this.props.match.params.containerId
                    : this.props.match.params.nodeId
                }
                onEdit={this.toggleEdit}
                onAdd={this.onAdd}
                onUpdate={this.toggleEdit}
                editStatus={this.state.editMode}
                onReset={this.retoreDefault}
                {...this.props}
              />
              <div>
                <Row type="flex" align="top">
                  <Col span={18}>
                    <AddWidgetModal
                      widgets={this.state.widgets}
                      isModalOpen={this.state.isModalOpen}
                      onRequestClose={this.onRequestClose}
                      onWidgetSelect={this.handleWidgetSelection}
                    />
                    <ResetDashboardModal
                      onReset={this.retoreDefault}
                      isModalOpen={this.state.resetModalOpen}
                      onRequestClose={this.onRestoreClose}
                      onConfirm={this.resetdefault}
                      title={getCurrentlocaleText(
                        "stats.config.reset.title.text",
                      )}
                      message={getCurrentlocaleText(
                        "stats.config.reset.message.text",
                      )}
                    />
                    <Dashboard
                      editable={this.state.editMode}
                      onAdd={this.onAdd}
                      addWidgetComponent={AddWidget}
                      addWidgetComponentText={
                        this.state.widgets ? "Change" : "Add"
                      }
                      onRemove={this.onRemove}
                      onMove={this.onMove}
                      widgets={this.state.widgets}
                      layout={this.state.layout}
                      {...this.props}
                    />
                  </Col>
                  <Col span={6}>
                    <EventsTimeLine />
                  </Col>
                </Row>
              </div>
            </Row>
          </div>
        </LoadingComponent>
      </div>
    );
  }
}

export default StatsContainer;
