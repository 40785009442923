import React, { Component } from "react";
import Chart from "react-google-charts";
import { Row, Col, Skeleton } from "antd";
import { getCurrentlocaleText } from "../../Core/Utils";

export default class CTDBarChart extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const customColors = [
      "#5c8edf",
      "#7de0d4",
      "#7e86d0",
      "#68bfe4",
      "#f3aaa1",
      "#cbc1fb",
    ];
    const dataForList = this.props.data ? this.props.data : [];

    const listData = dataForList.map((obj, index) => (
      <Row key={index}>
        <Col span={18}>
          <div className="d-flex">
            <div
              className="legend-circle"
              style={{ backgroundColor: customColors[index] }}
            ></div>
            <div>
              {obj && obj[this.props.objKey]
                ? String(obj[this.props.objKey])
                : "0"}
            </div>
          </div>
        </Col>
        <Col span={6} className="text-align-end pr-5">
          {obj.count ? obj.count : ""}
        </Col>
      </Row>
    ));
    var nameList = dataForList.map(obj =>
      obj && obj[this.props.objKey] ? String(obj[this.props.objKey]) : "",
    );
    var countsList = dataForList.map(obj => obj.count);
    const dataWithTitle = [
      [this.props.headingName, ...nameList],
      [...["Count"], ...countsList],
    ];
    const options = {
      chartArea: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },

      isStacked: "percent",
      legend: "none",
      bar: { groupWidth: "100%" },
      colors: customColors,
      tooltip: {
        trigger: "none",
      },
      hAxis: {
        title: "",
        minValue: 0,
        textPosition: "none",
        baselineColor: "none",
        gridlines: {
          color: "transparent",
        },
      },
      vAxis: {
        title: "",
        textPosition: "none",
        baselineColor: "none",
        gridlines: {
          color: "transparent",
        },
      },
    };

    return (
      <>
        <Chart
          chartType="BarChart"
          loader={
            <Skeleton.Input active block size="small" className="py-15" />
          }
          width="inherit"
          height="50px"
          data={dataWithTitle}
          options={options}
          className="py-10"
        />
        <Row className="overview-header mt-5">
          <Col span={18}>
            <div className=""></div>
            {this.props.headingName}
          </Col>
          <Col span={6} className="text-align-end">
            {getCurrentlocaleText("counts.text")}
          </Col>
        </Row>
        {listData}
      </>
    );
  }
}
