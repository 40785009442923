import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Form, Select, Spin, Cascader } from "antd";
import {
  getCurrentlocaleText,
  ALERT,
  isEmpty,
  customSort,
  checkforFeatureEnabled,
} from "Core/Utils";
import InodeController from "controller/InodeController";
import NetworkController from "controller/NetworkController";
import ServiceController from "controller/ServiceController";
import ClusterController from "controller/ClusterController";
import { OrgController } from "../../../controller/Controllers";
import { toJS } from "mobx";
import Icons from "components/UI-Components/Icons";
import Input from "components/UI-Components/InputBox";
import LoadingComponent from "components/UI-Components/LoadingComponent";

const FormItem = Form.Item;
const Option = Select.Option;
const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

@inject("UiStore", "AuthStore")
@observer
class AlertTargetItems extends Component {
  constructor(props) {
    super(props);
    this.UiStore = this.props.UiStore;
    this.state = {
      type: null,
      formItem: {
        scope: undefined,
        organization: undefined,
        inode: undefined,
        network: undefined,
        service: undefined,
        cluster: undefined,
      },
      nodes: { fetching: false, data: [] },
      clusters: { fetching: false, data: [] },
      nodesTree: [],
      clusterTree: [],
      networks: { fetching: false, data: [] },
      networksTree: [],
      services: { fetching: false, data: [] },
      orgsTree: [],
      orgs: { fetching: false, data: [] },
      newLabel: false,
      newClusterLabel: false,
      orgLoading: false,
      nodeLoading: false,
      clusterLoading: false,
      networkLoading: false,
      serviceLoading: false,
    };
  }

  componentDidMount() {
    // Getting type from pervious step
    let type;
    if (
      this.UiStore.alertFormData &&
      !isEmpty(this.UiStore.alertFormData.condition)
    ) {
      type = this.UiStore.alertFormData.condition.type;
      this.setState({ type });
    }

    // Set target scope data
    if (this.UiStore.alertFormData && !isEmpty(this.UiStore.getAlertTarget)) {
      let formItem = this.state.formItem,
        target = this.UiStore.getAlertTarget;
      if (target.cluster && target.cluster.length > 0) {
        formItem.scope = ALERT.SCOPE.CLUSTER_SCOPE;
      } else if (target.inode && target.inode.length > 0) {
        formItem.scope = ALERT.SCOPE.NODE_SCOPE;
      } else if (target.organization) {
        formItem.scope = ALERT.SCOPE.ORG_SCOPE;
      }
      formItem.organization = toJS(target.organization);

      formItem.inode = target.inode
        ? typeof target.inode === "string" && target.inode.includes(":")
          ? ["label", target.inode]
          : target.inode.map(x => {
              return x;
            })
        : [];

      formItem.cluster = target.cluster
        ? typeof target.cluster === "string" && target.cluster.includes(":")
          ? ["label", target.cluster]
          : target.cluster.map(x => {
              return x;
            })
        : [];

      if (
        (formItem.inode[0] === "name" || formItem.cluster[0] === "name") &&
        target.network &&
        target.network.length > 0
      ) {
        formItem.network = target.network
          ? target.network.map(x => {
              return x;
            })
          : [];
      } else if (type === ALERT.TYPE.TUNNEL_STATE_CHANGE) {
        //Since network id in last position of the array, so we are taking id in length-1 position
        formItem.network =
          formItem.inode && formItem.inode.length > 0
            ? [
                formItem.inode[formItem.inode.length - 1],
                formItem.inode[formItem.inode.length - 1],
              ]
            : formItem.cluster && formItem.cluster.length > 0
            ? [
                formItem.cluster[formItem.cluster.length - 1],
                formItem.cluster[formItem.cluster.length - 1],
              ]
            : [];
      }
      if (
        (formItem.inode[0] === "name" || formItem.cluster[0] === "name") &&
        target.service &&
        target.service.length > 0
      ) {
        formItem.service = target.service;
      } else if (type === ALERT.TYPE.SERVICE_STATE_CHANGE) {
        //Since service id in last position of the array, so we are taking id in length-1 position
        formItem.service =
          formItem.inode && formItem.inode.length > 0
            ? [
                formItem.inode[formItem.inode.length - 1],
                formItem.inode[formItem.inode.length - 1],
              ]
            : formItem.cluster && formItem.cluster.length > 0
            ? [
                formItem.cluster[formItem.cluster.length - 1],
                formItem.cluster[formItem.cluster.length - 1],
              ]
            : [];
      }
      if (formItem.organization) {
        this.fetchAllOrgs();
      }

      // Fetching inode, network or service
      if (formItem.inode) {
        this.fetchInodes(formItem.inode);
        if (formItem.network && formItem.network.length > 0) {
          this.fetchNetworks(formItem.inode[formItem.inode.length - 1]);
        }
        if (formItem.service) {
          this.fetchServices(formItem.inode[formItem.inode.length - 1]);
        }
      }
      // cluster
      if (formItem.cluster) {
        this.fetchClusters(formItem.cluster);
        if (formItem.network && formItem.network.length > 0) {
          this.fetchNetworks(formItem.cluster[formItem.cluster.length - 1]);
        }
        if (formItem.service) {
          this.fetchServices(formItem.cluster[formItem.cluster.length - 1]);
        }
      }
      this.props.formReference.setFields({
        scope: {
          value: formItem.scope,
        },
        organization: {
          value: formItem.organization,
        },
        inode: {
          value: formItem.inode,
        },
        network: {
          value: formItem.network,
        },
        service: {
          value: formItem.service,
        },
        cluster: {
          value: formItem.cluster,
        },
      });
    }
    let helpData = [
      {
        subtitle: "",
        content: getCurrentlocaleText("alertform.target.help.text"),
      },
      {
        subtitle: getCurrentlocaleText("general.component.orgs.text"),
        content: getCurrentlocaleText("alertform.target.org.help.text"),
      },
      {
        subtitle: getCurrentlocaleText("general.component.inode.text"),
        content: getCurrentlocaleText("alertform.target.inode.help.text"),
      },
    ];
    if (
      checkforFeatureEnabled(this.props.AuthStore.loggedInUserOrg, "cluster")
    ) {
      helpData.push({
        subtitle: getCurrentlocaleText("alertform.cluster.text"),
        content: getCurrentlocaleText("alertform.target.cluster.help.text"),
      });
    }
    // Update help text
    this.updateHelp({
      title: getCurrentlocaleText("managealerts.target.text"),
      data: helpData,
    });
  }

  /**
   * Check for securitypolicy Lable
   */
  checkForValidLabel = value => {
    if (value && value.length > 0) {
      let data = value ? value.split(":") : [];

      if (data.length <= 1) {
        return false;
      }
      if (data.length > 2) {
        return false;
      }
      if (data.length === 2) {
        if (data[0].length > 63 || data[1].length > 63) return false;
      }
      if (
        !this.checkForAphanumericValue(
          data[0] && String(data[0].trim()).substring(0, 1),
        ) ||
        (!this.checkForAphanumericValue(
          data[1] && String(data[1].trim()).substring(0, 1),
        ) &&
          String(data[1].trim()).length >= 1)
      ) {
        return false;
      }
      if (
        !this.checkForAphanumericValue(
          data[0] &&
            String(data[0].trim()).substring(
              data[0].trim().length - 1,
              data[0].trim().length,
            ),
        ) ||
        (!this.checkForAphanumericValue(
          data[1] &&
            String(data[1].trim()).substring(
              data[1].trim().length - 1,
              data[1].trim().length,
            ),
        ) &&
          String(data[1].trim()).length >= 1)
      ) {
        return false;
      }
      if (
        !this.checForAllowedValue(data[0].trim()) ||
        (!this.checForAllowedValue(data[1].trim()) &&
          String(data[1].trim()).length >= 1)
      ) {
        return false;
      }
      if (data.length <= 2 && data[0].charAt(0) === "_") {
        return false;
      } else return true;
    } else {
      false;
    }
  };

  updateHelp = data => {
    this.UiStore.SetStoreData("helpData", data);
  };

  /**
   * Check for First Character
   */
  checkForAphanumericValue = value => {
    var regex = /^[a-z0-9]+$/i;
    if (!regex.test(value)) {
      return false;
    } else {
      return true;
    }
  };
  checForAllowedValue = value => {
    var regex = /^[-A-Za-z0-9_.]+$/i;
    if (!regex.test(value)) {
      return false;
    } else {
      return true;
    }
  };

  handleScopeChange = value => {
    let formItem = this.state.formItem;
    formItem.scope = value;
    this.setState({ formItem });
    // Fetch all node of current user
    if (formItem.scope === ALERT.SCOPE.NODE_SCOPE) {
      // reset inode, network, service form data
      formItem.inode = undefined;
      formItem.network = undefined;
      formItem.service = undefined;
      this.setState({ formItem });
      this.props.formReference.setFields({
        inode: {
          value: formItem.inode,
        },
        network: {
          value: formItem.network,
        },
        service: {
          value: formItem.service,
        },
      });
      this.fetchInodes();
    } else if (formItem.scope === ALERT.SCOPE.CLUSTER_SCOPE) {
      formItem.cluster = undefined;
      formItem.network = undefined;
      formItem.service = undefined;
      this.setState({ formItem });
      this.props.formReference.setFields({
        cluster: {
          value: formItem.cluster,
        },
        network: {
          value: formItem.network,
        },
        service: {
          value: formItem.service,
        },
      });
      this.fetchClusters();
    } else if (formItem.scope === ALERT.SCOPE.ORG_SCOPE) {
      formItem.organization = undefined;
      this.setState({ formItem });
      this.props.formReference.setFields({
        organization: {
          value: formItem.organization,
        },
      });
      this.fetchAllOrgs();
    }
  };

  handleInodeChange = value => {
    let nodeId =
      value[0] === "#newlabel"
        ? value[0]
        : value[0] === "label"
        ? value[1]
        : value[0] === "name"
        ? value[2]
        : value[value.length - 1];
    // update new label key
    if (value[0] === "#newlabel" && value.length == 1) {
      this.setState({ newLabel: true });
    } else {
      this.setState({ newLabel: false });
    }
    let type = this.state.type;
    // Fetch network data based on selected node
    if (
      type === ALERT.TYPE.TUNNEL_STATE_CHANGE ||
      type === ALERT.TYPE.AGGREGATED_ROUTE_CHANGE
    ) {
      // reset network form data
      this.props.formReference.setFields({
        network: {
          value: undefined,
        },
      });
      this.fetchNetworks(nodeId);
    } else if (type === ALERT.TYPE.SERVICE_STATE_CHANGE) {
      // reset network form data
      this.props.formReference.setFields({
        service: {
          value: undefined,
        },
      });
      this.fetchServices(nodeId);
    }
    setTimeout(() => {
      if (this.state.newLabel) {
        // reset node form data
        this.props.formReference.setFields({
          inode: {
            value: undefined,
          },
        });
      }
    }, 300);
  };

  /**
   * CheckForValidLabel
   */
  CheckForValidLabel = (rule, value, callback) => {
    if (!this.checkForValidLabel(value))
      callback(getCurrentlocaleText("general.label.input.error1.text"));
    else callback();
  };

  handleClusterChange = value => {
    let clusterId =
      value[0] === "#newlabel"
        ? value[0]
        : value[0] === "label"
        ? value[1]
        : value[0] === "name"
        ? value[2]
        : value[value.length - 1];
    // update new label key
    if (value[0] === "#newlabel" && value.length == 1) {
      this.setState({ newClusterLabel: true });
    } else {
      this.setState({ newClusterLabel: false });
    }
    let type = this.state.type;
    // Fetch network data based on selected node
    if (
      type === ALERT.TYPE.TUNNEL_STATE_CHANGE ||
      type === ALERT.TYPE.AGGREGATED_ROUTE_CHANGE
    ) {
      // reset network form data
      this.props.formReference.setFields({
        network: {
          value: undefined,
        },
      });
      this.fetchNetworks(clusterId);
    } else if (type === ALERT.TYPE.SERVICE_STATE_CHANGE) {
      // reset network form data
      this.props.formReference.setFields({
        service: {
          value: undefined,
        },
      });
      this.fetchServices(clusterId);
    }
    setTimeout(() => {
      if (this.state.newClusterLabel) {
        // reset node form data
        this.props.formReference.setFields({
          cluster: {
            value: undefined,
          },
        });
      }
    }, 300);
  };

  fetchAllOrgs = () => {
    let type = this.state.type,
      params = {
        org_id:
          this.UiStore.currentUser && this.UiStore.currentUser.organization.id,
        size: 1,
      },
      orgs = this.state.orgs;
    orgs.data = [];
    orgs.fetching = true;
    this.setState({ orgLoading: true });
    this.orgCascaderLabelDisplay("none");
    // Adding loading option in cascader.
    let orgsTree = [];
    orgsTree.push({
      label: (
        <span key={"spinner"}>
          <Spin size="small" style={{ marginRight: 5 }} />{" "}
          {getCurrentlocaleText("loading.text")}
        </span>
      ),
      value: "org-tree-loading",
      key: "org-tree-loading",
      disabled: true,
    });
    this.setState({ orgs, orgsTree });
    OrgController.getOrgs(params, false, false, true)
      .then(response => {
        if (response && response.total_count) {
          let allParams = [],
            totalCount = response.total_count / 100;
          if (totalCount > 0) {
            for (let page = 0; page < totalCount; page++) {
              params = { org_id: params.org_id, page: page, size: 100 };
              allParams.push(params);
            }
            OrgController.getAllOrgsForDropdown(allParams)
              .then(response => {
                orgs.fetching = false;
                this.setState({ orgLoading: false });
                orgs.data = response;
                this.formOrgsTree(orgs);
              })
              .catch(error => {
                orgs.fetching = false;
                this.setState({ orgLoading: false });
                this.formOrgsTree(orgs);
              });
          } else {
            orgs.fetching = false;
            this.setState({ orgLoading: false });
            this.formOrgsTree(orgs);
          }
        } else {
          orgs.fetching = false;
          this.setState({ orgLoading: false });
          this.formOrgsTree(orgs);
        }
      })
      .catch(error => {
        orgs.fetching = false;
        this.setState({ orgLoading: false });
        this.formOrgsTree(orgs);
      });
  };

  fetchInodes = inode => {
    let type = this.state.type,
      params = {
        org_id:
          this.UiStore.currentUser && this.UiStore.currentUser.organization.id,
        size: 1,
      },
      nodes = this.state.nodes;
    nodes.data = [];
    nodes.fetching = true;
    this.setState({ nodeLoading: true });
    this.nodeCascaderLabelDisplay("none");
    // Adding loading option in cascader.
    let nodesTree = [];
    nodesTree.push({
      label: (
        <span key={"spinner"}>
          <Spin size="small" style={{ marginRight: 5 }} />{" "}
          {getCurrentlocaleText("loading.text")}
        </span>
      ),
      value: "node-tree-loading",
      key: "node-tree-loading",
      disabled: true,
    });
    this.setState({ nodes, nodesTree });
    // fetch physical nodes
    if (type === ALERT.TYPE.SERVICE_STATE_CHANGE) {
      params.profile_name = ["Virtual Edge", "Edge"];
    }
    InodeController.getInodesForDropdown(params)
      .then(response => {
        if (response && response.total_count) {
          let allParams = [],
            totalCount = response.total_count / 100;
          if (totalCount > 0) {
            for (let page = 0; page < totalCount; page++) {
              params = { org_id: params.org_id, page: page, size: 100 };
              // fetch physical nodes
              if (type === ALERT.TYPE.SERVICE_STATE_CHANGE) {
                params.profile_name = ["Virtual Edge", "Edge"];
              }
              allParams.push(params);
            }
            InodeController.getAllInodesForDropdown(allParams)
              .then(response => {
                nodes.fetching = false;
                this.setState({ nodeLoading: false });
                nodes.data = response;
                this.formNodesTree(nodes, inode);
              })
              .catch(error => {
                nodes.fetching = false;
                this.setState({ nodeLoading: false });
                this.formNodesTree(nodes, inode);
              });
          } else {
            nodes.fetching = false;
            this.setState({ nodeLoading: false });
            this.formNodesTree(nodes, inode);
          }
        } else {
          nodes.fetching = false;
          this.setState({ nodeLoading: false });
          this.formNodesTree(nodes, inode);
        }
      })
      .catch(error => {
        nodes.fetching = false;
        this.setState({ nodeLoading: false });
        this.formNodesTree(nodes, inode);
      });
  };

  // get cluster list
  fetchClusters = clusterData => {
    let params = {
        org_id:
          this.UiStore.currentUser && this.UiStore.currentUser.organization.id,
        size: 1,
      },
      clusters = this.state.clusters;
    clusters.data = [];
    clusters.fetching = true;
    this.setState({ clusterLoading: true });
    this.nodeCascaderLabelDisplay("none");
    // Adding loading option in cascader.
    let clusterTree = [];
    clusterTree.push({
      label: (
        <span key={"spinner"}>
          <Spin size="small" style={{ marginRight: 5 }} />{" "}
          {getCurrentlocaleText("loading.text")}
        </span>
      ),
      value: "cluster-tree-loading",
      key: "cluster-tree-loading",
      disabled: true,
    });
    this.setState({ clusters, clusterTree });
    ClusterController.getClustersForDropdown(params)
      .then(response => {
        if (response && response && response.total_count) {
          let allParams = [],
            totalCount = response.total_count / 100;
          if (totalCount > 0) {
            for (let page = 0; page < totalCount; page++) {
              params = { org_id: params.org_id, page: page, size: 100 };
              allParams.push(params);
            }
            ClusterController.getAllClustersForDropdown(allParams, false)
              .then(response => {
                clusters.fetching = false;
                this.setState({ clusterLoading: false });
                if (response) {
                  clusters.data = response;
                  this.formClusterTree(clusters, clusterData);
                }
              })
              .catch(error => {
                clusters.fetching = false;
                this.setState({ clusterLoading: false });
                this.formClusterTree(clusters, clusterData);
              });
          } else {
            clusters.fetching = false;
            this.setState({ clusterLoading: false });
            this.formClusterTree(clusters, clusterData);
          }
        } else {
          clusters.fetching = false;
          this.setState({ clusterLoading: false });
          this.formClusterTree(clusters, clusterData);
        }
      })
      .catch(error => {
        clusters.fetching = false;
        this.setState({ clusterLoading: false });
        this.formClusterTree(clusters, clusterData);
      });
  };

  formOrgsTree = orgs => {
    let my_org = {
      label: getCurrentlocaleText("alertform.myorg.text"),
      value: ALERT.ORGANIZATION.MY_ORG,
      key: ALERT.ORGANIZATION.MY_ORG,
      children: [],
    };

    let orgsTree = [
      {
        label: getCurrentlocaleText("alertform.myorg_and_mychild_orgs.text"),
        value: ALERT.ORGANIZATION.MY_ORG_AND_MY_CHILD_ORGS,
        key: ALERT.ORGANIZATION.MY_ORG_AND_MY_CHILD_ORGS,
        children: [],
      },
    ];
    let specific_orgs = {
      label: getCurrentlocaleText("alertform.specific_org.text"),
      value: ALERT.ORGANIZATION.SPECIFIC_ORGS,
      key: "",
      children: [],
    };

    if (orgs && orgs.data) {
      orgs.data.forEach(org => {
        if (org.is_me) {
          my_org.children.push({
            label: org.name,
            value: org.id,
            key: org.id,
            children: [],
          });
        }
      });
      orgsTree.push(my_org);
    }

    if (orgs && orgs.data) {
      orgs.data.forEach(org => {
        specific_orgs.children.push({
          label: org.name,
          value: org.id,
          key: org.id,
          children: [],
        });
      });
      orgsTree.push(specific_orgs);
    }

    // Apply sorting
    orgsTree.sort((x, y) => customSort(x.label, y.label));
    orgsTree.forEach(orgTreeData => {
      orgTreeData.children.sort((x, y) => customSort(x.label, y.label));
    });
    if (orgsTree.length === 0) {
      // Adding not found option in cascader.
      orgsTree.push({
        label: getCurrentlocaleText("alertform.notfound.label.text"),
        value: "org-tree-not-found",
        key: "org-tree-not-found",
        disabled: true,
      });
      this.orgCascaderLabelDisplay("none");
    } else {
      this.orgCascaderLabelDisplay("block");
    }
    this.setState({ orgs, orgsTree });
  };

  getTagKeyValuesAll = labels => {
    let tags = [];
    if (labels) {
      for (let key in labels) {
        if (key.startsWith("_iotium.")) {
          delete labels[key];
        }
      }
      for (let key in labels) {
        tags.push({
          value: key + ":" + labels[key],
          label: key + " : " + labels[key],
        });
      }
    }
    return tags;
  };

  removeDuplicates(myArr) {
    let tempData = [];
    return myArr.filter((obj, pos, arr) => {
      if (!tempData.includes(obj.value)) {
        tempData.push(obj.value);
        return obj;
      }
    });
  }
  formNodesTree = (nodes, inode) => {
    let nodesTree = [],
      nodeLabelTree = [],
      computedData = [];

    if (nodes && nodes.data) {
      nodes.data.forEach(node => {
        let labels = node.metadata ? node.metadata.labels : null;
        let labelTags = labels ? this.getTagKeyValuesAll(labels) : [];
        if (labelTags.length > 0) {
          labelTags.forEach(label => {
            nodeLabelTree.push(label);
          });
        }
        if (
          inode &&
          inode.length > 1 &&
          inode[0] === "label" &&
          !nodeLabelTree.includes(inode[1])
        ) {
          nodeLabelTree.push({
            value: inode[1],
            label: inode[1],
          });
        }
        let orgIndex = nodesTree.findIndex(tree => {
          return node.organization && node.organization.id === tree.value;
        });
        if (orgIndex !== -1) {
          nodesTree[orgIndex].children.push({
            label: node.name,
            value: node.id,
            key: node.id,
          });
        } else {
          let org = node.organization;
          nodesTree.push({
            label: org.name,
            value: org.id,
            key: org.id,
            children: [],
          });
          orgIndex = nodesTree.findIndex(tree => {
            return node.organization && node.organization.id === tree.value;
          });
          if (orgIndex !== -1) {
            nodesTree[orgIndex].children.push({
              label: node.name,
              value: node.id,
              key: node.id,
            });
          }
        }
      });
    }
    // Apply sorting
    nodesTree.sort((x, y) => customSort(x.label, y.label));
    nodesTree.forEach(nodeTreeData => {
      nodeTreeData.children.sort((x, y) => customSort(x.label, y.label));
    });

    if (nodesTree.length > 0) {
      computedData.push({
        value: "name",
        label: getCurrentlocaleText(
          "alertform.rule.select_by_name.placeholder1.text",
        ),
        tag: (
          <Icons
            type="fa"
            name="FaRegHdd"
            className="middle iot-tag-icon-style"
          />
        ),
        children: nodesTree,
      });
    }
    if (this.removeDuplicates(nodeLabelTree).length > 0) {
      computedData.push({
        value: "label",
        label: getCurrentlocaleText(
          "alertform.rule.select_by_label.placeholder2.text",
        ),
        tag: (
          <Icons
            type="fa"
            name="FaRegHdd"
            className="middle iot-tag-icon-style"
          />
        ),
        children: this.removeDuplicates(nodeLabelTree),
      });
    }
    computedData.push({
      value: "#newlabel",
      label: getCurrentlocaleText("alertform.rule.add_label.placeholder3.text"),
      tag: (
        <Icons
          type="fa"
          name="FaRegHdd"
          className="middle iot-tag-icon-style"
        />
      ),
      children: [],
    });

    if (computedData.length === 0) {
      // Adding not found option in cascader.
      computedData.push({
        label: getCurrentlocaleText("alertform.notfound.label.text"),
        value: "node-tree-not-found",
        key: "node-tree-not-found",
        disabled: true,
      });
      this.nodeCascaderLabelDisplay("none");
    } else {
      this.nodeCascaderLabelDisplay("block");
    }
    this.setState({ nodes, nodesTree: computedData });
  };

  formClusterTree = (clusters, clusterData) => {
    let clusterTree = [],
      clusterLabelTree = [],
      computedData = [];
    if (clusters && clusters.data) {
      clusters.data.forEach(cluster => {
        let labels = cluster.metadata ? cluster.metadata.labels : null;
        let labelTags = labels ? this.getTagKeyValuesAll(labels) : [];
        if (labelTags.length > 0) {
          labelTags.forEach(label => {
            clusterLabelTree.push(label);
          });
        }
        if (
          clusterData &&
          clusterData.length > 1 &&
          clusterData[0] === "label" &&
          !clusterLabelTree.includes(clusterData[1])
        ) {
          clusterLabelTree.push({
            value: clusterData[1],
            label: clusterData[1],
          });
        }
        let orgIndex = clusterTree.findIndex(tree => {
          return cluster.organization && cluster.organization.id === tree.value;
        });
        if (orgIndex !== -1) {
          clusterTree[orgIndex].children.push({
            label: cluster.name,
            value: cluster.id,
            key: cluster.id,
          });
        } else {
          let org = cluster.organization;
          clusterTree.push({
            label: org.name,
            value: org.id,
            key: org.id,
            children: [],
          });
          orgIndex = clusterTree.findIndex(tree => {
            return (
              cluster.organization && cluster.organization.id === tree.value
            );
          });
          if (orgIndex !== -1) {
            clusterTree[orgIndex].children.push({
              label: cluster.name,
              value: cluster.id,
              key: cluster.id,
            });
          }
        }
      });
    }
    // Apply sorting
    clusterTree.sort((x, y) => customSort(x.label, y.label));
    clusterTree.forEach(clusterTreeData => {
      clusterTreeData.children.sort((x, y) => customSort(x.label, y.label));
    });

    if (clusterTree.length > 0) {
      computedData.push({
        value: "name",
        label: getCurrentlocaleText(
          "alertform.rule.select_by_name.placeholder1.text",
        ),
        tag: (
          <Icons
            type="fa"
            name="FaRegHdd"
            className="middle iot-tag-icon-style"
          />
        ),
        children: clusterTree,
      });
    }
    if (this.removeDuplicates(clusterLabelTree).length > 0) {
      computedData.push({
        value: "label",
        label: getCurrentlocaleText(
          "alertform.rule.select_by_label.placeholder2.text",
        ),
        tag: (
          <Icons
            type="fa"
            name="FaRegHdd"
            className="middle iot-tag-icon-style"
          />
        ),
        children: this.removeDuplicates(clusterLabelTree),
      });
    }
    computedData.push({
      value: "#newlabel",
      label: getCurrentlocaleText("alertform.rule.add_label.placeholder3.text"),
      tag: (
        <Icons
          type="fa"
          name="FaRegHdd"
          className="middle iot-tag-icon-style"
        />
      ),
      children: [],
    });

    if (computedData.length === 0) {
      // Adding not found option in cascader.
      computedData.push({
        label: getCurrentlocaleText("alertform.notfound.label.text"),
        value: "cluster-tree-not-found",
        key: "cluster-tree-not-found",
        disabled: true,
      });
      this.clusterCascaderLabelDisplay("none");
    } else {
      this.clusterCascaderLabelDisplay("block");
    }
    this.setState({ clusters, clusterTree: computedData });
  };

  nodeCascaderLabelDisplay = display => {
    setTimeout(() => {
      let elm = document.querySelector(
        ".alert-node-cascader .ant-cascader-picker-label",
      );
      if (elm) {
        elm.style.display = display;
      }
    }, 100);
  };

  clusterCascaderLabelDisplay = display => {
    setTimeout(() => {
      let elm = document.querySelector(
        ".alert-cluster-cascader .ant-cascader-picker-label",
      );
      if (elm) {
        elm.style.display = display;
      }
    }, 100);
  };

  orgCascaderLabelDisplay = display => {
    setTimeout(() => {
      let elm = document.querySelector(
        ".alert-org-cascader .ant-cascader-picker-label",
      );
      if (elm) {
        elm.style.display = display;
      }
    }, 100);
  };
  fetchNetworks = nodeId => {
    let params = {};
    let formItem = this.state.formItem;
    if (nodeId) {
      if (nodeId === "#newlabel" || nodeId.includes(":")) {
        let networks = this.state.networks;
        networks.data = [];
        networks.fetching = true;
        this.setState({ networkLoading: true });
        this.networkCascaderLabelDisplay("none");
        // Adding loading option in cascader.
        let networksTree = [];
        networksTree.push({
          label: (
            <span key={new Date().getTime()}>
              <Spin key={"spinner"} size="small" style={{ marginRight: 5 }} />{" "}
              {getCurrentlocaleText("loading.text")}
            </span>
          ),
          value: "network-tree-loading",
          key: "network-tree-loading",
          disabled: true,
        });
        this.setState({ networks, networksTree });
        networks.fetching = false;
        this.setState({ networkLoading: false });
        if (this.state.type === ALERT.TYPE.AGGREGATED_ROUTE_CHANGE) {
          this.formNetworksTreeForAggrgatedRoute(networks, nodeId);
        } else {
          this.formNetworksTree(networks, nodeId);
        }
      } else {
        if (formItem.scope === ALERT.SCOPE.CLUSTER_SCOPE) {
          params.cluster_id = nodeId;
          params.cluster = true;
        } else {
          params.node_id = nodeId;
        }

        let networks = this.state.networks;
        networks.data = [];
        networks.fetching = true;
        this.setState({ networkLoading: false });
        this.networkCascaderLabelDisplay("none");
        // Adding loading option in cascader.
        let networksTree = [];
        networksTree.push({
          label: (
            <span key={new Date().getTime()}>
              <Spin key={"spinner"} size="small" style={{ marginRight: 5 }} />{" "}
              {getCurrentlocaleText("loading.text")}
            </span>
          ),
          value: "network-tree-loading",
          key: "network-tree-loading",
          disabled: true,
        });
        this.setState({ networks, networksTree });
        NetworkController.getNetworksForDropdown(params).then(response => {
          if (response) {
            networks.data = response.filter(nw => {
              return !nw.is_wan_network;
            });
          }
          networks.fetching = false;
          this.setState({ networkLoading: false });
          if (this.state.type === ALERT.TYPE.AGGREGATED_ROUTE_CHANGE) {
            this.formNetworksTreeForAggrgatedRoute(networks, nodeId);
          } else {
            this.formNetworksTree(networks, nodeId);
          }
        });
      }
    }
  };
  formNetworksTree = (networks, nodeId) => {
    // Form Tree data
    let networksTree = [];
    if (networks && networks.data) {
      networks.data
        .sort((x, y) => {
          customSort(x.name, y.name);
        })
        .forEach(nw => {
          networksTree.push({
            label:
              this.state.formItem.scope === ALERT.SCOPE.CLUSTER_SCOPE
                ? (nw.cluster
                    ? nw.cluster.name + " / "
                    : nw.node
                    ? nw.node.name + " / "
                    : null) + nw.name
                : nw.name,
            value: nw.id,
            key: nw.id,
            children: [
              {
                label: getCurrentlocaleText("alertform.allremotenetworks.text"),
                value: nw.id,
                key: nw.id,
              },
            ],
          });
          let nwIndex = networksTree.length - 1;
          if (nw.connected_networks && nw.connected_networks.length > 0) {
            networksTree[nwIndex].children = [];
            networksTree[nwIndex].children.push({
              label: getCurrentlocaleText("alertform.allremotenetworks.text"),
              value: nw.id,
              key: nw.id,
            });
            nw.connected_networks
              .sort((x, y) => customSort(x.network.name, y.network.name))
              .forEach(rnw => {
                networksTree[nwIndex].children.push({
                  label: rnw.node.name + " / " + rnw.network.name,
                  value: rnw.id,
                  key: rnw.id,
                });
              });
          }
        });
    }
    // Adding all networks option at the start
    networksTree.unshift({
      label: getCurrentlocaleText("alertform.allnetworks.text"),
      value: nodeId,
      key: nodeId,
      children: [
        {
          label: getCurrentlocaleText("alertform.allremotenetworks.text"),
          value: nodeId,
          key: nodeId,
        },
      ],
    });
    this.networkCascaderLabelDisplay("block");
    this.setState({ networks, networksTree });
  };
  formNetworksTreeForAggrgatedRoute = (networks, nodeId) => {
    // Form Tree data
    let networksTree = [];
    if (networks && networks.data) {
      networks.data
        .sort((x, y) => {
          customSort(x.name, y.name);
        })
        .forEach(nw => {
          networksTree.push({
            label:
              this.state.formItem.scope === ALERT.SCOPE.CLUSTER_SCOPE
                ? (nw.cluster
                    ? nw.cluster.name + " / "
                    : nw.node
                    ? nw.node.name + " / "
                    : null) + nw.name
                : nw.name,
            value: nw.id,
            key: nw.id,
          });
        });
    }
    // Adding all networks option at the start
    networksTree.unshift({
      label: getCurrentlocaleText("alertform.allnetworks.text"),
      value: nodeId,
      key: nodeId,
    });
    this.networkCascaderLabelDisplay("block");
    this.setState({ networks, networksTree });
  };

  networkCascaderLabelDisplay = display => {
    setTimeout(() => {
      let elm = document.querySelector(
        ".alert-network-cascader .ant-cascader-picker-label",
      );
      if (elm) {
        elm.style.display = display;
      }
    }, 100);
  };

  fetchServices = nodeId => {
    let params = {};
    let formItem = this.state.formItem;
    if (nodeId) {
      if (nodeId === "#newlabel" || nodeId.includes(":")) {
        let services = this.state.services;
        services.data = [];
        services.fetching = true;
        this.setState({ serviceLoading: true });
        this.setState({ services });
      } else {
        if (formItem.scope === ALERT.SCOPE.CLUSTER_SCOPE) {
          params.cluster_id = nodeId;
          params.cluster = true;
        } else {
          params.node_id = nodeId;
        }
        let services = this.state.services;
        services.data = [];
        services.fetching = true;
        this.setState({ serviceLoading: false });
        this.setState({ services });
        ServiceController.getServicesForDropdown(params)
          .then(response => {
            if (response) {
              services.data = response;
            }
            services.fetching = false;
            this.setState({ serviceLoading: false });
            this.setState({ services });
          })
          .catch(error => {
            services.fetching = false;
            this.setState({ serviceLoading: false });
          });
      }
    }
  };

  renderTargetScopeOptions = () => {
    let options = [{ label: "Org", value: ALERT.SCOPE.ORG_SCOPE }];
    if (this.state.type !== ALERT.TYPE.CERT_EXPIRY) {
      options.push({
        label: "Node",
        value: ALERT.SCOPE.NODE_SCOPE,
      });
    }
    if (
      checkforFeatureEnabled(this.props.AuthStore.loggedInUserOrg, "cluster") &&
      this.state.type !== ALERT.TYPE.NODE_METRICS
    ) {
      // cluster scope addition
      options.push({
        label: "Cluster",
        value: ALERT.SCOPE.CLUSTER_SCOPE,
      });
    }

    return options.map(option => {
      return (
        <Option key={option.value} value={option.value}>
          {option.label}
        </Option>
      );
    });
  };

  renderOrganizationOptions = () => {
    let options = [
      {
        label: getCurrentlocaleText("alertform.myorg.text"),
        value: ALERT.ORGANIZATION.MY_ORG,
      },
      {
        label: getCurrentlocaleText("alertform.myorg_and_mychild_orgs.text"),
        value: ALERT.ORGANIZATION.MY_ORG_AND_MY_CHILD_ORGS,
      },
    ];
    return options.map(option => {
      return (
        <Option key={option.value} value={option.value}>
          {option.label}
        </Option>
      );
    });
  };

  renderServiceOptions = () => {
    let services = this.state.services,
      options = [];
    let selectedNode = this.props.formReference.getFieldValue("inode");
    let nodeId =
      selectedNode && selectedNode[0] && selectedNode[1]
        ? selectedNode[selectedNode.length - 1]
        : null;
    let selectedCluster = this.props.formReference.getFieldValue("cluster");
    let clusterId =
      selectedCluster && selectedCluster[0] && selectedCluster[1]
        ? selectedCluster[selectedCluster.length - 1]
        : null;
    if (services && services.data && services.data.length > 0) {
      // Adding sort
      options = services.data
        .sort((x, y) => customSort(x.name, y.name))
        .map((service, index) => {
          return (
            <Option key={service.id} value={service.id}>
              {this.state.formItem.scope === ALERT.SCOPE.CLUSTER_SCOPE &&
                (service.cluster
                  ? service.cluster.name + " / "
                  : service.node
                  ? service.node.name + " / "
                  : null)}
              {service.name}
            </Option>
          );
        });
    }
    // Add All services option at the start after inode or cluster selected
    if (nodeId || clusterId) {
      options.unshift(
        <Option
          key={nodeId ? nodeId : clusterId}
          value={nodeId ? nodeId : clusterId}
        >
          {getCurrentlocaleText("alertform.allservices.text")}
        </Option>,
      );
    }

    return options;
  };

  remoteNetworkDisplayRender = labels => {
    if (labels) {
      return labels.length === 2 ? `${labels[0]} \u27F7 ${labels[1]}` : labels;
    }
  };

  render() {
    let { getFieldDecorator } = this.props.formReference;
    let { formItem, type } = this.state;
    return (
      <LoadingComponent
        loading={
          this.state.orgLoading ||
          this.state.nodeLoading ||
          this.state.clusterLoading ||
          this.state.networkLoading ||
          this.state.serviceLoading
        }
      >
        <div style={{ marginTop: 10 }}>
          <FormItem
            label={
              <span>{getCurrentlocaleText("alertform.targetscope.text")}</span>
            }
            {...formItemLayout}
          >
            {getFieldDecorator("scope", {
              rules: [
                {
                  required: true,
                  message: getCurrentlocaleText(
                    "alertform.targetscope.required.message.text",
                  ),
                },
              ],
              initialValue: formItem.scope,
            })(
              <Select
                disabled={
                  this.props.formType === "view" || this.props.isDefault
                }
                placeholder={getCurrentlocaleText(
                  "alertform.targetscope.placeholder.text",
                )}
                onChange={this.handleScopeChange}
              >
                {this.renderTargetScopeOptions()}
              </Select>,
            )}
          </FormItem>
          {formItem.scope === ALERT.SCOPE.ORG_SCOPE && (
            <FormItem
              label={
                <span>
                  {getCurrentlocaleText("alertform.organization.text")}
                </span>
              }
              {...formItemLayout}
            >
              {getFieldDecorator("organization", {
                rules: [
                  {
                    required: true,
                    message: getCurrentlocaleText(
                      "alertform.organization.required.message.text",
                    ),
                  },
                ],
                initialValue: formItem.organization,
              })(
                <Cascader
                  disabled={
                    this.props.formType === "view" || this.props.isDefault
                  }
                  allowClear={false}
                  className="alert-org-cascader"
                  popupClassName="alert-org-cascade-popup"
                  expandTrigger="hover"
                  options={this.state.orgsTree}
                  placeholder={getCurrentlocaleText(
                    "alertform.organization.placeholder.text",
                  )}
                />,
              )}
            </FormItem>
          )}
          {formItem.scope === ALERT.SCOPE.NODE_SCOPE &&
            (!this.state.newLabel ? (
              <FormItem
                label={
                  <span>{getCurrentlocaleText("alertform.inode.text")}</span>
                }
                {...formItemLayout}
              >
                {getFieldDecorator("inode", {
                  rules: [
                    {
                      required: true,
                      message: getCurrentlocaleText(
                        "alertform.inode.required.message.text",
                      ),
                    },
                  ],
                  initialValue: formItem.inode,
                })(
                  <Cascader
                    disabled={
                      this.props.formType === "view" || this.props.isDefault
                    }
                    allowClear={false}
                    className="alert-node-cascader"
                    popupClassName="alert-node-cascade-popup"
                    expandTrigger="hover"
                    options={this.state.nodesTree}
                    placeholder={getCurrentlocaleText(
                      "alertform.inode.placeholder.text",
                    )}
                    notFoundContent={getCurrentlocaleText(
                      "general.notfound.placeholder.text",
                    )}
                    onChange={this.handleInodeChange}
                  />,
                )}
              </FormItem>
            ) : (
              <FormItem
                label={
                  <span>{getCurrentlocaleText("alertform.inode.text")}</span>
                }
                {...formItemLayout}
              >
                {getFieldDecorator("inode", {
                  rules: [
                    {
                      required: true,
                      message: getCurrentlocaleText(
                        "alertform.inode.required.message.text",
                      ),
                    },
                    {
                      validator: this.CheckForValidLabel,
                    },
                  ],
                  initialValue: formItem.inode,
                })(
                  <span>
                    <Input
                      maxLength={127}
                      addonAfter={
                        <span
                          onClick={() => {
                            let formItem = this.state.formItem;
                            formItem.inode = undefined;
                            this.props.formReference.setFields({
                              inode: {
                                value: formItem.inode,
                              },
                            });
                            this.setState({
                              formItem: formItem,
                              newLabel: false,
                            });
                          }}
                        >
                          <Icons type="ai" name="AiOutlineCloseCircle" />
                        </span>
                      }
                    />
                  </span>,
                )}
              </FormItem>
            ))}
          {formItem.scope === ALERT.SCOPE.CLUSTER_SCOPE &&
            (!this.state.newClusterLabel ? (
              <FormItem
                label={
                  <span>{getCurrentlocaleText("alertform.cluster.text")}</span>
                }
                {...formItemLayout}
              >
                {getFieldDecorator("cluster", {
                  rules: [
                    {
                      required: true,
                      message: getCurrentlocaleText(
                        "alertform.cluster.required.message.text",
                      ),
                    },
                  ],
                  initialValue: formItem.cluster,
                })(
                  <Cascader
                    disabled={
                      this.props.formType === "view" || this.props.isDefault
                    }
                    allowClear={false}
                    className="alert-cluster-cascader"
                    popupClassName="alert-cluster-cascade-popup"
                    expandTrigger="hover"
                    options={this.state.clusterTree}
                    placeholder={getCurrentlocaleText(
                      "alertform.cluster.placeholder.text",
                    )}
                    notFoundContent={getCurrentlocaleText(
                      "general.notfound.placeholder.text",
                    )}
                    onChange={this.handleClusterChange}
                  />,
                )}
              </FormItem>
            ) : (
              <FormItem
                label={
                  <span>{getCurrentlocaleText("alertform.cluster.text")}</span>
                }
                {...formItemLayout}
              >
                {getFieldDecorator("cluster", {
                  rules: [
                    {
                      required: true,
                      message: getCurrentlocaleText(
                        "alertform.cluster.required.message.text",
                      ),
                    },
                    {
                      validator: this.CheckForValidLabel,
                    },
                  ],
                  initialValue: formItem.cluster,
                })(
                  <span>
                    <Input
                      maxLength={127}
                      addonAfter={
                        <span
                          onClick={() => {
                            let formItem = this.state.formItem;
                            formItem.cluster = undefined;
                            this.props.formReference.setFields({
                              cluster: {
                                value: formItem.cluster,
                              },
                            });
                            this.setState({
                              formItem: formItem,
                              newClusterLabel: false,
                            });
                          }}
                        >
                          <Icons type="ai" name="AiOutlineCloseCircle" />
                        </span>
                      }
                    />
                  </span>,
                )}
              </FormItem>
            ))}
          {(formItem.scope === ALERT.SCOPE.NODE_SCOPE ||
            formItem.scope === ALERT.SCOPE.CLUSTER_SCOPE) &&
            type === ALERT.TYPE.TUNNEL_STATE_CHANGE && (
              <FormItem
                label={
                  <span>
                    {getCurrentlocaleText(
                      "alertform.network_remote_network.text",
                    )}
                  </span>
                }
                {...formItemLayout}
              >
                {getFieldDecorator("network", {
                  rules: [
                    {
                      required: true,
                      message: getCurrentlocaleText(
                        "alertform.nw_remote_nw.required.message.text",
                      ),
                    },
                  ],
                  initialValue: formItem.network,
                })(
                  <Cascader
                    disabled={
                      this.props.formType === "view" || this.props.isDefault
                    }
                    allowClear={false}
                    className="alert-network-cascader"
                    popupClassName="alert-network-cascade-popup"
                    expandTrigger="hover"
                    options={this.state.networksTree}
                    placeholder={getCurrentlocaleText(
                      "alertform.nw_remote_nw.placeholder.text",
                    )}
                    notFoundContent={getCurrentlocaleText(
                      "general.notfound.placeholder.text",
                    )}
                    displayRender={this.remoteNetworkDisplayRender}
                  />,
                )}
              </FormItem>
            )}
          {(formItem.scope === ALERT.SCOPE.NODE_SCOPE ||
            formItem.scope === ALERT.SCOPE.CLUSTER_SCOPE) &&
            type === ALERT.TYPE.AGGREGATED_ROUTE_CHANGE && (
              <FormItem
                label={
                  <span>{getCurrentlocaleText("alertform.network.text")}</span>
                }
                {...formItemLayout}
              >
                {getFieldDecorator("network", {
                  rules: [
                    {
                      required: true,
                      message: getCurrentlocaleText(
                        "alertform.nw.required.message.text",
                      ),
                    },
                  ],
                  initialValue: formItem.network,
                })(
                  <Cascader
                    disabled={
                      this.props.formType === "view" || this.props.isDefault
                    }
                    allowClear={false}
                    className="alert-network-cascader"
                    popupClassName="alert-network-cascade-popup"
                    expandTrigger="hover"
                    options={this.state.networksTree}
                    placeholder={getCurrentlocaleText(
                      "alertform.nw.placeholder.text",
                    )}
                    notFoundContent={getCurrentlocaleText(
                      "general.notfound.placeholder.text",
                    )}
                    displayRender={this.remoteNetworkDisplayRender}
                  />,
                )}
              </FormItem>
            )}
          {(formItem.scope === ALERT.SCOPE.NODE_SCOPE ||
            formItem.scope === ALERT.SCOPE.CLUSTER_SCOPE) &&
            type === ALERT.TYPE.SERVICE_STATE_CHANGE && (
              <FormItem
                label={
                  <span>{getCurrentlocaleText("alertform.service.text")}</span>
                }
                {...formItemLayout}
              >
                {getFieldDecorator("service", {
                  rules: [
                    {
                      required: true,
                      message: getCurrentlocaleText(
                        "alertform.service.required.message.text",
                      ),
                    },
                  ],
                  initialValue: formItem.service,
                })(
                  <Select
                    disabled={
                      this.props.formType === "view" || this.props.isDefault
                    }
                    placeholder={getCurrentlocaleText(
                      "alertform.service.placeholder.text",
                    )}
                  >
                    {this.renderServiceOptions()}
                  </Select>,
                )}
              </FormItem>
            )}
        </div>
      </LoadingComponent>
    );
  }
}

export default AlertTargetItems;
