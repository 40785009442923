import React, { Component } from "react";
import { Link } from "react-router-dom";
import { observer, inject } from "mobx-react";
import Icons from "../UI-Components/Icons";
import CTDPieChart from "../Containers/CTDPieChart";
import OrgController from "../../controller/OrgController";
import LoadingComponent from "../UI-Components/LoadingComponent";
import { getCurrentlocaleText } from "../../Core/Utils";

const moment = require("moment");

@inject("OrgViewModel", "AuthStore")
@observer
export default class Threats extends Component {
  constructor(props) {
    super(props);

    this.orgModel = this.props.OrgViewModel;
    this.authStore = this.props.AuthStore;
    this.state = {
      orgId: this.props.match.params.id
        ? this.props.match.params.id
        : this.authStore.loggedInUser.organization &&
          this.authStore.loggedInUser.organization.id,
    };
  }

  componentDidMount() {
    const { orgId } = this.state;
    if (orgId == null) {
      const defaultOrgId = this.authStore.loggedInUser.organization.id;
      this.setState({ orgId: defaultOrgId }, () => {
        this.getTopThreats(defaultOrgId);
      });
    } else {
      this.getTopThreats(orgId);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      const currentOrgId = this.props.match.params.id
        ? this.props.match.params.id
        : this.authStore.loggedInUser.organization.id;
      this.setState({ orgId: currentOrgId });
      this.getTopThreats(currentOrgId);
    }
  }

  getTopThreats = orgId => {
    this.orgModel.getTopThreatsLoading = true;
    let params = {
      start_date: moment().subtract(7, "days"),
      end_date: moment(),
    };
    OrgController.getTopThreats(orgId, params)
      .then(resp => {
        if (resp) {
          this.orgModel.setTopThreats(resp);
        }
        this.orgModel.getTopThreatsLoading = false;
      })
      .catch(err => {
        this.orgModel.getTopThreatsLoading = false;
      });
  };

  render() {
    const { orgId } = this.state;
    const redirectUrl = `/orgs/${orgId}/threatintelligence`;
    const getTopThreats = this.orgModel.getTopThreats;

    return (
      <LoadingComponent loading={this.orgModel.getTopThreatsLoading}>
        <Link to={redirectUrl}>
          <div className="widget-title" style={{ color: "#000000d9" }}>
            {getCurrentlocaleText(
              "inode.continuous_threat_detection_alerts.text",
            )}
          </div>
          <div style={{ fontSize: "14px" }}>
            {getTopThreats && getTopThreats.stats && !getTopThreats.results && (
              <div className="p-15 m-15 all-center-vertical col-center">
                <Icons
                  type="fa"
                  name="FaSpinner"
                  className="spinner spineer-icon-rotate"
                />
                <h2 className="all-center primary-color">
                  {getTopThreats.stats.packets_analyzed}
                </h2>
                <div className="all-center text-muted">
                  {getCurrentlocaleText("threats.overview.title.desc")}
                </div>
              </div>
            )}
            {getTopThreats &&
              getTopThreats.results &&
              getTopThreats.results.length &&
              getTopThreats.results.length > 0 && (
                <CTDPieChart data={getTopThreats.results} />
              )}
          </div>
        </Link>
      </LoadingComponent>
    );
  }
}
