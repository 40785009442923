import React, { Component } from "react";
import {
  Card,
  Select,
  DatePicker,
  TreeSelect,
  Dropdown,
  Menu,
  Button,
  Row,
  Col,
} from "antd";
import { observer, inject } from "mobx-react";
import Icons from "components/UI-Components/Icons";
import {
  convertEpochToISOTimestampString,
  getCurrentlocaleText,
  getNodeVersion,
  DataSaverAndPodDnsBaseVersion,
} from "Core/Utils";
import { Link } from "react-router-dom";
import moment from "moment";
import InterfaceTrafficView from "components/UI-Components/InterfaceTrafficView";

const RangePicker = DatePicker.RangePicker;
const Option = Select.Option;
const TreeNode = TreeSelect.TreeNode;

@inject(
  "AuthStore",
  "NodeStatsViewModel",
  "InodeViewModel",
  "ServiceStatsViewModel",
)
@observer
class StatsHeader extends Component {
  constructor(props) {
    super(props);
    this.inodeModel = this.props.InodeViewModel;
    this.ServiceStatsViewModel = this.props.ServiceStatsViewModel;
    this.NodeStatsViewModel = this.props.NodeStatsViewModel;

    this.state = {
      isInvalidRange: false,
      ServiceList: this.props.ServiceList,
      value: this.props.nodeId,
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (this.props.ServiceList != newProps.ServiceList) {
      this.setState({
        ServiceList: newProps.ServiceList,
        value: this.props.nodeId,
      });
    }
  }

  getStartEndParams() {
    let duration = this.NodeStatsViewModel.statsDuration;
    let params = {};
    var now = new Date().getTime();
    let customStartDate = this.NodeStatsViewModel.customStartDate;
    let customEndDate = this.NodeStatsViewModel.customEndDate;

    switch (parseInt(duration)) {
      case 1:
        var hoursago = new Date(now - 1000 * 60 * 60 * 5);
        break;
      case 2:
        var hoursago = new Date(now - 1000 * 60 * 60 * 24);
        break;
      case 3:
        var hoursago = new Date(now - 1000 * 60 * 60 * 48);
        break;
      case 4:
        params.start_date = convertEpochToISOTimestampString(customStartDate);
        params.end_date = convertEpochToISOTimestampString(customEndDate);
        break;
      default:
        var hoursago = new Date(now - 1000 * 60 * 60 * 5);
        break;
    }
    if (parseInt(duration) === 4) {
      return params;
    } else {
      let updated_time = Date.parse(hoursago);
      params.start_date = convertEpochToISOTimestampString(updated_time);
      params.end_date = convertEpochToISOTimestampString(now);
      return params;
    }
  }

  /**
   *  Handle Duration method change
   */

  handleDurationOptionChange = value => {
    this.NodeStatsViewModel.statsDuration = value;
    if (value != "4") {
      this.NodeStatsViewModel.customStartDate = null;
      this.NodeStatsViewModel.customEndDate = null;
      this.props.fetchNewStatInfo();
    }
  };
  onScopeChange = (value, label, extra) => {
    this.setState({ value: value });
    if (
      label &&
      label[0] &&
      label[0].props &&
      label[0].props.to &&
      !label[0].props.disabled
    ) {
      this.props.history.push(label[0].props.to);
    }
  };

  renderServiceList = () => {
    let servicelist = this.state.ServiceList;
    return servicelist.map((element, index) => {
      let url = `/orgs/${element.organization.id}/inodes/${element.node.id}/service/${element.id}/stats`;
      return (
        <TreeNode
          value={element.id}
          disabled={true}
          title={
            <Link to={url} disabled={true}>
              <span>
                <Icons type="fa" name="FaCogs" />{" "}
                {element.name ? element.name : null}
              </span>
            </Link>
          }
          key={"0-1-" + index}
        >
          {element.spec &&
            element.spec.services &&
            element.spec.services.length > 0 &&
            this.renderContainer(element, element.spec.services)}
        </TreeNode>
      );
    });
  };

  renderContainer = (service, container_list) => {
    return container_list.map((element, index) => {
      let url = `/orgs/${service.organization.id}/inodes/${service.node.id}/service/${service.id}/${element.id}/stats`;
      return (
        <TreeNode
          value={element.id}
          title={
            <Link to={url}>
              <span>
                <Icons type="fa" name="FaCog" />{" "}
                {element.name ? element.name : null}
              </span>
            </Link>
          }
          key={"0-1-1" + index}
        />
      );
    });
  };

  onRangeDateSelectorChange = (DateObject, DateString) => {
    var duration;
    if (DateObject && DateObject[0] && DateObject[1]) {
      duration = moment.duration(DateObject[1].diff(DateObject[0]));
    } else {
      duration = null;
    }
    if (duration) {
      var minutes = duration.asMinutes();
      if (minutes <= 2880) {
        this.setState({ isInvalidRange: false });
      } else if (minutes > 2880) {
        this.setState({ isInvalidRange: true });
      }
    }
  };
  onRangeDateSelectorConfirm = (DateObject, DateString) => {
    if (!this.state.isInvalidRange) {
      this.NodeStatsViewModel.customStartDate = moment(DateObject[0]).valueOf();
      this.NodeStatsViewModel.customEndDate = moment(DateObject[1]).valueOf();
      this.props.fetchNewStatInfo();
    }
  };

  range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  disabledDate = current => {
    return current && current > moment().endOf("day");
  };

  disabledRangeTime = (_, type) => {
    if (type === "end" || type === "start") {
      const currentHour = moment().hour();
      const currentMinute = moment().minute();

      // Disable time range for the current hour and minute only
      if (moment(_).isSame(moment(), "day")) {
        return {
          disabledHours: () => this.range(currentHour + 1, 24),
          disabledMinutes: () => this.range(currentMinute + 1, 60),
          disabledSeconds: () => [],
        };
      }
    }
    return null;
  };

  render() {
    let statsDuration = this.NodeStatsViewModel.statsDuration;
    let node_data = this.inodeModel.inode;
    let nodeurl = `/orgs/${node_data &&
      node_data.organization &&
      node_data.organization.id}/inodes/${node_data.id}/stats`;
    this.menu = (
      <Menu style={{ marginTop: -6 }}>
        {!this.props.editStatus && (
          <Menu.Item key="1" style={this.color} onMouseEnter={this.toggleHover}>
            <Button
              icon={<Icons type="ai" name="AiOutlineEdit" />}
              title={getCurrentlocaleText("stats.config.edit.title.text")}
              onClick={this.props.onEdit}
              type="small"
              style={{ border: "none", position: "relative", left: "75px" }}
            >
              {getCurrentlocaleText("stats.config.edit.title.text")}
            </Button>
          </Menu.Item>
        )}
        <Menu.Item key="3" style={this.color} onMouseEnter={this.toggleHover}>
          <Button
            icon={<Icons type="ai" name="AiOutlineSync" />}
            style={{ border: "none", position: "relative", left: "75px" }}
            title={getCurrentlocaleText("stats.config.reset.button.text")}
            onClick={this.props.onReset}
            type="small"
          >
            {getCurrentlocaleText("stats.config.reset.button.text")}
          </Button>
        </Menu.Item>
      </Menu>
    );

    return (
      <div className="flex-column">
        {this.props.hideOptions && (
          <Row>
            <Col
              xs={{ span: 12 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 19 }}
              xl={{ span: 18 }}
            >
              {getCurrentlocaleText("stats.advanced.page.title.text")}
            </Col>
          </Row>
        )}
        <Card>
          {!this.props.hideOptions && (
            <span>
              <TreeSelect
                style={{ width: 300, marginRight: 10 }}
                value={this.state.value}
                dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                placeholder={getCurrentlocaleText(
                  "stats.scope.placeholder.text",
                )}
                treeDefaultExpandAll
                onChange={this.onScopeChange}
                dropdownClassName="iot-tree-selected-menu"
              >
                <TreeNode
                  value={node_data.id}
                  title={
                    <Link to={nodeurl}>
                      <span>
                        <Icons type="fa" name="FaRegHdd" />{" "}
                        {node_data.name ? node_data.name : null}
                      </span>
                    </Link>
                  }
                  key="0-1"
                >
                  {this.renderServiceList()}
                </TreeNode>
              </TreeSelect>
              <Select
                defaultValue={statsDuration}
                style={{ width: 160, marginRight: 10 }}
                onChange={this.handleDurationOptionChange}
                notFoundContent={getCurrentlocaleText(
                  "general.notfound.placeholder.text",
                )}
              >
                <Option value={"1"}>
                  {getCurrentlocaleText("netstats.selector.time.text", {
                    0: "5",
                  })}
                </Option>
                {/* LAT-11569 */}
                {/* <Option value={"2"}>
                  {" "}{getCurrentlocaleText("netstats.selector.time.text", {
                    0: "24",
                  })}
                </Option>
                <Option value={"3"}>
                  {getCurrentlocaleText("stats.filter.days.text", { 0: "2" })}
                </Option> */}
                <Option value={"4"}>
                  {getCurrentlocaleText("stats.filter.custom.text")}
                </Option>
              </Select>
              {this.NodeStatsViewModel.statsDuration === "4" && (
                <span style={{ display: "inline-grid" }}>
                  <RangePicker
                    showTime={{
                      hideDisabledOptions: true,
                      defaultValue: [moment("00:00:00", "HH:mm:ss")],
                    }}
                    format="YYYY-MM-DD HH:mm"
                    placeholder={[
                      getCurrentlocaleText("stats.fromto.placeholder.text", {
                        0: "Start",
                      }),
                      getCurrentlocaleText("stats.fromto.placeholder.text", {
                        0: "End",
                      }),
                    ]}
                    onChange={this.onRangeDateSelectorChange}
                    onOk={this.onRangeDateSelectorConfirm}
                    disabledDate={this.disabledDate}
                    disabledTime={this.disabledRangeTime}
                  />
                  {this.state.isInvalidRange && (
                    <span
                      style={{ color: "red", marginLeft: 10, marginTop: 2 }}
                    >
                      {getCurrentlocaleText(
                        "stats.fromto.invalid_range.error.text",
                      )}
                    </span>
                  )}
                </span>
              )}
            </span>
          )}
          {this.props.hideOptions && (
            <span>{getCurrentlocaleText("overview.text")}</span>
          )}
          {this.props.hideOptions && (
            <span style={{ float: "right", marginTop: 12 }}>
              {this.inodeModel.inode &&
                this.inodeModel.inode.node_state != "NEW" &&
                parseFloat(getNodeVersion(this.inodeModel.inode)) >=
                  DataSaverAndPodDnsBaseVersion && <InterfaceTrafficView />}
            </span>
          )}
        </Card>
        {/* <Row>
          <Col
            xs={{ span: 12 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 19 }}
            xl={{ span: 18 }}
          >
            {getCurrentlocaleText("stats.advanced.page.title.text")}
          </Col>
          <Col
            xs={{ span: 12 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 5 }}
            xl={{ span: 6, push: screen.width < 1500 ? 0 : 2 }}
            style={{ marginTop: "-1%", float: "right" }}
          >
            <span className="page-actions inode-action-card">
              <Dropdown overlay={this.menu} trigger={["hover"]}>
                <Button style={{ height: 46, border: "none", width: 260 }}>
                  {getCurrentlocaleText("stats.config.manage.button.text")}{" "}
                  <Icons type="ai" name="AiOutlineDown" />
                </Button>
              </Dropdown>
            </span>
          </Col>
        </Row> */}
        {/* <Card>
          <span>
            <TreeSelect
              style={{ width: 300, marginRight: 10 }}
              value={this.state.value}
              dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
              placeholder={getCurrentlocaleText("stats.scope.placeholder.text")}
              treeDefaultExpandAll
              onChange={this.onScopeChange}
              dropdownClassName="iot-tree-selected-menu"
            >
              <TreeNode
                value={node_data.id}
                title={
                  <Link to={nodeurl}>
                    <span>
                      <Icons type="fa" name="FaRegHdd" />{" "}
                      {node_data.name ? node_data.name : null}
                    </span>
                  </Link>
                }
                key="0-1"
              >
                {this.renderServiceList()}
              </TreeNode>
            </TreeSelect>
            <Select
              defaultValue={this.NodeStatsViewModel.statsDuration}
              style={{ width: 160, marginRight: 10 }}
              onChange={this.handleDurationOptionChange}
            >
              <Option value={"1"}>
                {getCurrentlocaleText("netstats.selector.time.text", {
                  0: "5",
                })}
              </Option>
              <Option value={"2"}>
                {" "}{getCurrentlocaleText("netstats.selector.time.text", {
                  0: "24",
                })}
              </Option>
              <Option value={"3"}>
                {getCurrentlocaleText("stats.filter.days.text", { 0: "2" })}
              </Option>
              <Option value={"4"}>
                {getCurrentlocaleText("stats.filter.custom.text")}
              </Option>
            </Select>
            {this.NodeStatsViewModel.statsDuration === "4" &&
              <span style={{ display: "inline-grid" }}>
                <RangePicker
                  showTime={{ format: "HH:mm" }}
                  format="YYYY-MM-DD HH:mm"
                  placeholder={[
                    getCurrentlocaleText("stats.fromto.placeholder.text", {
                      0: "Start",
                    }),
                    getCurrentlocaleText("stats.fromto.placeholder.text", {
                      0: "End",
                    }),
                  ]}
                  onChange={this.onRangeDateSelectorChange}
                  onOk={this.onRangeDateSelectorConfirm}
                  disabledTime={this.disabledRangeTime}
                  disabledDate={this.disabledDate}
                />
                {this.state.isInvalidRange &&
                  <span style={{ color: "red", marginLeft: 10, marginTop: 2 }}>
                    {getCurrentlocaleText(
                      "stats.fromto.invalid_range.error.text",
                    )}
                  </span>}
              </span>}
          </span>
          <span style={{ float: "right", marginTop: 12 }}>
            {this.inodeModel.inode &&
              this.inodeModel.inode.node_state != "NEW" &&
              parseFloat(getNodeVersion(this.inodeModel.inode)) >=
                DataSaverAndPodDnsBaseVersion &&
              <InterfaceTrafficView />}
          </span>
        </Card> */}
      </div>
    );
  }
}

export default StatsHeader;
