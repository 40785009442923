import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Menu, Dropdown, Button } from "antd";
import Icons from "components/UI-Components/Icons";
import ActionBtn from "Core/API/ActionBtn";
import { getCurrentlocaleText, copyObject } from "Core/Utils";
import OrgsForm from "components/Forms/OrgsForm";
import InodesForm from "components/Forms/InodesForm";
import UsersForm from "components/Forms/UsersForm";

import OrgController from "controller/OrgController";
import InodeController from "controller/InodeController";
import UserController from "controller/UserController";
import DashboardController from "controller/DashboardController";
import CursorMovementInModal from "../../CursorMovementInModal";
import AddService from "./AddService";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;
const iconStyle = {
  fontSize: 16,
  lineHeight: 1.3,
  marginRight: 8,
};

@inject("AuthStore", "UiStore", "OrgViewModel", "DashboardStore")
@observer
class QuickActionCreate extends Component {
  constructor(props) {
    super(props);
    // GLOBAL_SETTINGS configured from the node js
    this.GLOBAL_SETTINGS = GLOBAL_SETTINGS;
    this.state = {
      showAddServiceModal: false,
    };
  }

  isDashboardView = () => {
    // Check whether current view is dashboard or not
    let path = location.pathname.split("/");
    if (
      ((path[1] === "home" || path[1] === "dashboard") && !path[2]) ||
      (path[1] === "dashboard" && path[2] && !path[3])
    ) {
      return true;
    } else {
      return false;
    }
  };

  isiNodeDetailsView = () => {
    let path = location.pathname.split("/");
    if (
      path &&
      path[1] &&
      path[1] === "orgs" &&
      path[3] &&
      path[3] === "inodes" &&
      path.length >= 5
    ) {
      return true;
    } else {
      return false;
    }
  };

  orgList = () => {
    const currentOrgId =
      this.props.OrgViewModel.org.id != undefined
        ? this.props.OrgViewModel.org.id
        : this.props.AuthStore.loggedInUser.organization
        ? this.props.AuthStore.loggedInUser.organization.id
        : "";

    let params = {
      org_id: currentOrgId,
      own: true,
      page: 0,
    };

    // Check whether current view is dashboard or listing page
    if (this.isDashboardView()) {
      delete params.own;
      DashboardController.getOrgsCount(copyObject(params));
      this.props.DashboardStore.refreshActivity = true;
    } else {
      if (this.props.UiStore.showChildOrgs) {
        delete params.own;
      }
      params.search = this.props.UiStore.orgParams.searchText;
      let sortBy = this.props.UiStore.orgParams.sorterInfo.sort.by;
      let sortOrder = this.props.UiStore.orgParams.sorterInfo.sort.order;
      // For sort
      if (sortBy && sortOrder) {
        params.sort = `${sortBy}:${sortOrder}`;
      }
      OrgController.getOrgs(copyObject(params), true);
    }
  };

  inodeList = () => {
    const currentOrgId =
      this.props.OrgViewModel.org.id != undefined
        ? this.props.OrgViewModel.org.id
        : this.props.AuthStore.loggedInUser.organization
        ? this.props.AuthStore.loggedInUser.organization.id
        : "";

    let params = {
      org_id: currentOrgId,
      own: true,
      page: 0,
    };

    // Check whether current view is dashboard or listing page
    if (this.isDashboardView()) {
      delete params.own;
      this.updateNewNodeonDashboard(copyObject(params));
      this.props.DashboardStore.refreshActivity = true;
    } else {
      if (this.props.UiStore.showChildNodes) {
        delete params.own;
      }
      params.search = this.props.UiStore.nodeParams.searchText;
      let sortBy = this.props.UiStore.nodeParams.sorterInfo.sort.by;
      let sortOrder = this.props.UiStore.nodeParams.sorterInfo.sort.order;
      let filter = this.props.UiStore.nodeParams.filterInfo;
      // For sort
      if (sortBy && sortOrder) {
        params.sort = `${sortBy}:${sortOrder}`;
      }
      // For Filter
      if (filter) {
        let keys = Object.keys(filter);
        keys.forEach(key => {
          if (filter[key]) {
            params[key] = filter[key];
          }
        });
      }
      InodeController.getInodes(
        copyObject(params),
        true,
        this.isiNodeDetailsView() ? true : false,
      );
    }
  };
  /**
   * UPDATE DASHBOARD STORE FOR NEWLY ADDED NODES
   */
  updateNewNodeonDashboard = params => {
    DashboardController.getInodesCount(copyObject(params));
    DashboardController.getNetworksCount(copyObject(params));

    params.state = "NEW";
    DashboardController.getInodesCountByStatus(copyObject(params));

    // Edge with new
    params.profile_name = "edge";
    params.state = "NEW";
    DashboardController.getInodesCountByProfile(copyObject(params));

    // Virtual with new
    params.profile_name = "virtual";
    params.state = "NEW";
    DashboardController.getInodesCountByProfile(copyObject(params));

    // Virtual Edge with new
    params.profile_name = "virtual edge";
    params.state = "NEW";
    DashboardController.getInodesCountByProfile(copyObject(params));
  };

  userList = () => {
    const currentOrgId =
      this.props.OrgViewModel.org.id != undefined
        ? this.props.OrgViewModel.org.id
        : this.props.AuthStore.loggedInUser.organization
        ? this.props.AuthStore.loggedInUser.organization.id
        : "";

    let params = {
      org_id: currentOrgId,
      own: true,
      page: 0,
    };

    // Check whether current view is dashboard or listing page
    if (this.isDashboardView()) {
      delete params.own;
      DashboardController.getUsersCount(copyObject(params));
      this.props.DashboardStore.refreshActivity = true;
    } else {
      if (this.props.UiStore.showChildUsers) {
        delete params.own;
      }
      params.search = this.props.UiStore.userParams.searchText;
      let sortBy = this.props.UiStore.userParams.sorterInfo.sort.by;
      let sortOrder = this.props.UiStore.userParams.sorterInfo.sort.order;
      // For sort
      if (sortBy && sortOrder) {
        params.sort = `${sortBy}:${sortOrder}`;
      }
      UserController.getUsers(copyObject(params), true);
    }
  };

  render() {
    const currentOrgId =
      this.props.OrgViewModel.org.id != undefined
        ? this.props.OrgViewModel.org.id
        : this.props.AuthStore.loggedInUser.organization
        ? this.props.AuthStore.loggedInUser.organization.id
        : "";
    const menuItem = {
      height: 40,
      fontSize: 16,
      lineHeight: 1.3,
      background: "transparent",
    };
    const createMenu = (
      <Menu
        key="usermenu"
        component={CursorMovementInModal}
        theme={
          this.GLOBAL_SETTINGS && this.GLOBAL_SETTINGS.sidebar_mode
            ? this.GLOBAL_SETTINGS.sidebar_mode
            : "dark"
        }
      >
        {this.props.AuthStore.IsPermitted("ORG:CREATE") && (
          <Menu.Item key="addorg" style={menuItem}>
            {
              <span>
                <ActionBtn
                  title={
                    <span>
                      <Icons type="fa" name="FaSitemap" />
                      &nbsp;
                      {getCurrentlocaleText(
                        "org.create.button_title.text",
                      )} -{" "}
                      {this.props.OrgViewModel.org.name != undefined
                        ? this.props.OrgViewModel.org.name
                        : this.props.AuthStore.loggedInUser.organization
                        ? this.props.AuthStore.loggedInUser.organization.name
                        : ""}
                    </span>
                  }
                  resource="orgs"
                  icon="AiOutlinePlus"
                  action="create"
                  width={600}
                  orgId={currentOrgId}
                  actionForm={OrgsForm}
                  controller={OrgController}
                  afterCall={this.orgList}
                  style={{
                    border: "none",
                    lineHeight: 1.3,
                    fontWeight: "normal",
                    background: "transparent",
                  }}
                  isQuickAction={true}
                >
                  <Icons type="fa" name="FaSitemap" style={iconStyle} />
                  &nbsp;{getCurrentlocaleText("org.create.button_title.text")}
                </ActionBtn>
              </span>
            }
          </Menu.Item>
        )}
        {this.props.AuthStore.IsPermitted("NODE:CREATE") && (
          <Menu.Item key="addnode" style={menuItem}>
            {
              <span>
                <ActionBtn
                  title={
                    <span>
                      <Icons type="fa" name="FaRegHdd" />{" "}
                      {getCurrentlocaleText(
                        "node.list.table.add_inode.label.text",
                      )}
                      {" - "}
                      {this.props.OrgViewModel.org.name != undefined
                        ? this.props.OrgViewModel.org.name
                        : this.props.AuthStore.loggedInUser.organization
                        ? this.props.AuthStore.loggedInUser.organization.name
                        : ""}
                    </span>
                  }
                  resource="inodes"
                  icon="AiOutlinePlus"
                  action="create"
                  width={600}
                  orgId={currentOrgId}
                  actionForm={InodesForm}
                  afterCall={this.inodeList}
                  controller={InodeController}
                  style={{
                    border: "none",
                    lineHeight: 1.3,
                    fontWeight: "normal",
                    background: "transparent",
                  }}
                  isQuickAction={true}
                >
                  <Icons type="fa" name="FaRegHdd" style={iconStyle} />
                  &nbsp;
                  {getCurrentlocaleText("node.list.table.add_inode.label.text")}
                </ActionBtn>
              </span>
            }
          </Menu.Item>
        )}
        {this.props.AuthStore.IsPermitted("USER:CREATE") && (
          <Menu.Item key="adduser" style={menuItem}>
            {
              <span>
                <ActionBtn
                  title={
                    <span>
                      <Icons
                        type="md"
                        name="MdPerson"
                        style={{ fontSize: 16 }}
                      />{" "}
                      {getCurrentlocaleText("users.create.button_title.text")} -{" "}
                      {this.props.OrgViewModel.org.name != undefined
                        ? this.props.OrgViewModel.org.name
                        : this.props.AuthStore.loggedInUser.organization
                        ? this.props.AuthStore.loggedInUser.organization.name
                        : ""}
                    </span>
                  }
                  icon="AiOutlinePlus"
                  resource="users"
                  action="create"
                  width={600}
                  orgId={currentOrgId}
                  actionForm={UsersForm}
                  afterCall={this.userList}
                  controller={UserController}
                  noBorder={true}
                  style={{
                    border: "none",
                    fontWeight: "normal",
                    background: "transparent",
                  }}
                  isQuickAction={true}
                >
                  <Icons
                    type="md"
                    name="MdGroup"
                    style={iconStyle}
                    className="middle"
                  />
                  {getCurrentlocaleText("users.create.button_title.text")}
                </ActionBtn>
              </span>
            }
          </Menu.Item>
        )}
        {this.props.AuthStore.IsPermitted("SERVICE:CREATE") && (
          <>
            <Menu.Item key="addservice" style={menuItem}>
              {
                <span>
                  <div className="action-btn ">
                    <Button
                      title="Add Service"
                      style={{
                        border: "none",
                        lineHeight: 1.3,
                        fontWeight: "normal",
                        background: "transparent",
                      }}
                      onClick={() => {
                        this.setState({
                          showAddServiceModal: true,
                        });
                      }}
                    >
                      <Icons type="fa" name="FaCogs" className="middle" />
                      {getCurrentlocaleText("add.service")}
                    </Button>
                  </div>
                </span>
              }
            </Menu.Item>
            {this.state.showAddServiceModal && (
              <AddService
                orgId={currentOrgId}
                {...this.props}
                handleCancel={() =>
                  this.setState({ showAddServiceModal: false })
                }
              />
            )}
          </>
        )}
      </Menu>
    );
    return this.props.AuthStore.IsPermitted("NODE:CREATE") ||
      this.props.AuthStore.IsPermitted("USER:CREATE") ||
      this.props.AuthStore.IsPermitted("ORG:CREATE") ? (
      <span>
        {this.props.UiStore.isSiderFold ? (
          <Menu
            key="usermenu"
            theme={
              this.GLOBAL_SETTINGS && this.GLOBAL_SETTINGS.sidebar_mode
                ? this.GLOBAL_SETTINGS.sidebar_mode
                : "dark"
            }
          >
            <SubMenu
              key="users"
              title={
                <span>
                  <Button
                    className="quick-menu-button"
                    shape="circle"
                    size={"large"}
                    title={getCurrentlocaleText(
                      "general.navigation_menu.add.label.text",
                    )}
                    style={
                      !this.props.UiStore.isSiderFold ? { float: "left" } : {}
                    }
                  >
                    {" "}
                    <Icons
                      type="fa"
                      name="FaPlus"
                      style={{
                        fontSize: 20,
                      }}
                      className="middle quick-icon-style"
                    />
                  </Button>
                </span>
              }
            >
              {this.props.AuthStore.IsPermitted("ORG:CREATE") && (
                <Menu.Item key="addorg" style={menuItem}>
                  {
                    <span>
                      <ActionBtn
                        title={
                          <span>
                            <Icons type="fa" name="FaSitemap" />
                            &nbsp;
                            {getCurrentlocaleText(
                              "org.create.button_title.text",
                            )}{" "}
                            -{" "}
                            {this.props.OrgViewModel.org.name != undefined
                              ? this.props.OrgViewModel.org.name
                              : this.props.AuthStore.loggedInUser.organization
                              ? this.props.AuthStore.loggedInUser.organization
                                  .name
                              : ""}
                          </span>
                        }
                        resource="orgs"
                        icon="AiOutlinePlus"
                        action="create"
                        width={600}
                        orgId={currentOrgId}
                        actionForm={OrgsForm}
                        controller={OrgController}
                        afterCall={this.orgList}
                        style={{
                          border: "none",
                          background: "transparent",
                        }}
                        isQuickAction={true}
                      >
                        <Icons type="fa" name="FaSitemap" />
                        &nbsp;
                        {getCurrentlocaleText("org.create.button_title.text")}
                      </ActionBtn>
                    </span>
                  }
                </Menu.Item>
              )}
              {this.props.AuthStore.IsPermitted("NODE:CREATE") && (
                <Menu.Item key="addnode" style={menuItem}>
                  {
                    <span>
                      <ActionBtn
                        title={
                          <span>
                            <Icons type="fa" name="FaRegHdd" />{" "}
                            {getCurrentlocaleText(
                              "node.list.table.add_inode.label.text",
                            )}
                            -{" "}
                            {this.props.OrgViewModel.org.name != undefined
                              ? this.props.OrgViewModel.org.name
                              : this.props.AuthStore.loggedInUser.organization
                              ? this.props.AuthStore.loggedInUser.organization
                                  .name
                              : ""}
                          </span>
                        }
                        resource="inodes"
                        icon="AiOutlinePlus"
                        action="create"
                        width={600}
                        orgId={currentOrgId}
                        actionForm={InodesForm}
                        afterCall={this.inodeList}
                        controller={InodeController}
                        style={{
                          border: "none",
                          background: "transparent",
                        }}
                        isQuickAction={true}
                      >
                        <Icons type="fa" name="FaRegHdd" />
                        &nbsp;{" "}
                        {getCurrentlocaleText(
                          "node.list.table.add_inode.label.text",
                        )}
                      </ActionBtn>
                    </span>
                  }
                </Menu.Item>
              )}
              {this.props.AuthStore.IsPermitted("USER:CREATE") && (
                <Menu.Item key="adduser" style={menuItem}>
                  {
                    <span>
                      <ActionBtn
                        title={
                          <span>
                            <Icons
                              type="md"
                              name="MdPerson"
                              style={{ fontSize: 16 }}
                            />{" "}
                            {getCurrentlocaleText(
                              "users.create.button_title.text",
                            )}
                            -{" "}
                            {this.props.OrgViewModel.org.name != undefined
                              ? this.props.OrgViewModel.org.name
                              : this.props.AuthStore.loggedInUser.organization
                              ? this.props.AuthStore.loggedInUser.organization
                                  .name
                              : ""}
                          </span>
                        }
                        icon="AiOutlinePlus"
                        resource="users"
                        action="create"
                        width={600}
                        orgId={currentOrgId}
                        actionForm={UsersForm}
                        afterCall={this.userList}
                        controller={UserController}
                        noBorder={true}
                        style={{
                          border: "none",
                          background: "transparent",
                        }}
                        isQuickAction={true}
                      >
                        <Icons
                          type="md"
                          name="MdGroup"
                          style={iconStyle}
                          className="middle"
                        />
                        {getCurrentlocaleText("users.create.button_title.text")}
                      </ActionBtn>
                    </span>
                  }
                </Menu.Item>
              )}
            </SubMenu>
          </Menu>
        ) : (
          <Dropdown size="small" trigger={["click"]} overlay={createMenu}>
            <Button
              className="quick-menu-button"
              shape="circle"
              size={"large"}
              title={getCurrentlocaleText(
                "general.navigation_menu.add.label.text",
              )}
              style={!this.props.UiStore.isSiderFold ? { float: "left" } : {}}
            >
              {" "}
              <Icons
                type="fa"
                name="FaPlus"
                style={{
                  marginLeft: this.props.UiStore.isSiderFold ? 20 : 0,
                }}
                className="middle quick-icon-style"
              />
            </Button>
          </Dropdown>
        )}
      </span>
    ) : null;
  }
}
export default QuickActionCreate;
