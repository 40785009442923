import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { Button, Modal, Row, Col, Alert, Icon, Tag } from "antd";
import Input from "components/UI-Components/InputBox";
import HelpCard from "components/UI-Components/HelpCard";
import propTypes, { element } from "prop-types";
import { getCurrentlocaleText } from "../Utils";
import Icons from "components/UI-Components/Icons";

@inject("UiStore")
@observer
class ActionBtn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formValues: {},
      showFormModal: false,
      action: this.props.action,
      autoOpenModel: this.props.autoOpenModel,
      help: this.props.Help != undefined ? this.props.Help : false,
      loading: false,
      currentResource: null,
      isDeleteEnabled: false,
      showConvertToClusConfirm: false,
      clusterFormData: {},
      clusterMap: "",
    };
    this.autoOpenModal();
    this.deleteResource = this.deleteResource.bind(this);
    this.UiStore = this.props.UiStore;
    this.validateForm = this.validateForm.bind(this);
  }

  /* 'Validate Form Function: Validates the form
    -------------------------------------------------------------- */
  validateForm = (e, formFunction) => {
    if (this.props.action.toLowerCase() !== "delete") {
      e.preventDefault();

      this.actionForm.validateFields((err, values) => {
        if (!err) {
          this.setState({ formValues: values });
          if (this.props.overide) {
            this.props.overide(values);
          } else {
            if (this.props.beforeCall) {
              let computedValue = this.props.beforeCall(values);
              values = computedValue;
            }
            this.submitForm(values);
          }
          return values;
        }
      });
    } else {
      if (!this.props.overide) {
        if (this.props.beforeCall) {
          this.props.beforeCall();
        }
        this.submitForm();
      }
    }
  };

  /* 'Submits Form Function: Submits the form and does API Actions
    -------------------------------------------------------------- */
  submitForm = values => {
    const formData = values,
      resource = this.props.resource,
      action = this.props.action,
      map = this.props.map,
      params = this.props.params,
      rebootableNodes = this.props.rebootableNodes,
      nodes = this.props.nodes;

    // Implementate loader
    this.setState({ loading: true });

    // Extend formdata from props if present
    if (this.props.extendData) {
      Object.assign(formData, this.props.extendData);
    }
    switch (this.props.action.toLowerCase()) {
      case "create":
        this.createResource(resource, formData, params);
        break;

      case "edit":
        this.editResource(resource, formData, map, params);
        break;

      case "view":
        this.editResource(resource, formData, map, params);
        break;
      case "delete":
        this.deleteResource(resource, formData, map, params);
        break;
      case "reboot":
        this.rebootNode(rebootableNodes);
        break;
      case "scannownodedad":
      case "scandad":
        this.scanNowNodeDad(nodes);
        break;
      case "converttocluster":
        this.convertToClusterConfirm(resource, formData, map);
        break;
      default:
        break;
    }
  };

  rebootNode = rebootableNodes => {
    this.props.controller
      .reboot(rebootableNodes)
      .then(responses => {
        responses.forEach(response => {
          // do nothing
        });
        this.closeModal();
        if (this.props.afterCall) {
          this.props.afterCall();
        }
      })
      .catch(error => {
        this.setState({ loading: false });
      });
  };
  scanNowNodeDad = nodes => {
    this.props.controller
      .scanNowNodeDad(nodes)
      .then(responses => {
        this.closeModal();
        if (this.props.afterCall) {
          this.props.afterCall();
        }
      })
      .catch(error => {
        this.setState({ loading: false });
      });
  };

  createResource = (resource, formData, params) => {
    //Extra params
    if (this.props.orgId) {
      Object.assign(formData, { create_under: this.props.orgId });
    }
    if (resource === "pki" && formData.hardware_serial_number) {
      formData["hardware_serial_number"] = formData.hardware_serial_number
        .trim()
        .replace(/;/g, ",")
        .replace(/ /g, ",")
        .replace(/\n/g, ",")
        .split(",")
        .filter(Boolean);
    }

    if (resource === "users" && formData.confirm_password) {
      delete formData.confirm_password;
    }

    // For inodes creation only
    let cloud_config;
    if (resource === "inodes" && formData.cloud_config) {
      cloud_config = formData.cloud_config;
      delete formData.cloud_config;
    }
    this.props.controller
      .create(formData, params)
      .then(response => {
        this.setState({ okText: this.props.children });
        //Download file req
        if (
          this.props.controller.downloadInodeCert &&
          !formData.hardware_serial_number
        ) {
          this.props.controller
            .downloadInodeCert(response.id, cloud_config, formData)
            .then(resp => {
              this.successCallback();
            });
        } else {
          //Success callback
          this.successCallback();
        }
        return new Promise((resolve, reject) => {
          resolve(response);
        });
      })
      .then(response => {
        if (this.props.afterCall) {
          this.props.afterCall(response);
        }
      })
      .catch(error => {
        this.setState({ loading: false });
      });
  };

  successCallback = () => {
    this.setState({ showFormModal: false, loading: false });
    this.UiStore[this.props.resource].showFormModal = false;
    this.closeModal();
    if (this.props.afterCall) {
      this.props.afterCall();
    }
  };

  editResource = (resource, formData, map, params) => {
    //Extra params
    if (this.props.orgId) {
      Object.assign(formData, { create_under: this.props.orgId });
    }

    this.props.controller
      .update(formData, map, params)
      .then(response => {
        //Success callback
        return new Promise((resolve, reject) => {
          resolve(response);
        });
      })
      .then(response => {
        this.closeModal();
        if (this.props.afterCall) {
          this.props.afterCall(response);
        }
      })
      .catch(error => {
        this.setState({ loading: false });
      });
  };

  convertToClusterConfirm(resource, formData, map) {
    if (!this.props.showClose) {
      this.setState({
        showConvertToClusConfirm: true,
        clusterFormData: formData,
        clusterMap: map,
      });
    } else {
      this.closeModal();
    }
  }

  convertToCluster = () => {
    this.props.controller
      .converttocluster(this.state.clusterFormData, this.state.clusterMap)
      .then(response => {
        //Success callback
        return new Promise((resolve, reject) => {
          resolve(response);
        });
      })
      .then(response => {
        this.closeModal();
        if (this.props.afterCall) {
          this.props.afterCall(response);
        }
        this.closeCluster();
      })
      .catch(error => {
        this.closeCluster();
      });
  };

  closeCluster = () => {
    this.setState({
      loading: false,
      showConvertToClusConfirm: false,
    });
  };

  deleteResource = (resource, formData, map, params) => {
    if (this.props.type !== "high") {
      this.props.controller
        .delete(!map ? this.props.formData : [map], params)
        .then(response => {
          this.UiStore[this.props.resource].showFormModal = false;
          this.props.afterCall ? this.props.afterCall(response) : "";
          this.closeModal();
        })
        .catch(error => {
          this.setState({ loading: false });
        });
    } else {
      if (
        this.state.currentResource != null &&
        this.props.formData.name === this.state.currentResource
      ) {
        this.props.controller
          .delete(!map ? this.props.formData : [map], params)
          .then(response => {
            this.UiStore[this.props.resource].showFormModal = false;
            this.props.afterCall ? this.props.afterCall(response) : "";
            this.closeModal();
          })
          .catch(error => {
            this.setState({ loading: false });
          });
      } else {
        this.setState({ isDeletefailed: true, loading: false });
      }
    }
  };

  closeModal = () => {
    this.mounted &&
      this.setState({
        showFormModal: false,
        loading: false,
        isDeleteEnabled: false,
      });
    this.props.closeModal ? this.props.closeModal() : "";
    this.UiStore.closeFormModal(this.props.resource);
    if (this.props.afterCall && this.props.action !== "delete") {
      this.props.afterCall(this.props.resource);
    }
  };

  showModal = () => {
    setTimeout(() => {
      this.setState({ showFormModal: true, loading: false });

      if (this.UiStore) {
        this.UiStore.showFormModal(this.props.resource);
      }

      if (
        (this.props.changeRoute &&
          this.props &&
          this.props.initialVal &&
          this.props.initialVal.id) ||
        (this.props.changeRoute &&
          this.props.action &&
          this.props.action === "create")
      ) {
        this.props.changeRoute(
          this.props.resource,
          this.props.action,
          this.props.initialVal && this.props.initialVal.id
            ? this.props.initialVal.id
            : null,
        );
      } else if (
        this.props.changeRoute &&
        this.props &&
        this.props.formData &&
        this.props.formData.id
      ) {
        this.props.changeRoute(
          this.props.resource,
          this.props.action,
          this.props.formData && this.props.formData.id
            ? this.props.formData.id
            : null,
        );
      } else if (
        this.props.changeRoute &&
        this.props &&
        this.props.id &&
        this.props.resource === "firewallgroup"
      ) {
        this.props.changeRoute(
          this.props.resource,
          this.props.action,
          this.props.id,
        );
      }
    }, 1000);
  };

  UNSAFE_componentWillMount(prevProps, prevState) {
    if (!this.props.isQuickAction) {
      this.props.UiStore.SetStoreData("helpData", {});
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.forceClose) {
      this.successCallback();
    }
  }

  autoOpenModal = () => {
    if (this.props.autoOpenModel) {
      this.showModal();
    }
    this.mounted = true;
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.autoOpenModel !== this.props.autoOpenModel) {
      if (this.props.autoOpenModel) {
        this.autoOpenModal();
      }
    }
  }

  componentDidMount() {
    if (this.props.autoOpen) {
      this.showModal();
    }
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  renderDelete = () => {
    //set to array if props is an object
    if (this.props.formData) {
      let arr =
        this.props.formData.constructor.name !== "Array"
          ? [this.props.formData]
          : this.props.formData;
      let resource = this.props.resource,
        message = this.props.messageContent
          ? this.props.messageContent
          : "Do you really want to delete?";
      let warningMessage = this.props.warningMessage;
      let bottomConfirmationDisable = this.props.bottomConfirmationDisable;
      switch (resource) {
        case "nodes":
          resource = "Nodes";
          break;
        case "pki":
          resource = "serial numbers";
          break;
        case "secret":
          resource = "secrets";
          break;
        case "firewallgroup":
          resource = "firewallgroups";
          break;
        case "service":
          resource = "services";
          break;
        case "image":
          resource = "Images";
          break;
        case "certificates":
          resource = "SSH keys";
          break;
        case "listener":
          resource = "listener";
          break;
      }

      if (
        this.props.controller.getTotalCount &&
        this.props.resource &&
        !this.props.messageContent
      ) {
        let totalCount = this.props.controller.getTotalCount(
          this.props.isChildOrg || this.props.secretType,
        );
        if (arr.length > 1 && resource !== "users" && resource !== "listener") {
          message = `You are about to delete ${arr.length} out of ${totalCount} available ${resource} permanently.`;
        }
      }
      return (
        <div>
          <h4>{message}</h4>
          <div>
            {arr.map((val, i) => {
              return val.name ? (
                <Tag
                  key={i}
                  title={val.name}
                  className="tag-iotium-info tag-iotium-ellipse"
                >
                  {val.name}
                </Tag>
              ) : null;
            })}
          </div>
          {this.props.warningMessage && <br />}
          {this.props.warningMessage && (
            <Alert
              description={warningMessage}
              type="warning"
              showIcon
              icon={
                <Icons
                  style={{ margin: 0, fontSize: 32 }}
                  type="fa"
                  name="FaExclamationCircle"
                />
              }
            />
          )}
          {this.props.warningMessage && <br />}
          {resource != "downloads" && !bottomConfirmationDisable && (
            <h4>{"Are you sure you want to delete?"}</h4>
          )}
        </div>
      );
    }
  };

  renderMasterdelete = () => {
    //set to array if props is an object
    if (this.props.formData) {
      let arr =
        this.props.formData.constructor.name !== "Array"
          ? [this.props.formData]
          : this.props.formData;
      let resource = this.props.resource;
      let warningMessage = this.props.warningMessage;
      switch (resource) {
        case "nodes":
          resource = "Nodes";
          break;
        case "pki":
          resource = "serial numbers";
          break;
        case "secret":
          resource = "secrets";
          break;
        case "firewallgroup":
          resource = "firewallgroups";
          break;
        case "service":
          resource = "services";
          break;
        case "orgs":
          resource = "Organization";
          break;
      }
      let message = this.props.messageContent
        ? this.props.messageContent
        : "Do you really want to delete?";
      return (
        <div>
          <h4>{message}</h4>
          {arr.map((val, i) => {
            return val.name ? (
              <Tag
                key={i}
                title={val.name}
                className="tag-iotium-info tag-iotium-ellipse"
              >
                {val.name}
              </Tag>
            ) : null;
          })}
          {this.props.warningMessage && (
            <Alert
              description={warningMessage}
              type="warning"
              showIcon
              icon={
                <Icons
                  style={{ margin: 0, fontSize: 32 }}
                  type="fa"
                  name="FaExclamationCircle"
                />
              }
            />
          )}
          <br />
          <span>
            <p>
              {"If you wish to continue, please type the name of the " +
                resource +
                " "}
              {" to confirm."}
            </p>
          </span>
          <div>
            {arr.map((val, i) => {
              return (
                <span key={i}>
                  <Input
                    placeholder="Type the Name"
                    id={"currentValue"}
                    key={i}
                    onKeyUp={e => {
                      var ResourceValue = document.getElementById(
                        "currentValue",
                      );
                      var name = ResourceValue.value;
                      if (name === "") this.setState({ isDeletefailed: false });
                      this.setState({ currentResource: name });
                      if (name === this.props.formData.name)
                        this.setState({ isDeleteEnabled: true });
                      else this.setState({ isDeleteEnabled: false });
                    }}
                  />
                  {this.state.isDeletefailed && (
                    <p style={{ color: "red" }}>Name not found</p>
                  )}
                </span>
              );
            })}
          </div>
        </div>
      );
    }
  };

  renderiNodeDelete = () => {
    if (this.props.formData) {
      let formData =
        this.props.formData.constructor.name !== "Array"
          ? this.props.formData
          : this.props.formData[0];
      return (
        <div>
          <Row>
            <Col span={5}>
              {getCurrentlocaleText("inode.delete.inode.name.text")}:
            </Col>
            <Col>{formData.name}</Col>
          </Row>
          <Row className="mt-10">
            <Col span={5}>
              {getCurrentlocaleText("inode.delete.serialnumber.text")}:
            </Col>
            <Col>{formData.hardware_serial_number}</Col>
          </Row>
          <Row className="mt-15">
            <Col span={8}>
              <Input
                placeholder={getCurrentlocaleText(
                  "inode.delete.serialnumber.placeholder",
                )}
                id={"currentValue"}
                onKeyUp={e => {
                  var resourceValue = document.getElementById("currentValue");
                  if (
                    resourceValue &&
                    resourceValue.value === formData.hardware_serial_number
                  ) {
                    this.setState({ isDeleteEnabled: true });
                  } else {
                    this.setState({ isDeleteEnabled: false });
                  }
                }}
              />
            </Col>
          </Row>
          <Row className="mt-30">
            <Col span={24}>
              <Row>
                <Col>
                  {getCurrentlocaleText("inode.delete.container1.message")}
                </Col>
              </Row>
              <Row>
                <Col>
                  <ul>
                    <li>
                      {getCurrentlocaleText("inode.delete.container1.item1")}
                    </li>
                    <li>
                      {getCurrentlocaleText("inode.delete.container1.item2")}
                    </li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-15">
            <Col span={24}>
              <Row>
                <Col>
                  {getCurrentlocaleText("inode.delete.container2.message")}
                </Col>
              </Row>
              <Row>
                <Col>
                  <ul>
                    <li>
                      {getCurrentlocaleText("inode.delete.container2.item1")}
                    </li>
                    <li>
                      {getCurrentlocaleText("inode.delete.container2.item2")}
                    </li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      );
    }
  };

  renderNetworkDelete = () => {
    //set to array if props is an object
    if (this.props.formData) {
      let formData =
        this.props.formData.constructor.name !== "Array"
          ? this.props.formData
          : this.props.formData[0];
      return (
        <div>
          <Row>
            <Col span={5}>
              {getCurrentlocaleText("network.delete.network.name.text")}:
            </Col>
            <Col>{formData.name}</Col>
          </Row>
          <Row className="mt-30">
            <Col span={24}>
              <Row>
                <Col>
                  {getCurrentlocaleText("network.delete.container.message")}
                </Col>
              </Row>
              <Row>
                <Col>
                  <ul>
                    <li>
                      {getCurrentlocaleText("network.delete.container.item1")}
                    </li>
                    <li>
                      {getCurrentlocaleText("network.delete.container.item2")}
                    </li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      );
    }
  };

  render() {
    const ActionForm = this.props.actionForm;

    let footer = [
      <Button
        key="cancel"
        onClick={this.closeModal}
        disabled={this.state.loading}
      >
        {this.props.cancelText ? this.props.cancelText : "Cancel"}
      </Button>,
      <Button
        key="submit"
        type={
          (this.props.type && this.props.type.toLowerCase() === "high") ||
          (this.props.custom && !this.props.isDeleteEnabled)
            ? !this.state.isDeleteEnabled
              ? ""
              : "primary"
            : "primary"
        }
        onClick={
          this.props.action === "view" ? this.closeModal : this.validateForm
        }
        disabled={
          !this.props.isDeleteEnabled &&
          !this.state.isDeleteEnabled &&
          ((this.props.type && this.props.type.toLowerCase() === "high") ||
            this.props.custom)
            ? true
            : this.props.disableSubmit
            ? true
            : false
        }
        loading={this.state.loading ? this.state.loading : this.props.loading}
      >
        {"  "}
        {this.props.okText ? this.props.okText : this.props.children}
      </Button>,
    ];

    if (this.props.action === "view") {
      footer.splice(0, 1);
    }
    if (this.props.showClose) {
      footer.splice(0, 1);
    }
    return (
      <div className="action-btn ">
        <Button
          disabled={this.props.disabled}
          title={
            this.props.HoverText && this.props.HoverText.props
              ? this.props.HoverText.props.children
              : this.props.title.props
              ? this.props.title.props.children[
                  this.props.title.props.children.length - 1
                ]
              : this.props.title
          }
          type={this.props.type}
          onClick={this.showModal}
          style={this.props.style}
          onMouseEnter={this.props.onMouseEnter}
          size={this.props.size}
          className={this.props.className ? this.props.className : ""}
        >
          {this.props.iconButton ? (
            <span>
              <Icons
                type="ai"
                className={this.props.iconClassName}
                name={this.props.icon}
                style={this.props.iconBtnStyle}
              />
            </span>
          ) : (
            <span>{this.props.children}</span>
          )}

          {/*name for button*/}
          {this.props.buttonName ? this.props.buttonName : ""}
        </Button>
        {this.state.showFormModal ? (
          <Modal
            className="action-modal"
            wrapClassName="action-modal-wrap"
            title={this.props.title}
            visible={this.UiStore[this.props.resource].showFormModal}
            maskClosable={false}
            okText={this.props.children}
            onOk={this.validateForm}
            footer={footer}
            onCancel={this.closeModal}
            width={this.props.width ? this.props.width : 800}
            confirmLoading={
              this.state.loading ? this.state.loading : this.props.loading
            }
            closable={
              this.state.loading ? !this.state.loading : !this.props.loading
            }
            {...this.props}
          >
            {/* Show delete modal  */}
            {this.props.action.toLowerCase() === "delete" ? (
              <div>
                {/* <p>Do you really want to delete?</p> */}
                <div>
                  {this.props.type && this.props.type.toLowerCase() === "high"
                    ? this.renderMasterdelete()
                    : this.props.custom === true &&
                      this.props.resource === "inodes"
                    ? this.renderiNodeDelete()
                    : this.props.custom === true &&
                      this.props.resource === "networks"
                    ? this.renderNetworkDelete()
                    : this.renderDelete()}
                </div>
              </div>
            ) : (
              <Row gutter={24} type="flex">
                {/*TODO - TS Need to change the help state change method to OOPS method */}
                <Col span={this.state.help ? 14 : 24}>
                  {/*-----Show edit button --------  */}
                  {/* <div className="form-actions">
                                    {this.props.action === 'edit' && this.props.formData ?
                                        <Button className="edit-btn" size="small" type="primary"><Icons type="ai" name="AiOutlineEdit" /> Edit</Button>
                                        : null}
                                </div> */}
                  {/*------ Render Form-----------  */}
                  <ActionForm
                    {...this.props}
                    ref={c => {
                      this.actionForm = c;
                    }}
                    startFormFn={this.state.startFormFn}
                    onSubmit={this.validateForm}
                    onCancel={this.closeModal}
                  />
                </Col>
                {this.state.help ? <HelpCard /> : null}
              </Row>
            )}
          </Modal>
        ) : null}

        {this.state.showConvertToClusConfirm && (
          <Modal
            title={getCurrentlocaleText(
              "inode.convert_clus.warning.modal.title",
            )}
            visible={true}
            onOk={this.convertToCluster}
            onCancel={this.closeCluster}
            closable={false}
            maskClosable={false}
            okText={"Yes - Convert"}
            cancelText={"No - Don't Convert"}
          >
            {getCurrentlocaleText("inode.convert_clus.warning.modal.text")}
          </Modal>
        )}
      </div>
    );
  }
}

ActionBtn.propTypes = {
  action: propTypes.string.isRequired,
  resource: propTypes.string,
  orgId: propTypes.string,
  params: propTypes.object,
  map: propTypes.object,
  cache: propTypes.bool,
  autoOpenModel: propTypes.bool,
  afterCall: propTypes.func,
  onCancel: propTypes.func,
  beforeCall: propTypes.func,
  overide: propTypes.func,
  title: propTypes.any,
  icon: propTypes.string,
};

export default ActionBtn;
