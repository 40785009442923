import React, { Component } from "react";
import { Card, Row, Col, Button } from "antd";
import { observer, inject } from "mobx-react";
import axios from "axios";
import {
  getInitMFAUrl,
  getConfirmMFAUrl,
  getCurrentlocaleText,
} from "Core/Utils.js";
import DarkAppInfo from "components/UI-Components/DarkAppInfo";

import TwoFactorSetup from "../UI-Components/TwoFactorSetup";
import UserController from "controller/UserController";

@inject("ModelStore", "UiStore", "AuthStore")
@observer
class TwoFactorSetupPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMe: true,
      show2FA: false,
      is2FaEnabled: false,
      current_uri: "https://neeve.ai/",
    };
  }

  componentDidMount() {
    // Redirect to login page if 2fa url is not present in the session storage.
    if (!getInitMFAUrl() && !getConfirmMFAUrl()) {
      this.loadLoginPage();
      this.props.UiStore.errorMessage = getCurrentlocaleText(
        "user.two_factor_link.failure_message",
      );
      this.props.UiStore.isForceMessage = true;
    } else {
      setTimeout(() => {
        this.initiate2Fa();
      }, 1000);
    }
  }
  handleCancel = () => {
    this.loadLoginPage();
  };

  loadLoginPage = () => {
    if (this.props.history) {
      if (sessionStorage.confirm2FAUrl) {
        delete sessionStorage.confirm2FAUrl;
      }
      this.props.history.push("/login");
    }
  };
  /**
   * Intiate 2FA
   */
  initiate2Fa = () => {
    let url = getInitMFAUrl();
    if (url) {
      axios
        .post(url)
        .then(response => {
          if (response) {
            let current_uri = response.data.uri;
            if (sessionStorage.init2FAUrl) {
              delete sessionStorage.init2FAUrl;
            }
            if (!this.state.is2FaEnabled)
              this.setState({
                show2FA: true,
                current_uri: current_uri,
              });
            else this.setState({ is2FaEnabled: false });
          }
        })
        .catch(error => {
          if (error && error.response) {
            this.props.UiStore.errorMessage = error.response.data
              ? error.response.data.message
              : "Error";
          }
          setTimeout(() => {
            this.loadLoginPage();
          }, 100);
        });
    }
  };
  /**
   * Confirm 2FA
   */
  Setup2FaOTP = otp => {
    let params = { otp: Number(otp) };
    let url = getConfirmMFAUrl();
    if (url) {
      axios
        .post(url, params)
        .then(res => {
          this.props.UiStore.successMessage =
            "Two Factor Authentication configured successfully";
          if (!this.state.is2FaEnabled) {
            this.setState({
              show2FA: !this.state.show2FA,
            });
          } else {
            this.setState({ is2FaEnabled: false });
          }
          setTimeout(() => {
            this.loadLoginPage();
          }, 1000);
        })
        .catch(error => {
          if (error && error.response) {
            this.props.UiStore.errorMessage = error.response.data
              ? error.response.data.message
              : "Error";
          }
        });
    }
  };
  render() {
    return (
      <Row type="flex" className="login-card" align="middle" justify="center">
        <Col
          className="login-col"
          lg={{ span: 6 }}
          sm={{ span: 10 }}
          md={{ span: 10 }}
          xs={{ span: 16 }}
        >
          <Card>
            <Row type="flex" align="middle" justify="center">
              <center>
                <DarkAppInfo />
              </center>
            </Row>
            <Row type="flex" align="middle" justify="center">
              <p style={{ margin: "15px 0 15px", textAlign: "center" }}>
                <b>
                  {this.props.UiStore.force2FaMessage &&
                    this.props.UiStore.force2FaMessage}
                </b>
              </p>
            </Row>
            <TwoFactorSetup
              onFinish={otp => {
                this.Setup2FaOTP(otp);
              }}
              onCancel={() => {
                this.loadLoginPage();
              }}
              current_uri={this.state.current_uri}
              visibility={this.state.show2FA}
              fromLayout={true}
            />
          </Card>
        </Col>
      </Row>
    );
  }
}

export default TwoFactorSetupPage;
