import React, { Component } from "react";
import { Row, Col } from "antd";
import { observer, inject } from "mobx-react";
import LoadingComponent from "components/UI-Components/LoadingComponent";
import BreadcrumbComponent from "components/UI-Components/BreadcrumbComponent";
import Icons from "components/UI-Components/Icons";
import InodeController from "controller/InodeController";
import OrgSelector from "components/Orgs/OrgSelector";
import moment from "moment";
import LogsContainer from "components/Logs/Components/LogsContainer";
import LogController from "controller/LogController";
import FileSaver from "file-saver";
import { getDefaultTimezone } from "Core/Utils";

@inject(
  "AuthStore",
  "UiStore",
  "LogViewModel",
  "InodeViewModel",
  "TimezoneViewModel",
)
@observer
class InodeLogs extends Component {
  constructor(props) {
    super(props);
    this.logViewModel = this.props.LogViewModel;
    this.inodeModel = this.props.InodeViewModel;

    this.state = {
      nodeId: this.props.match.params.nodeId || this.props.match.params.id,
      BreadcrumbList: [],
      nodeDetails: {},
      triggerAutoScrollBottom: false,
      triggerAutoScrollTop: false,
      isAutoloaded: false,
    };
    this.color = {};
  }

  UNSAFE_componentWillMount() {
    this.logViewModel.resetLogs();
    this.logViewModel.setLoading(true);
  }

  componentDidMount() {
    this.iNodeDetails(null, true, true);
  }

  /**
   *  Get current iNode details for reference
   */
  iNodeDetails = (res = null, loading = true, forceCall = false) => {
    let map = {
      id: res !== null ? res.id : this.state.nodeId,
    };
    InodeController.getInode(map.id, loading, forceCall).then(res => {
      this.setState({ nodeDetails: res });
      return this.updateInodeDetails(res);
    });
  };
  /**
   *  Collect newest log
   */
  collectNewLogs = (autoScroll = false) => {
    let params = {};
    params.logFilePosition = "end";
    params.referenceTimestamp = "newest";
    params.offsetFrom = 2000000000;
    params.offsetTo = 2000000100;
    this.getLogData("inode_logs", params, true, autoScroll, false);
  };

  /**
   *  Collect oldest log
   */
  collectOldLogs = (autoScroll = false) => {
    let params = {};
    params.logFilePosition = "beginning";
    params.referenceTimestamp = "oldest";
    params.offsetFrom = -2000000100;
    params.offsetTo = -2000000000;
    this.getLogData("inode_logs", params, true, false, autoScroll);
  };

  /**
   *  Collect previous log, 1 step backward
   */
  collectPreviousLogs = () => {
    let params = {};
    params.logFilePosition = "end";
    params.referenceTimestamp = this.logViewModel.getLogPreviousReferenceTime;
    params.offsetFrom = this.logViewModel.getLogPreviousOffsetFrom;
    params.offsetTo = this.logViewModel.getLogPreviousOffsetTo;
    if (this.logViewModel.getLogPrevLineNum)
      params.referenceLineNum = this.logViewModel.getLogPrevLineNum;
    if (
      this.logViewModel.getLogPreviousReferenceTime &&
      this.logViewModel.getLogPreviousOffsetFrom &&
      this.logViewModel.getLogPreviousOffsetTo &&
      !this.logViewModel.movePointerToOldest
    )
      this.getPrevLogs("inode_logs", params, true);
    else if (this.logViewModel.movePointerToOldest) this.collectOldLogs();
  };

  /**
   *  Collect next recent log, 1 step forward
   */
  collectForwardLogs = () => {
    let params = {};
    params.logFilePosition = "end";
    params.referenceTimestamp = this.logViewModel.getLogNextReferenceTime;
    params.offsetFrom = this.logViewModel.getLogForwardOffsetFrom;
    params.offsetTo = this.logViewModel.getLogForwardOffsetTo;
    if (this.logViewModel.getLogNextLineNum)
      params.referenceLineNum = this.logViewModel.getLogNextLineNum;
    if (
      this.logViewModel.getLogNextReferenceTime &&
      this.logViewModel.getLogForwardOffsetFrom &&
      this.logViewModel.getLogForwardOffsetTo &&
      !this.logViewModel.movePointerToLatest
    )
      this.getNextLogs("inode_logs", params, true);
    else if (this.logViewModel.movePointerToLatest) this.collectNewLogs();
  };
  //
  getNextLogs = (resource, params = {}, loading = false) => {
    let map = {};
    map.node_id =
      this.state.nodeDetails && this.state.nodeDetails.id
        ? this.state.nodeDetails.id
        : this.state.nodeId;
    LogController.getForwardLogMessages(resource, map, params, loading).then(
      response => {
        this.setState({ isAutoloaded: true });
      },
    );
  };
  /**
   *  Common method to get previous/next log data
   */
  getPrevLogs = (resource, params = {}, loading = false) => {
    let map = {};
    map.node_id =
      this.state.nodeDetails && this.state.nodeDetails.id
        ? this.state.nodeDetails.id
        : this.state.nodeId;
    LogController.getPreviousLogMessages(resource, map, params, loading);
  };

  /**
   *  Common method to get log data
   */
  getLogData = (
    resource,
    params = {},
    loading = false,
    autoScrollBottom = false,
    autoScrollTop = false,
  ) => {
    let map = {};
    map.node_id =
      this.state.nodeDetails && this.state.nodeDetails.id
        ? this.state.nodeDetails.id
        : this.state.nodeId;
    if (this.logViewModel) {
      if (this.logViewModel.movePointerToOldest) {
        this.logViewModel.setPointerToOldest(false);
      }
      if (this.logViewModel.movePointerToLatest) {
        this.logViewModel.setPointerToLatest(false);
      }
    }
    LogController.getLogMessages(resource, map, params, loading)
      .then(result => {
        if (autoScrollBottom) {
          this.setState({
            triggerAutoScrollTop: false,
            triggerAutoScrollBottom: true,
          });
        } else if (autoScrollTop) {
          this.setState({
            triggerAutoScrollTop: true,
            triggerAutoScrollBottom: false,
          });
        } else {
          this.setState({
            triggerAutoScrollTop: false,
            triggerAutoScrollBottom: false,
          });
        }
      })
      .catch(error => {});
  };

  /**
   *  Send donwload data request
   */
  downloadLog = () => {
    let map = {};
    map.node_id =
      this.state.nodeDetails && this.state.nodeDetails.id
        ? this.state.nodeDetails.id
        : this.state.nodeId;
    LogController.downloadLogs("download_inode_logs", map, {}, true)
      .then(result => {
        let timezone =
          this.props.TimezoneViewModel.userTimezone ||
          this.props.TimezoneViewModel.orgTimezone ||
          getDefaultTimezone().value;
        let date = new Date();
        date = date ? moment.tz(date, timezone) : date;
        var datestring = date
          .toISOString()
          .replace(/-/g, "")
          .replace(/:/g, "")
          .split(".")[0];
        let blob = new Blob([result], { type: "text/plain;charset=utf-8" }),
          fileName =
            this.inodeModel.inode && this.inodeModel.inode.name
              ? this.inodeModel.inode.name
              : this.props.extendData.serviceData.node;

        FileSaver.saveAs(blob, fileName + "_" + datestring + ".log");
      })
      .catch(error => {});
  };

  /**
   * GO back
   */
  onCancel = () => {
    this.props.history.goBack();
  };
  resetScrollState = () => {
    this.setState({
      triggerAutoScrollTop: false,
      triggerAutoScrollBottom: false,
    });
  };
  /**
   *  Update iNode details for breadcrumb and other routes info
   */
  updateInodeDetails = res => {
    this.setState(() => {
      let newObj = {},
        nodeDetails = this.state.nodeDetails,
        BreadcrumbList = [];

      nodeDetails = res;

      let nodeCrumb = [
        {
          name: res.name ? res.name : "Details",
          link: `orgs/${res.organization.id}/inodes/${res.id}`,
        },
      ];
      BreadcrumbList.push(
        {
          name: res.organization.name,
          link: `dashboard/${res.organization.id}`,
        },
        { name: "Nodes", link: `orgs/${res.organization.id}/inodes` },
        ...nodeCrumb,
        {
          name: "Node Logs",
          link: `orgs/${res.organization.id}/inodes/${res.id}/logs`,
        },
      );
      this.props.UiStore.setInodeName(res.name);
      this.props.UiStore.setOrgName(res.organization.name);
      newObj["nodeDetails"] = nodeDetails;
      newObj["BreadcrumbList"] = BreadcrumbList;
      return newObj;
    });
    this.getLogData("inode_logs", {}, true, true, false);
  };

  render() {
    let node_data = this.inodeModel.inode;
    let container = (
      <div>
        <Row gutter={16} type="flex" align="middle">
          <Col span={12}>
            <h2 className="page-title">
              <Icons type="fa" name="FaRegHdd" />
              {"  "}
              {node_data.name ? node_data.name : null}
            </h2>
            <BreadcrumbComponent
              {...this.props}
              BreadcrumbList={this.state.BreadcrumbList}
            />
          </Col>
          <Col span={12} className="flex flex-h-right">
            <OrgSelector
              {...this.props}
              className="org-selector-menu-padding"
            />
          </Col>
        </Row>
        <div>
          <Row gutter={2} type="flex" align="top">
            <Col span={24} className="card-grid">
              <LogsContainer
                type={"inode"}
                downLoadRequest={this.downloadLog}
                searchText=""
                collectNewLogs={this.collectNewLogs}
                collectOldLogs={this.collectOldLogs}
                collectForwardLogs={this.collectForwardLogs}
                collectPreviousLogs={this.collectPreviousLogs}
                onCancel={this.onCancel}
                triggerAutoScrollTop={this.state.triggerAutoScrollTop}
                triggerAutoScrollBottom={this.state.triggerAutoScrollBottom}
                resetScrollState={this.resetScrollState}
                isAutoloaded={this.state.isAutoloaded}
                resetAutoScroll={() => {
                  this.setState({ isAutoloaded: false });
                }}
              />
            </Col>
          </Row>
        </div>
      </div>
    );

    return (
      <LoadingComponent
        loading={
          typeof this.logViewModel.loading === "boolean"
            ? this.logViewModel.loading
            : true
        }
      >
        {container}
      </LoadingComponent>
    );
  }
}

export default InodeLogs;
