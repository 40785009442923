import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { Row, Col, Card, Button, Tabs, Radio } from "antd";
import BreadcrumbComponent from "../UI-Components/BreadcrumbComponent";
import { getCurrentlocaleText } from "../../Core/Utils";
import DeviceDiscoveryScan from "./DeviceDiscoveryScan";
import DeviceDiscoveryReport from "./DeviceDiscoveryReport";
import InodeController from "../../controller/InodeController";
import ClusterController from "../../controller/ClusterController";

@inject(
  "AuthStore",
  "UiStore",
  "InodeViewModel",
  "OrgViewModel",
  "ClusterViewModel",
)
@observer
export default class DeviceDiscoveryContainer extends Component {
  constructor(props) {
    super(props);
    this.inodeModel = this.props.InodeViewModel;
    this.orgModel = this.props.OrgViewModel;
    this.clusterModel = this.props.ClusterViewModel;
    this.state = {
      BreadcrumbList: [],
      activeTab: "scans", // devices, scans, reports
    };
    this.orgId = this.props.match.params.orgId;
    this.nodeId = this.props.match.params.nodeId;
    this.clusterId = this.props.match.params.clusterId;
    this.tab = this.props.match.params.tab;
    this.reportId = this.props.match.params.reportId;
  }

  componentDidMount() {
    if (this.tab == "scans" || this.tab == "reports") {
      this.setState({
        activeTab: this.tab,
      });
    }

    if (this.clusterId) {
      if (this.clusterModel.cluster.name) {
        this.setClusterBreadcrumb();
      } else {
        ClusterController.getCluster(this.clusterId, false).then(result => {
          this.setClusterBreadcrumb();
        });
      }
    } else {
      let nodeName =
        this.inodeModel && this.inodeModel.inode && this.inodeModel.inode.name
          ? this.inodeModel.inode.name
          : "";
      if (nodeName) {
        this.setBreadcrumb();
      } else {
        InodeController.getInode(
          this.props.match.params.nodeId || this.props.match.params.id,
        ).then(res => {
          this.setBreadcrumb();
        });
      }
    }
  }

  setBreadcrumb() {
    let BreadcrumbList = [];
    BreadcrumbList.push({
      name:
        this.props.OrgViewModel &&
        this.props.OrgViewModel.org &&
        this.props.OrgViewModel.org.name
          ? this.props.OrgViewModel.org.name
          : this.props.AuthStore.loggedInUser.organization.name,
      link: `dashboard/${this.orgId}`,
    });
    if (this.nodeId) {
      let nodeName =
        this.inodeModel && this.inodeModel.inode && this.inodeModel.inode.name
          ? this.inodeModel.inode.name
          : "";

      BreadcrumbList.push(
        { name: "Nodes", link: `orgs/${this.orgId}/inodes` },
        {
          name: nodeName ? nodeName : "iNode Details",
          link: `orgs/${this.orgId}/inodes/${this.nodeId}`,
        },
      );
    }
    BreadcrumbList.push({
      name: "Device Discovery",
      link: `orgs/${this.orgId}/inodes/${this.nodeId}/devicediscovery`,
    });
    this.setState({ BreadcrumbList });
  }

  setClusterBreadcrumb() {
    let BreadcrumbList = [];
    BreadcrumbList.push(
      {
        name:
          this.props.OrgViewModel &&
          this.props.OrgViewModel.org &&
          this.props.OrgViewModel.org.name
            ? this.props.OrgViewModel.org.name
            : this.props.AuthStore.loggedInUser.organization.name,
        link: `orgs/${this.orgId}/orgs`,
      },
      {
        name: getCurrentlocaleText("clusters.navigation_menu.text"),
        link: `orgs/${this.orgId}/clusters`,
      },
      {
        name: this.clusterModel.cluster.name,
        link: `orgs/${this.orgId}/clusters/` + this.clusterModel.cluster.id,
      },
      {
        name: getCurrentlocaleText("device.discovery.text2"),
        link:
          `orgs/${this.orgId}/clusters/` +
          this.clusterModel.cluster.id +
          "/devicediscovery",
      },
    );

    this.setState({
      BreadcrumbList: BreadcrumbList,
    });
  }

  handleTabChange = key => {
    this.setState({ activeTab: key });
  };

  render() {
    let { BreadcrumbList, activeTab } = this.state;

    return (
      <div className="device-discovery">
        <Row gutter={16} type="flex" align="middle">
          <Col lg={{ span: 12 }} xl={{ span: 12 }}>
            <h2 className="page-title">
              {getCurrentlocaleText("device.discovery.text2")}
            </h2>
            <BreadcrumbComponent
              {...this.props}
              BreadcrumbList={BreadcrumbList}
            />
          </Col>
        </Row>
        <Card className="mb-5 p-10">
          <Tabs
            className="tab-no-border"
            activeKey={activeTab}
            onChange={this.handleTabChange}
          >
            <Tabs.TabPane tab="Scans" key="scans">
              <DeviceDiscoveryScan
                {...this.props}
                handleTabChange={val => this.handleTabChange(val)}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Reports" key="reports">
              <DeviceDiscoveryReport
                {...this.props}
                reportId={this.reportId}
                handleTabChange={val => this.handleTabChange(val)}
              />
            </Tabs.TabPane>
          </Tabs>
        </Card>
      </div>
    );
  }
}
