import React, { Component } from "react";
import { Card, Modal, Tag, Button } from "antd";
import { inject, observer } from "mobx-react";
import Icons from "components/UI-Components/Icons";
import {
  humanizeMetric,
  TIME_DATE_FORMAT,
  getTimezoneToDisplay,
  getCurrentlocaleText,
} from "Core/Utils.js";
import moment from "moment";
import VmdkImage from "../../assets/img/vmdk.png";
import OvaImage from "../../assets/img/ova.png";
import IsoImage from "../../assets/img/iso.svg";
import DebImage from "../../assets/img/deb.svg";
import FileImage from "../../assets/img/file.png";

import ReleaseController from "controller/ReleaseController";

@inject("AuthStore", "TimezoneViewModel")
@observer
class ReleaseElement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      loading: false,
      sortOrder: this.props.sortOrder,
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({ sortOrder: props.sortOrder });
  }

  getFileIcon = fileName => {
    if (fileName) {
      if (fileName.endsWith(".deb")) {
        return <DebImage height="45" width="45" alt="File" title={fileName} />;
      } else {
        if (fileName.endsWith("vmdk.bz2")) {
          return (
            <img
              src={VmdkImage}
              height="45"
              width="45"
              alt="File"
              title={fileName}
            />
          );
        } else {
          if (fileName.endsWith(".ova.bz2")) {
            return (
              <img
                src={OvaImage}
                height="45"
                width="45"
                alt="File"
                title={fileName}
              />
            );
          } else {
            if (fileName.endsWith(".iso")) {
              return (
                <IsoImage height="45" width="45" alt="File" title={fileName} />
              );
            } else {
              return (
                <img
                  src={FileImage}
                  height="45"
                  width="45"
                  alt="File"
                  title={fileName}
                />
              );
            }
          }
        }
      }
    } else {
      return (
        <img
          src={FileImage}
          height="45"
          width="45"
          alt="File"
          title={fileName}
        />
      );
    }
  };

  /**
   * Method to download latest released VMDK/OVA file
   */
  downloadFile = url => {
    ReleaseController.downloadFile(url).then(resp => {
      if (resp && resp.download_url) {
        window.location = resp.download_url;
      }
    });
  };

  renderList = () => {
    return this.props.releaseData.files &&
      this.props.releaseData.files.length > 0 ? (
      this.props.releaseData.files
        .sort((x, y) => {
          return this.state.sortOrder === "desc"
            ? Date.parse(y.last_modified_at) - Date.parse(x.last_modified_at)
            : Date.parse(x.last_modified_at) - Date.parse(y.last_modified_at);
        })
        .map((file, indx) => {
          let last_modified_at = `${moment(
            new Date(file.last_modified_at).getTime(),
          ).format(TIME_DATE_FORMAT)} ${getTimezoneToDisplay(
            file.last_modified_at,
          )}`;
          return (
            <div key={indx}>
              <div>
                <span>
                  <div className="releases_file_icon">
                    {file.name.endsWith(".exe") && (
                      <span
                        style={{
                          display: "grid",
                          fontWeight: "bold",
                          border: "1px solid black",
                          borderRadius: 5,
                          backgroundColor: "white",
                          padding: "2px",
                          marginTop: 12,
                          fontSize: 10,
                          position: "absolute",
                        }}
                      >
                        {"EXE "}
                        {file.name.includes("64-bit") ? "x64" : "x86"}
                      </span>
                    )}
                    {this.getFileIcon(file.name)}{" "}
                  </div>
                </span>
                <div style={{ display: "inline-block" }}>
                  <div>
                    <span className="releases_filename">
                      {file.name.endsWith(".exe")
                        ? file.name.replace(".exe", "")
                        : file.name}
                    </span>
                    <span>
                      <em>{humanizeMetric(file.size, true)}</em>
                    </span>
                    <span
                      className="releases_download_icon"
                      onClick={this.downloadFile.bind(this, file.url)}
                      title="Download"
                    >
                      <Icons
                        type="fa"
                        name="FaDownload"
                        style={{ fontSize: 18 }}
                      />
                    </span>
                  </div>
                  <div className="releases_last_modified_date">
                    <span>{last_modified_at}</span>
                  </div>
                </div>
              </div>

              <div className="divider" />
            </div>
          );
        })
    ) : (
      <div>{getCurrentlocaleText("releases.noContent.text")}</div>
    );
  };

  showModal = () => {
    this.setState({ visible: true });
  };

  closeModal = () => {
    this.setState({ visible: false });
  };

  deleteHandler = version => {
    this.setState({ loading: true });
    let data = [{ id: version }];
    ReleaseController.delete(data)
      .then(response => {
        this.setState({ loading: false });
        this.closeModal();
        // To delete form the list
        if (response) {
          this.props.deleteVersion(
            response
              .map(release => {
                return release.id;
              })
              .toString(),
          );
        }
      })
      .catch(error => {
        this.setState({ loading: false });
        this.closeModal();
      });
  };

  render() {
    return (
      <Card
        className="releases-card"
        title={this.props.cardTitle}
        style={{ margin: "30px 30px" }}
        extra={
          <div>
            {this.props.AuthStore.IsPermitted("SUPER:ADMIN") && (
              <span>
                <span
                  style={{ cursor: "pointer" }}
                  title="Delete"
                  onClick={this.showModal.bind(this)}
                >
                  <Icons
                    type="ai"
                    name="AiOutlineDelete"
                    style={{ fontSize: 14 }}
                  />
                </span>
                <Modal
                  title={
                    <span>
                      <Icons
                        type="ai"
                        name="AiOutlineDelete"
                        style={{ fontSize: 14 }}
                      />
                      {getCurrentlocaleText("delete.text")}
                    </span>
                  }
                  visible={this.state.visible}
                  maskClosable={false}
                  closable={!this.state.loading}
                  footer={[
                    <Button
                      key="cancel"
                      onClick={this.closeModal.bind()}
                      disabled={this.state.loading}
                    >
                      {getCurrentlocaleText("cancel.button.text")}
                    </Button>,
                    <Button
                      key="submit"
                      type="primary"
                      loading={this.state.loading}
                      onClick={this.deleteHandler.bind(
                        this,
                        this.props.version,
                      )}
                    >
                      {getCurrentlocaleText("support.submit.text")}
                    </Button>,
                  ]}
                >
                  <div>
                    <p>
                      {getCurrentlocaleText(
                        "managealerts.unsubscribe.confirmation.text",
                      )}
                    </p>
                    <Tag className="delete-tag">{this.props.version}</Tag>
                  </div>
                </Modal>
              </span>
            )}
          </div>
        }
      >
        {this.renderList()}
      </Card>
    );
  }
}

export default ReleaseElement;
