import React, { Component } from "react";
import { Button, Tag } from "antd";
import { Form } from "@ant-design/compatible";
import { observer, inject } from "mobx-react";
import _ from "lodash";
import FormErrorMessage from "components/UI-Components/FormErrorMessage";
import { getCurrentlocaleText } from "../../Core/Utils";

const FormItem = Form.Item;

@inject("ModelStore", "UiStore")
@observer
class RebootForm extends Component {
  constructor(props) {
    super(props);
    this.renderList = this.renderList.bind(this);
  }

  renderList = () => {
    let tempArray = [];

    _.forIn(this.props.selectedNode, function(value, key) {
      tempArray.push(value);
    });
    return tempArray.map((val, index) => {
      return val.status.toLowerCase() == "alive" && val.value == true ? (
        <span key={index}>
          <Tag className="tag-iotium-info">{val.name}</Tag>
        </span>
      ) : null;
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form noValidate onSubmit={this.props.onSubmit} className="login-form">
        <FormItem>
          <div>
            {_.isEmpty(this.props.selectedNode) ? (
              <strong>{getCurrentlocaleText("reboot.form.text")}</strong>
            ) : (
              <div>
                <strong>
                  {getCurrentlocaleText("reboot.form.confirmation.text")}
                </strong>
                <br />
                {this.renderList()}
              </div>
            )}
          </div>
        </FormItem>
        <Button className="hidden-submit" htmlType="submit" />
        <FormErrorMessage />
      </Form>
    );
  }
}

const RebootForms = Form.create()(RebootForm);
export default RebootForms;
