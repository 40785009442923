import React, { Component } from "react";
import { Row, Col, TreeSelect, message, Select } from "antd";
import { observer, inject } from "mobx-react";
import BreadcrumbComponent from "components/UI-Components/BreadcrumbComponent";
import Icons from "components/UI-Components/Icons";
import {
  convertEpochToISOTimestampString,
  DefaultStatsWidget,
  getCurrentlocaleText,
  copyObject,
  OverviewDefaultStatsWidget,
  CONTAINER_STATS_LIMIT,
} from "Core/Utils";
import NetworkStatsController from "controller/NetworkStatsController";
import ServiceController from "controller/ServiceController";
import InodeController from "controller/InodeController";
import EventController from "controller/EventController";
import LoadingComponent from "components/UI-Components/LoadingComponent";
import NetworkChart from "components/Stats/Charts/NetworkChart";
import FileSystemChart from "components/Stats/Charts/FileSystemChart";
import CPUChart from "components/Stats/Charts/CPUChart";
import MemoryChart from "components/Stats/Charts/MemoryChart";
import SystemOverview from "components/Stats/Charts/SystemOverview";
import ContainerOverview from "components/Stats/Charts/ContainerOverview";
import ServiceNetworkOverview from "components/Stats/Charts/ServiceNetworkOverview";
import ServiceStatusChart from "components/Stats/Charts/ServiceStatusChart";
import ContainerStatusChart from "components/Stats/Charts/ContainerStatusChart";
import StatsHeader from "components/Stats/StatsHeader";
import Dashboard, { addWidget } from "react-dazzle";
import "react-dazzle/lib/style/style.css";
import EventsTimeLine from "components/Stats/EventsTimeLine";
import AddWidgetModal from "components/Dashboard/components/AddWidgetModal";
import ResetDashboardModal from "components/Dashboard/components/ResetDashboardModal";
import AddWidget from "components/Dashboard/components/AddWidget";

const { SHOW_PARENT } = TreeSelect;
const { Option } = Select;

@inject(
  "AuthStore",
  "NodeStatsViewModel",
  "InodeViewModel",
  "ServiceStatsViewModel",
  "EventViewModel",
)
@observer
class StatsContainer extends Component {
  constructor(props) {
    super(props);
    this.inodeModel = this.props.InodeViewModel;
    this.ServiceStatsViewModel = this.props.ServiceStatsViewModel;
    this.NodeStatsViewModel = this.props.NodeStatsViewModel;
    this.interval = null;
    this.defaultInterval = 30000;

    this.state = {
      BreadcrumbList: [
        {
          name: "Stats",
          link: "stats",
        },
      ],
      data: [],
      opts: {},
      completeData: [],
      interfaces: [],
      FileSystemInfo: [],
      loading: false,
      ServiceList: [],
      isInvalidRange: false,
      isGauge: false,
      widgets: {
        CPUChart: {
          type: CPUChart,
          title: "CPU Usage",
          key: "CPUChart",
          props: this.props,
          category: "node",
        },
        MemoryChart: {
          type: MemoryChart,
          title: "Memory Usage",
          key: "MemoryChart",
          props: this.props,
          category: "node",
        },
        FileSystemChart: {
          type: FileSystemChart,
          title: "File System Usage",
          key: "FileSystemChart",
          props: this.props,
          category: "node",
        },
        NetworkChart: {
          type: NetworkChart,
          title: "Interface Traffic",
          key: "NetworkChart",
          props: this.props,
          category: "node",
        },
      },
      older_node_widget: {
        CPUChart: {
          type: CPUChart,
          title: "CPU Usage",
          key: "CPUChart",
          props: this.props,
          category: "node",
        },
        MemoryChart: {
          type: MemoryChart,
          title: "Memory Usage",
          key: "MemoryChart",
          props: this.props,
          category: "node",
        },
        NetworkChart: {
          type: NetworkChart,
          title: "Interface Traffic",
          key: "NetworkChart",
          props: this.props,
          category: "node",
        },
      },
      overview_widgets: {
        ContainerOverview: {
          type: ContainerOverview,
          title: "Container Overview",
          key: "ContainerOverview",
          props: this.props,
          category: "node",
        },
        ContainerStatusChart: {
          type: ContainerStatusChart,
          title: "Continer Status",
          key: "ContainerStatusChart",
          props: this.props,
          category: "container",
        },
        ServiceStatusChart: {
          type: ServiceStatusChart,
          title: "Service Status",
          key: "ServiceStatusChart",
          props: this.props,
          category: "container",
        },
        ServiceNetworkOverview: {
          type: ServiceNetworkOverview,
          title: "Service Overview",
          key: "ServiceNetworkOverview",
          props: this.props,
          category: "node",
        },
        SystemOverview: {
          type: SystemOverview,
          title: "System Overview",
          key: "SystemOverview",
          props: this.props,
          category: "node",
        },
      },
      layout:
        localStorage.layoutVersion &&
        JSON.parse(localStorage.layoutVersion) === 2 &&
        localStorage.statsLayout &&
        JSON.parse(localStorage.statsLayout).rows &&
        JSON.parse(localStorage.statsLayout).rows.length > 3
          ? JSON.parse(localStorage.statsLayout)
          : copyObject(DefaultStatsWidget),
      overview_layout:
        localStorage.layoutVersion &&
        JSON.parse(localStorage.layoutVersion) === 2 &&
        localStorage.statsOverviewLayout &&
        JSON.parse(localStorage.statsOverviewLayout).rows &&
        JSON.parse(localStorage.statsOverviewLayout).rows.length > 3
          ? JSON.parse(localStorage.statsOverviewLayout)
          : copyObject(OverviewDefaultStatsWidget),
      editMode: false,
      isModalOpen: false,
      addWidgetOptions: {},
      resetModalOpen: false,
      value: this.props.match.params.containerId
        ? this.props.match.params.containerId
        : this.props.match.params.nodeId,
      serviceId: this.props.match.params.spec
        ? this.props.match.params.spec
        : null,
      containerId: this.props.match.params.containerId
        ? this.props.match.params.containerId
        : null,
      autoExpandParent: true,
      allowedServiceContainers: [],
      selectedKeys: [],
      enabledServices: [],
      allowAllServices: false,
      containerSelector: "TOP_CPU",
    };
  }

  componentDidMount() {
    this.iNodeDetails(null, true, true);
    this.getServiceList(true, 0, "", false);
    this.eventList(true, 0);
    this.updateChart(true);
    this.setStatsInterval();
    let layout =
      localStorage.layoutVersion &&
      JSON.parse(localStorage.layoutVersion) === 2 &&
      localStorage.statsLayout
        ? JSON.parse(localStorage.statsLayout)
        : copyObject(DefaultStatsWidget);
    let overview_layout =
      localStorage.layoutVersion &&
      JSON.parse(localStorage.layoutVersion) === 2 &&
      localStorage.statsOverviewLayout
        ? JSON.parse(localStorage.statsOverviewLayout)
        : copyObject(OverviewDefaultStatsWidget);
    this.setState({ overview_layout: overview_layout });
    this.arrangeStatsLayoutWidgets(layout, this.state.containerSelector);
  }

  setStatsInterval = () => {
    if (this.interval) {
      clearInterval(this.interval);
    }
    /*API CALL EVERY timeoutInterval Intiated HERE */
    this.interval = setInterval(() => {
      let duration = this.NodeStatsViewModel.statsDuration;
      if (parseInt(duration) != 4) {
        this.updateChart(false);
        this.iNodeDetails(null, false, true);
        this.getServiceList(false, 0, "", false);
      }
    }, this.defaultInterval);
  };

  getNetworkStats = (loading = false) => {
    let params = this.getStartEndParams();
    params.fields = ["network", "spec"];
    NetworkStatsController.getServiceNetworkStats(
      this.state.serviceId,
      params,
      loading,
    )
      .then(res => {
        this.drawFileSystemValues();
      })
      .catch(error => {});
  };

  UNSAFE_componentWillReceiveProps(newProps) {
    let interval = 30000; // 30 Seconds
    if (this.inodeModel.inode && this.inodeModel.inode.stat_config) {
      if (this.inodeModel.inode.stat_config.stat_mode === "SLOW") {
        interval = 120000; // 2 Minutes
      }
    }
    if (interval !== this.defaultInterval) {
      this.defaultInterval = interval;
      this.setStatsInterval();
    }
    if (this.props.match.params != newProps.match.params) {
      this.setState({
        value: !this.props.match.params.containerId
          ? newProps.match.params.containerId
          : newProps.match.params.containerId
          ? newProps.match.params.containerId
          : newProps.match.params.nodeId,
        serviceId: newProps.match.params.spec
          ? newProps.match.params.spec
          : null,
        containerId: newProps.match.params.containerId
          ? newProps.match.params.containerId
          : null,
      });
      newProps.ServiceStatsViewModel.containerId = newProps.match.params
        .containerId
        ? newProps.match.params.containerId
        : null;
      setTimeout(() => {
        this.updateChart(true);
        if (this.state.containerSelector !== "CUSTOM") {
          this.getAllowedServiceContainerList().then(response => {
            this.setState({ allowedServiceContainers: response }, () => {
              // get service network stats
              this.getServiceNetworkStats(
                this.state.ServiceList ? this.state.ServiceList : [],
              );
              // get contianer stats
              this.getContainerStats(
                this.state.ServiceList ? this.state.ServiceList : [],
              );
            });
          });
        } else {
          // get service network stats
          this.getServiceNetworkStats(
            this.state.ServiceList ? this.state.ServiceList : [],
          );
          // get contianer stats
          this.getContainerStats(
            this.state.ServiceList ? this.state.ServiceList : [],
          );
        }
      }, 200);
    }
  }

  componentWillUnmount = () => {
    /*Required to clear interval on component unmount*/
    clearInterval(this.interval);
    this.props.NodeStatsViewModel.statsDuration = "1";
    this.props.NodeStatsViewModel.currentInterface = null;
  };

  eventList = (loading = true, page = 0, forcecall = false) => {
    let params = this.getStartEndParams();
    params.page = page;
    params.size = 1;
    if (params.start_date && params.end_date) {
      EventController.getInodeEvents(
        this.props.match.params.nodeId,
        params,
        loading,
        forcecall,
      ).then(res => {
        if (res) {
          let total_count =
            this.props.EventViewModel.pageable.total_count / 100;
          this.updateEventsData(total_count, [], loading, forcecall);
        }
      });
    }
  };

  onChangeServiceFilter = value => {
    let services = this.state.ServiceList;
    let containerscount = 0;
    let allowed_services = [];
    let enabled_values = [];
    let container_details = services.filter(service => {
      if (value.includes(service.id)) {
        allowed_services.push(service.id);
        enabled_values.push(service.id);
        return service;
      }
    });
    value.forEach(v => {
      if (v.startsWith("s-")) {
        containerscount = containerscount + 1;
        enabled_values.push(v);
      }
    });

    container_details.forEach(sl => {
      let containers = sl.spec && sl.spec.services ? sl.spec.services : [];
      if (containers.length > 0) {
        containers.forEach((cl, index) => {
          containerscount = containerscount + 1;
          enabled_values.push(cl.id);
        });
      }
    });
    let containerList = this.state.allowedServiceContainers;
    if (
      ((containerscount != 0 ||
        (containerList && containerList.length < CONTAINER_STATS_LIMIT)) &&
        containerscount <= CONTAINER_STATS_LIMIT) ||
      value.length === 0
    ) {
      this.setState({ allowedServiceContainers: value }, () => {
        this.getServiceNetworkStats(
          this.state.ServiceList ? this.state.ServiceList : [],
        );
        // get contianer stats
        this.getContainerStats(
          this.state.ServiceList ? this.state.ServiceList : [],
        );
      });
    } else {
      //unfocus the drop down
      document.querySelector(".ServiceFilter").click();
      message.warning(
        getCurrentlocaleText("stats.max.limit.warning.text", {
          0: CONTAINER_STATS_LIMIT,
        }),
      );
    }
  };

  updateEventsData(totalCount, availableNetworks, loading, forcecall) {
    if (totalCount > 0) {
      let allParams = [];
      for (let page = 0; page < totalCount; page++) {
        let params = this.getStartEndParams();
        params.page = page;
        params.size = 100;
        if (params.start_date && params.end_date) {
          allParams.push(params);
        }
      }
      if (allParams.length > 0) {
        EventController.getAllInodeEvents(
          this.props.match.params.nodeId,
          allParams,
          loading,
          forcecall,
        ).then(resp => {});
      }
    }
  }

  updateInodeDetails = res => {
    this.setState(() => {
      let newObj = {},
        nodeDetails = this.state.nodeDetails,
        BreadcrumbList = [];

      nodeDetails = res;

      let nodeCrumb = [
        {
          name: res.name ? res.name : "Details",
          link: `orgs/${res.organization.id}/inodes/${res.id}`,
        },
      ];
      BreadcrumbList.push(
        {
          name: res.organization.name,
          link: `dashboard/${res.organization.id}`,
        },
        { name: "Nodes", link: `orgs/${res.organization.id}/inodes` },
        ...nodeCrumb,
        {
          name: "Node Metrics",
          link: `orgs/${res.organization.id}/inodes/${res.id}/stats`,
        },
      );
      newObj["nodeDetails"] = nodeDetails;
      newObj["BreadcrumbList"] = BreadcrumbList;
      return newObj;
    });
  };

  fetchNewStatInfo = () => {
    this.updateChart(true);
    this.eventList(true);
    if (this.state.containerSelector !== "CUSTOM") {
      this.getAllowedServiceContainerList().then(response => {
        this.setState({ allowedServiceContainers: response }, () => {
          this.getServiceNetworkStats(
            this.state.ServiceList ? this.state.ServiceList : [],
          );
          // get contianer stats
          this.getContainerStats(
            this.state.ServiceList ? this.state.ServiceList : [],
          );
        });
      });
    } else {
      this.getServiceNetworkStats(
        this.state.ServiceList ? this.state.ServiceList : [],
      );
      // get contianer stats
      this.getContainerStats(
        this.state.ServiceList ? this.state.ServiceList : [],
      );
    }
  };

  /**
   *  Get current iNode details for reference
   */
  iNodeDetails = (res = null, loading = true, forceCall = false) => {
    let map = {
      id: this.props.match.params.nodeId,
    };
    InodeController.getInode(map.id, loading, forceCall).then(res => {
      return this.updateInodeDetails(res);
    });
  };

  updateChart = (loading = false) => {
    let params = this.getStartEndParams();
    params.fields = ["cpu", "memory", "network", "spec", "filesystem"];
    if (params.start_date && params.end_date) {
      NetworkStatsController.getNodeStats(
        this.props.match.params.nodeId,
        params,
        loading,
      )
        .then(res => {
          this.drawFileSystemValues();
        })
        .catch(error => {});
    }
  };

  //reset the default state
  resetdefault = () => {
    let layout = copyObject(DefaultStatsWidget);
    let overview_layout = copyObject(OverviewDefaultStatsWidget);
    this.setState({
      overview_layout: overview_layout,
    });
    this.updateStore(overview_layout, true);
    this.arrangeStatsLayoutWidgets(layout, this.state.containerSelector);
    this.onRestoreClose();
    this.setState({
      editMode: false,
    });
  };

  onRestoreClose = () => {
    this.setState({
      resetModalOpen: false,
    });
  };

  getStartEndParams() {
    let duration = this.NodeStatsViewModel.statsDuration;
    let params = {};
    var now = new Date().getTime() - 1000;
    let customStartDate = this.NodeStatsViewModel.customStartDate;
    let customEndDate = this.NodeStatsViewModel.customEndDate;

    switch (parseInt(duration)) {
      case 1:
        var hoursago = new Date(now - 1000 * 60 * 60 * 5);
        break;
      case 2:
        var hoursago = new Date(now - 1000 * 60 * 60 * 24);
        break;
      case 3:
        var hoursago = new Date(now - 1000 * 60 * 60 * 48);
        break;
      case 4:
        params.start_date = convertEpochToISOTimestampString(customStartDate);
        params.end_date = convertEpochToISOTimestampString(customEndDate);
        break;
      default:
        var hoursago = new Date(now - 1000 * 60 * 60 * 5);
        break;
    }
    if (parseInt(duration) === 4) {
      return params;
    } else {
      let updated_time = Date.parse(hoursago);
      params.start_date = convertEpochToISOTimestampString(updated_time);
      params.end_date = convertEpochToISOTimestampString(now);
      return params;
    }
  }

  getServiceList = (
    loading = true,
    page = 0,
    search = "",
    forceCall = false,
    resolveData = true,
  ) => {
    let params = this.props.params || {
      node_id: this.props.match.params.nodeId
        ? this.props.match.params.nodeId
        : this.props.nodeId,
      size: 1,
      page: 0,
    };
    params.page = 0;
    ServiceController.getServices(params, loading, forceCall, resolveData).then(
      resp => {
        if (resp && resp.results.length > 0) {
          this.updateAllServiceData(resp.total_count / 100, [], true);
        }
      },
    );
  };

  updateAllServiceData = (totalCount, availableServices, loading) => {
    if (totalCount > 0) {
      let allParams = [];
      for (let page = 0; page < totalCount; page++) {
        allParams.push({
          node_id: this.props.match.params.nodeId
            ? this.props.match.params.nodeId
            : this.props.nodeId,
          size: 100,
          page: page,
        });
      }
      ServiceController.QueryAllServices(allParams, true).then(resp => {
        if (resp) {
          availableServices = availableServices.concat(resp);
          this.setState({
            ServiceList: availableServices.length > 0 ? availableServices : [],
          });
          this.NodeStatsViewModel.setServices(availableServices);
          let duration = this.NodeStatsViewModel.statsDuration;
          if (this.state.containerSelector !== "CUSTOM") {
            this.getAllowedServiceContainerList().then(response => {
              this.setState({ allowedServiceContainers: response }, () => {
                if (parseInt(duration) != 4) {
                  // get service network stats
                  this.getServiceNetworkStats(
                    availableServices.length > 0 ? availableServices : [],
                  );
                  // get contianer stats
                  this.getContainerStats(
                    availableServices.length > 0 ? availableServices : [],
                  );
                  this.NodeStatsViewModel.setServiceListForView(
                    availableServices.length > 0 ? availableServices : [],
                  );
                }
              });
            });
          } else {
            if (parseInt(duration) != 4) {
              // get service network stats
              this.getServiceNetworkStats(
                availableServices.length > 0 ? availableServices : [],
              );
              // get contianer stats
              this.getContainerStats(
                availableServices.length > 0 ? availableServices : [],
              );
              this.NodeStatsViewModel.setServiceListForView(
                availableServices.length > 0 ? availableServices : [],
              );
            }
          }
          this.getLiveContainerStats(
            availableServices.length > 0 ? availableServices : [],
          );
          this.getLiveServiceNetworkStats(
            availableServices.length > 0 ? availableServices : [],
          );
        }
      });
    }
  };

  serviceList = (
    loading = true,
    page = 0,
    search = "",
    forceCall = false,
    resolveData = true,
  ) => {
    let params = this.props.params || {
      node_id: this.props.match.params.nodeId
        ? this.props.match.params.nodeId
        : this.props.nodeId,
    };
    params.page = page;
    params.search = search;

    ServiceController.getServices(params, loading, forceCall, resolveData).then(
      repsonse => {
        if (repsonse) {
          this.setState({
            ServiceList: repsonse.results ? repsonse.results : [],
          });
          this.NodeStatsViewModel.setServices(repsonse.results);
          let duration = this.NodeStatsViewModel.statsDuration;
          if (this.state.containerSelector !== "CUSTOM") {
            this.getAllowedServiceContainerList().then(response => {
              this.setState({ allowedServiceContainers: response }, () => {
                if (parseInt(duration) != 4) {
                  // get service network stats
                  this.getServiceNetworkStats(
                    repsonse.results ? repsonse.results : [],
                  );
                  // get contianer stats
                  this.getContainerStats(
                    repsonse.results ? repsonse.results : [],
                  );
                  this.NodeStatsViewModel.setServiceListForView(
                    repsonse.results ? repsonse.results : [],
                  );
                }
              });
            });
          } else {
            if (parseInt(duration) != 4) {
              // get service network stats
              this.getServiceNetworkStats(
                repsonse.results ? repsonse.results : [],
              );
              // get contianer stats
              this.getContainerStats(repsonse.results ? repsonse.results : []);
              this.NodeStatsViewModel.setServiceListForView(
                repsonse.results ? repsonse.results : [],
              );
            }
          }
          this.getLiveContainerStats(repsonse.results ? repsonse.results : []);
          this.getLiveServiceNetworkStats(
            repsonse.results ? repsonse.results : [],
          );
        }
      },
    );
  };

  getServiceNetworkStats = services => {
    let allowed = this.state.allowedServiceContainers;
    let params = this.getStartEndParams();
    params.fields = ["network", "spec"];
    if (services.length > 0) {
      let path_params = [];
      services.forEach(service => {
        if (allowed.includes("all_services") || allowed.includes(service.id)) {
          path_params.push({
            serviceId: service.id,
            serviceName: service.name,
            service: { id: service.id, name: service.name },
          });
        }
      });
      if (path_params.length > 0) {
        NetworkStatsController.getAllServiceNetworkStats(
          path_params,
          params,
          true,
        )
          .then(allStats => {})
          .catch(error => {});
      } else {
        this.props.NodeStatsViewModel.pod_network_stats = [];
      }
    }
  };

  getLiveServiceNetworkStats = services => {
    let params = {};
    params.fields = ["network", "spec"];
    params.size = 10;
    if (services.length > 0) {
      let path_params = [];
      services.forEach(service => {
        path_params.push({
          serviceId: service.id,
          serviceName: service.name,
          service: { id: service.id, name: service.name },
        });
      });
      if (path_params.length > 0) {
        NetworkStatsController.getAllServiceLiveNetworkStats(
          path_params,
          params,
          true,
        )
          .then(allStats => {})
          .catch(error => {});
      } else {
        this.props.NodeStatsViewModel.pod_network_stats = [];
      }
    }
  };

  getContainerStats = services => {
    let allowed = this.state.allowedServiceContainers;
    let params = this.getStartEndParams();
    params.fields = ["cpu", "memory", "network", "spec", "filesystem"];

    if (services.length > 0) {
      let path_params = [];
      let filtered_container_list = [];
      let all_containers_list = [];
      services.forEach(service => {
        let containers =
          service.spec && service.spec.services ? service.spec.services : [];
        if (allowed.includes(service.id) && containers.length > 0) {
          containers.forEach(c => {
            allowed.push(c.id);
          });
        }
        if (containers && containers.length > 0) {
          containers.forEach(con => {
            if (allowed.includes("all_services") || allowed.includes(con.id)) {
              path_params.push({
                serviceId: service.id,
                containerId: con.id,
                containerName: con.name,
                service: { id: service.id, name: service.name },
              });
              filtered_container_list.push(service.name + " / " + con.name);
            }
            all_containers_list.push(service.name + " / " + con.name);
          });
        }
      });
      // get filtered(top 5)
      this.props.NodeStatsViewModel.setContianerListForView(
        filtered_container_list.sort(),
      );
      // all containers
      this.props.NodeStatsViewModel.setAllContianerList(
        all_containers_list.sort(),
      );
      if (path_params.length > 0) {
        NetworkStatsController.getAllContainerStats(path_params, params, true)
          .then(allStats => {})
          .catch(error => {});
      } else {
        this.props.NodeStatsViewModel.container_stats = [];
      }
    }
  };

  getLiveContainerStats = services => {
    let params = this.getStartEndParams();
    params.fields = ["cpu", "memory", "spec", "filesystem"];
    params.size = 5;

    if (services.length > 0) {
      let path_params = [];
      let all_containers_list = [];
      services.forEach(service => {
        let containers =
          service.spec && service.spec.services ? service.spec.services : [];
        if (containers && containers.length > 0) {
          containers.forEach(con => {
            all_containers_list.push(service.name + " / " + con.name);
          });
        }
        path_params.push({
          serviceId: service.id,
          service: { id: service.id, name: service.name },
        });
      });
      this.props.NodeStatsViewModel.setContianerListForView(
        all_containers_list.sort(),
      );
      if (path_params.length > 0) {
        NetworkStatsController.getAllContainerCurrentStats(
          path_params,
          params,
          true,
        )
          .then(allStats => {})
          .catch(error => {});
      } else {
        this.props.NodeStatsViewModel.container_stats = [];
      }
    }
  };

  UNSAFE_componentWillMount() {
    this.props.NodeStatsViewModel.loading = true;
    this.props.NodeStatsViewModel.currentInterface = null;
  }

  drawFileSystemValues = () => {
    let containerInfo = this.NodeStatsViewModel.getStatsAndInterfaces.stats;
    let devices = [];
    let filesystems =
      containerInfo && containerInfo[0] && containerInfo[0].filesystem
        ? containerInfo[0] && containerInfo[0].filesystem
        : [];
    for (var i = 0; i < filesystems.length; i++) {
      var data = filesystems[i];
      var totalUsage = Math.floor((data.usage * 100.0) / data.capacity);
      if (data.device.includes("vda11") || data.device.includes("tmpfs")) {
        devices.push({
          name: data.device,
          usage: totalUsage,
          capacity: data.capacity,
          total: data.usage,
        });
      }
    }
    this.setState({ FileSystemInfo: devices });
    this.NodeStatsViewModel.setLoading(false);
  };
  //check for repeated widget
  avoidMultiple = layout => {
    let layOutPattern = layout.rows,
      LayoutIndex = null,
      OldData = null,
      actualData = null;

    layOutPattern.map((val, index) => {
      if (val.columns[0].widgets.length > 1) {
        actualData = val.columns[0].widgets[1];
        OldData = val.columns[0].widgets[0];
        layout.rows[index].columns[0].widgets = Array(actualData);
      } else if (val.columns[0].widgets.length === 0) {
        LayoutIndex = index;
      }
    });
    if (LayoutIndex !== null && OldData !== null) {
      layout.rows[LayoutIndex].columns[0].widgets = Array(OldData);
    }

    return layout;
  };
  //move func for widget
  onMove = (layout, isGauge) => {
    let updatedLayout = this.avoidMultiple(layout);
    if (isGauge) {
      this.setState({
        overview_layout: updatedLayout,
      });
    } else {
      this.setState({
        layout: updatedLayout,
      });
    }

    this.updateStore(updatedLayout, isGauge);
    this.toggleEdit();
  };
  //remove func for widget
  onRemove = (layout, rowIndex, columnIndex, isGauge) => {
    if (isGauge) {
      this.setState({
        overview_layout: layout,
      });
    } else {
      this.setState({
        layout: layout,
      });
    }

    this.updateStore(layout, isGauge);
    this.toggleEdit();
  };
  //switching between edit and done for dashboard
  toggleEdit = () => {
    this.setState({
      editMode: !this.state.editMode,
    });
  };
  //on add new modal func
  onAdd = (layout, rowIndex, columnIndex, isGauge) => {
    this.setState({
      isModalOpen: true,
      isGauge: isGauge,
      addWidgetOptions: {
        layout,
        rowIndex,
        columnIndex,
        isGauge,
      },
    });
  };
  //Modal close func
  onRequestClose = () => {
    this.setState({
      isModalOpen: false,
    });
  };

  //restore the default state
  retoreDefault = () => {
    this.setState({
      resetModalOpen: true,
    });
  };

  //update the current layout in the stream.
  updateStore(layout, isGauge = false) {
    let storableData = [];
    let layoutData = {
      rows: layout.rows,
    };
    storableData.push(layoutData);
    if (isGauge) {
      localStorage.setItem("statsOverviewLayout", JSON.stringify(layout));
      localStorage.setItem("layoutVersion", JSON.stringify(2));
      this.setState({ overview_layout: layout });
    } else {
      localStorage.setItem("statsLayout", JSON.stringify(layout));
      localStorage.setItem("layoutVersion", JSON.stringify(2));
      this.setState({ layout: layout });
    }
    this.getServiceList(true, 0, "", false);
    this.updateChart(true);
  }
  // on change slection widgets
  handleWidgetSelection = widgetName => {
    const {
      layout,
      rowIndex,
      columnIndex,
      isGauge,
    } = this.state.addWidgetOptions;
    let Updatedlayout = this.avoidDuplicates(layout, widgetName);
    if (layout.rows[rowIndex].columns[columnIndex].widgets.length > 0) {
      let data = layout.rows[rowIndex].columns[columnIndex].widgets.splice(0);
      layout.rows[rowIndex].columns[columnIndex].widgets = Array(data[0]);
      layout.rows[rowIndex].columns[columnIndex].widgets[0].key = widgetName;
    } else {
      Updatedlayout = addWidget(layout, rowIndex, columnIndex, widgetName);
    }
    if (isGauge) {
      this.setState({
        overview_layout: Updatedlayout,
      });
    } else {
      this.setState({
        layout: Updatedlayout,
      });
    }
    this.updateStore(Updatedlayout, isGauge);
    this.toggleEdit();
    this.onRequestClose();
  };

  avoidDuplicates = (layout, WidgetName) => {
    let layOutPattern = layout.rows;

    layOutPattern.map((val, index) => {
      if (val.columns[0].widgets.length > 0) {
        if (WidgetName === val.columns[0].widgets[0].key) {
          {
            layout.rows[index].columns[0].widgets = [];
          }
        }
      }
    });
    return layout;
  };

  renderServiceContainers = () => {
    let isDiabled = this.state.containerSelector !== "CUSTOM";
    let filterOptions = this.state.ServiceList ? this.state.ServiceList : [];
    let defaultMenu = {
      title: "All Services",
      key: "all_services",
      value: "all_services",
      disabled: isDiabled,
      children: [],
    };
    if (filterOptions.length > 0) {
      filterOptions.forEach(sl => {
        let containers = sl.spec && sl.spec.services ? sl.spec.services : [];
        let sl_data = {
          title: sl.name,
          key: sl.id,
          value: sl.id,
          disabled: isDiabled,
          children: [],
        };
        containers.forEach(c => {
          let cl_data = {
            title: c.name,
            key: c.id,
            value: c.id,
            disabled: isDiabled,
          };
          sl_data.children.push(cl_data);
        });

        defaultMenu.children.push(sl_data);
      });
    }
    return defaultMenu;
  };

  getAllowedServiceContainerList = () => {
    return new Promise((resolve, reject) => {
      let allowed_service_containers = [];
      let containerSelector = this.state.containerSelector;
      let nodeId = this.props.match.params.nodeId
        ? this.props.match.params.nodeId
        : this.props.nodeId;
      let params = this.getStartEndParams();
      if (
        params.start_date &&
        params.start_date !== "" &&
        params.end_date &&
        params.end_date !== ""
      ) {
        NetworkStatsController.getNodeServiceContainersStatsSummary(
          nodeId,
          params,
        )
          .then(response => {
            if (response && response.container_summary) {
              let topContainerSummary = [];
              if (containerSelector === "TOP_CPU") {
                if (response.container_summary.cpu) {
                  topContainerSummary = response.container_summary.cpu;
                }
              } else if (containerSelector === "TOP_MEMORY") {
                if (response.container_summary.memory) {
                  topContainerSummary = response.container_summary.memory;
                }
              } else if (containerSelector === "TOP_FILESYSTEM") {
                if (response.container_summary.filesystem) {
                  topContainerSummary = response.container_summary.filesystem;
                }
              }
              topContainerSummary
                .slice(0, CONTAINER_STATS_LIMIT)
                .forEach(item => {
                  if (item && item.container && item.container.id) {
                    allowed_service_containers.push(item.container.id);
                  }
                });
              resolve(allowed_service_containers);
            } else {
              resolve(allowed_service_containers);
            }
          })
          .catch(err => {
            resolve(allowed_service_containers);
          });
      }
    });
  };

  onChangeContainerSelector = value => {
    if (value) {
      this.setState({ containerSelector: value }, () => {
        let statsLayout = localStorage.statsLayout
          ? JSON.parse(localStorage.statsLayout)
          : null;
        let layout =
          localStorage.layoutVersion &&
          JSON.parse(localStorage.layoutVersion) === 2 &&
          statsLayout &&
          statsLayout.rows &&
          statsLayout.rows.length > 3
            ? statsLayout
            : copyObject(DefaultStatsWidget);
        this.arrangeStatsLayoutWidgets(layout, value);
        if (value !== "CUSTOM") {
          this.getAllowedServiceContainerList().then(response => {
            this.setState({ allowedServiceContainers: response }, () => {
              // get service network stats
              this.getServiceNetworkStats(
                this.state.ServiceList ? this.state.ServiceList : [],
              );
              // get contianer stats
              this.getContainerStats(
                this.state.ServiceList ? this.state.ServiceList : [],
              );
            });
          });
        }
      });
    }
  };

  arrangeStatsLayoutWidgets = (layout, containerSelectorValue) => {
    let rows = [];
    let statsLayout =
      layout && layout.rows && layout.rows.length > 0
        ? copyObject(layout)
        : null;
    if (statsLayout) {
      if (containerSelectorValue === "TOP_CPU") {
        // Order cpu, memory, filesystem, network interface
        rows = this.getDefaultStatsLayoutRows(layout);
      } else if (containerSelectorValue === "TOP_MEMORY") {
        // Order memory, cpu, filesystem, network interface
        statsLayout.rows.forEach((row, index) => {
          if (index === 0) {
            let isExists = this.isWidgetExistInStatsLayout(
              layout,
              "MemoryChart",
            );
            if (isExists) {
              row.columns[0].widgets[0] = { key: "MemoryChart" };
            } else {
              row.columns[0].widgets = [];
            }
          } else if (index === 1) {
            let isExists = this.isWidgetExistInStatsLayout(layout, "CPUChart");
            if (isExists) {
              row.columns[0].widgets[0] = { key: "CPUChart" };
            } else {
              row.columns[0].widgets = [];
            }
          } else if (index === 2) {
            let isExists = this.isWidgetExistInStatsLayout(
              layout,
              "FileSystemChart",
            );
            if (isExists) {
              row.columns[0].widgets[0] = { key: "FileSystemChart" };
            } else {
              row.columns[0].widgets = [];
            }
          } else if (index === 3) {
            let isExists = this.isWidgetExistInStatsLayout(
              layout,
              "NetworkChart",
            );
            if (isExists) {
              row.columns[0].widgets[0] = { key: "NetworkChart" };
            } else {
              row.columns[0].widgets = [];
            }
          }
          rows.push(row);
        });
      } else if (containerSelectorValue === "TOP_FILESYSTEM") {
        // Order filesystem, cpu, memory, network interface
        statsLayout.rows.forEach((row, index) => {
          if (index === 0) {
            let isExists = this.isWidgetExistInStatsLayout(
              layout,
              "FileSystemChart",
            );
            if (isExists) {
              row.columns[0].widgets[0] = { key: "FileSystemChart" };
            } else {
              row.columns[0].widgets = [];
            }
          } else if (index === 1) {
            let isExists = this.isWidgetExistInStatsLayout(layout, "CPUChart");
            if (isExists) {
              row.columns[0].widgets[0] = { key: "CPUChart" };
            } else {
              row.columns[0].widgets = [];
            }
          } else if (index === 2) {
            let isExists = this.isWidgetExistInStatsLayout(
              layout,
              "MemoryChart",
            );
            if (isExists) {
              row.columns[0].widgets[0] = { key: "MemoryChart" };
            } else {
              row.columns[0].widgets = [];
            }
          } else if (index === 3) {
            let isExists = this.isWidgetExistInStatsLayout(
              layout,
              "NetworkChart",
            );
            if (isExists) {
              row.columns[0].widgets[0] = { key: "NetworkChart" };
            } else {
              row.columns[0].widgets = [];
            }
          }
          rows.push(row);
        });
      } else {
        // Order cpu, memory, filesystem, network interface
        rows = this.getDefaultStatsLayoutRows(layout);
      }
    }
    statsLayout.rows = rows;
    this.setState({
      layout: statsLayout,
    });
    this.updateStore(statsLayout, false);
  };

  getDefaultStatsLayoutRows = layout => {
    let rows = [];
    let statsLayout =
      layout && layout.rows && layout.rows.length > 0
        ? copyObject(layout)
        : null;
    if (statsLayout) {
      // Order cpu, memory, filesystem, network interface
      statsLayout.rows.forEach((row, index) => {
        if (index === 0) {
          let isExists = this.isWidgetExistInStatsLayout(layout, "CPUChart");
          if (isExists) {
            row.columns[0].widgets[0] = { key: "CPUChart" };
          } else {
            row.columns[0].widgets = [];
          }
        } else if (index === 1) {
          let isExists = this.isWidgetExistInStatsLayout(layout, "MemoryChart");
          if (isExists) {
            row.columns[0].widgets[0] = { key: "MemoryChart" };
          } else {
            row.columns[0].widgets = [];
          }
        } else if (index === 2) {
          let isExists = this.isWidgetExistInStatsLayout(
            layout,
            "FileSystemChart",
          );
          if (isExists) {
            row.columns[0].widgets[0] = { key: "FileSystemChart" };
          } else {
            row.columns[0].widgets = [];
          }
        } else if (index === 3) {
          let isExists = this.isWidgetExistInStatsLayout(
            layout,
            "NetworkChart",
          );
          if (isExists) {
            row.columns[0].widgets[0] = { key: "NetworkChart" };
          } else {
            row.columns[0].widgets = [];
          }
        }
        rows.push(row);
      });
    }
    return rows;
  };

  isWidgetExistInStatsLayout = (layout, widgetKey) => {
    let isExists = true;
    if (layout && layout.rows && layout.rows.length > 0) {
      let index = layout.rows.findIndex(row => {
        return (
          row.columns[0].widgets &&
          row.columns[0].widgets.length > 0 &&
          row.columns[0].widgets[0].key === widgetKey
        );
      });
      if (index === -1) {
        isExists = false;
      }
    }
    return isExists;
  };

  render() {
    let node_data = this.inodeModel.inode;
    let treeData = [this.renderServiceContainers()];

    const tProps = {
      treeData,
      value: this.state.allowedServiceContainers,
      treeCheckable: true,
      showCheckedStrategy: SHOW_PARENT,
      searchPlaceholder: "Please select",
    };

    return (
      <div>
        <LoadingComponent loading={this.NodeStatsViewModel.loading}>
          <Row gutter={24} type="flex" align="middle">
            <Col span={20}>
              <h2 className="page-title">
                <Icons type="fa" name="FaRegHdd" />
                {"  "}
                {node_data.name ? node_data.name : null}
              </h2>
              <BreadcrumbComponent
                {...this.props}
                BreadcrumbList={this.state.BreadcrumbList}
              />
            </Col>
          </Row>
          <div
            className="widget-dash"
            style={{
              padding: 24,
              background: "#fff",
              minHeight: 360,
            }}
          >
            <div
              className={
                "flex-column " + (this.state.editMode ? "" : "stats-dashboard")
              }
            >
              <StatsHeader
                fetchNewStatInfo={this.fetchNewStatInfo}
                ServiceList={this.state.ServiceList}
                nodeId={
                  this.props.match.params.containerId
                    ? this.props.match.params.containerId
                    : this.props.match.params.nodeId
                }
                onEdit={this.toggleEdit}
                onAdd={this.onAdd}
                onUpdate={this.toggleEdit}
                editStatus={this.state.editMode}
                onReset={this.retoreDefault}
                {...this.props}
                hideOptions={true}
              />
              <Dashboard
                editable={this.state.editMode}
                onAdd={(layout, rowIndex, columnIndex) => {
                  this.onAdd(layout, rowIndex, columnIndex, true);
                }}
                addWidgetComponent={AddWidget}
                addWidgetComponentText={this.state.widgets ? "Change" : "Add"}
                onRemove={(layout, rowIndex, columnIndex) => {
                  this.onRemove(layout, rowIndex, columnIndex, true);
                }}
                onMove={layout => {
                  this.onMove(layout, true);
                }}
                widgets={this.state.overview_widgets}
                layout={this.state.overview_layout}
                {...this.props}
                isGauge={true}
                className="flex-column"
              />
            </div>

            <Row gutter={0}>
              <StatsHeader
                fetchNewStatInfo={this.fetchNewStatInfo}
                ServiceList={this.state.ServiceList}
                nodeId={
                  this.props.match.params.containerId
                    ? this.props.match.params.containerId
                    : this.props.match.params.nodeId
                }
                onEdit={this.toggleEdit}
                onAdd={this.onAdd}
                onUpdate={this.toggleEdit}
                editStatus={this.state.editMode}
                onReset={this.retoreDefault}
                {...this.props}
              />
              <div className="flex-column">
                <Select
                  defaultValue={this.state.containerSelector}
                  value={this.state.containerSelector}
                  onChange={this.onChangeContainerSelector}
                  style={{ margin: "10px 15px 10px 15px", width: 225 }}
                >
                  <Option value="CUSTOM">{"Custom"}</Option>
                  <Option value="TOP_CPU">{`Top ${CONTAINER_STATS_LIMIT} CPU used containers`}</Option>
                  <Option value="TOP_FILESYSTEM">{`Top ${CONTAINER_STATS_LIMIT} Disk used containers`}</Option>
                  <Option value="TOP_MEMORY">{`Top ${CONTAINER_STATS_LIMIT} Memory used containers`}</Option>
                </Select>
                {this.state.containerSelector === "CUSTOM" && (
                  <TreeSelect
                    onChange={this.onChangeServiceFilter}
                    {...tProps}
                    treeDefaultExpandAll
                    style={{ width: 250 }}
                    placeholder="Please select"
                    className="ServiceFilter"
                  />
                )}
                <Row
                  type="flex"
                  align="top"
                  className={this.state.editMode ? "" : "stats-dashboard"}
                >
                  <Col
                    xs={{ span: 12 }}
                    sm={{ span: 12 }}
                    md={{ span: 13 }}
                    lg={{ span: 16 }}
                    xl={{ span: 18 }}
                  >
                    <AddWidgetModal
                      widgets={
                        this.state.isGauge
                          ? this.state.overview_widgets
                          : this.state.FileSystemInfo.length > 0
                          ? this.state.widgets
                          : this.state.older_node_widget
                      }
                      isModalOpen={this.state.isModalOpen}
                      onRequestClose={this.onRequestClose}
                      onWidgetSelect={this.handleWidgetSelection}
                    />
                    <ResetDashboardModal
                      onReset={this.retoreDefault}
                      isModalOpen={this.state.resetModalOpen}
                      onRequestClose={this.onRestoreClose}
                      onConfirm={this.resetdefault}
                      title={getCurrentlocaleText(
                        "stats.config.reset.title.text",
                      )}
                      message={getCurrentlocaleText(
                        "stats.config.reset.message.text",
                      )}
                    />
                    <Dashboard
                      editable={this.state.editMode}
                      onAdd={(layout, rowIndex, columnIndex) => {
                        this.onAdd(layout, rowIndex, columnIndex, false);
                      }}
                      addWidgetComponent={AddWidget}
                      addWidgetComponentText={
                        this.state.widgets ? "Change" : "Add"
                      }
                      onRemove={(layout, rowIndex, columnIndex) => {
                        this.onRemove(layout, rowIndex, columnIndex, false);
                      }}
                      onMove={layout => {
                        this.onMove(layout, false);
                      }}
                      widgets={this.state.widgets}
                      layout={this.state.layout}
                      {...this.props}
                      isGauge={false}
                    />
                  </Col>
                  <Col
                    xs={{ span: 12 }}
                    sm={{ span: 12 }}
                    md={{ span: 11 }}
                    lg={{ span: 8 }}
                    xl={{ span: 6 }}
                  >
                    <EventsTimeLine />
                  </Col>
                </Row>
              </div>
            </Row>
          </div>
        </LoadingComponent>
      </div>
    );
  }
}

export default StatsContainer;
