import React, { Component } from "react";
import { Row, Col, Card } from "antd";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import "mapbox-gl/dist/mapbox-gl.css";
import { RightOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

import checkImage from "../../assets/img/alive_node.png";
import unreachableImage from "../../assets/img/unreachable_node.png";
import newImage from "../../assets/img/new_node.png";
import rebootImage from "../../assets/img/reboot.png";
import clusterImage from "../../assets/img/cluster_image.png";
import DashboardController from "controller/DashboardController";
import { getCurrentlocaleText, toTitleCase } from "../../Core/Utils";

class DashboardMap extends Component {
  constructor(props) {
    super(props);
    // GLOBAL_SETTINGS is configured form node js
    this.GLOBAL_SETTINGS = GLOBAL_SETTINGS;
    this.EnvConfig = ENV_CONFIG;
    this.state = {};
    this.map = {};
    mapboxgl.accessToken =
      this.EnvConfig && this.EnvConfig.MAPBOX_TOKEN
        ? this.EnvConfig.MAPBOX_TOKEN
        : "pk.eyJ1Ijoia2FubmFuMTIzNDUiLCJhIjoiY2p0c3hqNXVkMTV0aTN6bnIzZHA3eGlheSJ9.dr7PUwb77tqQT3eOHwfJ5Q";
    this.textColor =
      this.GLOBAL_SETTINGS && this.GLOBAL_SETTINGS.text_color
        ? this.GLOBAL_SETTINGS.text_color
        : "#012a22";
    this.mapContainer = React.createRef();
    this.statusColor = {
      ALIVE: "#00a854",
      NEW: "#108ee9",
      REBOOTING: "#f3ce01",
      UNREACHABLE: "#108ee9",
    };
  }

  componentDidMount() {
    this.getNodeLocationInfo();
    // DOC: https://docs.mapbox.com/help/tutorials/use-mapbox-gl-js-with-react/
    this.map = new mapboxgl.Map({
      container: this.mapContainer.current,
      style: "mapbox://styles/mapbox/light-v11",
      zoom: 1,
      center: [40, 10],
      minZoom: window.innerWidth > 1280 ? 1.5 : 1.1,
      projection: "mercator",
      fitBoundsOptions: { padding: 100 },
    }).addControl(new mapboxgl.NavigationControl());

    // disable map rotation using right click + drag
    this.map.dragRotate.disable();

    // disable map rotation using touch rotation gesture
    this.map.touchZoomRotate.disableRotation();
    window.addEventListener("resize", () => {
      this.map.resize();
    });
  }

  getNodeLocationInfo = () => {
    let params =
      this.props.match.params && this.props.match.params.id
        ? { org_id: this.props.match.params.id }
        : {};
    DashboardController.getInodesLocations(params)
      .then(response => {
        let location_list = [];
        if (response.location_list) {
          location_list = response.location_list;
          // preventive fix to avoid image addition to the map redering issue due to delay[LAT-18542]
          setTimeout(() => {
            this.getMapData(location_list);
          }, 500);
        }
      })
      .catch(err => {});
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.match &&
      prevProps.match.params &&
      this.props.match &&
      this.props.match.params
    ) {
      if (prevProps.match.params.id != this.props.match.params.id) {
        this.getNodeLocationInfo();
        this.map = new mapboxgl.Map({
          container: this.mapContainer.current,
          style: "mapbox://styles/mapbox/light-v11",
          zoom: 1,
          center: [40, 10],
          minZoom: window.innerWidth > 1280 ? 1.5 : 1.1,
          projection: "mercator",
          fitBoundsOptions: { padding: 100 },
        }).addControl(new mapboxgl.NavigationControl());

        // disable map rotation using right click + drag
        this.map.dragRotate.disable();

        // disable map rotation using touch rotation gesture
        this.map.touchZoomRotate.disableRotation();
        window.addEventListener("resize", () => {
          this.map.resize();
        });
      }
    }
  }

  getMapData = response => {
    // Remove the layer and point if it exists
    if (this.map.getLayer("points-layer")) {
      this.map.removeLayer("points-layer");
    }
    if (this.map.getSource("map-dash")) {
      this.map.removeSource("map-dash");
    }

    const currentOrgId =
      this.props.match.params && this.props.match.params.id
        ? this.props.match.params.id
        : this.props.OrgViewModel &&
          this.props.OrgViewModel.org &&
          this.props.OrgViewModel.org.id != undefined
        ? this.props.OrgViewModel.org.id
        : this.props.AuthStore &&
          this.props.AuthStore.loggedInUser &&
          this.props.AuthStore.loggedInUser.organization &&
          this.props.AuthStore.loggedInUser.organization.id;
    let usedCoordinates = [];
    let iNodeLatLong = response.map((obj, i) => {
      let coordinates =
        obj.geo_location && obj.geo_location.coordinates
          ? [...obj.geo_location.coordinates]
          : [];
      usedCoordinates.push(coordinates.toString());
      // Check if the coordinates have been used before
      if (usedCoordinates.includes(coordinates.toString())) {
        const adjustmentValue =
          0.0001 *
          usedCoordinates.filter(coord => coord === coordinates.toString())
            .length;
        // If yes, slightly adjust the latitude
        coordinates[1] += adjustmentValue;
      }
      // Add the adjusted coordinates to the usedCoordinates array
      return {
        type: "Feature",
        properties: {
          color: this.statusColor[obj.node_status],
          status: obj.node_status,
          node_name: obj.node.name,
          node_id: obj.node.id,
          index: i,
          popuphtml:
            "<div id='popup' class='popup'>" +
            "<h3>" +
            getCurrentlocaleText("dashboard-inode-details-title") +
            "</h3>" +
            "<p><strong>" +
            getCurrentlocaleText("dashboard-node-name-title") +
            "</strong> <a href=/orgs/" +
            currentOrgId +
            "/inodes/" +
            obj.node.id +
            "  id='place-name' >" +
            obj.node.name +
            "</a></p>" +
            "<p><strong>" +
            getCurrentlocaleText("event.status.text") +
            "</strong> <span id='place-address'>" +
            obj.node_status +
            "</span></p>" +
            "<p><strong>" +
            getCurrentlocaleText("inode.location.label.text") +
            "</strong> <span id='place-address'>" +
            obj.location +
            "</span></p>" +
            "</div>",
        },
        // LAT,LONG format reverse of geo json to make it work with map
        geometry:
          obj.geo_location && obj.geo_location.coordinates
            ? { type: "Point", coordinates: coordinates }
            : { type: "Point", coordinates: [] },
      };
    });

    this.map.addSource("map-dash", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [...iNodeLatLong],
      },
      cluster: true,
      clusterRadius: 80,
    });

    this.map.loadImage(checkImage, (error, image) => {
      if (error) throw error;
      this.map.addImage("alive-image", image);
    });

    this.map.loadImage(unreachableImage, (error, image) => {
      if (error) throw error;
      this.map.addImage("unreachable-image", image);
    });

    this.map.loadImage(newImage, (error, image) => {
      if (error) throw error;
      this.map.addImage("new-image", image);
    });
    this.map.loadImage(rebootImage, (error, image) => {
      if (error) throw error;
      this.map.addImage("reboot-image", image);
    });

    this.map.loadImage(clusterImage, (error, image) => {
      if (error) throw error;
      this.map.addImage("cluster-image", image);
    });

    // inspect a cluster on click

    this.map.on("click", "clusters", e => {
      var features = this.map.queryRenderedFeatures(e.point, {
        layers: ["clusters"],
      });
      var clusterId = features[0].properties.cluster_id;
      this.map
        .getSource("map-dash")
        .getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err) return;

          this.map.easeTo({
            center: features[0].geometry.coordinates,
            zoom: zoom,
          });
        });
    });

    this.map.on("mouseenter", "clusters", () => {
      this.map.getCanvas().style.cursor = "pointer";
    });

    this.map.on("mouseleave", "clusters", () => {
      this.map.getCanvas().style.cursor = "";
    });

    this.map.addLayer({
      id: "clusters",
      type: "symbol",
      source: "map-dash",
      filter: ["has", "point_count"],
      layout: {
        "icon-image": "cluster-image",
        "icon-size": 0.2,
        "icon-anchor": "center",
        "text-field": "{point_count_abbreviated}",
        "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
        "text-size": 20,
      },
      paint: {
        "text-color": this.textColor,
      },
    });

    this.map.addLayer({
      id: "points-layer",
      type: "symbol",
      source: "map-dash",
      filter: ["!=", "cluster", true],
      layout: {
        "icon-image": [
          "match",
          ["get", "status"],
          "ALIVE",
          "alive-image",
          "UNREACHABLE",
          "unreachable-image",
          "NEW",
          "new-image",
          "REBOOTING",
          "reboot-image",
          "new-image",
        ],
        "icon-size": 1.1,
        "icon-allow-overlap": true,
      },
    });

    this.map.on("click", "points-layer", e => {
      var coordinates = e.features[0].geometry.coordinates.slice();
      // Ensure that if the map is zoomed out such that multiple
      // copies of the feature are visible, the popup appears
      // over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }
      let content =
        e.features[0] &&
        e.features[0].properties &&
        e.features[0].properties.popuphtml
          ? e.features[0].properties.popuphtml
          : null;
      new mapboxgl.Popup()
        .setLngLat(e.lngLat)
        .setHTML(content)
        .addTo(this.map);
    });

    this.map.on("mouseenter", "points-layer", () => {
      this.map.getCanvas().style.cursor = "pointer";
    });

    this.map.on("mouseleave", "points-layer", () => {
      this.map.getCanvas().style.cursor = "";
    });
  };

  getSWCoordinates(coordinatesCollection) {
    const lowestLng = Math.min(
      ...coordinatesCollection.map(coordinates => coordinates[0]),
    );
    const lowestLat = Math.min(
      ...coordinatesCollection.map(coordinates => coordinates[1]),
    );
    return [lowestLng, lowestLat];
  }

  getNECoordinates(coordinatesCollection) {
    const highestLng = Math.max(
      ...coordinatesCollection.map(coordinates => coordinates[0]),
    );
    const highestLat = Math.max(
      ...coordinatesCollection.map(coordinates => coordinates[1]),
    );
    return [highestLng, highestLat];
  }

  calcBoundsFromCoordinates(coordinatesCollection) {
    return [
      this.getSWCoordinates(coordinatesCollection),
      this.getNECoordinates(coordinatesCollection),
    ];
  }

  render() {
    const currentOrgId =
      this.props.match.params && this.props.match.params.id
        ? this.props.match.params.id
        : this.props.OrgViewModel &&
          this.props.OrgViewModel.org &&
          this.props.OrgViewModel.org.id != undefined
        ? this.props.OrgViewModel.org.id
        : this.props.AuthStore &&
          this.props.AuthStore.loggedInUser &&
          this.props.AuthStore.loggedInUser.organization &&
          this.props.AuthStore.loggedInUser.organization.id;
    return (
      <div>
        <Card
          className={"dashboard-map-card-title"}
          title={
            <Row>
              <Col span={12} style={{ fontSize: 18 }}>
                {getCurrentlocaleText("dashboard-map-title")}
              </Col>
              <Col
                span={12}
                push={8}
                style={{
                  float: "right",
                  fontSize: 18,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Link to={"/orgs/" + currentOrgId + "/inodes"}>
                  {getCurrentlocaleText("dashboard-map-hyperlink-title")}
                </Link>
                <RightOutlined style={{ marginBottom: -4 }} />
              </Col>
            </Row>
          }
        >
          <div className="dashboard-map-legend">
            <Row>
              <Col>
                <img src={checkImage} />
                <span>{getCurrentlocaleText("general.alive.text")}</span>
              </Col>
              <Col style={{ marginLeft: 20 }}>
                <img src={unreachableImage} />
                <span>{getCurrentlocaleText("general.unreachable.text")}</span>
              </Col>
              <Col style={{ marginLeft: 20, marginRight: 20 }}>
                <img src={newImage} />
                <span>{getCurrentlocaleText("general.new.text")}</span>
              </Col>
              <Col>
                <img src={rebootImage} />
                <span>{getCurrentlocaleText("general.rebooting.text")}</span>
              </Col>
            </Row>
          </div>
          <div className="dashboard-map-container-wrapper">
            <div
              ref={this.mapContainer}
              id="map"
              className="map map-container"
            />
          </div>
        </Card>
      </div>
    );
  }
}

export default DashboardMap;
