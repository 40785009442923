import React, { Component } from "react";
//Logo
import NeeveFavicon from "assets/img/neeve-favicon.png";

const style = {
  marginLeft: 8,
  marginRight: 8,
  minWidth: 32,
  maxWidth: 40,
  minHeight: 32,
  maxHeight: 40,
};

class FaviconLogo extends Component {
  constructor(props) {
    super(props);
    // GLOBAL_SETTINGS configured from the node js
    this.GLOBAL_SETTINGS = GLOBAL_SETTINGS;
  }

  render() {
    return (
      <div className="loader-wrapper">
        {this.GLOBAL_SETTINGS ? (
          !this.GLOBAL_SETTINGS.is_default &&
          this.GLOBAL_SETTINGS.favicon_url ? (
            <img
              src={this.GLOBAL_SETTINGS.favicon_url}
              style={style}
              className="App-logo"
            />
          ) : this.GLOBAL_SETTINGS && this.GLOBAL_SETTINGS.favicon_url ? (
            <a
              href="https://neeve.ai"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img
                src={this.GLOBAL_SETTINGS.favicon_url}
                style={style}
                className="App-logo"
                alt="neeve.ai"
                title="view.com"
              />
            </a>
          ) : (
            <a
              href="https://neeve.ai"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img
                src={NeeveFavicon}
                className="App-logo"
                style={style}
                alt="neeve.ai"
                title="neeve.ai"
              />
            </a>
          )
        ) : (
          <a href="https://neeve.ai" rel="noopener noreferrer" target="_blank">
            <img
              src={NeeveFavicon}
              className="App-logo"
              style={style}
              alt="neeve.ai"
              title="neeve.ai"
            />
          </a>
        )}
      </div>
    );
  }
}

export default FaviconLogo;
