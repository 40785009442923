import React, { Component } from "react";
import {
  Row,
  Col,
  Icon,
  Button,
  Card,
  Modal,
  Tag,
  Switch,
  Collapse,
  DatePicker,
  Radio,
  Slider,
} from "antd";
import { Form } from "@ant-design/compatible";
import moment from "moment";
import Input from "../UI-Components/InputBox";
import { observer, inject } from "mobx-react";
import {
  getCurrentlocaleText,
  focusFirstInput,
  removeDefaultLabels,
  getOrgPolicyCounInfo,
  getDefaultTimezone,
  markCollapseHeader,
  computeMinutesToDuration,
  checkforFeatureEnabled,
  isEmpty,
  getSamplingFreqMarks,
} from "Core/Utils";
import LabelProvider from "components/UI-Components/LabelProvider";
import Icons from "components/UI-Components/Icons";
import HelpCard from "components/UI-Components/HelpCard";

import ClusterNodeConfigSelector from "components/Clusters/ClusterNodeConfigSelector";
import ClusterController from "controller/ClusterController";
import DurationSelector from "components/UI-Components/DurationSelector";
import ClusterViewModel from "view-model/ClusterViewModel";
import PopOverHelp from "components/UI-Components/PopOverHelp";
const FormItem = Form.Item;
const formItemLayout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 14 },
};

const Panel = Collapse.Panel;
const RadioGroup = Radio.Group;
const RadioButton = Radio.Button;

@inject(
  "UiStore",
  "AuthStore",
  "OrgViewModel",
  "TimezoneViewModel",
  "ClusterViewModel",
)
@observer
class ClusterFormPage extends Component {
  constructor(props) {
    super(props);
    this.orgModel = this.props.OrgViewModel;
    this.timezoneModel = this.props.TimezoneViewModel;
    this.clustereModel = this.props.ClusterViewModel;
    this.state = {
      tags: [],
      metadata: {},
      intialValues: { name: null, metadata: null },
      removedMasterNodes: [],
      nonCandidateMasterNodes: [],
      resetNodes: [],
      resetNodesToCandidate: [],
      upgradeChannelSelected: "",
      vrrpTypeSelected: "WAN",
      vrrpstatus: false,
      softwareUpgrade: false,
      upgradeAfter: moment.tz(
        new Date().getTime(),
        this.timezoneModel.userTimezone ||
          this.timezoneModel.orgTimezone ||
          getDefaultTimezone().value,
      ),
      upgradeFrom: null,
      upgradeTo: null,
      getUpgradeAfter: null,
      getUpgradeBefore: null,
      HwMonitoring: false,
      samplingFrequencySelected: 50000,
      threatDetection: false,
      deviceDiscovery: false,
      HWMDurationValue: {
        duration: 5,
        units: "MINUTES",
      },
      tanDadMode: false,
      tanDadDurationValue: {
        duration: 5,
        units: "MINUTES",
      },
      orgInitialVal: {},
      clusterHighAvailability: false,
      multiNICMode: false,
    };
    this.clusterModel = this.props.ClusterViewModel;
  }

  getTagKeyValues = labels => {
    let tags = [];
    if (labels.name) {
      delete labels.name;
    }
    for (let key in labels) {
      if (key && key.charAt(0) != "_") tags.push(key + " : " + labels[key]);
    }
    return tags;
  };

  updateSelectedKeys = values => {
    let selectedLabels = [];
    if (values.length > 0) {
      values.forEach(val => {
        selectedLabels.push(val.split(":")[0].trim());
      });
    }
    return selectedLabels;
  };

  componentDidMount() {
    focusFirstInput();
    this.getOrgInfo();
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.formValues
    ) {
      let formValues = this.props.location.state.formValues;
      this.setState({
        intialValues: formValues,
        tags: this.getTagKeyValues(
          formValues.metadata && formValues.metadata.labels,
        ),
        metadata: removeDefaultLabels(
          formValues.metadata && formValues.metadata.labels,
        ),
      });
      this.setUpgradeDetails(formValues);
    } else {
      if (this.props.match.params && this.props.match.params.cluster_id) {
        ClusterController.getCluster(this.props.match.params.cluster_id).then(
          result => {
            this.setUpgradeDetails(result);
            this.setState({
              intialValues: result,
              tags: this.getTagKeyValues(
                result.metadata && result.metadata.labels,
              ),
              metadata: removeDefaultLabels(
                result.metadata && result.metadata.labels,
              ),
            });
          },
        );
      }
    }
    if (this.props.match.params && this.props.match.params.cluster_id) {
      this.getDadInfo(this.props.match.params.cluster_id);
      this.getHWMInfo(this.props.match.params.cluster_id);
      this.getTrafficInsight(this.props.match.params.cluster_id);
      this.getDeviceDiscovery(this.props.match.params.cluster_id);
    }
  }

  getDeviceDiscovery = node_id => {
    ClusterController.getDeviceDiscovery(node_id).then(res => {
      if (res && res.enable) {
        this.setState({
          deviceDiscovery: res.enable,
        });
        this.clustereModel.setDeviceDiscovery(res.enable);
      }
    });
  };

  changeDeviceDiscovery = () => {
    this.setState({
      deviceDiscovery: !this.state.deviceDiscovery,
    });
  };

  onHWMModeChange = value => {
    this.setState({
      HwMonitoring: value,
      HWMDurationValue: {
        duration: 5,
        units: "MINUTES",
      },
    });
  };

  onHWMDurationChange = value => {
    this.props.form.setFields({
      max_hwm_time: {
        value: {
          duration: value.duration,
          units: value.units,
        },
      },
    });
    this.setState({ HWMDurationValue: value });
  };

  /**
   *  GET TAN DAD VALUE TO PRE-FILL
   */
  getDadInfo = clusterId => {
    ClusterController.getTanDad(clusterId).then(resp => {
      if (resp) {
        this.setState({
          tanDadMode: resp.enable,
          tanDadDurationValue: computeMinutesToDuration(resp.scan_interval),
        });
        this.clustereModel.setTandadOldVal({
          Mode: resp.enable,
          Duration: resp.scan_interval,
        });
        if (resp.enable) {
          markCollapseHeader("node-advanced-settings-collapse", true);
        }
      }
    });
  };
  /**
   *  CHECK FOR Threat Intelligence
   */
  getTrafficInsight = clusterId => {
    let ctdMethod = false;
    ClusterController.getTrafficInsight(clusterId).then(resp => {
      if (resp) {
        if (!resp.threat_detection_enable && !resp.network_visibility_enable) {
          this.setState({
            threatDetection: resp.threat_detection_enable,
          });
        } else {
          this.setState({
            samplingFrequencySelected: resp.sampling_frequency_in_pkts,
            threatDetection: resp.threat_detection_enable,
          });
        }
        this.clustereModel.setTrafficInsightData({
          resp,
        });
      }
    });
  };
  getHWMInfo = clusterId => {
    ClusterController.getHWM(clusterId).then(resp => {
      if (resp) {
        this.setState({
          HwMonitoring: resp.enable,
          HWMDurationValue: computeMinutesToDuration(resp.scan_interval),
        });

        this.clusterModel.setHwMonitoringOldVal({
          Mode: resp.enable,
          Duration: resp.scan_interval,
        });
        if (resp.enable) {
          markCollapseHeader("node-advanced-settings-collapse", true);
        }
      }
    });
  };

  /**
   * GET CURRENT ORG POLICY INFO
   */
  getOrgInfo = () => {
    let currentOrgInfo = this.orgModel.org;
    if (currentOrgInfo) {
      this.setState({ orgInitialVal: currentOrgInfo });
      if (
        currentOrgInfo.policy &&
        currentOrgInfo.policy.software_upgrade &&
        currentOrgInfo.policy.software_upgrade.channel
      ) {
        this.props.form.setFields({
          upgrade_policy_channel: {
            value: currentOrgInfo.policy.software_upgrade.channel,
          },
        });
        this.setState({
          upgradeChannelSelected:
            currentOrgInfo.policy.software_upgrade.channel,
        });
        if (currentOrgInfo.policy.software_upgrade.channel !== "OFF") {
          this.setState({ softwareUpgrade: true });
        }
      }
    }
  };

  setUpgradeDetails = formValues => {
    if (formValues) {
      // Update upgrade config
      if (formValues.upgrade_config && formValues.upgrade_config.channel) {
        this.setState({
          upgradeChannelSelected: formValues.upgrade_config.channel,
          softwareUpgrade:
            formValues.upgrade_config.channel != "OFF" ? true : false,
        });
        if (formValues.upgrade_config.channel != "OFF") {
          markCollapseHeader("node-advanced-settings-collapse", true);
        }
      }

      if (formValues.config && formValues.config.election_network_type) {
        this.setState({
          vrrpstatus: true,
          vrrpTypeSelected: formValues.config.election_network_type,
        });
      }
      if (
        !ClusterViewModel.cluster.nodes ||
        (ClusterViewModel.cluster.nodes &&
          ClusterViewModel.cluster.nodes.length >= 1)
      ) {
        this.setState({
          vrrpstatus: true,
        });
      }
      if (
        ClusterViewModel.cluster.config &&
        !ClusterViewModel.cluster.config.election_network_id
      ) {
        this.setState({
          vrrpstatus: false,
        });
      }

      // Update upgrade config
      if (formValues.upgrade_config && formValues.upgrade_config.after) {
        if (this.state.upgradeAfter != formValues.upgrade_config.after) {
          this.setState({
            upgradeAfter: moment.tz(
              formValues.upgrade_config.after,
              this.timezoneModel.userTimezone ||
                this.timezoneModel.orgTimezone ||
                getDefaultTimezone().value,
            ),
          });
        } else {
          this.setState({
            upgradeAfter: moment.tz(
              new Date().getTime(),
              this.timezoneModel.userTimezone ||
                this.timezoneModel.orgTimezone ||
                getDefaultTimezone().value,
            ),
          });
        }
      }
      // Update cluster high availability
      if (
        formValues.config &&
        formValues.config.high_availability_on_interface_failover
      ) {
        this.setState({
          clusterHighAvailability: formValues.config
            .high_availability_on_interface_failover.enable
            ? true
            : false,
        });
      }
      // Update MultiNIC mode
      if (formValues.config && formValues.config.multinic_mode) {
        this.setState({
          multiNICMode: formValues.config.multinic_mode.enable ? true : false,
        });
      }
    }
  };
  updateHelp = data => {
    this.props.UiStore.SetStoreData("helpData", data);
  };

  resetHelp = () => {
    this.props.UiStore.SetStoreData("helpData", {});
  };

  setClusteredNodes = value => {
    if (value != null) {
      this.props.form.setFields({
        nodes: {
          value: value,
        },
      });
    } else {
      this.props.form.resetFields(["nodes"]);
    }
  };

  validateForm = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        values.config = {};
        // election_network_type
        if (values.election_network_type) {
          values.config.election_network_type = values.election_network_type;
          delete values.election_network_type;
        }
        // election_network_id
        if (values.election_network_id) {
          values.config.election_network_id = values.election_network_id;
          delete values.election_network_id;
        }
        // cluster high availability
        if (
          values.high_availability_on_interface_failover !== undefined ||
          values.high_availability_on_interface_failover !== null
        ) {
          values.config.high_availability_on_interface_failover = {
            enable: values.high_availability_on_interface_failover,
          };
          delete values.high_availability_on_interface_failover;
        }
        // MultiNIC mode
        if (
          values.multinic_mode !== undefined ||
          values.multinic_mode !== null
        ) {
          values.config.multinic_mode = {
            enable: values.multinic_mode,
          };
          delete values.multinic_mode;
        }
        // Don't send empty config
        if (values.config && isEmpty(values.config)) {
          delete values.config;
        }
        if (this.props.match.params.cluster_id) {
          // For update cluster
          this.props.updateCluster(values, this.props.match.params.cluster_id);
        } else {
          // For create cluster
          this.props.createCluster(values);
        }
      }
    });
  };

  handleLabelChange = values => {
    this.props.form.setFields({
      metadata: {
        value: { labels: values },
      },
    });
  };

  submitForm = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        // Check the candidate count
        let candidateCount = 0;
        let maxCandidateCount = getOrgPolicyCounInfo(
          this.orgModel.org,
          "cluster_candidate_count",
        );
        if (values && values.nodes) {
          let candidates = values.nodes.filter(node => {
            return node && node.config && node.config.is_candidate;
          });
          candidateCount = candidates ? candidates.length : 0;
        }
        if (candidateCount > maxCandidateCount) {
          this.props.UiStore.setErrorMessage(
            getCurrentlocaleText("cluster.maximum_limit.candidate.error.text", {
              0: maxCandidateCount,
            }),
          );
        } else {
          if (
            this.props.location &&
            this.props.location.state &&
            this.props.location.state.formValues
          ) {
            // For update cluster
            this.setState({
              removedMasterNodes: [],
              nonCandidateMasterNodes: [],
              resetNodes: [],
              resetNodesToCandidate: [],
            });
            // To show warning messages
            let masterNodeIds = this.getMasterInodeIds(this.state.intialValues);
            if (values.nodes && masterNodeIds && masterNodeIds.length > 0) {
              // Find the removed master nodes if any
              let removedMasterNodes = [];
              masterNodeIds.forEach(nodeId => {
                let masterNode = values.nodes.find(node => {
                  return node && node.node_id === nodeId;
                });
                if (!masterNode) {
                  removedMasterNodes.push(nodeId);
                }
              });
              // Show warning message if master node remove from cluster
              if (removedMasterNodes && removedMasterNodes.length > 0) {
                this.setState({
                  removedMasterNodes: removedMasterNodes,
                });
              } else {
                // Find the non-candidate master nodes if any
                let nonCandidateMasterNodes = [];
                masterNodeIds.forEach(nodeId => {
                  let masterNode = values.nodes.find(node => {
                    return node && node.node_id === nodeId;
                  });
                  if (
                    masterNode &&
                    masterNode.config &&
                    !masterNode.config.is_candidate
                  ) {
                    nonCandidateMasterNodes.push(nodeId);
                  }
                });
                // Show warning message if changing master node as non-candidate from cluster
                if (
                  nonCandidateMasterNodes &&
                  nonCandidateMasterNodes.length > 0
                ) {
                  this.setState({
                    nonCandidateMasterNodes: nonCandidateMasterNodes,
                  });
                } else {
                  this.validateForm();
                }
              }
            } else {
              this.validateForm();
            }
          } else {
            // For create cluster
            this.validateForm();
          }
        }
      }
    });
  };

  /**
   *  Method to get the cluster master node ids from given cluster data
   */
  getMasterInodeIds = clusterData => {
    let masterNodeIds = [];
    if (clusterData && clusterData.status && clusterData.status.nodes) {
      let masterNodes = clusterData.status.nodes.filter(node => {
        return (
          node && node.id && node.state && node.state.toUpperCase() === "MASTER"
        );
      });
      if (masterNodes) {
        masterNodes
          .filter(node => node && node.id)
          .forEach(node => {
            masterNodeIds.push(node.id);
          });
      }
    }
    return masterNodeIds;
  };

  getNodeNameTag = (nodeIds = []) => {
    let clusterData = this.state.intialValues;
    if (nodeIds && nodeIds.length > 0 && clusterData && clusterData.nodes) {
      let nodeNames = [];
      clusterData.nodes.forEach(clusterNode => {
        if (
          clusterNode &&
          clusterNode.node &&
          clusterNode.node.id &&
          nodeIds.includes(clusterNode.node.id)
        ) {
          nodeNames.push(clusterNode.node.name);
        }
      });
      if (nodeNames && nodeNames.length > 0) {
        return nodeNames.map((nodeName, index) => {
          return (
            <Tag key={index} className="tag-iotium-info tag-iotium-ellipse">
              {nodeName}
            </Tag>
          );
        });
      }
    }
  };

  addRemovedMasterNode = () => {
    let removedMasterNodes = this.state.removedMasterNodes;
    let clusterData = this.state.intialValues;
    let masterNodeIds = [];
    let nodes = this.props.form.getFieldValue("nodes");
    if (!nodes) {
      nodes = [];
    }
    if (removedMasterNodes && clusterData && clusterData.nodes) {
      clusterData.nodes.forEach(clusterNode => {
        if (
          clusterNode &&
          clusterNode.node &&
          clusterNode.node.id &&
          removedMasterNodes.includes(clusterNode.node.id)
        ) {
          nodes.push({
            node_id: clusterNode.node.id,
            config: {
              priority: clusterNode.config.priority,
              is_candidate: clusterNode.config.is_candidate,
            },
          });
          masterNodeIds.push(clusterNode.node.id);
        }
      });
      this.setClusteredNodes(nodes);
    }
    this.setState({ removedMasterNodes: [], resetNodes: masterNodeIds });
  };

  makeMasterNodeAsCandidate = () => {
    let nonCandidateMasterNodes = this.state.nonCandidateMasterNodes;
    let nodes = this.props.form.getFieldValue("nodes");
    let masterNodeIds = [];
    if (nonCandidateMasterNodes && nodes) {
      nonCandidateMasterNodes.forEach(masterNodeId => {
        let index = nodes.findIndex(node => {
          return node && node.node_id === masterNodeId;
        });
        if (index !== -1) {
          nodes[index].config.is_candidate = true;
          masterNodeIds.push(nodes[index].node_id);
        }
      });
      this.setClusteredNodes(nodes);
    }
    this.setState({
      nonCandidateMasterNodes: [],
      resetNodesToCandidate: masterNodeIds,
    });
  };
  disabledDate = current => {
    // Can not select days before today and today and after 90 days
    let timezone =
      this.props.TimezoneViewModel.userTimezone ||
      this.props.TimezoneViewModel.orgTimezone ||
      getDefaultTimezone().value;
    let endDate = moment.tz(timezone).add(90, "days");
    return (
      (current && current.valueOf() < moment.tz(timezone).valueOf()) ||
      (current && current.isAfter(endDate))
    );
  };

  /**
   *  CHECK FOR SUPPORTED VERSIONS
   */
  checkForFeatureSupported = feature => {
    switch (feature) {
      case "SOFTWAREUPGRADE":
        if (this.props.AuthStore.IsPermitted("ORG:SUPPORT")) return true;
        else return false;
        break;
    }
  };
  samplingFrequencyOnSelect = value => {
    this.setState({
      samplingFrequencySelected: value,
    });
  };

  upgradeChannelOnSelect = e => {
    let value = e.target.value;
    this.setState({
      upgradeChannelSelected: value,
    });
  };

  vrrpTypeOnSelect = e => {
    let value = e.target.value;
    this.setState({
      vrrpTypeSelected: value,
    });
  };

  changeSoftwareUpdate = () => {
    this.setState({
      softwareUpgrade: !this.state.softwareUpgrade,
      upgradeChannelSelected: "STABLE",
      upgradeAfter: moment.tz(
        new Date().getTime(),
        this.timezoneModel.userTimezone ||
          this.timezoneModel.orgTimezone ||
          getDefaultTimezone().value,
      ),
    });
  };
  /**
   * Updgrade After change event
   */
  onStartAfterChange = (date, dateString) => {
    this.setState({
      upgradeAfter: date,
    });
    this.props.form.setFields({
      upgrade_after: {
        value: date,
      },
    });
  };

  disabledDate = current => {
    // Can not select days before today and today and after 90 days
    let timezone =
      this.props.TimezoneViewModel.userTimezone ||
      this.props.TimezoneViewModel.orgTimezone ||
      getDefaultTimezone().value;
    let endDate = moment.tz(timezone).add(90, "days");
    return (
      (current && current.valueOf() < moment.tz(timezone).valueOf()) ||
      (current && current.isAfter(endDate))
    );
  };

  changeThreatDetection = () => {
    this.setState({
      threatDetection: !this.state.threatDetection,
      samplingFrequencySelected: 50000,
    });
  };

  onTanDadModeChange = value => {
    this.setState({
      tanDadMode: value,
      tanDadDurationValue: {
        duration: 5,
        units: "MINUTES",
      },
    });
  };

  onTanDadModeDurationChange = value => {
    this.props.form.setFields({
      max_tandad_time: {
        value: {
          duration: value.duration,
          units: value.units,
        },
      },
    });
    this.setState({ tanDadDurationValue: value });
  };

  changeClusterHighAvailability = () => {
    this.setState({
      clusterHighAvailability: !this.state.clusterHighAvailability,
    });
  };

  changeMultiNICMode = () => {
    this.setState({
      multiNICMode: !this.state.multiNICMode,
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    let {
      removedMasterNodes,
      nonCandidateMasterNodes,
      orgInitialVal,
    } = this.state;
    let isHWMVisible = checkforFeatureEnabled(orgInitialVal, "hwm");

    return (
      <Card
        title={
          <span className="iot-main-card-title">
            <Icons type="bs" name="BsBoundingBox" />
            <span>
              {this.props.location &&
              this.props.location.state &&
              this.props.location.state.viewOnly
                ? getCurrentlocaleText("view.cluster.text")
                : this.props.match.params.cluster_id &&
                  this.props.match.params.cluster_id != null
                ? getCurrentlocaleText(
                    "cluster.form.useraction_edit.label.text",
                  )
                : getCurrentlocaleText(
                    "cluster.list.create.addbutton.text",
                  )}{" "}
            </span>
          </span>
        }
      >
        <Form
          noValidate
          onSubmit={this.props.onSubmit}
          className="network-form"
        >
          <Row gutter={2} type="flex" align="top">
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 13 }}
              lg={{ span: 13 }}
              xl={{ span: 12 }}
              className="card-grid"
              style={{
                paddingRight: 20,
                borderRight: "solid 1px rgba(0,0,0,0.2)",
              }}
            >
              <FormItem
                label={getCurrentlocaleText("cluster.name.help.title")}
                hasFeedback
                {...formItemLayout}
              >
                {getFieldDecorator("name", {
                  initialValue: this.state.intialValues.name,
                  rules: [
                    {
                      required: true,
                      message: getCurrentlocaleText(
                        "cluster.form.clustername.errormessage1.text",
                      ),
                      whitespace: true,
                    },
                    {
                      max: 255,
                      message: getCurrentlocaleText(
                        "cluster.form.clustername.errormessage2.text",
                      ),
                    },
                    {
                      pattern: /^[A-Za-z0-9 ._-]*$/,
                      message: getCurrentlocaleText(
                        "cluster.form.clustername.errormessage3.text",
                      ),
                    },
                  ],
                })(
                  <Input
                    autoComplete="off"
                    prefix={<Icons type="ai" name="AiOutlineIdcard" />}
                    placeholder={getCurrentlocaleText(
                      "cluster.form.name.placeholder.text",
                    )}
                    className="firstInput"
                    disabled={
                      (this.props.location &&
                        this.props.location.state &&
                        this.props.location.state.viewOnly) ||
                      this.state.isDisabled
                    }
                    title={getCurrentlocaleText(
                      "cluster.form.name.placeholder.text",
                    )}
                    onFocus={this.updateHelp.bind(this, {
                      title: getCurrentlocaleText("cluster.name.help.title"),
                      data: [
                        {
                          subtitle: "",
                          content: getCurrentlocaleText(
                            "cluster.name.help.content",
                          ),
                        },
                      ],
                    })}
                  />,
                )}
              </FormItem>
              <FormItem
                colon={false}
                label={
                  <span
                    className={"iot-label-not-required"}
                    title={getCurrentlocaleText(
                      "general.form.label.text",
                    ).replace(":", " ")}
                  >
                    {getCurrentlocaleText("general.form.label.text")}
                  </span>
                }
                {...formItemLayout}
              >
                {getFieldDecorator("metadata", {
                  initialValue: { labels: this.state.metadata },
                })(
                  <LabelProvider
                    tags={this.state.tags}
                    metadata={this.state.metadata}
                    handleChange={this.handleLabelChange}
                    showPopupLabel={false}
                    isModal={true}
                    viewOnly={
                      this.props.location &&
                      this.props.location.state &&
                      this.props.location.state.viewOnly
                    }
                  />,
                )}
              </FormItem>
            </Col>
            <Col
              xs={{ span: 0 }}
              sm={{ span: 0 }}
              md={{ span: 11 }}
              lg={{ span: 11 }}
              xl={{ span: 12 }}
            >
              <HelpCard
                customStyle={{
                  borderLeft: "none",
                }}
                span={24}
              />
            </Col>
          </Row>

          <Collapse
            className="node-advanced-settings-collapse"
            style={{ marginBottom: 10 }}
          >
            <Panel
              header={
                <div className="panel-header-text" style={{ fontSize: 14 }}>
                  {getCurrentlocaleText("advanced.settings.text")}
                </div>
              }
              key="1"
            >
              <div>
                {this.props.AuthStore.IsPermitted("ORG:SUPPORT") && (
                  <Row>
                    <Col span={24}>
                      <Row>
                        <Col span={8}>
                          <FormItem
                            label={getCurrentlocaleText(
                              "node.software.upgrade.text",
                            )}
                          >
                            {getFieldDecorator("upgrade_policy", {
                              initialValue: this.state.softwareUpgrade,
                            })(
                              <Switch
                                checked={this.state.softwareUpgrade}
                                style={{ marginTop: -5 }}
                                onChange={this.changeSoftwareUpdate}
                                checkedChildren="On"
                                unCheckedChildren="Off"
                                disabled={
                                  this.props.location &&
                                  this.props.location.state &&
                                  this.props.location.state.viewOnly
                                }
                              />,
                            )}
                          </FormItem>
                        </Col>
                        <Col span={8}>
                          {this.state.softwareUpgrade && (
                            <FormItem
                              label={getCurrentlocaleText(
                                "org.form.upgrade.channel.label.text",
                              )}
                              colon={true}
                            >
                              {getFieldDecorator("upgrade_policy_channel", {
                                initialValue: !this.checkForFeatureSupported(
                                  "SOFTWAREUPGRADE",
                                )
                                  ? "STABLE"
                                  : this.state.upgradeChannelSelected,
                              })(
                                <RadioGroup
                                  onChange={this.upgradeChannelOnSelect}
                                  size="small"
                                  disabled={
                                    this.props.location &&
                                    this.props.location.state &&
                                    this.props.location.state.viewOnly
                                  }
                                >
                                  <RadioButton value={"ALPHA"}>
                                    {getCurrentlocaleText(
                                      "org.form.upgrade.channel.alpha.label.text",
                                    )}
                                  </RadioButton>
                                  <RadioButton value={"BETA"}>
                                    {getCurrentlocaleText(
                                      "org.form.upgrade.channel.beta.label.text",
                                    )}
                                  </RadioButton>
                                  <RadioButton value={"STABLE"}>
                                    {getCurrentlocaleText(
                                      "org.form.upgrade.channel.stable.label.text",
                                    )}
                                  </RadioButton>
                                </RadioGroup>,
                              )}
                            </FormItem>
                          )}
                        </Col>{" "}
                        <Col span={8}>
                          {this.state.softwareUpgrade && (
                            <FormItem
                              label={getCurrentlocaleText(
                                "inode.upgrade.after.label.text",
                              )}
                            >
                              {getFieldDecorator("upgrade_after", {
                                initialValue: this.state.upgradeAfter,
                              })(
                                <DatePicker
                                  disabledDate={this.disabledDate}
                                  showTime={{
                                    defaultValue: moment.tz(),
                                  }}
                                  style={{ width: 250 }}
                                  format="YYYY-MM-DD HH:mm:ss"
                                  placeholder="Upgrade After"
                                  onChange={this.onStartAfterChange}
                                  disabled={
                                    this.props.location &&
                                    this.props.location.state &&
                                    this.props.location.state.viewOnly
                                  }
                                />,
                              )}
                            </FormItem>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}

                {this.props.AuthStore.IsPermitted("DISCOVERY:ADMIN") && (
                  <Row>
                    <Col span={24}>
                      <FormItem
                        label={getCurrentlocaleText("device.discovery.text")}
                      >
                        {getFieldDecorator("device_discovery", {
                          initialValue: this.state.deviceDiscovery,
                        })(
                          <>
                            <Switch
                              checked={this.state.deviceDiscovery}
                              onChange={this.changeDeviceDiscovery}
                              checkedChildren="On"
                              unCheckedChildren="Off"
                              disabled={
                                this.props.location &&
                                this.props.location.state &&
                                this.props.location.state.viewOnly
                              }
                            />
                            <div className="beta ml-15 d-inline-block">
                              <Icons
                                type="fa"
                                name="FaRocket"
                                className="beta-icon"
                              />
                              {getCurrentlocaleText("beta.version")}
                            </div>
                          </>,
                        )}
                      </FormItem>
                    </Col>
                  </Row>
                )}

                {isHWMVisible && (
                  <Row>
                    <Col span={8}>
                      <FormItem
                        colon={false}
                        label={
                          <span>
                            {getCurrentlocaleText("cluster.hwm.monitoring") +
                              " :"}{" "}
                            <PopOverHelp
                              isHelpOnForm={true}
                              height={150}
                              width={170}
                              placement="right"
                              helpData={{
                                title: getCurrentlocaleText(
                                  "inode.hwm.help.text",
                                ),
                                data: [
                                  {
                                    subtitle: "",
                                    content: getCurrentlocaleText(
                                      "inode.hwm.help.cluster.message",
                                    ),
                                  },
                                ],
                              }}
                            />
                          </span>
                        }
                      >
                        {getFieldDecorator("inode_hw_monitoring", {
                          initialValue: this.state.HwMonitoring,
                        })(
                          <Switch
                            checked={this.state.HwMonitoring}
                            onChange={this.onHWMModeChange}
                            style={{ marginTop: -5 }}
                            checkedChildren="On"
                            unCheckedChildren="Off"
                            disabled={
                              this.props.location &&
                              this.props.location.state &&
                              this.props.location.state.viewOnly
                            }
                          />,
                        )}
                      </FormItem>
                    </Col>
                    <Col span={8}>
                      {this.state.HwMonitoring && (
                        <FormItem
                          label={getCurrentlocaleText("cluster.hwm.title.text")}
                        >
                          {getFieldDecorator("max_hwm_time", {
                            initialValue: this.state.HWMDurationValue,
                          })(
                            <DurationSelector
                              onChange={this.onHWMDurationChange}
                              minutesDuration={5}
                              minutesMinimum={5}
                              minutesMaximum={1440}
                              minutesStep={1}
                              hourDuration={1}
                              hourMinimum={1}
                              hourMaximum={24}
                              hourStep={1}
                              dayDuration={1}
                              dayMinimum={1}
                              dayMaximum={1}
                              dayStep={1}
                              Val={this.state.HWMDurationValue}
                              disabled={
                                ClusterViewModel.HWMOldVal &&
                                ClusterViewModel.HWMOldVal.Duration
                                  ? true
                                  : false
                              }
                            />,
                          )}
                        </FormItem>
                      )}
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col span={8}>
                    <FormItem
                      colon={false}
                      label={
                        <span>
                          {getCurrentlocaleText(
                            "inode.tan_dad_mode.label.text",
                          ) + " :"}{" "}
                          <PopOverHelp
                            isHelpOnForm={true}
                            height={140}
                            width={300}
                            placement="right"
                            helpData={{
                              title: getCurrentlocaleText(
                                "inode.tan_dad_mode.help.text",
                              ),
                              data: [
                                {
                                  subtitle: "",
                                  content: getCurrentlocaleText(
                                    "inode.tan_dad.help.message",
                                  ),
                                },
                              ],
                            }}
                          />
                        </span>
                      }
                    >
                      {getFieldDecorator("tan_dad_mode", {
                        initialValue: this.state.tanDadMode,
                      })(
                        <span>
                          <Switch
                            style={{ marginTop: -5 }}
                            checkedChildren="Active"
                            unCheckedChildren="Not Active"
                            onChange={this.onTanDadModeChange}
                            checked={this.state.tanDadMode}
                            disabled={
                              this.props.location &&
                              this.props.location.state &&
                              this.props.location.state.viewOnly
                            }
                          />
                        </span>,
                      )}
                    </FormItem>
                  </Col>
                  <Col span={8}>
                    {this.state.tanDadMode && (
                      <FormItem
                        label={getCurrentlocaleText("inode.tan_dad.title.text")}
                      >
                        {getFieldDecorator("max_tandad_time", {
                          initialValue: this.state.tanDadDurationValue,
                        })(
                          <DurationSelector
                            onChange={this.onTanDadModeDurationChange}
                            minutesDuration={5}
                            minutesMinimum={5}
                            minutesMaximum={1440}
                            minutesStep={1}
                            hourDuration={1}
                            hourMinimum={1}
                            hourMaximum={24}
                            hourStep={1}
                            dayDuration={1}
                            dayMinimum={1}
                            dayMaximum={1}
                            dayStep={1}
                            disabled={
                              this.props.location &&
                              this.props.location.state &&
                              this.props.location.state.viewOnly
                            }
                            Val={this.state.tanDadDurationValue}
                          />,
                        )}
                      </FormItem>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <FormItem
                      label={
                        <span>
                          {getCurrentlocaleText("vrrp.select.label") + " :"}{" "}
                          <PopOverHelp
                            isHelpOnForm={true}
                            height={150}
                            width={230}
                            placement="right"
                            helpData={{
                              title: getCurrentlocaleText(
                                "vrrp.select.cluster.label",
                              ),
                              data: [
                                {
                                  subtitle: "",
                                  content: getCurrentlocaleText(
                                    "vrrp.select.cluster.message",
                                  ),
                                },
                              ],
                            }}
                          />
                        </span>
                      }
                      colon={true}
                    >
                      {getFieldDecorator("election_network_type", {
                        initialValue: this.state.vrrpTypeSelected,
                      })(
                        <RadioGroup
                          onChange={this.vrrpTypeOnSelect}
                          size="small"
                          disabled={
                            this.state.vrrpstatus ||
                            (this.props.location &&
                              this.props.location.state &&
                              this.props.location.state.viewOnly)
                          }
                        >
                          <RadioButton value={"WAN"}>WAN</RadioButton>
                          <RadioButton value={"TAN"}>TAN</RadioButton>
                        </RadioGroup>,
                      )}
                    </FormItem>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Row>
                      <Col span={24}>
                        <FormItem
                          label={getCurrentlocaleText("threat.detection.text")}
                        >
                          {getFieldDecorator("ctd_threat_detection", {
                            initialValue: this.state.threatDetection,
                          })(
                            <Switch
                              checked={this.state.threatDetection}
                              style={{ marginTop: -5 }}
                              onChange={this.changeThreatDetection}
                              checkedChildren="On"
                              unCheckedChildren="Off"
                              disabled={
                                this.props.location &&
                                this.props.location.state &&
                                this.props.location.state.viewOnly
                              }
                            />,
                          )}
                        </FormItem>
                      </Col>
                      <Col span={14}>
                        {this.state.threatDetection && (
                          <FormItem
                            colon={true}
                            label={
                              <span>
                                {getCurrentlocaleText(
                                  "threat.detection.sampling.text",
                                )}
                                <PopOverHelp
                                  isHelpOnForm={true}
                                  height={50}
                                  width={235}
                                  placement={"rightTop"}
                                  helpData={{
                                    data: [
                                      {
                                        subtitle: "",
                                        content: getCurrentlocaleText(
                                          "threat.detection.text.desc",
                                        ),
                                      },
                                    ],
                                  }}
                                />
                              </span>
                            }
                          >
                            {getFieldDecorator("ctd_sampling_frequency", {
                              rules: [
                                {
                                  required: true,
                                  message: getCurrentlocaleText(
                                    "threat.detection.sampling.message",
                                  ),
                                },
                              ],
                              initialValue: this.state
                                .samplingFrequencySelected,
                            })(
                              <div className="pl-15">
                                <Slider
                                  min={5000}
                                  max={100000}
                                  step={5000}
                                  tooltipVisible={false}
                                  marks={getSamplingFreqMarks()}
                                  defaultValue={
                                    this.state.samplingFrequencySelected
                                  }
                                  onChange={this.samplingFrequencyOnSelect}
                                />
                                <div className="">
                                  {getCurrentlocaleText("cluster.form.text1")}{" "}
                                  <b className="px-5">
                                    {this.state.samplingFrequencySelected}
                                  </b>{" "}
                                  {getCurrentlocaleText("cluster.form.text2")}
                                </div>
                                <div
                                  className="text-muted"
                                  style={{ lineHeight: "10px" }}
                                >
                                  <small>
                                    {getCurrentlocaleText(
                                      "threat.detection.sampling.note",
                                    )}
                                  </small>
                                </div>
                              </div>,
                            )}
                          </FormItem>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <FormItem
                      label={
                        <span>
                          {getCurrentlocaleText(
                            "cluster.high.availability.text",
                          ) + " :"}{" "}
                          <PopOverHelp
                            isHelpOnForm={true}
                            height={110}
                            width={230}
                            placement="right"
                            helpData={{
                              data: [
                                {
                                  subtitle: "",
                                  content: getCurrentlocaleText(
                                    "cluster.high.availability.help.text",
                                  ),
                                },
                              ],
                            }}
                          />
                        </span>
                      }
                      colon={true}
                    >
                      {getFieldDecorator(
                        "high_availability_on_interface_failover",
                        {
                          initialValue: this.state.clusterHighAvailability,
                        },
                      )(
                        <Switch
                          checked={this.state.clusterHighAvailability}
                          style={{ marginTop: -5 }}
                          onChange={this.changeClusterHighAvailability}
                          checkedChildren="On"
                          unCheckedChildren="Off"
                          disabled={
                            this.props.location &&
                            this.props.location.state &&
                            this.props.location.state.viewOnly
                          }
                        />,
                      )}
                    </FormItem>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <FormItem
                      label={
                        <span>
                          {getCurrentlocaleText("multinic.mode.text") + " :"}{" "}
                          <PopOverHelp
                            isHelpOnForm={true}
                            height={210}
                            width={230}
                            placement="right"
                            helpData={{
                              data: [
                                {
                                  subtitle: "",
                                  content: getCurrentlocaleText(
                                    "multinic.mode.cluster.help.text",
                                  ),
                                },
                                {
                                  subtitle: "",
                                  content: getCurrentlocaleText(
                                    "multinic.mode.cluster.network.update.help.text",
                                  ),
                                },
                              ],
                            }}
                          />
                        </span>
                      }
                      colon={true}
                    >
                      {getFieldDecorator("multinic_mode", {
                        initialValue: this.state.multiNICMode,
                      })(
                        <Switch
                          checked={this.state.multiNICMode}
                          style={{ marginTop: -5 }}
                          onChange={this.changeMultiNICMode}
                          checkedChildren="On"
                          unCheckedChildren="Off"
                          disabled={
                            this.props.location &&
                            this.props.location.state &&
                            this.props.location.state.viewOnly
                          }
                        />,
                      )}
                    </FormItem>
                  </Col>
                </Row>
              </div>
            </Panel>
          </Collapse>

          <Row gutter={2} type="flex" align="top" style={{ paddingTop: 15 }}>
            <Col span={24}>
              <FormItem>
                {getFieldDecorator("nodes", {
                  rules: [{ required: false }],
                })(
                  <ClusterNodeConfigSelector
                    hwmEnabled={this.state.HwMonitoring}
                    setClusteredNodes={this.setClusteredNodes}
                    {...this.props}
                    intialValues={this.state.intialValues}
                    viewOnly={
                      this.props.location &&
                      this.props.location.state &&
                      this.props.location.state.viewOnly
                    }
                    addNodes={this.state.resetNodes}
                    makeCandiateNodes={this.state.resetNodesToCandidate}
                  />,
                )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col span={24} className="col-center">
              <div style={{ paddingTop: 15 }}>
                <Button
                  onClick={this.props.onCancel}
                  style={{ marginRight: 10 }}
                  key="cancel"
                >
                  {getCurrentlocaleText("network.form.button.cancel.text")}
                </Button>
                {!(
                  this.props.location &&
                  this.props.location.state &&
                  this.props.location.state.viewOnly
                ) && (
                  <Button
                    type="primary"
                    onSubmit={this.submitForm}
                    onClick={this.submitForm}
                  >
                    <Icons
                      type="ai"
                      name="AiOutlineSave"
                      style={{ margin: 0 }}
                    />
                    {this.props.match && this.props.match.params.cluster_id
                      ? getCurrentlocaleText("network.form.button.update.text")
                      : getCurrentlocaleText("network.form.button.submit.text")}
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </Form>
        <div>
          {/* Model for Cluster Master Remove Warning*/}
          {removedMasterNodes && removedMasterNodes.length > 0 && (
            <Modal
              title={getCurrentlocaleText(
                "cluster.form.masterRemove.warning.title",
              )}
              visible={true}
              closable={false}
              maskClosable={false}
              footer={[
                <Button key="cancel" onClick={this.addRemovedMasterNode}>
                  {getCurrentlocaleText(
                    "cluster.form.masterRemove.warning.cancel.text",
                  )}
                </Button>,
                <Button key="ok" type="primary" onClick={this.validateForm}>
                  {getCurrentlocaleText(
                    "cluster.form.masterRemove.warning.ok.text",
                  )}
                </Button>,
              ]}
              width={600}
            >
              <div>
                <h4>
                  {getCurrentlocaleText(
                    "cluster.form.masterRemove.warning.message",
                  )}
                </h4>
                <div>{this.getNodeNameTag(this.state.removedMasterNodes)}</div>
              </div>
            </Modal>
          )}
          {/* Model for Cluster Master Candidate Change Warning*/}
          {nonCandidateMasterNodes && nonCandidateMasterNodes.length > 0 && (
            <Modal
              title={getCurrentlocaleText(
                "cluster.form.masterNonCandidate.warning.title",
              )}
              visible={true}
              closable={false}
              maskClosable={false}
              footer={[
                <Button key="cancel" onClick={this.makeMasterNodeAsCandidate}>
                  {getCurrentlocaleText(
                    "cluster.form.masterNonCandidate.warning.cancel.text",
                  )}
                </Button>,
                <Button key="ok" type="primary" onClick={this.validateForm}>
                  {getCurrentlocaleText(
                    "cluster.form.masterNonCandidate.warning.ok.text",
                  )}
                </Button>,
              ]}
              width={600}
            >
              <div>
                <h4>
                  {getCurrentlocaleText(
                    "cluster.form.masterNonCandidate.warning.message",
                  )}
                </h4>
                <div>
                  {this.getNodeNameTag(this.state.nonCandidateMasterNodes)}
                </div>
              </div>
            </Modal>
          )}
        </div>
      </Card>
    );
  }
}
const ClusterForm = Form.create()(ClusterFormPage);
export default ClusterForm;
