import React, { Component } from "react";
//Logo
import LogoWithText from "assets/img/neeve-light-logo.png";

const style = {
  maxWidth: 120,
  maxHeight: 40,
  minWidth: 60,
  minHeight: 20,
  fill: "white",
};

class LightLogo extends Component {
  constructor(props) {
    super(props);
    // GLOBAL_SETTINGS configured from the node js
    this.GLOBAL_SETTINGS = GLOBAL_SETTINGS;
  }

  render() {
    return (
      <span>
        {this.GLOBAL_SETTINGS ? (
          (!this.GLOBAL_SETTINGS.is_default &&
            this.GLOBAL_SETTINGS.sidebar_mode &&
            this.GLOBAL_SETTINGS.sidebar_mode === "dark" &&
            this.GLOBAL_SETTINGS.light_logo_url) ||
          this.GLOBAL_SETTINGS.dark_logo_url ? (
            <img
              src={
                this.GLOBAL_SETTINGS.sidebar_mode === "dark"
                  ? this.GLOBAL_SETTINGS.light_logo_url
                  : this.GLOBAL_SETTINGS.dark_logo_url
              }
              style={style}
              className="App-logo"
            />
          ) : this.GLOBAL_SETTINGS &&
            this.GLOBAL_SETTINGS.sidebar_mode &&
            (this.GLOBAL_SETTINGS.light_logo_url ||
              this.GLOBAL_SETTINGS.dark_logo_url) ? (
            <a
              href="https://neeve.ai"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img
                src={
                  this.GLOBAL_SETTINGS.sidebar_mode === "dark"
                    ? this.GLOBAL_SETTINGS.light_logo_url
                    : this.GLOBAL_SETTINGS.dark_logo_url
                }
                style={style}
                className="App-logo"
                alt="neeve.ai"
                title="neeve.ai"
              />
            </a>
          ) : (
            <a
              href="https://neeve.ai"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img
                src={LogoWithText}
                style={style}
                className="App-logo"
                alt="neeve.ai"
                title="neeve.ai"
              />
            </a>
          )
        ) : (
          <a href="https://neeve.ai" rel="noopener noreferrer" target="_blank">
            <img
              src={LogoWithText}
              style={style}
              className="App-logo"
              alt="neeve.ai"
              title="neeve.ai"
            />
          </a>
        )}
      </span>
    );
  }
}

export default LightLogo;
