import React, { Component } from "react";
import { getCurrentlocaleText } from "Core/Utils";
import Icons from "../UI-Components/Icons";
import { Modal, Button, Alert } from "antd";

class RebootMultiNic extends Component {
  render() {
    return (
      <div>
        <Modal
          title={
            <div>
              <span>
                <Icons
                  type="go"
                  name="GoAlert"
                  className="iotium-warning"
                  style={{ fontSize: 20, marginRight: 5 }}
                />
              </span>
              <h3 className="d-inline-block">
                {getCurrentlocaleText("reboot.form.title")}
              </h3>
            </div>
          }
          visible={this.props.visible}
          width="30%"
          maskClosable={false}
          closable={true}
          footer={[
            <Button key="cancel" onClick={this.props.closeModal}>
              {getCurrentlocaleText("cancel.button.text")}
            </Button>,
            <Button
              key="confirm"
              type="primary"
              onClick={this.props.handleConfirm}
            >
              {getCurrentlocaleText("general.button.confirm.label.text")}
            </Button>,
          ]}
        >
          <div>
            {this.props.type === "multiNic" ? (
              <Alert
                description={getCurrentlocaleText(
                  "reboot.node.confirmation.multinic.message",
                )}
                type="warning"
                showIcon
              />
            ) : (
              <Alert
                description={getCurrentlocaleText(
                  "reboot.node.confirmation.interface.message",
                )}
                type="warning"
                showIcon
              />
            )}
          </div>
        </Modal>
      </div>
    );
  }
}
export default RebootMultiNic;
