import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Table, Button, Row, Col, Collapse, Spin, Select } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Form } from "@ant-design/compatible";
import Input from "components/UI-Components/InputBox";
import { getCurrentlocaleText } from "Core/Utils";
import Icons from "../UI-Components/Icons";

const FormItem = Form.Item;
const Panel = Collapse.Panel;
const Option = Select.Option;

@inject("UiStore")
@observer
class ServicePortConfiguration extends Component {
  constructor(props) {
    super(props);
    this.formErrorResponse = {};
    this.state = {
      service_ports: [],
      options: [],
      activeKey: [],
      count: 0,
      loading: false,
      viewOnly: false,
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    let ports = [];
    props.servicePorts.forEach((port, i) => {
      let key = i;
      let item = {
        key: key,
        protocol: port.protocol ? port.protocol : "TCP",
        port: port.port,
        node_port: port.node_port,
      };
      ports.push(item);
    });
    this.setState({
      service_ports: ports,
      count: ports.length,
      viewOnly: props.viewOnly,
    });
  }
  UNSAFE_componentWillMount() {
    let ports = [];
    this.props.servicePorts.forEach((port, i) => {
      let key = i;
      let item = {
        key: key,
        protocol: port.protocol ? port.protocol : "TCP",
        port: port.port,
        node_port: port.node_port,
      };
      ports.push(item);
    });
    this.setState({
      service_ports: ports,
      count: ports.length,
      viewOnly: this.props.viewOnly,
    });
  }

  updateHelp = data => {
    this.props.UiStore.SetStoreData("helpData", data);
  };

  handleOnChangeCollapse = key => {
    this.setState({ activeKey: key });
    if (key && key.length) {
      this.setState({ loading: true });
      setTimeout(() => {
        this.setState({ loading: false });
      }, 200);
    }
  };

  pushData = ports => {
    if (this.props.setServicePorts) {
      let finalPorts = [];
      ports.forEach(port => {
        if (port.port || port.protocol || port.node_port) {
          let portFinal = {};
          portFinal.protocol = port.protocol;
          portFinal.node_port = port.node_port;
          portFinal.port = port.port;
          finalPorts.push(portFinal);
        }
      });
      this.props.setServicePorts(finalPorts);
    }
  };

  getServicePortsCount = () => {
    return this.state.service_ports.filter(val => {
      return val.isdeleted === false;
    }).length;
  };

  protocolOnSelect = (value, option) => {
    this.setValue(option.props.rowId, "protocol", value);
  };
  portOnChange = (record, e) => {
    this.setValue(record.key, "port", e.target.value);
  };
  checkNodePortRange = (rule, value, callback) => {
    let pattern1 = /^[0-9]{1,5}$/g;
    let message;
    if (value) {
      if (pattern1.test(value)) {
        if (parseInt(value) >= 1024 && parseInt(value) <= 32767) {
          //Valid Node port
        } else {
          message = getCurrentlocaleText(
            "service_listener.service_ports.node_port.error",
          );
        }
      } else {
        message = getCurrentlocaleText(
          "service_listener.service_ports.node_port.error",
        );
      }
    } else {
      message = getCurrentlocaleText(
        "service_listener.service_ports.node_port.must.error",
      );
    }

    if (message) {
      callback(message);
    }
    callback();
  };
  checkPortRange = (rule, value, callback) => {
    let pattern1 = /^[0-9]{1,5}$/g;
    let message = null;
    if (value) {
      if (pattern1.test(value)) {
        if (parseInt(value) >= 0 && parseInt(value) <= 65535) {
          //Valid port
        } else {
          message = getCurrentlocaleText(
            "service_listener.service_ports.service_port.error",
          );
        }
      } else {
        message = getCurrentlocaleText(
          "service_listener.service_ports.service_port.error",
        );
      }
    } else {
      message = getCurrentlocaleText(
        "service_listener.service_ports.service_port.must.error",
      );
    }
    if (message) {
      callback(message);
    }
    callback();
  };
  nodePortOnChange = (record, e) => {
    this.setValue(record.key, "node_port", e.target.value);
  };

  setValue = (key, property, value) => {
    let ports = this.state.service_ports;
    let index = this.state.service_ports.findIndex(val => {
      return val.key === key;
    });
    ports[index][property] = value;
    this.setState({
      service_ports: ports,
    });
    this.pushData(ports);
  };

  addItem = () => {
    let key = this.state.service_ports.length + 1;
    let newItem = {
      key: key,
      protocol: "TCP",
      port: null,
      node_port: null,
      isdeleted: false,
    };
    let ports = this.state.service_ports;
    ports.push(newItem);
    this.setState({
      service_ports: ports,
    });
    this.pushData(ports);
    this.setState({ count: this.getServicePortsCount() });
    // this timeout event is to make the last added table row  into view
    setTimeout(() => {
      if (document.querySelector(".service_port-" + key))
        document.querySelector(".service_port-" + key).scrollIntoView();
    }, 300);
  };
  removeItem = record => {
    let ports = this.state.service_ports;

    let index = ports.findIndex(val => {
      return val.key === record.key;
    });

    this.setState({
      service_ports: ports,
    });

    ports = ports.filter((val, i) => {
      return i != index;
    });
    let portsAfterRemoved = [];
    ports.forEach(port => {
      if (!port.isdeleted) {
        portsAfterRemoved.push(port);
      }
    });
    this.pushData(portsAfterRemoved);
    this.setState({ count: this.getServicePortsCount() });
  };
  getServicePortsCount = () => {
    return this.state.service_ports.filter(val => {
      return val.isdeleted === false;
    }).length;
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    this.columns = [
      {
        title: <span>Protocol</span>,
        dataIndex: "protocol",
        width: "8%",
        render: (text, record) => {
          return (
            <FormItem style={{ marginTop: "10px" }}>
              {getFieldDecorator(`protocol-${record.key}`, {
                rules: [
                  {
                    message: "Please select protocol.",
                    whitespace: true,
                    required: true,
                  },
                ],
                initialValue: record.protocol ? record.protocol : "TCP",
              })(
                <Select
                  disabled={this.props.viewOnly}
                  placeholder="Select protocol"
                  onSelect={this.protocolOnSelect.bind()}
                  notFoundContent={getCurrentlocaleText(
                    "general.notfound.placeholder.text",
                  )}
                  onFocus={this.updateHelp.bind(this, {
                    title: getCurrentlocaleText(
                      "service_listener.ports.protocol.help.title",
                    ),
                    data: [
                      {
                        subtitle: "",
                        content: getCurrentlocaleText(
                          "service_listener.ports.protocol.help.message",
                        ),
                      },
                    ],
                  })}
                >
                  <Option value="TCP" rowId={record.key}>
                    {getCurrentlocaleText("tcp.text")}
                  </Option>
                  <Option value="UDP" rowId={record.key}>
                    {getCurrentlocaleText("udp.text")}
                  </Option>
                </Select>,
              )}
            </FormItem>
          );
        },
      },
      {
        title: <span style={{ wordBreak: "keep-all" }}>{"Node Port"}</span>,
        dataIndex: "node_port",
        width: "6%",
        render: (text, record) => {
          return (
            <FormItem style={{ marginTop: "10px" }}>
              {getFieldDecorator(`node_port-${record.key}`, {
                rules: [{ required: true, validator: this.checkNodePortRange }],
                initialValue: record.node_port ? record.node_port : undefined,
              })(
                <Input
                  onChange={this.nodePortOnChange.bind(this, record)}
                  className={`node_port-${record.key}`}
                  autoComplete="off"
                  placeholder="Port"
                  disabled={this.state.viewOnly}
                  onFocus={this.updateHelp.bind(this, {
                    title: getCurrentlocaleText(
                      "service_listener.ports.node_port.help.title",
                    ),
                    data: [
                      {
                        subtitle: "",
                        content: getCurrentlocaleText(
                          "service_listener.ports.node_port.help.message",
                        ),
                      },
                    ],
                  })}
                />,
              )}
            </FormItem>
          );
        },
      },
      {
        title: <span style={{ wordBreak: "break-word" }}>{"Port"}</span>,
        dataIndex: "port",
        width: "6%",
        render: (text, record) => {
          return (
            <FormItem style={{ marginTop: "10px" }}>
              {getFieldDecorator(`port-${record.key}`, {
                rules: [{ required: true, validator: this.checkPortRange }],
                initialValue: record.port ? record.port : undefined,
              })(
                <Input
                  onChange={this.portOnChange.bind(this, record)}
                  autoComplete="off"
                  placeholder="Port"
                  disabled={this.props.viewOnly}
                  onFocus={this.updateHelp.bind(this, {
                    title: getCurrentlocaleText(
                      "service_listener.ports.port.help.title",
                    ),
                    data: [
                      {
                        subtitle: "",
                        content: getCurrentlocaleText(
                          "service_listener.ports.port.help.message",
                        ),
                      },
                    ],
                  })}
                />,
              )}
            </FormItem>
          );
        },
      },
      {
        title: "",
        dataIndex: "",
        width: "1%",
        render: (text, record) => {
          return (
            <FormItem style={{ marginTop: "10px" }}>
              {getFieldDecorator(`isdeleted-${record.key}`, {
                initialValue: record.isdeleted,
              })(
                <Button
                  icon={<Icons type="ai" name="AiOutlineMinus" />}
                  shape={"circle"}
                  onClick={this.removeItem.bind(this, record)}
                  title={"Remove"}
                  className="all-center"
                  disabled={this.state.count === 1 || this.state.viewOnly}
                />,
              )}
            </FormItem>
          );
        },
      },
    ];

    let content = (
      <Row gutter={2}>
        <Col>
          {!this.state.viewOnly && (
            <div style={{ textAlign: "right" }}>
              <Button
                style={{
                  marginRight: 3,
                  marginBottom: 16,
                }}
                icon={<PlusOutlined />}
                onClick={this.addItem.bind()}
                title={getCurrentlocaleText(
                  "service_listener.add.text.button.label",
                )}
                disabled={this.state.viewOnly ? true : false}
              >
                {getCurrentlocaleText("service_listener.add.text.button.label")}
              </Button>
            </div>
          )}
          <Table
            disabled={this.props.viewOnly ? true : false}
            className="servicePorts-Form-Table scroll-auto"
            columns={this.columns}
            dataSource={this.state.service_ports}
            size="small"
            pagination={false}
            scroll={{ y: 240 }}
          />
        </Col>
      </Row>
    );

    return (
      <div>
        {this.props.isCollapsible ? (
          <Collapse
            onChange={this.handleOnChangeCollapse}
            className="servicePorts-config-collapse"
            activeKey={["1"]}
            defaultActiveKey={["1"]}
          >
            <Panel
              key="1"
              header={
                <div className="panel-header-text">
                  {getCurrentlocaleText("service_listener.service_ports.text")}
                </div>
              }
            >
              {this.state.loading ? (
                <div style={{ textAlign: "center" }}>
                  <Spin tip="Loading..." />
                </div>
              ) : (
                <div>{content}</div>
              )}
            </Panel>
          </Collapse>
        ) : (
          content
        )}
      </div>
    );
  }
}

const ServicePortForm = Form.create()(ServicePortConfiguration);
export default ServicePortForm;
