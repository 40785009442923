import { getNodeVersion } from "../../Core/Utils";
class NetworkContainer {
  //pushLocalRepnetwork
  pushLocalRepnetwork = (connectedNetworks, localRepnetwork) => {
    let updatedconnetedNetworks = [];
    connectedNetworks.filter(collections => {
      let network = {
        node_id: collections.node_id,
        network_id: collections.network_id,
        local_representation_network: localRepnetwork,
        peer_representation_network: collections.peer_representation_network,
      };
      updatedconnetedNetworks.push(network);
    });
    return updatedconnetedNetworks;
  };
  /**
   *
   * Check for connection time
   */
  checkforConnectionTime(networks) {
    let status = false;
    if (networks) {
      networks.forEach(n => {
        if (!n.connected_at) {
          status = true;
          return true;
        } else if (
          parseInt(new Date(n.connected_at).getTime()) <= 1525864948000
        ) {
          // converting ISO date string to epoch timestamp
          //1525864948000 this value is a timestamp to detect the earlier versiom. Exact timestamp value is Wednesday, 9 May 2018 11:22:28
          status = true;
          return true;
        } else {
          status = false;
          return false;
        }
      });
    }
    return status;
  }
  /**
   *  check for /30 for the current node
   */
  checkForviNetchange(values, NetworksConnectedwithRemote, node) {
    let baseVersion = 1135,
      statusCheck = parseFloat(getNodeVersion(node)) >= baseVersion,
      timeStampCheck = this.checkforConnectionTime(NetworksConnectedwithRemote);
    if (statusCheck && timeStampCheck) {
      return true;
    } else {
      return false;
    }
  }
  cameFromOrgDetailPage(node_id) {
    if (node_id) {
      return true;
    }
    return false;
  }

  cameFromDashboardPage(path) {
    if (path.indexOf("/dashboard/") !== -1) {
      return true;
    }
    return false;
  }

  dashboardiNodeURL(path) {
    return path.substring(1, path.lastIndexOf("/"));
  }

  linkNameForiNode(path) {
    if (path.indexOf("/dashboard/inodes/status") !== -1) {
      return "Nodes Status";
    } else if (path.indexOf("/dashboard/inodes/profile") !== -1) {
      return "Nodes Profile";
    } else if (path.indexOf("/dashboard/inodes") !== -1) {
      return "Nodes";
    } else if (path.indexOf("/dashboard/network") !== -1) {
      return "Networks";
    } else if (path.indexOf("/dashboard/service") !== -1) {
      return "Services";
    } else if (path.indexOf("/dashboard/listeners") !== -1) {
      return "Listeners";
    } else {
      return "Nodes";
    }
  }
  getNetwork = (networkId, networks) => {
    if (networkId) {
      let network = null;
      networks.networks.forEach(n => {
        if (n.id === networkId) {
          network = n;
        }
      });
      return network;
    }
  };
  /**
   * Filter Local Network for network object
   */
  filterLocalNetwork = (networks, network_id) => {
    let localNetworks = [];
    if (networks.length > 0) {
      networks.forEach(n => {
        if (n.id === network_id) {
          localNetworks.push(n);
        }
      });
    }
    return localNetworks;
  };
  isItCidrChange = (formData, inodeModelData, NetworkModelData, networkId) => {
    //if its node in new state return false.
    if (inodeModelData.inode && inodeModelData.inode.node_state === "NEW") {
      return false;
    }

    let networks = NetworkModelData.networks
        ? NetworkModelData.networks
        : undefined,
      network = {};
    if (networks) {
      network = this.filterLocalNetwork(networks, networkId);
      network =
        network[0] && network[0].config ? network[0].config.network : undefined;
    }
    // check the cidr value from form to existing network data
    if (!network) {
      return false;
    }

    if (network.cidr !== formData.cidr) {
      // isCidrChanged
      return true;
    }

    if (formData.reserved && network.reserved && network.reserved) {
      let reserved = network.reserved;
      if (
        reserved[0].start !== formData.reserved.start ||
        reserved[0].end !== formData.reserved.end
      ) {
        return true;
      }
    }
    return false;
  };

  /**
   * Check for First Character
   */
  checkForAphanumericValue = value => {
    var regex = /^[a-z0-9]+$/i;
    if (!regex.test(value)) {
      return false;
    } else {
      return true;
    }
  };
  checForAllowedValue = value => {
    var regex = /^[-A-Za-z0-9_.]+$/i;
    if (!regex.test(value)) {
      return false;
    } else {
      return true;
    }
  };

  /**
   * Check for securitypolicy Lable
   */
  checkForValidLabel = value => {
    if (value && value.length > 0) {
      let data = value ? value.split(":") : [];

      if (data.length <= 1) {
        return false;
      }
      if (data.length > 2) {
        return false;
      }
      if (data.length === 2) {
        if (data[0].length > 63 || data[1].length > 63) return false;
      }
      if (
        !this.checkForAphanumericValue(
          data[0] && String(data[0].trim()).substring(0, 1),
        ) ||
        (!this.checkForAphanumericValue(
          data[1] && String(data[1].trim()).substring(0, 1),
        ) &&
          String(data[1].trim()).length >= 1)
      ) {
        return false;
      }
      if (
        !this.checkForAphanumericValue(
          data[0] &&
            String(data[0].trim()).substring(
              data[0].trim().length - 1,
              data[0].trim().length,
            ),
        ) ||
        (!this.checkForAphanumericValue(
          data[1] &&
            String(data[1].trim()).substring(
              data[1].trim().length - 1,
              data[1].trim().length,
            ),
        ) &&
          String(data[1].trim()).length >= 1)
      ) {
        return false;
      }
      if (
        data.length <= 2 &&
        !data[0].trim().includes("_iotium.firewall.name") &&
        data[0].trim().includes("_iotium.")
      ) {
        return false;
      }

      if (
        !this.checForAllowedValue(data[0].trim()) ||
        (!this.checForAllowedValue(data[1].trim()) &&
          String(data[1].trim()).length >= 1)
      ) {
        return false;
      }
      if (
        data.length <= 2 &&
        !data[0].includes("_iotium.firewall.name") &&
        data[0].charAt(0) === "_"
      ) {
        return false;
      } else return true;
    } else {
      false;
    }
  };
  /**
   * Check for iNode version to enable and disable firewall
   */
  checkforiNodeVersion = node_data => {
    // firewall base version
    let baseVersion = 1165,
      versionCheck = parseFloat(getNodeVersion(node_data)) >= baseVersion;
    if (versionCheck) {
      return true;
    } else {
      return false;
    }
  };
  /**
   * Check for multiple values
   */
  checkForMultiplePolicies(security_policy = [], security_groups = []) {
    if (security_policy.length > 0 && security_groups.length > 0) {
      let count = security_groups.filter(groups => {
        // check for duplicates when secuirty policy name is repeated.
        if (
          groups === security_policy[0] &&
          security_policy[0].includes("_iotium.firewall.name")
        )
          return groups;
      }).length;

      if (count > 1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
}
export default new NetworkContainer();
